<template>
  <div :class="className"><span v-show="statsLike > 0">{{ statsLike}} like<span v-show="statsLike > 1">s</span></span> <span v-show="statsComment > 0" @click="onHandle(id, pageType)"><span v-show="statsLike > 0 && statsComment > 0">•</span> {{ statsComment}} comment<span v-show="statsComment > 1">s</span></span></div>
</template>
<script>
export default {
  props: {
    id: {
      required: false,
      type: String
    },
    pageType: {
      required: false,
      type: String
    },
    statsLike: {
      required: false,
      type: Number
    },
    statsComment: {
      required: false,
      type: Number
    },
    className: {
       required: false,
      type: String
    }
  },
  methods: {
    onHandle(id, pageType) {
       const params = {
        id: id,
        pageType: pageType
      }
      this.$store.dispatch("comment/ACTION_GET_COMMENT", params);
    }
  }
};
</script>

