import gql from "../../utils/apollo-client";
import gqlQuery from "../../_gql/query/index.js";
import mutation from "../../_gql/mutation/index.js";
import _ from "lodash";

function initialState() {
  return {
    data: [],
    reload: false,
    reloadCreators: false,
  }
}

function fetchRecs(commit, params, mutationType) {
  return new Promise((resolve, reject) => {
    commit("STATUS_REQUEST");
    const meUserId = localStorage.getItem("meId");
    params.filterType = params.where.filterType
    if (params.where.friendId != null && params.where.friendId == "me") {
      params.IDFriend = meUserId;
      params.where.friendId = meUserId;
    } else if (params.where.friendId != null) {
      params.IDFriend = params.where.friendId;
    }
    gql
      .mutate({
        variables: params,
        mutation: gqlQuery.recs
      })
      .then(res => {
        commit("STATUS_SUCCESS");
        commit(mutationType, res.data.recs)
        resolve(res.data);
      })
      .catch(error => {
        commit("STATUS_ERROR", error);
        resolve(error);
      });
  });
}

function fetchCreators(commit, params, mutationType) {
  return new Promise((resolve, reject) => {
    commit("STATUS_REQUEST");
    gql
      .mutate({
        variables: {
          cityId: params.cityId
        },
        mutation: gqlQuery.creatorSuggestion
      })
      .then(({ data }) => {
        commit("STATUS_SUCCESS");
        commit(mutationType, data.creatorSuggestion);
        resolve(data.creatorSuggestion);
      })
      .catch(error => {
        commit("STATUS_ERROR", error);
        resolve(error);
      });
  });
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: (state, getters, rootState) => {
      return _.forEach(state.data, function(item, key) {
        item.isActiveBookmark = item.bookmarkStatus; // Add field active isActiveBookmark
      });
    },
    reload: state => state.reload,
    reloadCreators: state => state.reloadCreators,
  },

  actions: {
    ACTION_FETCH_REC({ commit }, params) {
      return fetchRecs(commit, params, "MUTATION_FETCH_REC");
    },
    ACTION_FETCH_MORE_RECS({ commit }, params) {
      return fetchRecs(commit, params, "MUTATION_FETCH_MORE_RECS");
    },
    ACTION_RESET_STATE({ commit }) {
      commit("MUTATION_RESET_STATE");
    },
    ACTION_TAG_HIT({ commit }, param) {
      return gql.mutate({
        variables: param,
        mutation: mutation.updateTagPopularity
      });
    },
    ACTION_FETCH_CREATORS({ commit }, params) {
      return fetchCreators(commit, params, "MUTATION_FETCH_CREATORS");
    }
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
    },
    STATUS_SUCCESS(state) {
      state = Object.assign(state, { status: "success", reload: false });
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", {
        error
      });
    },

    MUTATION_FETCH_REC(state, items) {
      state.data = items;
    },

    MUTATION_FETCH_MORE_RECS(state, items) {
      state.data.push(...items);
    },

    MUTATION_RESET_STATE(state) {
      state.data = initialState().data;
    },

    MUTATION_FETCH_CREATORS(state, items) {
      state.creators = items;
    },

    MUTATION_RELOAD_CREATORS(state) {
      state.reloadCreators = true;
    },

    // Bookmark
    MUTATION_BOOKMARK(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.bookmarkStatus = true;
        cartItem.isActiveBookmark = true;
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.isActiveBookmark = false;
        cartItem.bookmarkStatus = false;
      }
    },

    MUTATION_UPDATE_RATE(state) {
      state.reload = true;
    }
  }
};
