<template>
  <f7-page id="page-post-comment" name="comment" no-toolbar>
    <header-navbar title="Comment"/>
    <div class="review-block">
      <f7-list media-list no-hairlines class="comments-block">
        <f7-list-item
          v-for="(item, key) in tip.tipComments"
          :key="key"
          :title="`${item.author.nickname}`"
          :text="`${item.content}`"
        >
          <span slot="footer">{{ item.createdAt | moment("from", true) }} ago</span>
          <img
            v-if="item.author.avatar && item.author.avatar.url != null"
            :src="item.author.avatar.url"
          >
          <span v-else slot="media" class="text">{{item.author.nickname.charAt(0)}}</span>
          <div class="action" v-if="me.id == item.author.id">
            <f7-link @click="handleEditComment(item.content, item.id)" icon-ios="f7:more"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>
    </div>

    <!-- Toolbar -->
    <f7-toolbar class="create-comment-input">
      <div no-hairlines class="no-margin-vertical">
        <f7-input
          type="text"
          placeholder="Add a comment..."
          :resizable="true"
          clear-button
          :value="content"
          @input="content = $event.target.value"
        ></f7-input>
        <span v-if="isEdit && content != null" class="icon-location" @click="editComment()">
          <f7-icon f7="edit" size="16px"></f7-icon>
        </span>
        <span v-else class="icon-location" @click="addNewComment(tip.tipId)">
          <img src="@/static/icons/location.svg">
        </span>
      </div>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import HeaderNavbar from "../../components/HeaderNavbar";
import C from "../../_helpers/constants";
export default {
  components: { HeaderNavbar },
  name: "comment",
  data() {
    return {
      content: "",
      isEdit: false,
      idEdit: null
    };
  },

  computed: {
    ...mapGetters("auth", {
      me: "me",
    }),
      ...mapGetters("magazine", {
      tip: "tip",
    }),
  },

  methods: {
    // Add new comment
    addNewComment(id) {
      const self = this;
      if (self.content) {
        const params = {
          id: id,
          content: self.content,
          type: C.COMMENT_TYPE.TIP
        };
        this.$store
          .dispatch("comment/ACTION_POST_COMMENT", params)
          .then(res => {
            Object.assign(this.$data, this.$options.data());
          });
      }
    },

    // Edit comment
    editComment() {
      const self = this;
      if (self.content) {
        const params = {
          id: this.idEdit,
          content: self.content,
          type: C.COMMENT_TYPE.TIP
        };
        this.$store
          .dispatch("comment/ACTION_PUT_COMMENT", params)
          .then(res => {
            Object.assign(this.$data, this.$options.data());
          });
      }
    },

    // Action comment
    handleEditComment(item, id) {
      const self = this;
      self.content = item;
      self.isEdit = true;
      self.idEdit = id;
    }
  }
};
</script>
