import gql from "graphql-tag";

const bookmark = gql `
mutation bookmark($data: BookmarkInput!) {
  bookmark(data: $data)
}
`

export  {
  bookmark
};
