export default {
  namespaced: true,
  state: {

  },

  getters: {

  },

  actions: {
    ACTION_ERROR({
      commit
    }, data) {
      if (data.error.message == 'GraphQL error: invalid token' || data.error.message == 'GraphQL error: jwt malformed') {
        this._vm.$f7.views.main.router.navigate({
          name: C.ROUTES_NAME.LOGIN_PAGE,
        });
        localStorage.clear();
        localStorage.removeItem("token")
      }
    },
  },

  mutations: {

  }
}
