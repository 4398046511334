<template>
  <div v-if="allPosts.length>0">
    <big-post-component :item="allPosts[0]" />
    <small-posts-component :allPosts="allPosts.slice(1, this.MaxPostInOneSummary)" />
    <see-more-component :isShow="showSeeMore" :toggleTip="handleSeeMore" v-if="allPosts.length>MaxPostInOneSummary"/>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import SeeMoreComponent from "../components/SeeMore";
import BigPostComponent from "../components/BigPost";
import SmallPostsComponent from "../components/SmallPosts";

export default {
  components: {
    SeeMoreComponent,
    BigPostComponent,
    SmallPostsComponent
  },
  data() {
    return {
      constants: C,
      MaxPostInOneSummary: 4,
    };
  },
  computed: {
    ...mapGetters("auth", {
      data: "data"
    })
  },
  props: {
    allPosts: {
      required: true,
      type: Array
    },
    topicId: {
      required: true,
      type: String
    },
    topicName: {
      required: true,
      type: String
    },
    showSeeMore: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    handleSeeMore() {
      const params = {
        id: this.topicId,
        name: this.topicName
      }
      this.$store.dispatch("topicPosts/ACTION_GET", params)
    }
  }
};
</script>

