<template>
  <div class="event-list-wrapper">
    <f7-block class="vendor-list" no-padding v-for="(item, index) in items" :key="index">
      <f7-row>
        <f7-col width="60">
          <div class="vendor-title" v-if="showOrdinal">{{index+1}}. {{item.name}}</div>
          <div class="vendor-title" v-else>{{item.name}}</div>
          <span class="date">
            <span class="event-date">{{ showEventDate(item) }}</span>
            <span class="event-time">{{ showEventTime(item, 'long') }}</span>
          </span>
          <p class="line-clamp-3 content">{{item.description}}</p>
        </f7-col>
        <f7-col width="40">
          <f7-card>
            <place-image-component
              v-if="item.place && item.place.photos.length > 0"
              :url="item.place.photos[0].url"
              :onHandle="()=>onPhotoBrowser(index)"
            />

            <place-image-component v-else :url="item.cover" :onHandle="()=>onPhotoBrowser(index)" />

            <div class="wrap-icon-bookmark-share">
              <bookmark-component
                class="icon-bookmark"
                :type="constants.BOOKMARK_CONTENT_TYPE.EVENT"
                :item="item"
                :id="item.id"
              />

              <share-component
                :actionsOpenId="`actions-event-place-${item.place && item.place.id}`"
                :type="constants.TYPE_SHARE.PLACE"
                :typeId="item.place && item.place.id"
              />
            </div>
          </f7-card>
        </f7-col>
      </f7-row>

      <div class="events-list" v-if="item.place">
        <h4 class="venue">VENUE</h4>
        <h4 class="title">{{item.place && item.place.name}}</h4>
        <f7-swiper :params="{speed:500, slidesPerView: 2, spaceBetween: 7}">
          <f7-swiper-slide
            v-for="(photo, key) in item.place.photos"
            :style="`background-image:url(`+photo.url+`)`"
            :key="key"
          >
            <span class="events-list__img" @click="()=>onPhotoBrowser(index)"></span>
            <!-- <div class="user-widget avatar">
              <span class="avatar-wrap">
                <img
                  v-if="true"
                  :src="getAvatarAuthImg('https://images.foody.vn/res/g1/1472/s800/foody-au-parc-560-636691847577683329.jpg')"
                >
                <span v-else class="text">J</span>
              </span>
              <span class="user-name">DD</span>
            </div>-->
          </f7-swiper-slide>
        </f7-swiper>
        <f7-photo-browser theme="dark" :photos="photoBrowser(item.place.photos)" ref="photoBrowser"></f7-photo-browser>
        <see-more-component :isShow="true" :toggleTip="()=>toPlaceDetail(item.place.id)" />
      </div>
    </f7-block>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import TransitionExpandComponent from "../components/TransitionExpand.vue";
import PlaceImageComponent from "../components/PlaceImage";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import SeeMoreComponent from "../components/SeeMore";

import EventPostView from "./EventPost";
import { showEventDate, showEventTime } from '../utils/eventHelpers';

export default {
  props: {
    items: {
      required: false,
      type: Array
    },
    showOrdinal: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  components: {
    TransitionExpandComponent,
    PlaceImageComponent,
    BookmarkComponent,
    ShareComponent,
    SeeMoreComponent,

    EventPostView
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    photoBrowser(photo) {
      return photo;
    },
    onPhotoBrowser(key) {
      this.$refs.photoBrowser[key].open();
    },
    // Get avatar auth
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return "";
      }
    },
    toPlaceDetail(id) {
      this.$store.dispatch("placeDetail/ACTION_GET", { id });
    },
    showEventDate,
    showEventTime
  }
};
</script>

