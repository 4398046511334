import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';

export default {
  namespaced: true,
  state: {
    data: [],
    firstTopic: null,
    reload: false,
    isLoading: true
  },

  getters: {
    data: state => state.data,
    reload: ({ reload }) => reload,
    isLoading: ({ isLoading }) => isLoading
  },

  actions: {
    ACTION_FETCH_TOPIC({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.topics
          })
          .then(({ data }) => data)
          .then(({ topics }) => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_FETCH_TOPIC", topics);
            resolve(topics);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },
    ACTION_RELOAD_PAGE({ commit }) {
      commit("MUTATION_RELOAD_PAGE");
    },
    ACTION_SET_LOADING({ commit }) {
      commit("MUTATION_SET_LOADING");
    }
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
    },
    STATUS_SUCCESS(state) {
      state = Object.assign(state, {
        status: "success",
        reload: false,
        isLoading: false
      });
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", { error });
    },
    MUTATION_FETCH_TOPIC(state, topics) {
      state.data = topics;
    },
    MUTATION_RELOAD_PAGE(state) {
      state.reload = true;
    },
    MUTATION_UPDATE_LIKE(state, { id }) {
      return state.data.map(topic => {
        if (topic.id === id) {
          topic.isLike = !topic.isLike;
        }
      });
    },
    MUTATION_SET_LOADING(state) {
      state.isLoading = true;
    }
  }
};
