import gql from "graphql-tag";

const collectionDetailPost = gql`
  query collection(
    $type: COLLECTION_TYPE!
    $cityId: ID!
    $userId: ID
    $filterType: COLLECTION_FILTER
    $page: Int!
  ) {
    collection(
      type: $type
      cityId: $cityId
      userId: $userId
      filterType: $filterType
      page: $page
    ) {
      city {
        id
        name
        country {
          name
        }
      }
      user {
        id
        nickname
        avatar {
          url
          name
        }
      }
      posts {
        id
        title
        content
        externalUrl
        externalSource
        siteName
        siteDescription
        city {
          name
          country {
            name
          }
        }
        author {
          id
          nickname
          avatar {
            url
            name
          }
        }
        photos {
          name
          url
        }
        statsLike
        statsComment
        likeStatus
        bookmarkStatus
        createdAt
        places {
          id
          placeId
          name
          photos {
            name
            url
          }
          rateStarts {
            publicStatus
            publicScore
            publicStats
            friendStatus
            friendScore
            friendStats
          }
          categories {
            id
            name
          }
          city {
            name
          }
          bookmarkStatus
        }
      }
    }
  }
`;

export { collectionDetailPost };
