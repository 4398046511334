<template>
  <div>
    <f7-block class="vendor-list" no-padding v-for="(item, index) in items" :key="index">
      <f7-row>
        <f7-col width="10" class="vendor-list-index">
          {{checkIndex(index + 1)}}
        </f7-col>
        <place-info-component
          :item="item"
          :onToPlaceDetail="()=>toPlaceDetail(item.id, pageType)"
          :index="index"
          :constants="constants"
          :width="55"
          :showDistance="showDistance"
          class="z-index-1 vendor-info"
        />
        <f7-col width="35">
          <f7-card>
            <div @click="toPlaceDetail(item.id, pageType)">
              <place-image-component :url="item.photos.length > 0 ? item.photos[0].url : ''"/>
            </div>
            <div class="wrap-icon-bookmark-share">
              <bookmark-component
                class="icon-bookmark"
                :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                :item="item"
                :id="item.id"
                :isActiveBookmarkStatus="item.bookmarkStatus"
                :pageType="pageType"
              />
              <share-component
                :actionsOpenId="`actions-recs-friend-place-${item.id}`"
                :type="constants.TYPE_SHARE.PLACE"
                :typeId="item.id"
              />
            </div>
          </f7-card>
        </f7-col>
        
        <f7-col width="100" class="wrapper-tips">
          <div v-if="item.tips.length">
            <f7-list
              media-list
              no-hairlines
              v-for="(tip, index) in item.tips.slice(0, 2)"
              :key="index"
            >
              <recs-tip-component
                :onAuthProfile="() => actionFetchAuthProfile(tip.author.id)"
                :tip="tip"
                :placeId="item.id"
                :pageType="constants.PAGE_CONTENT_TYPE.RECS"
                :getUrlImg="
                  getAvatarAuthImg(
                    tip.author.avatar != null && tip.author.avatar.name
                  )
                "
                :constants="constants"
              />
            </f7-list>
          </div>

          <div v-if="item.tips.length == 0 && item.rates.length">
            <f7-list
              media-list
              no-hairlines
              v-for="(rate, index) in item.rates.slice(0, 2)"
              :key="index"
            >
              <rate-component
                :onAuthProfile="() => actionFetchAuthProfile(rate.author.id)"
                :rate="rate"
                :getUrlImg="
                  getAvatarAuthImg(
                    rate.author.avatar != null && rate.author.avatar.name
                  )
                "
                :constants="constants"
              ></rate-component>
            </f7-list>
          </div>

          <div v-if="item.tips.length > 2 && !item.isActiveTip">
            <recs-tip-hidden-component
              :item="item"
              :getUrlImg="getAvatarAuthImg(item.tips[2].author.avatar != null && item.tips[2].author.avatar.name)"
            />
          </div>
          <transition-expand-component>
            <div v-if="item.isActiveTip" class="wrapper-expanded">
              <f7-list
                media-list
                no-hairlines
                v-for="(tip, index) in item.tips.slice(2)"
                :key="index"
              >
                <recs-tip-component
                  :onAuthProfile="()=>actionFetchAuthProfile(tip.author.id)"
                  :placeId="item.id"
                  :pageType="constants.PAGE_CONTENT_TYPE.RECS"
                  :tip="tip"
                  :getUrlImg="getAvatarAuthImg(tip.author.avatar != null && tip.author.avatar.name)"
                  :constants="constants"
                />
                <div class="wrapper-place" @click="toPlaceDetail(item.id, pageType)"></div>
              </f7-list>
            </div>
          </transition-expand-component>

          <see-more-component
            :item="item"
            :isShow="item.tips.length > 2"
            :isActive="item.isActiveTip"
            :toggleTip="()=>toggleTip(item)"
          />
          <div class="wrapper-place" @click="toPlaceDetail(item.id, pageType)"></div>
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import TransitionExpandComponent from "../components/TransitionExpand.vue";
import PlaceInfoComponent from "../components/PlaceInfo";
import RecsTipComponent from "../components/Tip";
import RecsTipHiddenComponent from "../components/TipHidden";
import PlaceImageComponent from "../components/PlaceImage";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import SeeMoreComponent from "../components/SeeMore";
import RateComponent from "../components/Rate";

export default {
  props: {
    pageType: {
      required: false,
      type: String
    },
    items: {
      required: false,
      type: Array
    },
    showDistance: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  components: {
    TransitionExpandComponent,
    PlaceInfoComponent,
    RecsTipComponent,
    RecsTipHiddenComponent,
    PlaceImageComponent,
    BookmarkComponent,
    ShareComponent,
    SeeMoreComponent,
    RateComponent
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    // Get avatar auth
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return "";
      }
    },

    // Toggle tip
    toggleTip(item) {
      const self = this;
      item.isActiveTip = !item.isActiveTip; // Change status is active tip
      this.$forceUpdate();
    },

    // To detail
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params); // set id place detail
    },

    checkIndex(index) { //adds leading 0 if index < 10
      if (index < 10) {
        return `0`+ index.toString();
      } else {
        return index.toString();
      }
    }
  }
};
</script>

