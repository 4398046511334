<template>
  <f7-col :width="width" :class="className">
    <div
      class="vendor-title"
      v-on:click="()=> onToPlaceDetail()"
    >{{ item.name  }}</div>
    <f7-block-header class="sub-title" v-if="showDistance">
      <span class="f7-icons">placemark_fill</span>
      {{item.distance}}
    </f7-block-header>
    <f7-row no-gap class="rating-display">
      <f7-col>
        <div class="title">Public</div>
        <div class="count">
          <span>
            <img
              v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_3_active.png"
              alt
            >
            <img
              v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.LIKE"
              src="@/static/icons/rating_2_active.png"
              alt
            >
            <img
              v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.OK"
              src="@/static/icons/rating_1_active.png"
              alt
            >
            <img
              v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.OK && item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.LIKE && item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_0.png"
              alt
            >
          </span>
          <label>{{item && item.rateStarts && item.rateStarts.publicScore ? item.rateStarts.publicScore : 0}}</label>
        </div>
        <div class="total-rate">{{item && item.rateStarts && item.rateStarts.publicStats ? item.rateStarts.publicStats : 0}} rated</div>
      </f7-col>
      <f7-col>
        <div class="title">Friends</div>
        <div class="count">
          <span>
            <img
              v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_3_active.png"
              alt
            >
            <img
              v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.LIKE"
              src="@/static/icons/rating_2_active.png"
              alt
            >
            <img
              v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.OK"
              src="@/static/icons/rating_1_active.png"
              alt
            >
            <img
              v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.OK && item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.LIKE && item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_0.png"
              alt
            >
          </span>
          <label>{{item && item.rateStarts && item.rateStarts.friendScore ? item.rateStarts.friendScore : 0}}</label>
        </div>
        <div class="total-rate">{{item && item.rateStarts && item.rateStarts.friendStats ? item.rateStarts.friendStats : 0}} rated</div>
      </f7-col>
    </f7-row>
  </f7-col>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    onToPlaceDetail: {
      required: true,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    index: {
      required: false,
      type: Number
    },
    width: {
      required: false,
      type: Number
    },
    constants: {
      required: true,
      type: Object
    },
    showDistance: {
      required: false,
      type: Boolean
    },
    className: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>
