<template>
  <p class="wrap-content">
    <a v-if="externalUrl" :href="externalUrl" target="_blank" external class="more external link">
      <span :class="className">
        <span class="site-name" v-if="siteName">{{siteName}}</span> {{ siteDescription }}
        <a class="more external link">more&#32;<img src="/static/icons/link.svg"/></a>
      </span>
    </a>
    <span v-else class="more" @click="()=> onToPostDetail()">more</span>
  </p>
</template>
<script>
export default {
  props: {
    siteDescription: {
      required: false,
      type: String
    },
    isShow: {
      required: false,
      type: Boolean,
      default: true
    },
    isDetail: {
      required: false,
      type: Boolean,
      default: false
    },
    externalUrl: {
      required: false,
      type: String
    },
    siteName: {
      required: false,
      type: String
    },
    onToPostDetail: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    className: {
      required: false,
      type: String
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

