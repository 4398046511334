export const copySuccess = (e, comp) => {
  // Create toast
  if (!comp.toastBottom) {
    comp.toastBottom = comp.$f7.toast.create({
      text: "Link copied",
      closeTimeout: 2000
    });
  }
  // Open it
  comp.toastBottom.open();
};

export const copyError = e => {
  alert("Failed to copy texts");
};
