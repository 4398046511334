import gql from "graphql-tag";

const updateProfile = gql `
mutation updateProfile($data: UserUpdateInput!) {
  updateProfile(data: $data) {
    id
    nickname
    username
    email
    avatar {
      name
      url
    }
    background {
      name
      url
    }
    friends {
      id
      status
      createdAt
      receive {
        id
        nickname
        avatar {
          url
          name
        }
      }
    }
  }
}
`

export  {
  updateProfile

};
