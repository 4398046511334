<template>
  <f7-page id="passport-page" :class="`${colorTheme}`" name="home-settings">
    <!-- hide-bars-on-scroll -->

    <header-navbar-passport-component
      :colorTheme="colorTheme"
      :actionsOpen="`.actions-passport-${profile.id}`"
      :meId="me.id"
      :profileId="profile.id"
      :activeNavBar="activeNavBar"
    />

    <actions-passport-component
      :actionsOpenName="`actions-passport-${profile.id}`"
      :userId="profile.id"
    />

    <div v-if="isLoading">
      <passport-skeleton :className="`skeleton-text skeleton-effect-${effect}`"/>
    </div>

    <div class="head-background" v-else>
      <img
        v-if="profile.background && profile.background.name"
        :src="handleImg(profile.background)"
      >
      <img v-else src="/static/images/passport-default.png" alt="Passport Theme">
      <span class="overlay-bottom"></span>
    </div>
    <f7-block :class="`${colorTheme} user-info`">
      <span class="overlay-top"></span>
      <f7-row no-gap>
        <f7-col width="25">
          <div class="avatar">
              <img v-if="profile.avatar" :src="profile.avatar.url">
              <span v-else class="text">{{profile.nickname.charAt(0)}}</span>
            </div>
        </f7-col>
        <f7-col width="75">
          <span class="user-name">{{ profile.nickname }}</span>
        </f7-col>
      </f7-row>
      <f7-block class="no-padding no-margin">
        <f7-row class="justify-content-left">
          <f7-col width="35">
            <div class="count">
              <p class="number">{{passport.profile.noOfFollowers}}</p>
              <span class="text">followers</span>
            </div>
            <div class="count">
              <p class="number">{{passport.profile.noOfFollowers}}</p>
              <span class="text">following</span>
            </div>
            <div class="count">
              <p class="number">{{passport.statsCity}}</p>
              <span class="text">cities visited</span>
            </div>
            <div class="count">
              <p class="number">{{passport.profile.noOfRecs}}</p>
              <span class="text">recommendations</span>
            </div>
          </f7-col>
          <f7-col width="65">
            <f7-button outline color="white" @click="handleOthers">See friends</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
    <div class="content-wrap cities-list">
      <h3>{{profile.id != me.id && profile.nickname.slice(-1) == "s" ? profile.nickname+"'" : profile.id == me.id ? 'Your' : profile.nickname+"'s"}} travel magazines</h3>
      <f7-block v-for="(country, key) in magazines" :key="key" class="city-block">
        <h4 class="title">{{ country.name }}</h4>
        <f7-swiper :params="{speed:500, slidesPerView: 2, spaceBetween: 10}">
          <f7-swiper-slide
            v-for="city in country.cities"
            :style="`background-image:url(${handleImg(city.cover)})`"
            :key="city.name"
          >
            <city-info-component
              :published="city.published"
              :name="city.name"
              :statsPlace="city.statsPlace"
              :isMagazine="true"
              text="RECS"
              :onHandle="() => toDetailMagazine(city.id)"
              :actionsOpen="`.actions-magazine-groups-${city.id}`"
            />

            <actions-magazine-component
              :actionsOpenName="`actions-magazine-groups-${city.id}`"
              :cityId="city.id"
              :userId="profile.id"
            />
          </f7-swiper-slide>
        </f7-swiper>
      </f7-block>
      </div>

    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="true"
      :handlePassport="handleScrollPassport"
    />

    <toolbar-social
      v-else
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="true"
      :handlePassport="handleScrollPassport"
    />
  </f7-page>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../../_helpers/constants";
import Toolbar from "../../components/Toolbar";
import ToolbarSocial from "../../components/ToolbarSocial";
import PassportSkeleton from "../../components/PassportSkeleton";
import CityInfoComponent from "../../components/CityInfo";
import ActionsMagazineComponent from "../../components/ActionsMagazine";
import ActionsPassportComponent from "../../components/ActionsPassport";
import HeaderNavbarPassportComponent from "../../components/HeaderNavbarPassport";
import { getUrlImg } from "../../utils/cloundinary-image";
export default {
  components: {
    Toolbar,
    ToolbarSocial,
    PassportSkeleton,
    CityInfoComponent,
    ActionsMagazineComponent,
    ActionsPassportComponent,
    HeaderNavbarPassportComponent
  },
  data() {
    return {
      constants: C,
      colorTheme: C.COLORS.DARK,
      isLoading: false,
      effect: null,
      activeNavBar: false
    };
  },
  mounted() {
    const self = this;
    const getColorTheme = localStorage.getItem("colorTheme");
    if (getColorTheme) {
      self.colorTheme = getColorTheme;
    }
    this.$f7.$(".page-content").on("scroll", e => {
      this.contentScrolled(e.srcElement.scrollTop);
    });
  },
  computed: {
    ...mapGetters("passport", {
      profile: "profile",
      magazines: "magazines",
      passport: "data"
    }),
    ...mapGetters("auth", {
      me: "me"
    })
  },
  methods: {
    // Scroll bottom show content first page (Trending, Summary)
    contentScrolled(position) {
      if (position > 0) {
        this.activeNavBar = true;
      } else {
        this.activeNavBar = false;
      }
    },

    // Scroll to top
    handleScrollPassport() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Redirect to magazine detail
    toDetailMagazine(id) {
      const self = this;
      const params = {
        cityId: id,
        userId: this.profile.id
      };
      this.$store.dispatch(
        "currentUrl/ACTION_GET_CURRENT_URL",
        C.ROUTES_NAME.PASSPORT_LIST_PAGE
      );
      this.$store.dispatch("magazine/ACTION_GET_MAGAZINE_SELECTED", params);
    },

    // Show others
    handleOthers() {
      const self = this;
      if (this.me.id == this.profile.id) {
        this.$f7router.navigate(C.ROUTER_NAVIGATE.SEE_FRIENDS);
      }
    },

    // Handle Image
    handleImg(photo) {
      return photo?.url || "/static/images/passport-default.png"
    }
  }
};
</script>
