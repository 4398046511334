<template>
<div>
  <f7-button :class="`requested-btn`" v-if="followed">{{this.displayStatus(role,followed)}}</f7-button>
  <f7-button :class="`follow-btn`" @click="()=>request()" v-else>FOLLOW</f7-button>
</div>
</template>

<script>
export default {
  props: {
    userId: {
      required: true,
      type: String
    },
    requested: {
      required: true,
      type: Boolean
    },
    role: {
      required: false,
      type: String
    },
  },
  data() {
    return {
      followed: false
    };
  },
  mounted() {
    this.initStatus();
  },
  methods: {
    request() {
      const self = this;
      this.$store.dispatch("friend/ACTION_ADD_FRIEND", this.userId).then(res => {
        if (res.data) {
          this.followed = !this.followed
        }
      });
    },
    initStatus() {
      this.followed = this.requested
    },
    displayStatus(role, followed) {
      if ((role && (role == "PUBLISHER")) && followed)
        return "FOLLOWING"
      else
        return "FRIENDS"
    }
  }
};
</script>
