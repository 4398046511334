import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';
import gqlQuery from '../../_gql/query/index.js';
import C from "../../_helpers/constants";

export default {
  namespaced: true,
  state: {
    data: null
  },

  getters: {
    data: state => state.data,
  },

  actions: {

    ACTION_GET_CURRENT_URL({
      commit
    }, page) {
      if (page) {
        commit('MUTATIONS_GET_CURRENT_URL', page);
      }
    }

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATIONS_GET_CURRENT_URL(state, item) {
      state.data = item;
    }

  }
}
