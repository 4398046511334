<template>
  <div>
    <img
      class="status-rate"
      v-if="action == constants.RATE_STARTS.FAVS"
      src="@/static/icons/rating_3_active.png"
      alt
    >
    <img
      class="status-rate"
      v-if="action == constants.RATE_STARTS.LIKE"
      src="@/static/icons/rating_2_active.png"
      alt
    >
    <img
      class="status-rate"
      v-if="action == constants.RATE_STARTS.OK"
      src="@/static/icons/rating_1_active.png"
      alt
    >
  </div>
</template>
<script>
import C from "../_helpers/constants";

export default {
  props: {
    action: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      constants: C
    };
  },
};
</script>

