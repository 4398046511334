import gql from "graphql-tag";

const magazineShare = gql`
  query magazine(
    $cityId: ID!
    $where: MagazineFilter!
    $page: Int
    $IDFriend: ID
  ) {
    magazine(cityId: $cityId, where: $where, page: $page) {
      profile {
        id
        nickname
        email
        avatar {
          name
          url
        }
      }
      city {
        name
        cover {
          name
          url
        }
      }
      magazines {
        timelines {
          id
          createdAt
          relationship {
            __typename
            ... on Bookmark {
              post {
                id
                title
                content
                externalUrl
                externalSource
                siteName
                siteDescription
                city {
                  name
                  country {
                    name
                  }
                }
                author {
                  id
                  nickname
                  avatar {
                    url
                    name
                  }
                }
                photos {
                  name
                  url
                }
                statsLike
                statsComment
                createdAt
                places {
                  id
                  placeId
                  name
                  photos {
                    name
                    url
                  }
                  # rateStarts {
                  #   publicStatus
                  #   publicScore
                  #   publicStats
                  #   friendStatus
                  #   friendScore
                  #   friendStats
                  # }
                  categories {
                    id
                    name
                  }
                  city {
                    name
                  }
                }
              }
              place {
                id
                name
                photos {
                  name
                  url
                }
                # rateStarts {
                #   publicStatus
                #   publicScore
                #   publicStats
                #   friendStatus
                #   friendScore
                #   friendStats
                # }
                photos {
                  name
                  url
                }
              }
              # place {
              #   id
              #   name
              #   rateStarts {
              #     publicStatus
              #     publicScore
              #     publicStats
              #     friendStatus
              #     friendScore
              #     friendStats
              #   }
              #   photos{
              #     url
              #   }
              #   bookmarkStatus
              # }
            }

            ... on Post {
              id
              title
              content
              createdAt
              statsLike
              statsComment
              siteName
              siteDescription
              externalUrl
              externalSource
              city {
                name
                country {
                  name
                }
              }
              author {
                id
                nickname
                avatar {
                  url
                  name
                }
              }
              photos {
                name
                url
              }
              comments {
                id
                content
                author {
                  id
                  nickname
                  avatar {
                    url
                    name
                  }
                }
                createdAt
              }
              places {
                id
                placeId
                name
                photos {
                  name
                  url
                }
                # rateStarts {
                #   publicStatus
                #   publicScore
                #   publicStats
                #   friendStatus
                #   friendScore
                #   friendStats
                # }
                categories {
                  id
                  name
                }
                city {
                  name
                }
              }
            }
            ... on Rate {
              action
              place {
                id
                name
                # rateStarts {
                #   publicStatus
                #   publicScore
                #   publicStats
                #   friendStatus
                #   friendScore
                #   friendStats
                # }
                photos {
                  name
                  url
                }
                # bookmarkStatus
              }
            }
            ... on Tip {
              tipId: id
              tipContent: content
              # tipLikeStatus: likeStatus
              tipPlace: place {
                id
                name
                # rateStarts {
                #   publicStatus
                #   publicScore
                #   publicStats
                #   friendStatus
                #   friendScore
                #   friendStats
                # }
                # rateStatus {
                #   id
                #   action
                # }
                photos {
                  name
                  url
                }
              }
              tipComments: comments {
                id
                content
                author {
                  id
                  nickname
                  avatar {
                    url
                    name
                  }
                }
                createdAt
              }
            }
          }
        }
        places {
          id
          name
          address
          rating
          priceLever
          categories {
            id
            name
          }
          rates(where: { author: { id: $IDFriend } }, last: 1) {
            action
            author {
              id
              nickname
              avatar {
                name
                url
              }
            }
          }
          tips(where: { author: { id: $IDFriend } }) {
            id
            content
            author {
              id
              nickname
              avatar {
                url
                name
              }
            }
            place {
              rates {
                id
                action
              }
            }
            rate {
              id
              action
            }
          }
          photos {
            name
            url
          }
          # rateStarts {
          #   publicStatus
          #   publicScore
          #   publicStats

          #   friendStatus
          #   friendScore
          #   friendStats
          # }
          phone
          website
        }
      }
    }
  }
`;

export { magazineShare };
