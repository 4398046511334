import gql from "graphql-tag";

const searchFriendFavs = gql`
query searchFriendFavs($q: String!, $cityId: ID!) {
  search(q: $q, cityId: $cityId) {
    friendFavs {
      placeId
      name
      address
      coverUrl
      distance
      rateStarts {
        publicStatus
        publicScore
        publicStats
        friendStatus
        friendScore
        friendStats
        friendNames
      }
    }
  }
}`

const searchPlaces = gql`
  query searchPlaces($q: String!, $cityId: ID!) {
    search(q: $q, cityId: $cityId) {
      places {
        placeId
        name
        address
        coverUrl
        distance
        rateStarts {
          publicStatus
          publicScore
          publicStats
          friendStatus
          friendScore
          friendStats
          friendNames
        }
      }
    }
  }
`;

const searchUsers = gql`
  query searchUsers($q: String!, $cityId: ID!) {
    search(q: $q, cityId: $cityId) {
      users {
        id
        nickname
        username
        avatar {
          name
          url
        }
        friendStatus
        noOfFollowers
        noOfRecs
      }
    }
  }
`;

const searchEvents = gql`
  query searchEvents($q: String!, $cityId: ID!) {
    search(q: $q, cityId: $cityId) {
      events {
        id
        name
        place {
          id
          address
        }
        description
        host
        eventDate
        startTime
        cover
      }
    }
  }
`;

export {
  searchFriendFavs,
  searchPlaces,
  searchUsers,
  searchEvents
};
