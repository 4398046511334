import gql from "graphql-tag";

const trendingEvents = gql`query trendingEvents {
    trendingEvents {
      title
      events {
        id
        name
        cover
        createdAt
        eventDate
        startTime
        endTime
        categories {
          name
        }
        bookmarkStatus
        place {
          id
          name
          photos {
            name
            url
          }
        }
      }
    }
  }
`;

export { trendingEvents };
