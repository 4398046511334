<template>
  <f7-block class="post">
    <div class="wrapper-card no-photo no-video">
      <div class="wrapper-content-card">
        <p class="content line-clamp-3">{{ content }}</p>
      </div>
      <f7-card-footer>
        <span>
          <img
            v-if="item ? item.isActiveLikeStatus == false : false"
            @click="toggleAddedTipLike(item, id)"
            src="@/static/icons/like.svg"
          >
          <img v-else @click="toggleAddedTipLike(item, id)" src="@/static/icons/like_active.svg">
        </span>

        <span>
          <img src="@/static/icons/comment.svg" @click="toTipComment(id, item)">
        </span>
      </f7-card-footer>
    </div>
    <div
      class="like-comment like-comment--magazine"
    >{{ statsLike}} likes • {{ statsComment}} comments</div>
    <p class="time">{{ createdAt | moment("from", true) }} ago</p>
  </f7-block>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    createdAt: {
      required: false,
      type: String
    },
    content: {
      required: false,
      type: String
    },
    item: {
      required: false,
      type: Object
    },
    id: {
      required: false,
      type: String
    },
    statsLike: {
      required: false,
      type: Number
    },
    statsComment: {
      required: false,
      type: Number
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    },
    toggleAddedTipLike(item, id) {
      const self = this;
      item.isActiveLikeStatus = !item.isActiveLikeStatus;
      this.$forceUpdate();
      if (item.isActiveLikeStatus == false) {
        const params = {
          id: id,
          type: C.BOOKMARK_CONTENT_TYPE.TIP,
          status: C.LIKE_STATUS.UNLIKE
        };
        this.$store.dispatch("like/ACTION_LIKE", params).then(res => {
          item.isActiveLikeStatus = false;
        });
      } else {
        const params = {
          id: id,
          type: C.BOOKMARK_CONTENT_TYPE.TIP,
          status: C.LIKE_STATUS.LIKE
        };
        this.$store.dispatch("like/ACTION_LIKE", params).then(res => {
          item.isActiveLikeStatus = true;
        });
      }
    },

    toTipComment(id, items) {
      this.$store.dispatch("magazine/ACTION_SET_COMMENTS_TIP", items);
      this.$f7router.navigate({
        name: C.ROUTER_NAVIGATE.MAGAZINE_TIP_COMMENT,
        params: { id: id }
      });
    }
  }
};
</script>

