<template>
  <f7-page
    id="page-detail-collection"
    name="detail-collection-place"
    infinite
    :infinite-preloader="false"
    @infinite="loadMore"
  >
    <header-navbar-action-component
      :onChange="(event)=>handleOnChangePlace(event)"
      :actionType="constants.ACTION_TYPE.ACTIONS"
      :actionsOpenName="`actions-share-collection-${city.id}`"
      :id="city.id"
      :userId="me.id"
      :selectedPlace="true"
    />

    <f7-block class="block-article page-detail-collection__user-info" v-if="!me.id">
      <user-info-component
        :onAuthProfile="()=>actionFetchAuthProfile(user && user.id)"
        :getAvatar="getAvatarAuthImg(user && user.avatar && user.avatar.name)"
        :nickname="user && user.nickname"
        :isAction="false"
      />
    </f7-block>

    <f7-block-title class="city-selected" no-padding>{{city.name}}</f7-block-title>
    <sort-by-component
      :handleSortBy="(type, text)=>handleSortBy(type, text)"
      :toggleSorting="()=>toggleSorting()"
      :textSortBy="itemSortBy"
      :isSorting="isSorting"
      :indexSortBy="indexSortBy"
      :textFriendRating="textFriendRating"
    />

    <place-view
      :items="items"
      :showDistance="false"
      :pageType="constants.PAGE_CONTENT_TYPE.COLLECTION_PLACES"
    />

    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="true"
      :current_passport="false"
      :handleCollection="handleScrollCollection"
    />
    <toolbar-social
      v-else
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
    <div class="preloader infinite-scroll-preloader" v-show="hasMoreItems"></div>
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import HeaderNavbar from "../../components/HeaderNavbar";
import Toolbar from "../../components/Toolbar";
import ToolbarSocial from "../../components/ToolbarSocial";
import SelectAction from "../../components/Select";
import PlaceInfo from "../../components/PlaceInfo";
import RecsTip from "../../components/Tip";
import RecsTipHidden from "../../components/TipHidden";
import TransitionExpand from "../../components/TransitionExpand.vue";
import HeaderNavbarActionComponent from "../../components/HeaderNavbarAction";
import UserInfoComponent from "../../components/UserInfo";
import Vue from "vue";
import C from "../../_helpers/constants";
import _ from "lodash";
import { getUrlImg } from "../../utils/cloundinary-image";

import SortByComponent from "../../components/SortBy";
import PlaceView from "../../views/PlaceView";
export default {
  components: {
    HeaderNavbar,
    Toolbar,
    ToolbarSocial,
    SelectAction,
    PlaceInfo,
    RecsTip,
    RecsTipHidden,
    TransitionExpand,
    HeaderNavbarActionComponent,
    UserInfoComponent,
    SortByComponent,

    PlaceView
  },
  data() {
    return {
      constants: C,
      isLoading: false,
      nextItem: 0,
      allowInfinite: true,
      hasMoreItems: true,
      idUser: null,
      idCity: null,
      isSorting: false,
      itemSortBy: C.FILTER.TEXT_MY_RATE,
      indexSortBy: C.FILTER.FRIEND_RATE,
      textFriendRating: C.FILTER.TEXT_MY_RATE
    };
  },

  mounted() {
    this.$store.dispatch("collectionDetailPlace/ACTION_RESET_STATE");
    this.loadMore();
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("collectionDetailPlace", {
      city: "city",
      user: "user",
      collectionDetail: "collectionType",
      items: "place"
    }),
    ...mapGetters("collection", {
      urlSearchParams: "url_search_params"
    })
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    // Scroll to top
    handleScrollCollection() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Get avatar
    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    // Load more
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      self.nextItem++;
      // Params
      const params = {
        filterType: self.indexSortBy,
        type: C.COLLECTION_TYPE.PLACE,
        cityId: this.urlSearchParams.cid,
        userId: this.urlSearchParams.uid,
        page: self.nextItem
      };
      // If exist auth
      if (self.me.id) {
        // Call api
        this.$store
          .dispatch(
            "collectionDetailPlace/ACTION_GET_DETAIL_COLLECTION_PLACE",
            params
          )
          .then(res => {
            this.textFriendRating = C.FILTER.TEXT_MY_RATE;
            this.itemSortBy = C.FILTER.TEXT_MY_RATE;
            if (res.places.length > C.PAGE_SIZE.DEFAULT) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false;
              self.hasMoreItems = false;
            }
          });
      } else {
        // If not auth
        this.$store
          .dispatch(
            "collectionDetailPlace/ACTION_GET_DETAIL_COLLECTION_PLACE_SHARE",
            params
          )
          .then(res => {
            this.textFriendRating =
              res.user &&
              res.user.nickname +
                `${res.user.nickname.slice(-1) == "s" ? "'" : "'s"}` +
                " Ratings";
            this.itemSortBy =
              res.user &&
              res.user.nickname +
                `${res.user.nickname.slice(-1) == "s" ? "'" : "'s"}` +
                " Ratings";
            if (res.places.length > C.PAGE_SIZE.DEFAULT) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false;
              self.hasMoreItems = false;
            }
          });
      }
    },

    // Change select
    handleOnChangePlace(e) {
      const self = this;
      if (e.target.value == C.DETAIL_COLLECTION_TYPE.POST) {
        self.$f7router.navigate({
          name: C.ROUTES_NAME.COLLECTION_DETAIL_POST,
          params: { id: self.urlSearchParams.uid }
        });
      }
      if (e.target.value == C.DETAIL_COLLECTION_TYPE.EVENT) {
        self.$f7router.navigate({
          name: C.ROUTES_NAME.COLLECTION_DETAIL_EVENT,
          params: { id: self.urlSearchParams.uid }
        });
      }
    },

    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    // Status show/hide sortby
    toggleSorting() {
      this.isSorting = !this.isSorting;
    },

    // Handle sort by
    handleSortBy(type, item) {
      const self = this;
      self.itemSortBy = item; // Set text
      self.indexSortBy = type; // Set type
      self.isSorting = false; // Set status
      if (type) {
        self.nextItem = 0;
        // Reset place
        this.$store.dispatch("collectionDetailPlace/ACTION_RESET_STATE");
        if (type == C.FILTER.DISTANCE) {
          this.geolocation();
        }
        this.loadMore(); // get data
      }
    },

    // Get location
    geolocation: function() {
      const self = this;
      const options = {
        timeout: 1000,
        maximumAge: 10000,
        enableHighAccuracy: true
      };
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            //do succes handling
            if (position.coords.latitude && position.coords.longitude) {
              localStorage.setItem(
                "currentLatLocation",
                position.coords.latitude
              );
              localStorage.setItem(
                "currentLngLocation",
                position.coords.longitude
              );
            }
          },
          function errorCallback(error) {
            self.$f7.dialog.alert(error.message, "Geolocation Error");
          },
          options
        );
      } else {
        self.$f7.dialog.alert(
          "Geolocation is not available",
          "Geolocation Error"
        );
      }
    }
  }
};
</script>
