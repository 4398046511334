import gql from "graphql-tag";

const createTip = gql `
mutation tip($data: CreateTipInput!) {
  createTip(data: $data) {
      id
      place {
        tips {
          id
          content
          author {
            id
            nickname
            avatar {
              url
              name
            }
          }
          place {
            rates {
              id
              action
            }
          }
          rate {
            id
            action
          }
        }
      }
    }
}
`

export  {
  createTip
};
