<template>
  <f7-block class="rating-steppers">
    <f7-row no-gap>
      <f7-col width="25">
        <f7-link class="stepper">
          <img 
            src="@/static/icons/rating_0.png"
            @click="handelRate(constants.RATE_STARTS.NO_RATING)"
            alt
          >
        </f7-link>
      </f7-col>
      <f7-col width="25" :class="`${ratingOK} line`">
        <f7-link class="stepper">
          <img
            v-if="rating === constants.RATE_STARTS.OK"
            src="@/static/icons/rating_1_active.png"
            alt
          >
          <img
            v-else
            src="@/static/icons/rating_1.png"
            alt
            @click="handelRate(constants.RATE_STARTS.OK)"
          >
        </f7-link>
      </f7-col>
      <f7-col width="25" :class="`${ratingHOT} line`">
        <f7-link class="stepper">
          <img
            v-if="rating === constants.RATE_STARTS.LIKE"
            src="@/static/icons/rating_2_active.png"
            alt
          >
          <img
            v-else
            src="@/static/icons/rating_2.png"
            alt
            @click="handelRate(constants.RATE_STARTS.LIKE)"
          >
        </f7-link>
      </f7-col>
      <f7-col width="25" :class="`${ratingFAVS} line`">
        <f7-link class="stepper">
          <img
            v-if="rating === constants.RATE_STARTS.FAVS"
            src="@/static/icons/rating_3_active.png"
            alt
          >
          <img
            v-else
            src="@/static/icons/rating_3.png"
            alt
            @click="handelRate(constants.RATE_STARTS.FAVS)"
          >
        </f7-link>
      </f7-col>
    </f7-row>
  </f7-block>
</template>
<script>
import C from "../_helpers/constants";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      constants: C,
      rating: null,
      ratingOK: "",
      ratingHOT: "",
      ratingFAVS: ""
    };
  },
  computed: {
    ...mapGetters("placeDetail", {
      item: "data",
      placeId: "id",
      form: "form"
    }),
    ...mapGetters("auth", {
      me: "data"
    })
  },
  mounted() {
    this.rateStatus();
  },
  methods: {
    onHandleDefault() {
      return null;
    },

    deleteRate(rateId, tipIds) {
      this.$store
          .dispatch("placeDetail/ACTION_DELETE_RATE", { id: rateId, tipIds: tipIds })
          .then(() => {
            this.ratingOK = ""
            this.ratingHOT = ""
            this.ratingFAVS = ""
          })
          .catch(error => {});
    },

    showToastBottom(rateState) {
      const self = this;
      let text;
      if(rateState == "new") {
        text = "Added to your list and magazine"
      } else if (rateState == "update") {
        text = "Updated in your list and magazine"
      } else if (rateState == "delete") {
        text = "Removed from your list and magazine"
      }
      // Create toast and open
      self.toastBottom = self.$f7.toast.create({ text, closeTimeout: 2000 });
      self.toastBottom.open();
    },

    handelRate(value) {
      const self = this;

      if (value === C.RATE_STARTS.NO_RATING && self.item.rateStatus) {
        const tipIds = self.item.tips
          .filter(({ author }) => author.id === self.me.id)
          .map(t => t.id)

        if (tipIds.length > 0) {

          self.$f7.dialog.create({
            title: '',
            text: 'Removing your rating will also delete your tip. Please confirm.',
            closeByBackdropClick: 'true',
            buttons: [
              {
                text: 'Cancel',
              },
              {
                text: 'Delete',
                cssClass: 'danger',
                onClick: function () {
                  self.rating = value;
                  self.deleteRate(self.item.rateStatus.id, tipIds);
                  self.showToastBottom("delete");
                }
              }
            ],
          }).open()
          return
        }

        this.rating = value;
        this.deleteRate(self.item.rateStatus.id, []);
        self.showToastBottom("delete");
      }

      this.rating = value;
      if (value === C.RATE_STARTS.OK) {
        if (self.item.rateStatus) {
          const paramsPut = {
            id: self.item.rateStatus.id,
            action: C.RATE_STARTS.OK,
            typePage: self.form,
            me: self.me.id,
            idPlace: self.item.id
          };
          this.$store
            .dispatch("placeDetail/ACTION_PUT_RATE", paramsPut)
            .then(res => {
              if (res.updateRate.id) {
                this.ratingOK = "active";
                this.ratingHOT = "";
                this.ratingFAVS = "";
              }
            })
            .catch(error => {});
          self.showToastBottom("update");
        } else {
          const paramsPost = {
            id: self.item.id,
            action: C.RATE_STARTS.OK,
            typePage: self.form,
            me: self.me.id,
            idPlace: self.item.id
          };
          this.$store
            .dispatch("placeDetail/ACTION_POST_RATE", paramsPost)
            .then(res => {
              if (res.createRate.id) {
                this.ratingOK = "active";
                this.ratingHOT = "";
                this.ratingFAVS = "";
              }
            })
            .catch(error => {});
           self.showToastBottom("new");
        }
      }
      if (value === C.RATE_STARTS.LIKE) {
        if (self.item.rateStatus) {
          const paramsPut = {
            id: self.item.rateStatus.id,
            action: C.RATE_STARTS.LIKE,
            typePage: self.form,
            me: self.me.id,
            idPlace: self.item.id
          };
          this.$store
            .dispatch("placeDetail/ACTION_PUT_RATE", paramsPut)
            .then(res => {
              if (res.updateRate.id) {
                this.ratingOK = "active";
                this.ratingHOT = "active";
                this.ratingFAVS = "";
              }
            })
            .catch(error => {});
          self.showToastBottom("update");
        } else {
          const paramsPost = {
            id: self.item.id,
            action: C.RATE_STARTS.LIKE,
            typePage: self.form,
            me: self.me.id,
            idPlace: self.item.id
          };
          this.$store
            .dispatch("placeDetail/ACTION_POST_RATE", paramsPost)
            .then(res => {
              if (res.createRate.id) {
                this.ratingOK = "active";
                this.ratingHOT = "active";
                this.ratingFAVS = "";
              }
            })
            .catch(error => {});
          self.showToastBottom("new");
        }
      }
      if (value === C.RATE_STARTS.FAVS) {
        if (self.item.rateStatus) {
          const paramsPut = {
            id: self.item.rateStatus.id,
            action: C.RATE_STARTS.FAVS,
            typePage: self.form,
            me: self.me.id,
            idPlace: self.item.id
          };
          this.$store
            .dispatch("placeDetail/ACTION_PUT_RATE", paramsPut)
            .then(res => {
              if (res.updateRate.id) {
                this.ratingOK = "active";
                this.ratingHOT = "active";
                this.ratingFAVS = "active";
              }
            })
            .catch(error => {});
          self.showToastBottom("update");
        } else {
          const paramsPost = {
            id: self.item.id,
            action: C.RATE_STARTS.FAVS,
            typePage: self.form,
            me: self.me.id,
            idPlace: self.item.id
          };
          this.$store
            .dispatch("placeDetail/ACTION_POST_RATE", paramsPost)
            .then(res => {
              if (res && res.createRate && res.createRate.id) {
                this.ratingOK = "active";
                this.ratingHOT = "active";
                this.ratingFAVS = "active";
              }
            })
            .catch(error => {});
          self.showToastBottom("new");
        }
      }
    },
    rateStatus() {
      const self = this;
      if (self.item.rateStatus) {
        switch (self.item.rateStatus.action) {
          case C.RATE_STARTS.NO_RATING:
            this.ratingOK = ""
            this.ratingHOT = ""
            this.ratingFAVS = ""
            this.rating = null
            return {
              active: this.ratingOK,
              active: this.ratingHOT,
              active: this.ratingFAVS,
              action: this.rating
            }
          case C.RATE_STARTS.OK:
            this.ratingOK = "active";
            this.rating = C.RATE_STARTS.OK;
            return {
              active: this.ratingOK,
              action: this.rating
            };
            break;
          case C.RATE_STARTS.LIKE:
            this.ratingOK = "active";
            this.ratingHOT = "active";
            this.rating = C.RATE_STARTS.LIKE;
            return {
              active: this.ratingHOT,
              action: this.rating
            };
            break;
          case C.RATE_STARTS.FAVS:
            this.ratingOK = "active";
            this.ratingHOT = "active";
            this.ratingFAVS = "active";
            this.rating = C.RATE_STARTS.FAVS;
            return {
              active: this.ratingOK,
              active: this.ratingHOT,
              active: this.ratingFAVS,
              action: this.rating
            };
            break;
          default:
            return (this.rating = null);
        }
      }
    }
  }
};
</script>

