import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    data: []
  },

  getters: {
    data: (state, getters, rootState) => {
      return _.forEach(state.data, function (item, key) {
        if (item.places.length > 0) {
          _.forEach(item.places, function (place, placeIdx) {
            place.isActiveBookmark = place.bookmarkStatus; // Add field active isActiveBookmark
          });
        }
      });
    }
  },

  actions: {
    ACTION_FETCH_TRENDING({
      commit
    }) {

      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.trendings
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_TRENDING', res.data.trendings)

            resolve(res.data.trendings)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
    MUTATION_FETCH_TRENDING(state, items) {
      state.data = items;
    },
  }
}
