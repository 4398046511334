import gql from "graphql-tag";

const placeFromShare = gql`
  query place($id: ID!) {
    place(id: $id) {
      id
      name
      address
      rating
      priceLever
      location {
        coordinates
      }
      categories {
        id
        name
      }

      tips {
        id
        content
        author {
          id
          nickname
          avatar {
            name
            url
          }
        }
        place {
          rates {
            id
            action
          }
        }
      }
      photos {
        name
        url
      }
      bookmarkStatus
      rateStatus {
        id
        action
      }
      rateStarts {
        publicStatus
        publicScore
        publicStats

        friendStatus
        friendScore
        friendStats
      }
      phone
      website
      coverUrl
      fb_link
      phone
      website
      coverUrl
      fb_link
    }
  }
`;

export { placeFromShare };
