<template>
  <span :class="className" @click="onHandle(id, pageType)">
    <img src="@/static/icons/comment.svg">
  </span>
</template>
<script>
export default {
  props: {
    id: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String,
      default: ""
    },
    pageType: {
      required: false,
      type: String
    },
  },
  methods: {
    onHandle(id, pageType) {
       const params = {
        id: id,
        pageType: pageType
      }
      this.$store.dispatch("comment/ACTION_GET_COMMENT", params);
    }
  }
};
</script>

