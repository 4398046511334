<template>
  <f7-page
    id="topic-posts"
    name="topic-posts"
    infinite
    :infinite-preloader="false"
    @infinite="loadMore"
  >
    <header-navbar-component title="Topics" />

    <div v-if="posts.length > 0">
      <div
        v-for="index in Math.ceil(posts.length / MaxPostInOneSummary)"
        :key="index"
      >
        <summary-view :showSeeMore="false" :allPosts="getOneSlice(index)" :topicId="id" :topicName="name"/>
      </div>
    </div>

    <toolbar-component
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
  </f7-page>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import HeaderNavbarComponent from "./HeaderNavbar";
import ToolbarComponent from "./Toolbar";
import SummaryView from "../views/Summary";

export default {
  components: {
    HeaderNavbarComponent,
    ToolbarComponent,
    SummaryView
  },
  data() {
    return {
      constants: C,
      MaxPostInOneSummary: 4,
      offset: 0,
      allowInfinite: true,
      hasMoreItems: true,
      nextItem: 0
    };
  },
  watch: {
    reload(isReload) {
      if (isReload) this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  props: {
    id: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
  },
  computed: {
    ...mapGetters("topicPosts", {
      posts: "posts"
    })
  },
  mounted() {},

  methods: {
    fetchData() {
      const self = this;
      self.nextItem = 1; // Set page 1
      self.hasMoreItems = true; // Remove icon load more
      self.allowInfinite = true; // Set status allow Infinite
      self.isLoading = true;
      this.actionFetchData(); // Call api fetch data recs
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return; // Return (not call api)
      self.allowInfinite = false; // Set status allow Infinite
      self.nextItem++; // Count +1 page (pagination)
      this.actionFetchData(); // Call api fetch data recs
    },
    // Function call api fetch data recs
    actionFetchData(actionType) {
      const self = this;
      // params
      const params = {
        id: self.id,
        name: decodeURI(self.name),
        page: self.nextItem
      };

      this.fetchPosts(params, actionType);
    },
    fetchPosts(params, actionType = "topicPosts/ACTION_FETCH_TOPIC_POSTS") {
      const self = this;
      this.$store
        .dispatch(actionType, params)
        .then(res => {
          this.$store.state.posts = res;
          self.isLoading = false; // // Check status data return form (api)
          if (res && res.length >= C.PAGE_SIZE.POSTS_IN_TOPIC) {
            self.allowInfinite = true; // Set status allow Infinite
          } else {
            // When data null
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          }
        })
        .catch(error => {
          self.isLoading = false; // Check status data return form (api)
          self.allowInfinite = false; // Set status allow Infinite
          self.hasMoreItems = false; // Remove icon load more
        });
    },
    getOneSlice(index){
      const endIdx = index * this.MaxPostInOneSummary
      const startIdx = endIdx - this.MaxPostInOneSummary
      
      return this.posts.slice(
        startIdx ,
        endIdx
      );
    },
  }
};
</script>
