import gql from "graphql-tag";

const feeds = gql `
query feeds($page: Int){
  feeds(page: $page) {
    id
    title
    content
    externalUrl
    externalSource
    siteName
    siteDescription
    city {
      name
    }
    author {
      id
      nickname
      avatar {
        url
        name
      }
    }
    photos{
      name
      url
    }
    statsLike
    statsComment
    likeStatus
    bookmarkStatus
    createdAt
    places {
      id
      placeId
      name
      photos{
        name
        url

      }
      rateStarts {
        publicStatus
        publicScore
        publicStats
        friendStatus
        friendScore
        friendStats
      }
      # categories {
      #   id
      #   name
      # }
      # city {
      #   name
      # }
      bookmarkStatus
    }
  }
}
`
export {feeds};
