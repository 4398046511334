import gql from "graphql-tag";

const me = gql `query me {
  me {
    id
    nickname
    email
    username
    avatar {
      name
      url
    }
    background {
      name
      url
    }
    friends {
      id
      status
      createdAt
      receive {
        id
        nickname
        avatar {
          name
          url
        }
        noOfRecs
      }
    }
    isActive
    featureFlags {
      showPostTab
      showPlansTab
    }
  }
}`


export { me };
