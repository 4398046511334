import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';

export default {
  namespaced: true,
  state: {
    data: {},
    createPostSuccess: false
  },

  getters: {
    data: state => state.data,
    createPostSuccess: state => state.createPostSuccess
  },

  actions: {
    ACTION_CREATE_POST_SUCCESS({
      commit
    }, status) {
      commit('MUTATIONS_CREATE_POST_SUCCESS', status);
    },

    ACTION_CREATE_POST({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.createPost
          })
          .then(res => {
            commit('STATUS_SUCCESS', res.data.createPost)
            commit('MUTATIONS_CREATE_POST_SUCCESS', true);
            resolve(res.data.createPost)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state, {
      post
    }) {
      state.data = post;
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
    MUTATIONS_CREATE_POST_SUCCESS(state, items) {
      state.createPostSuccess = items
    }
  }
}
