<template>
  <f7-page id="page-settings" name="settings">
    <header-navbar-action-component
      :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
      :actionType="constants.ACTION_TYPE.SAVE"
      :cancel="true"
      :save="() => handleUpdateProfile()"
    />
    <f7-block class="block-avatar">
      <f7-row>
        <f7-col width="100" class="wrap-avatar" raised @click="$refs.avatart.click()">
          <div v-if="imagesUpload.length == 0">
            <img v-if="content.avatar" :src="getUrlImg(content.avatar.url)">
            <span v-else class="text">
              <span>{{content.nickname.charAt(0)}}</span>
            </span>
          </div>
          <div v-else class="cell link" v-for="(value, key) in imagesUpload" :key="key">
            <img :src="value" :alt="value">
          </div>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block class="block-input">
      <f7-list no-hairlines class="no-margin-vertical">
        <input hidden ref="avatart" type="file" accept="image/*" @change="fileSelected">

        <f7-list-input
          class="required"
          label="NICKNAME"
          type="text"
          placeholder="Nickname"
          clear-button
          :value="content.nickname"
          @input="content.nickname = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          class="required"
          label="USERNAME"
          type="text"
          placeholder="Username"
          clear-button
          :value="content.username"
          @input="content.username = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="EMAIL"
          type="email"
          placeholder="Email"
          disabled
          :value="content.email"
          @input="content.email = $event.target.value"
        ></f7-list-input>
      </f7-list>
    </f7-block>

    <f7-block class="passport-background-theme" no-padding>
      <f7-row>
        <f7-col
          width="55"
          :class="`${colorTheme == constants.COLORS.DARK ? constants.COLORS.DARK : constants.COLORS.LIGHT} passport-background`"
        >
          <f7-block-title class="title">PASSPORT BACKGROUND</f7-block-title>
          <input
            hidden
            ref="passportBackground"
            type="file"
            accept="image/*"
            @change="fileSelectedPassport"
          >
          <div class="head-background" raised @click="$refs.passportBackground.click()">
            <div class="background-color"></div>
            <span v-if="backgroundUpload.length > 0">
              <img :src="value" :alt="value" v-for="(value, key) in backgroundUpload" :key="key">
            </span>
            <span v-else>
              <img
                v-if="content && content.background && content.background.url"
                :src="getUrlImg(content && content.background && content.background.url)"
              >
              <img v-else src="/static/images/passport-default.png" alt="Passport Theme">
            </span>
            <span class="overlay-bottom"></span>
            <f7-block class="user-info">
              <span class="overlay-top"></span>
              <f7-block class="no-padding">
                <h3 class="user-name">{{content.nickname}}</h3>
                <f7-row no-gap class="justify-content-left">
                  <f7-col width="100">
                    <div class="count">
                      <span class="number">{{passport.statsFriend}}</span>
                      <span class="text">friends</span>
                    </div>
                    <div class="count">
                      <span class="number">{{passport.statsCity}}</span>
                      <span class="text">cities visited</span>
                    </div>
                    <div class="count">
                      <span class="number">{{passport.statsPlace}}</span>
                      <span class="text">recommendations</span>
                    </div>
                  </f7-col>
                </f7-row>
              </f7-block>
            </f7-block>
          </div>
        </f7-col>
        <f7-col width="45" class="theme">
          <f7-block-title class="title">THEME</f7-block-title>
          <f7-button
            :class="`${colorTheme == constants.COLORS.DARK ? 'active' : ''} dark`"
            @click="handleColorTheme(constants.COLORS.DARK)"
          >Dark</f7-button>
          <f7-button
            :class="`${colorTheme == constants.COLORS.LIGHT ? 'active' : ''} light`"
            @click="handleColorTheme(constants.COLORS.LIGHT)"
          >Light</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
    <div class="account-privacy-logout">
      <f7-button>
        <span>
          Account privacy:
          <span class="bold">Public</span>
        </span>
      </f7-button>
      <f7-button @click="handleChangePassword()">
        <span>Change password</span>
      </f7-button>
      <f7-button>
        <a class= "link external" href="https://docs.google.com/document/d/1nsUnGPcSjYAR_8whba6SAIgUromp-1AXy8CgubX8q18/edit?usp=sharing" target="_blank">
        <span>Questions?  <img class="link-out" src="/static/icons/link.svg"/></span>
        </a>
      </f7-button>
      <f7-button>
        <a class= "link external" href="https://forms.gle/rhnXZqrZ9TtNXZLE9" target="_blank">
        <span>Take our survey 🙌  <img class="link-out" src="/static/icons/link.svg"/></span>
        </a>
      </f7-button>
      <f7-button @click="handleContactUs()">
        <span>Contact us</span>
      </f7-button>
      <f7-button @click="handleLogout()">
        <span class="log-out">Log out</span>
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { UploadImages } from "../utils/cloundinary-upload";
import { resizeImage } from "../utils/resize-image";
import { getUrlImg } from "../utils/cloundinary-image";
import C from "../_helpers/constants";
import HeaderNavbarActionComponent from "../components/HeaderNavbarAction";
export default {
  components: { HeaderNavbarActionComponent },
  name: "collection",
  data() {
    return {
      constants: C,
      imagesUpload: [],
      backgroundUpload: [],
      content: {
        avatar: "",
        nickname: "",
        username: "",
        // passport: null,
        email: "",
        background: null
      },
      colorTheme: C.COLORS.DARK,
      headingImg:
        "https://images.unsplash.com/photo-1555993539-2a718234a290?ixlib=rb-1.2.1&auto=format&fit=crop&w=1402&q=80"
    };
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("passport", {
      passport: "data"
    }),
    ...mapGetters("updateProfile", {
      message: "message"
    })
  },

  watch: {
    message(news, old) {
      if (this.toastBottom) {
        this.toastBottom = null;
      }
    }
  },

  beforeMount() {
    const self = this;
    const getColorTheme = localStorage.getItem("colorTheme");

    self.$store.dispatch("passport/ACTION_GET_PROFILE", {
      userId: this.me.id
    })

    if (getColorTheme) {
      self.colorTheme = getColorTheme;
      this.$store.dispatch("settings/ACTION_SET_COLOR_THEME", getColorTheme);
    }

    if (this.me) {
      this.content = {
        background: this.me.background,
        avatar: this.me.avatar,
        nickname: this.me.nickname,
        email: this.me.email,
        username: this.me.username || ""
      };
    }
  },
  mounted() {
    this.$f7.welcomescreen.close()
    if (!localStorage.getItem("skipTutorial")) {
      this.$f7.toast.create({
        text: 'Please update your profile.',
        position: 'top',
        closeTimeout: 5000,
        closeButton: true,
        closeButtonText: 'Close',
        closeButtonColor: '#FFF',
        cssClass: 'update-profile-toast'
      }).open()
    }
    localStorage.setItem("skipTutorial", true)
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    showToastBottomLogin(text) {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: text,
          closeTimeout: 5000,
          cssClass: "toast-login"
        });
      }
      // Open it
      self.toastBottom.open();
    },
    handleLogout() {
      const self = this;
      this.$f7.dialog
        .create({
          text: "Log out of your account?",
          buttons: [
            {
              text: "Cancel",
              onClick: function() {}
            },
            {
              text: "Log Out",
              onClick: function(dialog, e) {
                self.$store.dispatch("auth/ACTION_LOGOUT").then(res => {
                  if (res) {
                    FB.getLoginStatus(function(response) {
                      if (response && response.status === "connected") {
                        // if logged in via FB
                        FB.logout(function(response) {
                          console.log("Logged out of FB", response)
                          self.$f7router.navigate(C.ROUTER_NAVIGATE.LOGIN);
                        });
                      } else if (response.status === "unknown") {
                        // if not logged in via FB
                        self.$f7router.navigate(C.ROUTER_NAVIGATE.LOGIN);
                      }
                    });
                  }
                });
              }
            }
          ]
          // verticalButtons: true
        })
        .open();
    },
    handleContactUs() {
      this.$f7.dialog
        .create({
          text: "Feel free to reach out directly - jacques@nativeprojex.com",
          buttons: [
            {
            text: "Got it",
            onClick: function() {}
            }
          ]
        })
        .open();
    },
    handleChangePassword() {
      this.$f7.dialog
        .create({
          text: "Sorry! This feature is not implemented yet. Email 'Favs password reset' to info@nativeprojex.com and we will reset your password for that email on Favs.",
          buttons: [
            {
            text: "Got it",
            onClick: function() {}
            }
          ]
        })
        .open();
    },
    fileSelected(e) {
      const self = this;
      return resizeImage(e).then(res => {
        if (res) {
          if (self.imagesUpload.length < 10) {
            self.imagesUpload = [];
            self.imagesUpload.push(res);
          } else {
            self.$f7.dialog.alert(
              "Sorry, you’ve exceeded our max of 10 photos."
            );
          }
        } else {
          self.$f7.dialog.alert(
            "An error occurred in the upload. Please try again!"
          );
        }
      });
    },
    fileSelectedPassport(e) {
      const self = this;
      return resizeImage(e).then(res => {
        if (res) {
          if (self.backgroundUpload.length < 10) {
            self.backgroundUpload = [];
            self.backgroundUpload.push(res);
          } else {
            self.$f7.dialog.alert(
              "Sorry, you’ve exceeded our max of 10 photos."
            );
          }
        } else {
          self.$f7.dialog.alert(
            "An error occurred in the upload. Please try again!"
          );
        }
      });
    },
    handleColorTheme(color) {
      const self = this;
      if (color) {
        self.colorTheme = color;
      }
    },
    async handleUpdateProfile() {
      const self = this;
      self.$f7.dialog.progress("Updating...");
      if (this.content.nickname.length < 1) {
        self.$f7.dialog.close();
        self.$f7.dialog.alert("Please input nickname");
      } else if (this.content.username.length < 1) {
        self.$f7.dialog.close();
        self.$f7.dialog.alert("Please input username");
      } else if (this.content.username.split(" ").length > 1) {
        self.$f7.dialog.close();
        self.$f7.dialog.alert("Username must contain no spaces");
      } else if (this.content.email.length < 1) {
        self.$f7.dialog.close();
        self.$f7.dialog.alert("Please input email");
      } else {
        if (self.imagesUpload.length > 0 && self.imagesUpload[0] != null) {
          let images = await UploadImages(this.imagesUpload);
          if (images.some(image => image.url == undefined)) {
            self.$f7.dialog.close();
            return self.$f7.dialog.alert("Error, image size has exceeded 10MB");
          } else {
            // let arrImage = _.map(
            //   images,
            //   _.partialRight(_.pick, ["name", "width", "height", "url"])
            // );
            let arrImage = _.map(images);
            this.content.avatar = arrImage[0];
          }
        }
        if (
          self.backgroundUpload.length > 0 &&
          self.backgroundUpload[0] != null
        ) {
          let images = await UploadImages(this.backgroundUpload);
          if (images.some(image => image.url == undefined)) {
            self.$f7.dialog.close();
            return self.$f7.dialog.alert("Error, image size has exceeded 10MB");
          } else {
            // let arrImage = _.map(
            //   images,
            //   _.partialRight(_.pick, ["name", "width", "height", "url"])
            // );
            let arrImage = _.map(images);
            this.content.background = arrImage[0];
          }
        }
        if (this.content) {
          const params = {
            avatar: self.imagesUpload.length > 0 ? self.content.avatar : null,
            username: self.content.username,
            nickname: self.content.nickname,
            email: self.content.email,
            background:
              self.backgroundUpload.length > 0 ? self.content.background : null
          };
          this.$store
            .dispatch("updateProfile/ACTION_UPDATE_PROFILE", params)
            .then(res => {
              if (res.id) {
                self.$f7.dialog.close();
                // self.$f7.dialog.alert("Success");
                self.$f7router.navigate(C.ROUTER_NAVIGATE.PASSPORT);
              } else {
                self.$f7.dialog.close();
                self.showToastBottomLogin(self.message.text);
              }
            });
          this.$store.dispatch(
            "settings/ACTION_SET_COLOR_THEME",
            self.colorTheme
          );
        }
      }
    },
    getUrlImg(name) {
      return getUrlImg(name);
    }
  }
};
</script>
