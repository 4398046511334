<template>
  <a v-if="externalUrl" :href="externalUrl" target="_blank" external class="more external link">
    <span medium v-show="title" @click="()=> onHandle()" :class="className">{{ title }}</span>
  </a>
  <span v-else medium v-show="title" @click="()=> onHandle()" :class="className">{{ title }}</span>
</template>
<script>
export default {
  props: {
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    title: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String,
      default: ""
    },
    externalUrl: {
      required: false,
      type: String
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

