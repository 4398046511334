const processUpload = async file => {
  const url = 'https://api.cloudinary.com/v1_1/myfavsapp/upload'
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const image = JSON.parse(xhr.responseText);
        const response = {
         name: image.public_id,
         sha256: image.signature,
         hash: image.signature,
         ext: image.format.replace(".", ""),
         mime: "image/jpeg",
         size: image.bytes / 1024,
         url: image.secure_url,
         provider_metadata: {
           public_id: image.public_id,
           resource_type: image.resource_type
         },
         provider: "cloudinary"
        }
        resolve(response)
      } else if (xhr.readyState === 4 && xhr.status !== 200) {
        reject('Error uploading images. Please try again')
      }
    }

    fd.append('upload_preset', 'fz2kvqxa');
    fd.append('tags', 'browser_upload');
    fd.append('file', file);
    xhr.send(fd);
  })
};

export function UploadImage(file) {
  return processUpload(file);
}

export function UploadImages(files) {
  return Promise.all(
    files.map(file => {
      return processUpload(file);
    })
  );
}
