import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';

export default {
  namespaced: true,
  state: {
    people: [],
    places: [],
    friendFavs: [],
    showDistance: false,
    events: []
  },

  getters: {
    people: state => state.people,
    places: state => state.places,
    friendFavs: state => state.friendFavs,
    showDistance: state => state.showDistance,
    events: state => state.events
  },

  actions: {
    ACTION_ADD_FRIENDS_FROM_RECS({
      commit
    }, params) {
      if (params) {
        commit('MUTATIONS_ADD_FRIENDS_FROM_RECS', params);
      }
    },
    ACTION_SHOW_DISTANCE({
      commit
    }, params) {
      if (params) {
        commit('MUTATIONS_SHOW_DISTANCE', params);
      }
    },
    ACTION_PEOPLE_SEARCH({
      commit
    }, params ) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              q: params.q,
              cityId: params.cityId
            },
            mutation: gqlQuery.searchUsers
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_PEOPLE_SEARCH", res.data.search.users);
            resolve(res.data.search.users);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      })
    },
    ACTION_FRIENDFAVS_SEARCH({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              q: params.q,
              cityId: params.cityId
            },
            mutation: gqlQuery.searchFriendFavs
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FRIENDFAVS_SEARCH', res.data.search.friendFavs)
            resolve(res.data.search.friendFavs)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
    ACTION_EVENTS_SEARCH({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              q: params.q,
              cityId: params.cityId
            },
            mutation: gqlQuery.searchEvents
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_EVENTS_SEARCH", res.data.search.events);
            resolve(res.data.search.events);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      })
    },
    ACTION_PLACE_SEARCH({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              q: params.q,
              cityId: params.cityId
            },
            mutation: gqlQuery.searchPlaces
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_PLACE_SEARCH', res.data.search.places)
            resolve(res.data.search.places)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },

    MUTATION_PEOPLE_SEARCH(state, items) {
      state.people = items;
    },

    MUTATION_PLACE_SEARCH(state, items) {
      state.places = items;
    },

    MUTATION_FRIENDFAVS_SEARCH(state, items) {
      state.friendFavs = items;
    },

    MUTATION_EVENTS_SEARCH(state, items) {
      state.events = items;
    },

    MUTATIONS_ADD_FRIENDS_FROM_RECS(state, items) {
      state.add_friends_from_recs = items
    },

    MUTATIONS_SHOW_DISTANCE(state, items) {
      state.showDistance = items
    },


  }
}
