export default {
  namespaced: true,
  state: {
    data: [],
  },

  getters: {
    data: state => state.data,
  },

  actions: {
    ACTION_REDIRECT_PAGE({
      commit
    }, data) {
      commit('STATUS_SUCCESS')
      commit('MUTATION_REDIRECT_PAGE', data)
    },
  },


  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },

    MUTATION_REDIRECT_PAGE(state, data) {
      state.data = data
    },
  }
}
