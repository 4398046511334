import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    data: []
  },

  getters: {
    data: (state, getters, rootState) => {
      let arr =
        state.data ?
        state.data : [];
      return _.forEach(arr, function (item, key) {
        item.isActiveBookmark = item.bookmarkStatus; // Add field active isActiveBookmark
      });
    },
  },

  actions: {
    ACTION_FETCH_FRIEND_FAVOURITE({
      commit
    }) {

      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.friendFavs
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FRIEND_FAVOURITE', res.data.friendFavs)
            resolve(res.data.friendFavs)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
    MUTATION_FRIEND_FAVOURITE(state, items) {
      state.data = items;
    },
  }
}
