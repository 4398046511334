<template>
  <div class="item-content item-input">
    <div class="item-inner">
      <div class="item-title item-label">{{title}}</div>
      <div class="item-input-wrap">
        <select @change="onChange($event.target.value)">
          <option
            v-for="(item, key) in items"
            :key="key"
            :selected="cityId === item.id"
            :value="item.id"
          >{{ item.name }}</option>
        </select>
        <span class="icon-chevron">
          <f7-icon f7="chevron_up"></f7-icon>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      required: false,
      type: Array,
      default: null
    },
    cityId: {
      required: false,
      type: String,
      default: null
    },
    title: {
      required: false,
      type: String,
      default: "Select City"
    },
    onChange: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

