import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';

export default {
  namespaced: true,
  state: {
    data: []
  },

  getters: {
    data: state => state.data,
  },

  actions: {
    ACTION_FETCH_CATEOGRY({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.categoriesEvents
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_CATEOGRY', res.data.categoriesEvents)
            resolve(res.data)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
    MUTATION_FETCH_CATEOGRY(state, items) {
      state.data = items;
    }
  }
}
