import gql from "graphql-tag";

const updateTagPopularity = gql `
  mutation updateTagPopularity($id: ID!) {
    updateTagPopularity(id: $id) {
      id
    }
  }
`

export  {
  updateTagPopularity
};
