import gql from "graphql-tag";

const collections = gql `query collections {
  collections{
    id
    name
    cover
    statsPlace
    statsPost
  }
}`

export {
  collections
};
