import gql from "graphql-tag";

const updateTip = gql `
mutation updateTip($data: UpdateTipInput!) {
  updateTip(data: $data) {
    id
    place {
      tips {
        id
        content
        author {
          id
          nickname
          avatar {
            url
            name
          }
        }
        place {
          rates {
            id
            action
          }
        }
      }
    }
  }
}
`

export  {
  updateTip

};
