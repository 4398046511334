<template>
  <f7-page id="page-post-comment" name="comment">
    <header-navbar title="Comment"/>

    <div class="review-block">
      <f7-list media-list no-hairlines class="comments-block">
        <f7-list-item v-for="(item, key) in items.comments" :key="key" :text="`${item.content}`">
          <span slot="footer">{{ item.createdAt | moment("from", true) }} ago</span>
          <span
            slot="title"
            @click="actionFetchAuthProfile(item.author.id)"
          >{{item.author.nickname}}</span>
          <img
            slot="media"
            v-if="item.author.avatar && item.author.avatar.name"
            :src="handleImg(item.author.avatar ? item.author.avatar.name : false)"
            @click="actionFetchAuthProfile(item.author.id)"
          >
          <span
            v-else
            slot="media"
            class="text"
            @click="actionFetchAuthProfile(item.author.id)"
          >{{item.author.nickname.charAt(0)}}</span>
          <div class="action" v-if="me.id == item.author.id">
            <f7-link @click="handleEditComment(item.content, item.id)" icon-ios="f7:more"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>
    </div>

    <!-- Toolbar -->
    <!-- <f7-toolbar class="create-comment-input" position="bottom">

    </f7-toolbar>-->
    <div no-hairlines class="no-margin-vertical create-comment-input">
      <f7-input
        type="textarea"
        placeholder="Add a comment..."
        clear-button
        :value="content"
        @input="content = $event.target.value"
        @focus="setActive"
        @blur="clearActive"
        :class="isActiveInput || content != '' ? 'active' : ''"
      ></f7-input>
      <span v-if="content == ''" class="icon-location text-post">
        Post
      </span>
      <span v-else-if="isEdit && content != null" class="icon-location" @click="editComment()">
        <f7-icon f7="edit" size="16px"></f7-icon>
      </span>
      <span v-else-if="content != '' && !isEdit" class="icon-location text-post" @click="addNewComment(pageType)">Post</span>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import HeaderNavbar from "../../components/HeaderNavbar";
import C from "../../_helpers/constants";
import { getUrlImg, getUrlImgArticle } from "../../utils/cloundinary-image";
export default {
  components: { HeaderNavbar },
  name: "comment",
  data() {
    return {
      constants: C,
      content: "",
      isEdit: false,
      idComment: null,
      isActiveInput: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("comment", {
      items: "data",
      pageType: "pageType"
    })
    // id() {
    //   // Get id post
    //   return this.$store.state.detailPost.data.post.id;
    // },
  },

  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    handleImg(name) {
      return getUrlImg(name);
    },

    // Add new comment
    addNewComment(page) {
      const self = this;
      if (self.content) {
        const params = {
          id: this.items.id,
          content: self.content,
          type: C.COMMENT_TYPE.POST
        };
        const pageType = {
          type: page
        };
        this.$store
          .dispatch("comment/ACTION_POST_COMMENT", { params, pageType })
          .then(res => {
            Object.assign(this.$data, this.$options.data());
          });
      }
    },

    // Edit comment
    editComment() {
      const self = this;
      if (self.content) {
        const params = {
          id: this.idComment,
          content: self.content,
          type: C.COMMENT_TYPE.POST
        };
        this.$store.dispatch("comment/ACTION_PUT_COMMENT", params).then(res => {
          Object.assign(this.$data, this.$options.data());
        });
      }
    },
    setActive() {
      this.isActiveInput = true;
    },
    clearActive() {
      this.isActiveInput = false;
      this.isEdit = false;

    },
    // Action comment
    handleEditComment(item, id) {
      const self = this;
      self.content = item;
      self.isEdit = true;
      self.idComment = id;
    }
  }
};
</script>

<style scoped>
</style>
