import gql from "graphql-tag";
const registerEmail = gql `
  mutation registerEmail($data: AuthInput!) {
    registerEmail(data: $data) {
      token
      user {
        id

      }
    }
  }
`
export  {
  registerEmail
};
