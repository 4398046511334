 <template>
  <f7-row :class="className">
    <f7-col width="60">
      <f7-block-header class="sub-title">
        <span class="f7-icons">placemark_fill</span>

        Distance
      </f7-block-header>
      <f7-row no-gap class="rating-display">
        <f7-col>
          <div class="title">Public</div>
          <div class="count"></div>
          <div class="total-rate">publicScore rated</div>
        </f7-col>
        <f7-col>
          <div class="title">Friends</div>
          <div class="count"></div>
          <div class="total-rate">friendScore rated</div>
        </f7-col>
      </f7-row>
    </f7-col>
    <f7-col width="40">
       <f7-row no-gap class="rating-display">
        <f7-col class="recs-block-skeleton_thumb"></f7-col>
      </f7-row>
    </f7-col>
  </f7-row>
</template>
<script>
export default {
  props: {
    className: {
      required: true,
      type: String
    }
  }
};
</script>
