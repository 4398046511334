<template>
  <f7-row no-gap class="rating-display">
    <f7-col>
      <span v-on:click="()=> onHandle()">
      <div class="title">Public</div>
      <div class="count">
        <span v-if="item.rateStarts">
          <img
            v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.FAVS"
            src="@/static/icons/rating_3_active.png"
            alt
          >
          <img
            v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.LIKE"
            src="@/static/icons/rating_2_active.png"
            alt
          >
          <img
            v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.OK"
            src="@/static/icons/rating_1_active.png"
            alt
          >
          <img
            v-if="item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.OK && item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.LIKE && item && item.rateStarts && item.rateStarts.publicStatus == constants.RATE_STARTS.FAVS"
            src="@/static/icons/rating_0.png"
            alt
          >
        </span>
        <span v-else >
          <img src="@/static/icons/rating_1_active.png" alt>
        </span>
        <label>{{item && item.rateStarts && item.rateStarts.publicScore ? item.rateStarts.publicScore : 0}}</label>
      </div>
      <div
        class="total-rate"
      >{{item && item.rateStarts && item.rateStarts.publicStats ? item.rateStarts.publicStats : 0}} rated</div>
      </span>
    </f7-col>
    <f7-col>
      <span v-on:click="()=> onHandle()">
      <div class="title">Friends</div>
      <div class="count">
        <span v-if="item.rateStarts" v-on:click="()=> onHandle()">
          <img
            v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.FAVS"
            src="@/static/icons/rating_3_active.png"
            alt
          >
          <img
            v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.LIKE"
            src="@/static/icons/rating_2_active.png"
            alt
          >
          <img
            v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.OK"
            src="@/static/icons/rating_1_active.png"
            alt
          >
          <img
            v-if="item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.OK && item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.LIKE && item && item.rateStarts && item.rateStarts.friendStatus == constants.RATE_STARTS.FAVS"
            src="@/static/icons/rating_0.png"
            alt
          >
        </span>
        <span v-else>
          <img src="@/static/icons/rating_1_active.png" alt>
        </span>
        <label>{{item && item.rateStarts && item.rateStarts.friendScore ? item.rateStarts.friendScore : 0}}</label>
      </div>
      <div
        class="total-rate"
      >{{item && item.rateStarts && item.rateStarts.friendStats ? item.rateStarts.friendStats : 0}} rated</div>
      </span>
    </f7-col>
  </f7-row>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    constants: {
      required: true,
      type: Object
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

