<template>
  <f7-page class="page-recs" name="recs" infinite :infinite-preloader="false" @infinite="loadMore" ptr @ptr:refresh="refetchData">
    <header-navbar-component title="Recs" :backButtonEnabled="false"/>
    <nav-city-component :name="city.name"/>
    <f7-block class="page-feed__block-recs">
      <f7-swiper :params="{speed:500, slidesPerView: 'auto'}">
        <f7-swiper-slide v-for="(item, index) in categories" :key="index">
          <select-topic :color="`#0395A7`" :name="item.name" :onHandle="() => handleCategories(index, item)" :active="index == indexCategories.index"/>
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>

    <f7-swiper class="friends" :params="{speed:500, slidesPerView: 5, spaceBetween: 0}">
      <f7-swiper-slide>
        <button-friend-component
          :className="`${indexFriends.index === null ? 'active' : ''}`"
          tabLink=".tab-recs-friend"
          name="All"
          :active="indexFriends.index == null"
          :onClick="() => handleFriends(null, {id: null, nickname: `Friends`})"
          :buttonType="constants.BUTTON_TYPE.ALL"
        />
      </f7-swiper-slide>
      <f7-swiper-slide>
        <button-friend-component
          :className="`${indexFriends.index === 'me' ? 'active' : ''}`"
          tabLink=".tab-recs-me"
          :avatar="me && me.avatar && me.avatar.name"
          :onHandleAvatar="getAvatarAuthImg(me && me.avatar != null && me.avatar.name)"
          :nickname="me && me.nickname && me.nickname.charAt(0)"
          name="Me"
          :onClick="() => handleFriends('me', {id: 'me', nickname: `My`})"
          :buttonType="constants.BUTTON_TYPE.FRIEND"
        />
      </f7-swiper-slide>
      <f7-swiper-slide v-for="(item, index) in friends" :key="'F'+index">
        <button-friend-component
          :className="`${'F'+index === indexFriends.index ? 'active' : ''}`"
          tabLink=".tab-recs-friend"
          :avatar="item.avatar && item.avatar.name"
          :onHandleAvatar="getAvatarAuthImg(item.avatar != null && item.avatar.name)"
          :nickname="item.nickname.charAt(0)"
          :name="item.nickname"
          :onClick="() => handleFriends('F'+index, item)"
          :buttonType="constants.BUTTON_TYPE.FRIEND"
        />
      </f7-swiper-slide>
      <f7-swiper-slide>
        <button-friend-component :buttonType="constants.BUTTON_TYPE.ADD_FRIEND"/>
      </f7-swiper-slide>
      <f7-swiper-slide v-for="(item, index) in publishers" :key="'P'+index">
        <button-friend-component
          :className="`${'P'+index === indexFriends.index ? 'active' : ''}`"
          tabLink=".tab-recs-friend"
          :avatar="item.avatar && item.avatar.name"
          :onHandleAvatar="getAvatarAuthImg(item.avatar != null && item.avatar.name)"
          :nickname="item.nickname.charAt(0)"
          :name="item.nickname"
          :onClick="() => handleFriends('P'+index, item)"
          :buttonType="constants.BUTTON_TYPE.FRIEND"
        />
      </f7-swiper-slide>
      <f7-swiper-slide></f7-swiper-slide>
      <f7-swiper-slide></f7-swiper-slide>
      <f7-swiper-slide></f7-swiper-slide>
    </f7-swiper>
    <sort-by-recs-component
      :handleSortBy="(type, text)=>handleSortBy(type, text)"
      :toggleSorting="()=>toggleSorting()"
      :textSortBy="textSortBy"
      :handleOnTagClick="handleOnTagClick"
      :tag="tag"
      :tagName="tagName"
      :subFilters="subFilters || firstCategory.subFilters"
      :popularTags="popularTags || firstCategory.popularTags"
      :indexSortBy="indexSortBy"
      :isSorting="isSorting"
      :textFriendRating="textFriendRating"
      :clearFilter="handleClearFilterClick"
    />
    <f7-tabs>
      <f7-tab :class="`tab-recs-me`" tab-active>
        <div v-if="isLoading">
          <recs-skeleton-component
            v-for="n in 10"
            :key="n"
            :className="`skeleton-text skeleton-effect-${effect}`"
          />
        </div>
        <div v-if="!isLoading">
          <place-view :items="recs" :pageType="constants.PAGE_CONTENT_TYPE.RECS"/>

          <f7-block v-show="recs.length === 0 || !showDistance" class="recs-null" no-padding>
            <h4 v-if="recs.length === 0">
              Your favs list needs some love 😥
              <!-- <img src="@/static/icons/questions.png" alt> -->
            </h4>
            <p v-if="recs.length === 0">
              <span>
                Rate a place to add to your favs list.
                <span>Select "All" or a friend for suggestions.</span>
              </span>
            </p>
          </f7-block>
        </div>
      </f7-tab>
      <f7-tab :class="`tab-recs-friend`">
        <div v-if="isLoading">
          <recs-skeleton-component
            v-for="n in 10"
            :key="n"
            :className="`skeleton-text skeleton-effect-${effect}`"
          />
        </div>
        <div v-if="!isLoading">
          <place-view :items="recs" :pageType="constants.PAGE_CONTENT_TYPE.RECS"/>

          <f7-block class="recs-null" v-show="recs.length === 0 || !showDistance">
            <div v-if="recs.length === 0 && indexFriends.name !=='Friends'">
              <h4>{{indexFriends.name}} has no favs here 😒</h4>
              <p>
                  Select a different category to see their other recommendations.
              </p>
            </div>
            <!-- Empty message needs to be adjusted for "friends which is plural" -->
            <div v-else-if="recs.length === 0 && indexFriends.name ==='Friends'">
              <h4>Friends have no favs here 😒</h4>
              <p>
                  Select a different category or sort by option.
              </p>
            </div>
          </f7-block>
        </div>
      </f7-tab>
    </f7-tabs>
    <toolbar-component
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="true"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
      :handleRecs="handleScrollRecs"
    />

    <div class="preloader infinite-scroll-preloader" v-show="hasMoreItems"></div>
  </f7-page>
</template>

<script>
import HeaderNavbarComponent from "../components/HeaderNavbar";
import ToolbarComponent from "../components/Toolbar";

import RecsSkeletonComponent from "../components/RecsSkeleton";

import NavCityComponent from "../components/NavCity";
import CategoryComponent from "../components/Category";
import ButtonFriendComponent from "../components/ButtonFriend";
import SortByRecsComponent from "../components/SortByRecs";
import SelectTopic from '../components/SelectTopic'

import PlaceView from "../views/PlaceView";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
import C from "../_helpers/constants";
import { mapGetters, mapState, mapActions } from "vuex";
import fetchGeoLocation from '../utils/fetchGeoLocation'

export default {
  components: {
    HeaderNavbarComponent,
    ToolbarComponent,
    RecsSkeletonComponent,
    SelectTopic,

    NavCityComponent,
    CategoryComponent,
    ButtonFriendComponent,
    SortByRecsComponent,

    PlaceView
  },
  data() {
    return {
      constants: C,
      allowInfinite: true,
      hasMoreItems: true,
      nextItem: 0,
      isActiveTip: false,
      indexCategories: { index: 0, id: null },
      indexFriends: { index: null, id: null, name: "Friends" },
      typeSortBy: C.FILTER.FRIEND_RATE,
      indexSortBy: C.FILTER.FRIEND_RATE,
      textSortBy: '',
      tag: null,
      tagName: null,
      subFilters: null,
      popularTags: null,
      isSorting: false,
      isLoading: false,
      effect: null,
      textFriendRating: C.FILTER.TEXT_FRIEND_RATE,
      friends: [],
      publishers: [],
    };
  },
  watch: {
    bookmarkStatus(news) {
      if (news) {
        this.showToastBottom();
      }
    },
    reload(isReload) {
      if (isReload) {
        this.fetchData()
      }
    },
    reloadCreators() {
      this.fetchCreators()
    }
  },

  created() { 
    this.fetchData().then(() => {
      if (this.recs.length === 0) {
        return this.fetchAllRecsByPopularity()
      }
      this.textSortBy = C.FILTER.TEXT_FRIEND_RATE
    })
  },

  mounted() {
    this.fetchCreators()
    this.fetchMe()
  },

  computed: {
    ...mapGetters("cities", {
      city: "currentCity"
    }),
    ...mapGetters("bookMark", {
      bookmarkStatus: "bookmark"
    }),
    ...mapGetters("currentUrl", {
      currentUrl: "data"
    }),
    ...mapGetters("me", {
      me: "data"
    }),
    ...mapGetters("categories", {
      categories: "data",
      firstCategory: "firstCategory"
    }),
    ...mapGetters("search", {
      showDistance: "showDistance"
    }),
    ...mapGetters("recs", {
      recs: "data",
      reload: "reload",
      reloadCreators: "reloadCreators",
    }),
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Saved to plan",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    },
    // Scroll to top
    handleScrollRecs() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Load more (when scroll to bottom)
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return; // Return (not call api)
      self.allowInfinite = false; // Set status allow Infinite
      if (self.recs.length >= 500) {
        self.hasMoreItems = false; // Remove icon load more
        self.allowInfinite = false; // Set status allow Infinite
        return;
      }
      self.nextItem++; // Count +1 page (pagination)
      this.actionFetchData("recs/ACTION_FETCH_MORE_RECS"); // Call api fetch data recs
    },

    // Fetch data first load
    fetchData() {
      const self = this;
      self.nextItem = 1; // Set page 1
      self.hasMoreItems = true; // Remove icon load more
      self.allowInfinite = true; // Set status allow Infinite
      self.isLoading = true;
      return this.actionFetchData(); // Call api fetch data recs
    },

    // Get location
    getLocation: async function() {
      const self = this
      return await fetchGeoLocation({
        onSuccess: (lat, lng) => {
          self.$store.dispatch('search/ACTION_SHOW_DISTANCE', true)
          self.currentLocation = { lat, lng }
        },
        onError: (error) => {
          self.$f7.dialog.alert(error, 'Geolocation Error')
          self.$store.dispatch('search/ACTION_SHOW_DISTANCE', false)
        }
      })
    },

    // Function call api fetch data recs
    actionFetchData(actionType) {
      const self = this;
      // params
      const params = {
        where: {
          categoryId:
            self.indexCategories.id == null
              ? this.firstCategory.id
              : self.indexCategories.id,
          friendId: self.indexFriends.id ,
          filterType: self.typeSortBy,
        },
        page: self.nextItem
      };

      if (self.tag) params.where.filterTags = [self.tag]
      return this.fetchRecs(params, actionType)
    },

    // Get avatar auth
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return "";
      }
    },

    // Toggle sort by
    toggleSorting() {
      this.isSorting = !this.isSorting;
    },

    // Select friend
    handleFriends(index, item) {
      const self = this;
      self.indexFriends.index = index; // Set index
      self.indexFriends.id = item.id; // Set id
      self.indexFriends.name = item.nickname; // Set show name
      self.textFriendRating =
        self.indexFriends.name +
        `${
          self.indexFriends.name.slice(-1) == "s" && index != "me"
            ? "'"
            : index == "me"
            ? ""
            : "'s"
        }` +
        " Ratings"; // Set show text sort by
      if (self.typeSortBy == C.FILTER.FRIEND_RATE) {
        self.textSortBy =
          self.indexFriends.name +
          `${
            self.indexFriends.name.slice(-1) == "s" && index != "me"
              ? "'"
              : index == "me"
              ? ""
              : "'s"
          }` +
          " Ratings";
      }
      this.fetchData(); //Fetch data
    },

    // Select category
    handleCategories(index, { id, subFilters, popularTags }) {
      const self = this
      self.indexCategories.index = index
      self.indexCategories.id = id
      self.tag = null
      self.tagName = null
      self.subFilters = subFilters
      self.popularTags = popularTags
      this.fetchData()
    },

    // Sort by
    handleSortBy(type, text) {
      const self = this;
      self.isLoading = true;
      if (type) {
        self.textSortBy = text; // Set text
        self.indexSortBy = type; // Set type
        self.isSorting = !self.isSorting; // Set toggle sort by
        self.typeSortBy = type;
        const params = {
          where: {
            categoryId:
              self.indexCategories.id == null
                ? this.firstCategory.id
                : self.indexCategories.id,
            friendId: self.indexFriends.id,
            filterType: type
          },
          page: 1
        };

        if (self.tag) params.where.filterTags = [self.tag]
        if (type === C.FILTER.DISTANCE) {
          return this.getLocation().then(() => this.fetchRecs(params))
        }

        this.fetchRecs(params)
      }
    },

    handleClearFilterClick() {
      this.typeSortBy = C.FILTER.FRIEND_RATE
      this.indexSortBy = C.FILTER.FRIEND_RATE
      if (this.typeSortBy == C.FILTER.FRIEND_RATE) {
        this.textSortBy =
          this.indexFriends.name +
          `${
            this.indexFriends.name.slice(-1) == "s" && this.indexFriends.index != "me"
              ? "'"
              : this.indexFriends.index  == "me"
              ? ""
              : "'s"
          }` +
          " Ratings";
      }
      this.tag = null
      this.tagName = null

      const params = {
        where: {
          categoryId: this.indexCategories.id || this.firstCategory.id,
          friendId: this.indexFriends.id,
          filterType: this.typeSortBy
        },
        page: 1
      }

      this.fetchRecs(params)
    },

    handleOnTagClick({ id, name }) {
      const self = this
      const { indexFriends, indexCategories, firstCategory, typeSortBy, isSorting } = self
      self.tag = id
      self.tagName = name
      self.isLoading = true
      self.isSorting = !isSorting
      const params = {
        where: {
          categoryId: indexCategories.id || firstCategory.id,
          friendId: indexFriends.id,
          filterType: typeSortBy,
          filterTags: [id]
        },
        page: 1
      }
      this.$store.dispatch("recs/ACTION_TAG_HIT", { id })
      this.fetchRecs(params)
    },

    fetchRecs (params, actionType = "recs/ACTION_FETCH_REC") {
      const self = this

      return this.$store
        .dispatch(actionType, params)
        .then(res => {
          self.isLoading = false; // // Check status data return form (api)
          if (res && res.recs.length > C.PAGE_SIZE.DEFAULT) {
            self.allowInfinite = true; // Set status allow Infinite
          } else {
            // When data null
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          }
        })
        .catch(error => {
          self.isLoading = false; // Check status data return form (api)
          self.allowInfinite = false; // Set status allow Infinite
          self.hasMoreItems = false; // Remove icon load more
        })
    },

    fetchAllRecsByPopularity () {
      this.indexFriends.index = null
      this.indexFriends.id = null
      this.typeSortBy = C.FILTER.POPULARITY
      this.textSortBy = C.FILTER.TEXT_POPULARITY
      this.indexSortBy = C.FILTER.POPULARITY
      this.fetchData()
    },

    refetchData(_event, done) {
      this.fetchCreators()
      Promise.all(
        [this.fetchData()]
      ).then(() => done())
    },
    fetchMe () {
      return this.$store.dispatch("me/ACTION_GET_ME")
    },
    fetchCreators () {
      self.isLoading = true
      this.friends = []
      this.publishers = []
      const params = {
        cityId: this.city.id
      }
      return this.$store.dispatch("recs/ACTION_FETCH_CREATORS", params)
      .then((data) => {
        self.isLoading = false
        if (data && data.length > 0) {
          data.forEach(user => {
            if (user.role == "PUBLISHER") {
              this.publishers.push(user)
            } else {
              this.friends.push(user)
            }
          })
        }
      })
      .catch(error => {
        self.isLoading = false
      })
    }
  }
};
</script>
