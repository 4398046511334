<template>
  <div :class="className">
    <div class="head-background">
      <span class="overlay-bottom"></span>
      <f7-block class="user-info">
        <span class="overlay-top"></span>
        <f7-block class="no-padding">
          <f7-block-title medium class="user-name">Lily Barnes</f7-block-title>
          <f7-row no-gap class="justify-content-left">
            <f7-col width="20">
              <div class="count">
                <p class="number">120</p>
                <!-- <span class="text">friends</!-->
              </div>
              <div class="count">
                <p class="number">26</p>
                <!-- <span class="text">cities visited</span> -->
              </div>
              <div class="count">
                <p class="number">61</p>
                <!-- <span class="text">recommendations</span> -->
              </div>
            </f7-col>
            <f7-col width="30">
              <f7-button outline color="white"></f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-block>
    </div>
    <div class="content-wrap cities-list">
      <f7-block v-for="k in 1" :key="k" class="city-block">
        <f7-block-header medium>Header</f7-block-header>
        <f7-swiper :params="{speed:500, slidesPerView: 2, spaceBetween: 7}">
          <f7-swiper-slide v-for="n in 5" :key="n">
            <f7-link href="/passport/list/">
              <div class="caption">
                <div class="title">Title</div>
              </div>
            </f7-link>
          </f7-swiper-slide>
        </f7-swiper>
      </f7-block>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    className: {
      required: true,
      type: String
    }
  }
};
</script>
