import gql from "graphql-tag";

const topicPosts = gql`
  query postsInTopic(
    $id: ID!, 
    $name: String,
    $page: Int
  ) {
    postsInTopic(
      id: $id, 
      name: $name,
      page: $page
    ) {
      id
      title
      content
      externalUrl
      externalSource
      siteName
      siteDescription
      city {
        name
      }
      author {
        id
        nickname
        avatar {
          url
          name
        }
      }
      photos {
        name
        url
      }
      statsLike
      statsComment
      likeStatus
      bookmarkStatus
      createdAt
      places {
        id
        placeId
        name
        photos {
          name
          url
        }
        rateStarts {
          publicStatus
          publicScore
          publicStats
          friendStatus
          friendScore
          friendStats
        }
        bookmarkStatus
      }
    }
  }
`;
export { topicPosts };
