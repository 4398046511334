import gql from "graphql-tag";

const cities = gql `query cities {
  countries {
      id
      name
      cover {
        name
        url
      }
      cities {
        id
        name
        cover {
          name
          url
        }
        published
        location {
          type
          coordinates
        }
        friendsCount
      }
    }
}`

export {
  cities
};
