<template>
  <div>
    <f7-block-title class="sort-by-menu" @click.native="toggleSorting">
      <span>Sort by:</span>
      <span class="text-value">{{[textSortBy, tagName].filter(Boolean).join(', ')}}</span<>
      <span class="icon-chevron">
        <f7-icon f7="chevron_up" :class="{'active': isSorting}"></f7-icon>
      </span>
    </f7-block-title>
    <transition>
      <f7-list class="sort-list" v-show="isSorting">
        <f7-list-group>
            <f7-row>
              <f7-col width="70">
                <label class="sort">+ Sort Order </label>
              </f7-col>
              <f7-col width="30">
                <f7-link class="link" @click="clearFilter()"> Clear filters</f7-link>
              </f7-col>
            </f7-row>
          <f7-list-item
            :class="'filter-option ' + (indexSortBy == constants.FILTER.FRIEND_RATE ? 'active' : '')"
            :title="textFriendRating"
            @click="handleSortBy(constants.FILTER.FRIEND_RATE, textFriendRating)"
          ></f7-list-item>
          <f7-list-item
            :class="'filter-option ' + (indexSortBy == constants.FILTER.PUBLIC_RATE ? 'active' : '')"
            :title="constants.FILTER.TEXT_PUBLIC_RATE"
            @click="handleSortBy(constants.FILTER.PUBLIC_RATE, constants.FILTER.TEXT_PUBLIC_RATE)"
          ></f7-list-item>
          <f7-list-item
            :class="'filter-option ' + (indexSortBy == constants.FILTER.POPULARITY ? 'active' : '')"
            :title="constants.FILTER.TEXT_POPULARITY"
            @click="handleSortBy(constants.FILTER.POPULARITY, constants.FILTER.TEXT_POPULARITY)"
          ></f7-list-item>
          <f7-list-item
            :class="'filter-option ' + (indexSortBy == constants.FILTER.DISTANCE ? 'active' : '')"
            :title="constants.FILTER.TEXT_DISTANCE"
            @click="handleSortBy(constants.FILTER.DISTANCE, constants.FILTER.TEXT_DISTANCE)"
          ></f7-list-item>
          <div v-for="(subFilter) in subFilters">
            <label class="sort">+ {{ subFilter.name }}</label>
            <f7-list-item  
              v-for="(filterTag) in subFilter.tags" 
              :key="filterTag.id"
              :class="'filterOption ' + tag == filterTag.id ? 'active' : ''"
              :title="filterTag.name"
              @click="handleOnTagClick(filterTag)"
              ></f7-list-item>
          </div>
          <label class="sort" v-if="popularTags.length > 0">+ Popular Filters</label>
          <f7-list-item  
            v-for="(popularTag) in popularTags" 
            :key="popularTag.id"
            :class="'filter-option ' + (tag == popularTag.id ? 'active' : '')"
            :title="popularTag.name"
            @click="handleOnTagClick(popularTag)"
            ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </transition>
  </div>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    textSortBy: {
      required: false,
      type: String,
      default: ""
    },
    tag: {
      required: false,
      type: String
    },
    tagName: {
      required: false,
      type: String
    },
    textFriendRating: {
      required: false,
      type: String,
      default: ""
    },
    indexSortBy: {
      required: false,
      type: String,
      default: ""
    },
    isSorting: {
      required: false,
      type: Boolean,
      default: false
    },
    
    created: {
      required: false,
      type: Boolean,
      default: false
    },

    subFilters: {
      required: false,
      type: Array,
      default: []
    },

    popularTags: {
      required: false,
      type: Array,
      default: []
    },

    toggleSorting: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleSortBy: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleOnTagClick: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    clearFilter: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  data() {
    return {
      constants: C
      // indexSortBy: C.FILTER.FRIEND_RATE
    };
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>
