<template>
  <f7-block-header :class="className" @click.native="()=> onHandle()">
    {{title}}
  </f7-block-header>
</template>
<script>
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String,
      default: "sub-title"
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

