import gql from "graphql-tag";

const categories = gql `query categories {
  categories {
    id,
    name
    subFilters {
      name
      tags {
        id
        name
      }
    }
    popularTags {
      id
      name
    }
  }
}
`

export {
  categories
};
