import gql from "graphql-tag";

const friendFavs = gql`query friendFavs {
  friendFavs {
    category{
      id
      name
    }
    places{
      id
      placeId
      name
      photos {
        name
        url
      }
      rateStarts {
        publicStatus
        publicScore
        publicStats
        friendStatus
        friendScore
        friendStats
      }
      bookmarkStatus
    }
  }
}`;

export {
  friendFavs
};
