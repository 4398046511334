<template>
  <f7-row class="title-block">
    <f7-col width="70" class="col-left">
      <div class="title">{{item.name}}</div>
      <f7-block-header class="sub-title">
        <span v-if="item.categories.length == 1">
          <span>{{item.categories[0].name}}</span>
        </span>
        <span v-else>
        <span v-for="(item, key) in item.categories" :key="key">
          <span>•</span>
          <span>{{item.name}}</span>
        </span>
        </span>
      </f7-block-header>
    </f7-col>
    <f7-col width="30" class="col-right">
      <f7-button :actionsOpen="`.actions-header-event-detail-${item.id}`">Share</f7-button>
    </f7-col>
    <actions-event-component
      :actionsOpenId="`actions-header-event-detail-${item.id}`"
      :type="constants.TYPE_SHARE.EVENT"
      :typeId="item.id"
    />
  </f7-row>
</template>
<script>
import ActionsEventComponent from "./ActionEvent";
import C from "../_helpers/constants";
export default {
  components: {
    ActionsEventComponent
  },
  data() {
    return {
      constants: C
    };
  },
  props: {
    item: {
      required: false,
      type: Object
    }
  }
};
</script>
