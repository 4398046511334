import gql from "graphql-tag"

const fetchFromLink = gql`
  query fetchFromLink($url: String!) {
    fetchFromLink(url: $url) {
      title
      id
      site_name
      image
      video
      description
      type
    }
  }
`;

export {
  fetchFromLink
};
