<template>
  <f7-popup :class="`${classPopup} height-select-${actionNumber} select-popup`">
    <f7-page>
      <f7-block>
        <ul>
          <li v-if="statusShareTo" @click="(e) => handleShareTo(e)">
            <a>Share to...</a>
          </li>
          <li v-if="statusCopyLink" class="remove-border">
            <!-- <a>Copy Link</a> -->
            <f7-link
              v-clipboard:copy="dataCopyLink"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >Copy Link</f7-link>
            <input type="hidden" :value="dataCopyLink">
            <!-- <input type="text" id="copy-link" :value="dataCopyLink"> -->
          </li>
          <li v-if="statusReport" @click="(e) => handleReport(e)" class="text-red">
            <a>Report</a>
          </li>
          <li
            v-if="statusUnfollow"
            @click="(e) => handleUnfollow(e)"
            class="text-red remove-border"
          >
            <a>Unfollow</a>
          </li>
          <li class="cancel" v-if="statusCancel">
            <f7-link popup-close>Cancel</f7-link>
          </li>
        </ul>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>
<script>
export default {
  props: {
    classPopup: {
      type: String,
      default: null
    },
    actionNumber: {
      type: String,
      default: null
    },
    statusShareTo: {
      type: Boolean,
      default: false
    },
    statusCopyLink: {
      type: Boolean,
      default: false
    },
    statusReport: {
      type: Boolean,
      default: false
    },
    statusUnfollow: {
      type: Boolean,
      default: false
    },
    statusCancel: {
      type: Boolean,
      default: true
    },
    dataCopyLink: {
      type: String,
      default: null
    },
    handleShareTo: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleReport: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleUnfollow: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onCopy: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onError: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>
