<template>
<span>
  <span
    :class="className"
    @click="onToggleHeart(item, id, type, pageType)"
    v-if="!isActiveLikeStatus"
  >
    <img src="@/static/icons/like.svg">
  </span>
  <span v-else :class="className" @click="onToggleHeart(item, id, type, pageType)">
    <img src="@/static/icons/like_active.svg">
  </span></span>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    className: {
      required: false,
      type: String,
      default: ""
    },
    item: {
      required: false,
      type: Object
    },
    isActiveLikeStatus: {
      required: false,
      type: Boolean,
      default: false
    },
    id: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    pageType: {
      required: false,
      type: String
    }
  },
  methods: {
    onToggleHeart(item, id, type, page) {
      item.likeStatus = !item.likeStatus
      if (item.likeStatus == false) {
        // If status like
        const params = {
          id: id,
          type: type,
          status: C.LIKE_STATUS.UNLIKE
        };
        const pageType = {
          type: page
        };
        this.$store
          .dispatch("like/ACTION_LIKE", { params, pageType })
          .then(res => {
            // Call api like (unlike)
            item.isActiveLikeStatus = false;
            this.$forceUpdate();
          });
      } else {
        // If status unlike
        const params = {
          id: id,
          type: type,
          status: C.LIKE_STATUS.LIKE
        };
        const pageType = {
          type: page
        };
        this.$store
          .dispatch("like/ACTION_LIKE", { params, pageType })
          .then(res => {
            // Call api like (like)
            item.isActiveLikeStatus = true;
            this.$forceUpdate();
          });
      }
    }
  }
};
</script>

