import gql from "graphql-tag";

const collectionDetailEvent = gql `
query collection ($type: COLLECTION_TYPE!, $cityId: ID!, $userId: ID,  $filterType: COLLECTION_FILTER, $page: Int!) {
  collection(type: $type, cityId: $cityId, userId: $userId, filterType: $filterType, page: $page) {
    city {
    	id
      name
      country {
        name
      }
    }
    user {
      id
      nickname
      avatar {
        url
        name
      }
    }
    events {
      id
      name
      description
      cover
      createdAt
      bookmarkStatus
      eventDate
      startTime
      endTime
      place {
        id
        name
        photos{
          name
          url
        }
      }
    }
  }
}
`

export {
  collectionDetailEvent
};
