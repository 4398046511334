import moment from "moment-timezone";

export const showEventDate = (event) => {
  return moment(event.eventDate).format("ddd MMM DD");
}

export const showEventTime = ({ startTime, endTime }, format = "short") => {
  let s = "";

  if (startTime && endTime) {
    if (format == "long") {
        s += ` · ${startTime} - ${endTime}`;
    } else {
        // only display start time so it's not too long
        s += ` · ${startTime}`;
    }
  } else if (startTime) {
    s += ` · ${startTime}`;
  } else if (endTime) {
    // display end time if start time is not available
    s += ` · ${endTime}`;
  }

  return s;
}