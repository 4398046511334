import gql from "graphql-tag";

const uploadPlacePhotos = gql `
mutation uploadPlacePhotos($data: PlacePhotosPayload!) {
  uploadPlacePhotos(data: $data) {
    id
    photos {
      name
      url
    }
  }
}
`

export {
  uploadPlacePhotos
};
