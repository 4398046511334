<template>
  <div class="block-event block" v-if="trendingEvents[indexEvent] && trendingEvents[indexEvent].events.length > 0">
    <f7-block class="heading">
      <f7-block-title medium>{{trendingEvents[indexEvent].title}}</f7-block-title>
    </f7-block>
    <f7-swiper :params="{ speed: 500, slidesPerView: 2 }" class="place" ref="trendSwiper">
      <f7-swiper-slide v-for="(event, index) in trendingEvents[indexEvent].events" :key="index">
        <f7-card>
          <place-image-component v-if="event.cover" :url="event.cover" :onHandle="()=>toEventDetail(event.id,null)"/>
          <place-image-component
            v-else-if="event.place && event.place.photos.length > 0"
            :url="event.place.photos[0].url"
            :onHandle="()=>toEventDetail(event.id,null)"
          />

          <div class="wrap-icon-bookmark-share">
            <bookmark-component
              class="icon-bookmark"
              :type="constants.BOOKMARK_CONTENT_TYPE.EVENT"
              :item="event"
              :id="event.id"
              :isActiveBookmarkStatus="event.isActiveBookmark"
            />
            <share-component
              :actionsOpenId="`actions-event-detail-${event.id}`"
              :type="constants.TYPE_SHARE.EVENT"
              :typeId="event.id"
            />
          </div>
        </f7-card>

        <f7-block>
          <span v-on:click="()=>toEventDetail(event.id,null)">
          <place-title-component class="main" :title="`${ index + 1 }. ${ event.name }`" />
          <div class="sub">
            <span
              class="name"
            >{{event && event.place && event.place.name ? event.place.name : null}}</span>
            <span class="date">
              <span class="event-date">{{ showEventDate(event) }}</span>
              <span class="event-time">{{ showEventTime(event) }}</span>
            </span>
          </div>
          </span>
        </f7-block>

      </f7-swiper-slide>
    </f7-swiper>
    <see-more-component :isShow="true" :toggleTip="handleSeeMore" />
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";

import C from "../_helpers/constants";
import { sharePost, sharePlace } from "../utils/share";
import { copySuccess, copyError } from "../utils/copy";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import PlaceImageComponent from "../components/PlaceImage";
import PlaceTitleComponent from "../components/PlaceTitle";
import SeeMoreComponent from "../components/SeeMore";
import { showEventDate, showEventTime } from '../utils/eventHelpers';

export default {
  components: {
    BookmarkComponent,
    ShareComponent,
    PlaceImageComponent,
    PlaceTitleComponent,
    SeeMoreComponent
  },
  
  data() {
    return {
      constants: C,
      events: []
    };
  },
  
  props: {
    trendingEvents: {
      required: true,
      type: Array
    },
    indexEvent: {
      required: true,
      type: Number
    }
  },

  methods: {
    handleSeeMore() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.EVENT);
    },
    toEventDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("eventDetail/ACTION_GET", params);
    },
    showEventDate,
    showEventTime
 }
};
</script>

