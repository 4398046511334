import moment from 'moment-timezone'

export const extractHostName = (url) => {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }
  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];
  return hostname;
}

export const momentFunc = (time) => {
  return (time | moment("from", true) + 'ago')
}

export const copySuccess = (e) => {
  alert("You just copied: " + e.text);
}

export const copyError = (e) => {
  alert("Failed to copy texts");
}
