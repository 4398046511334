import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

function initialState() {
  return {
    data: []
  }
}
export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: (state, getters, rootState) => {
      _.forEach(state.data, function (item, key) {
        item.isActiveBookmark = item.bookmarkStatus; // Add field active isActiveBookmark
      });
      return state.data
    },
  },

  actions: {
    ACTION_GET({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.events
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET', res.data.events)
            resolve(res);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_RESET_STATE({
      commit
    }) {
      commit('MUTATION_RESET_STATE');
    },

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATION_RESET_STATE(state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },

    MUTATION_GET(state, items) {
      state.data.push(...items)
    },

  }
}
