// Import Vue
import Vue from 'vue'
import VueHead from 'vue-head'
// Import Framework7
import Framework7 from 'framework7/framework7.esm.bundle.js'

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css'

// Import Icons and App Custom Styles
import './css/icons.css'
import './css/app.scss'

// Import App Component
import App from './components/App'

// Resize Image
import ImageUploader from 'vue-image-upload-resize'

// Import Apollo
import apolloClient from './utils/apollo-client';
import VueApollo from 'vue-apollo';
import {
  Cloudinary
} from 'cloudinary-core'

import * as VueGoogleMaps from 'vue2-google-maps'

import ImageStatic from './constants/assets'

import C from "./_helpers/constants";

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: {
    id: C.API_KEYS.GA_MEASUREMENT_ID,
    params: {
      send_page_view: false
    }
  }
})

import _ from 'lodash';

// Import Moment
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
moment.suppressDeprecationWarnings = true;
// Import Share
import SocialSharing from 'vue-social-sharing'

// Import Store
import store from './_store'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue)

import welcomescreen from 'f7-welcomescreen';
Framework7.use(welcomescreen)

Vue.use(VueGoogleMaps, {
  load: {
    key: C.API_KEYS.GOOGLE_MAPS,
    libraries: 'places',
  },
})
Vue.use(VueMoment, {
  moment
})
Vue.use(VueHead)
Vue.use(VueApollo)
Vue.use(_)
Vue.use(ImageStatic)
window.eventBus = new Vue();

Vue.prototype.$cl = new Cloudinary({
  cloud_name: C.CLOUD.NAME
})

Vue.use(SocialSharing);

Vue.use(ImageUploader);

/** Vue Filters Start */
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});
/** Vue Filters End */
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

// // Check that service workers are supported
// if ('serviceWorker' in navigator) {
//   // Use the window load event to keep the page load performant
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js');
//   });
// }
// // Check that service workers are supported
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('./service-worker.js').then(registration => {
//       console.log('SW registered: ', registration);
//       // registration.pushManager.subscribe({userVisibleOnly: true});
//     }).catch(registrationError => {
//       console.log('SW registration failed: ', registrationError);
//     });
//   });
// }

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  apolloProvider,
  // Register App Component
  components: {
    app: App
  }
})

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: C.SENTRY_DSN,
    integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
  });
}