import gql from "graphql-tag";
const loginEmail = gql `
  mutation loginEmail($data: AuthInput!) {
    loginEmail(data: $data) {
      token
      user {
        id
        isActive
        nickname
      }
    }
  }
`
export  {
  loginEmail
};
