<template>
  <f7-app :params="f7params">
    <!-- Status bar overlay for fullscreen mode-->

    <f7-statusbar></f7-statusbar>
    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <f7-view main url="/"></f7-view>
      <!-- <f7-view v-else main tab url="/home" tab-active></f7-view> -->
    </f7-views>
  </f7-app>
</template>
<script>
import cordovaApp from "../_helpers/cordova-app";
import routes from "../_helpers/routes";
import { mapState, mapGetters, mapActions } from "vuex";
import C from "../_helpers/constants";
export default {
  data() {
    return {
      constants: C,
      current: null,
      bgImage: "../static/images/bg_login_v2.jpg",
      loginScreenOpened: true,
      // Framework7 Parameters

      f7params: {
        id: "io.framework7.myfavs", // App bundle ID
        name: "myfavs", // App name
        theme: "ios", //
        // App root data
        data() {
          return {}
        },
        // App routes
        routes: routes,
        // Register service worker
        // serviceWorker: this.$device.cordova ? {} : {
        //   path: '/service-worker.js',
        // },
        // Input settings
        input: {
          scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered: this.$device.cordova && !this.$device.electron
        },
        // Cordova Statusbar settings
        statusbar: {
          overlay: (this.$device.cordova && this.$device.ios) || "ios",
          iosOverlaysWebView: true,
          androidOverlaysWebView: false
        },
        welcomescreen: { // Setup welcomescreen plugin
            slides: [
              {
                id: 'slide0',
                text: `
                  <h1 class="welcomescreen-header"> Welcome to favs beta </h1>
                  <h3 class="welcomescreen-subtitle"> Some essentials before you get started </h3>
                  <p class="welcomescreen-description">
                    We kept the group of users small for a couple reasons: 

                    <strong>1. this version is ~40% there. </strong> We are several months out from a public beta, but we believe it can provide value to you in its current form. So don’t sweat the small stuff. Improvements are released daily. 

                    <strong>2. This is real data updated daily: </strong> events, articles, and videos. A few user accounts and most publishers are seeded to give you a fuller experience.

                    <strong>Lastly, your feedback is vital.</strong>
                    Text, email us, we’ll take any and all feedback. Our contact info and link to the survey is in your passport settings.
                  </p>
                  <button class="welcomescreen-next-button" data-index="0">Next</button>
                `
              },
              {
                id: 'slide1',
                text: `
                  <h1 class="welcomescreen-header"> Download the app </h1>
                  <p class="welcomescreen-description"> Our app is a Progressive Web App (PWA). This let’s us bypass the app store, but it does require different steps. </p>
                  <h3 class="welcomescreen-subsection"> For iPhones </h3>
                  <p class="welcomescreen-description">
                    1. Open favs link in the Safari browser
                    2. Tap the share icon at the bottom of the browser
                    3. Select “Add to Home Screen” and follow the instructions 
                  </p>
                  <h3 class="welcomescreen-subsection"> For Androids </h3>
                  <p class="welcomescreen-description">
                    1. Open favs link in the Chrome browser
                    2. Tap the menu button in the upper right hand corner
                    3. Select “Add to Home Screen” and follow the instructions
                  </p>
                  <button class="welcomescreen-next-button" data-index="1">Ready!</button>
                `
              }
          ],
          options: {
            'bgcolor': '#fff',
            'fontcolor': '#0395a7',
            'closeButton': false,
            'open': false,
            'onOpened': () => {
              const self = this

              $('.welcomescreen-next-button').click((e) => {
                if ($(e.target).data('index') != 1) {
                  return self.$f7.welcomescreen.next()
                }
                self.$f7.views.main.router.navigate(C.ROUTER_NAVIGATE.SETTINGS, { animate: false })
              })
            }
          }
        },

        // view: {
        //   pushState: "true",
        //   pushStateSeparator: ""
        // }
      }
    };
  },
  computed: {
    redirect() {
      return this.$store.state.social.data.redirect;
    }
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"])
  },
  mounted() {
    const self = this;
    this.$f7ready(f7 => {
      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
    })
  },
};
</script>
