import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';
import C from "../../_helpers/constants";
export default {
  namespaced: true,
  state: {
    data: [],
    status: null,
    bookmark: null,
  },

  getters: {
    data: state => state.data,
    status: state => state.status,
    bookmark: state => state.bookmark
  },

  actions: {
    ACTION_BOOK_MARK({
      commit
    }, params) {
      let data = params.params
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.bookmark
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            resolve(res.data.bookmark)
            commit('STATUS_BOOKMARK', false)
            if (params.params.status == C.BOOKMARK_STATUS.BOOKMARK) {
              commit('STATUS_BOOKMARK', res.data.bookmark)
              switch (params.pageType.type) {
                case `${C.PAGE_CONTENT_TYPE.COLLECTION_PLACES}`:
                  // Update status at the collection
                  commit('collectionDetailPlace/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.RECS}`:
                  // Update status at the recs
                  commit('recs/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_PLACE}`:
                  // Update status at the recs
                  commit('magazine/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.PLACE_DETAIL}`:
                  // Update status at the collection
                  commit('collectionDetailPlace/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the recs
                  commit('recs/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the timeline (post place)
                  commit('magazine/MUTATION_BOOKMARK_POSTED_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the recs
                  commit('magazine/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.FEED_POST_PLACE}`:
                  // Update status at the feed
                  commit('feeds/MUTATION_BOOKMARK_PLACE_POST', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.PLACE_POST_DETAIL}`:
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the feed
                  commit('feeds/MUTATION_BOOKMARK_PLACE_POST', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the collection
                  commit('collectionDetailPost/MUTATION_BOOKMARK_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK_PLACE_POST', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the timeline (place post)
                  commit('magazine/MUTATION_BOOKMARK_ADD_TO_PLANS_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the timeline
                  commit('magazine/MUTATION_BOOKMARK_POSTED_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.FEED}`:
                  // Update status at the feed
                  commit('feeds/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.COLLECTION_POST}`:
                  // Update status at the collection
                  commit('collectionDetailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.COLLECTION_POST_PLACE}`:
                  // Update status at the collection
                  commit('collectionDetailPost/MUTATION_BOOKMARK_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_BOOKMARK_POSTED', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE}`:
                  // Update status at the timeline (post place)
                  commit('magazine/MUTATION_BOOKMARK_POSTED_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })

                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_BOOKMARK_ADD_TO_PLANS', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_BOOKMARK_ADD_TO_PLANS_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE_ONLY}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_BOOKMARK_ADD_TO_PLANS_PLACE_ONLY', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_BOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                  // default:
                  //   // code block
              }
            } else {
              commit('STATUS_BOOKMARK', false)
              switch (params.pageType.type) {

                case `${C.PAGE_CONTENT_TYPE.COLLECTION_PLACES}`:
                  // Update status at the collection
                  commit('collectionDetailPlace/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.RECS}`:
                  // Update status at the recs
                  commit('recs/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_PLACE}`:
                  // Update status at the recs
                  commit('magazine/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.PLACE_DETAIL}`:
                  // Update status at the collection
                  commit('collectionDetailPlace/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the recs
                  commit('recs/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the timeline (post place)
                  commit('magazine/MUTATION_UNBOOKMARK_POSTED_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  commit('magazine/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.FEED_POST_PLACE}`:
                  // Update status at the feed
                  commit('feeds/MUTATION_UNBOOKMARK_PLACE_POST', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.PLACE_POST_DETAIL}`:
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the feed
                  commit('feeds/MUTATION_UNBOOKMARK_PLACE_POST', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the collection
                  commit('collectionDetailPost/MUTATION_UNBOOKMARK_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK_PLACE_POST', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the timeline (place post)
                  commit('magazine/MUTATION_UNBOOKMARK_ADD_TO_PLANS_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the timeline
                  commit('magazine/MUTATION_UNBOOKMARK_POSTED_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.FEED}`:
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the feed
                  commit('feeds/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.COLLECTION_POST}`:
                  // Update status at the collection
                  commit('collectionDetailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.COLLECTION_POST_PLACE}`:
                  // Update status at the collection
                  commit('collectionDetailPost/MUTATION_UNBOOKMARK_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_UNBOOKMARK_POSTED', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_UNBOOKMARK_POSTED_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_UNBOOKMARK_ADD_TO_PLANS', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('detailPost/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_UNBOOKMARK_ADD_TO_PLANS_PLACE', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                case `${C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE_ONLY}`:
                  // Update status at the timeline
                  commit('magazine/MUTATION_UNBOOKMARK_ADD_TO_PLANS_PLACE_ONLY', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  // Update status at the detail
                  commit('placeDetail/MUTATION_UNBOOKMARK', {
                    id: params.params.id
                  }, {
                    root: true
                  })
                  break;
                  // default:
                  //   // code block
              }
            }
          })
          .catch(error => {
            commit('STATUS_BOOKMARK', false)
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_CHANGE_STATUS_BOOKMARK({
      commit
    }, params) {
      commit('MUTATIONS_CHANGE_STATUS_BOOKMARK', params);
    }

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_BOOKMARK(state, data) {
      state.bookmark = data;
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
    MUTATIONS_CHANGE_STATUS_BOOKMARK(state, data) {
      state.bookmark = data
    }
  }
}
