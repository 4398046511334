import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';
export default {
  namespaced: true,
  state: {
    data: [],
    currentCountry: [],
    currentCity: []
  },

  getters: {
    data: state => state.data,
    currentCountry: state => state.currentCountry,
    currentCity: state => state.currentCity,
    published: (state, getters, rootState) => {
      const citiesArr = []
      _.forEach(state.currentCountry.cities, function (item, key) {
        if (item.published == true) {
          citiesArr.push(item);
        }
      });
      return citiesArr;
    }
  },

  actions: {

    ACTION_FETCH_CITIES({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            mutation: gqlQuery.cities
          })
          .then(resp => {
            commit('STATUS_SUCCESS', resp.data.countries);
            resolve(resp.data.countries);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_GET_COUNTRY_SELECT({
      commit
    }, params) {
      if (params) {
        const data = _.find(params.countryList, {
          id: params.idCountry
        });
        const dataCity = _.find(data.cities, {
          id: params.idCity
        });

        commit('MUTATIONS_GET_COUNTRY_SELECT', {
          data,
          dataCity
        });
        localStorage.setItem('city', JSON.stringify(dataCity));
        localStorage.setItem('cityId', dataCity.id);
        localStorage.setItem('country', JSON.stringify(data))
        localStorage.setItem("currentLatLocation", dataCity && dataCity.location && dataCity.location.lat);
        localStorage.setItem("currentLngLocation", dataCity && dataCity.location && dataCity.location.lng);
      }
    },

    ACTION_UPDATE_COUNTRY_SELECT({
      commit
    }, params) {
      if (params) {
        const country = params.country;
        const city = params.city;
        commit('MUTATIONS_UPDATE_COUNTRY_SELECT', {
          country,
          city
        });
        commit('recs/MUTATION_RELOAD_CREATORS', null, { root: true });
      }
    }
  },

  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state, items) {
      state.data = items;
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },

    // GET CITIES
    MUTATIONS_GET_COUNTRY_SELECT(state, {
      data,
      dataCity
    }) {
      state.currentCountry = data;
      state.currentCity = dataCity;
    },

    // UPDATE CITIES
    MUTATIONS_UPDATE_COUNTRY_SELECT(state, {
      country,
      city
    }) {
      state.currentCountry = country;
      state.currentCity = city;
    }

  }
}
