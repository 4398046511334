<template>
  <f7-card-header 
    :style="`background-image:url(`+url+`)`"
  >
    <span v-on: @click="actionFetchAuthProfile(id)" class="background-user-image dark">
      <div class="overlay-top"></div>
    </span>
    <div class="userDetail">
      <div class="avatar">
        <img
          slot="media"
          :src="getAvatarAuthImg(avaUrl)"
        >
        <span class="username"
          @click="actionFetchAuthProfile(id)"
        >{{ nickname }}</span>
      </div>
    </div>
  </f7-card-header>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: {
    url: {
      required: false,
      type: String
    },
    avaUrl: {
      required: false,
      type: String
    },
    nickname: {
      required: false,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    onHandleDefault() {
      return null;
    },
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return null;
      }
    },
  }
};
</script>

