import gql from "graphql-tag";

const createPost = gql`
mutation createPost($data: PostCreateInput!) {
  createPost(data: $data) {
    id
    title
    content
    externalUrl
    externalSource
    siteName
    siteDescription
    city {
      name
      country {
        name
      }
    }
    author {
      id
      nickname
      avatar {
        url
        name
      }
    }
    photos{
      name
      url
    }
    statsLike
    statsComment
    likeStatus
    bookmarkStatus
    createdAt
    places {
      id
      placeId
      name
      photos{
        name
        url
      }
      rateStarts {
        publicStatus
        publicScore
        publicStats
        friendStatus
        friendScore
        friendStats
      }
      categories {
        id
        name
      }
      city {
        name
      }
      bookmarkStatus
    }
    topics {
      id,
      name
    }
  }
}
`;

export {
  createPost

};
