<template>
  <div class="review-block" v-if="tips && tips.length > 0">
    <f7-block-title class="bearer">TIPS</f7-block-title>
    <f7-list media-list no-hairlines class="tips-comments-block">
      <f7-list-item v-for="(tip, index) in tips" :key="index">
        <user-name-component
          slot="title"
          :userId="tip.author && tip.author.id"
          :nickname="tip.author && tip.author.nickname"
        />

        <div class="wrap-edit-tip" v-if="idEditTip == tip.id">
          <div class="item-content item-input item-input-with-value">
            <div class="item-inner">
              <div class="item-input-wrap">
                 <f7-list-input
                  type="textarea"
                  :value="tip.content"
                  @input="contentEdit = $event.target.value"
                  class="input-with-value"
                ></f7-list-input>
              </div>
            </div>
          </div>
          <span class="text-edit" @click="handleEditTip(tip.id)">Update</span>
          <span class="text-edit" @click="handleOnDeleteTipClick(tip.id)">Delete</span>
          <span class="text-cancel" @click="cancelEditTip()">Cancel</span>
        </div>

        <span slot="text" v-else>{{tip.content}}</span>

        <avatar-component slot="media" :author="tip.author"/>

        <f7-link
          v-if="!(idEditTip == tip.id) && me.id == tip.author.id"
          @click="editTip(tip.id)"
          class="edit-tip"
          icon-ios="f7:more_fill"
        ></f7-link>

        <rates-action-component :action="tip && tip.rate && tip.rate.action ? tip.rate.action : tip.place.rates[0].action"/>
      </f7-list-item>
    </f7-list>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import UserNameComponent from "../components/UserName";
import AvatarComponent from "../components/Avatar";
import RatesActionComponent from "../components/RatesAction";
export default {
  components: {
    UserNameComponent,
    AvatarComponent,
    RatesActionComponent
  },
  data() {
    return {
      constants: C,
      idEditTip: null
    };
  },
  beforeMount() {},
  computed: {
    ...mapGetters("placeDetail", {
      tips: "tip"
    }),
    ...mapGetters("auth", {
      me: "me"
    })
  },

  methods: {
    handleEditTip(id) {
      const self = this;
      if (id) {
        const params = {
          id: id,
          content: self.contentEdit
        };
        this.$store.dispatch("placeDetail/ACTION_PUT_TIP", params).then(res => {
          if (res.updateTip.id) {
            Object.assign(this.$data, this.$options.data());
          }
        })
      }
    },
    handleOnDeleteTipClick (id) {
      this.$store.dispatch("placeDetail/ACTION_DELETE_TIP", { id }).then(res => {
        Object.assign(this.$data, this.$options.data());
      })
    },
    cancelEditTip() {
      Object.assign(this.$data, this.$options.data());
    },
    editTip(id) {
      if (id) {
        this.idEditTip = id;
      }
    }
  }
};
</script>

