<template>
  <f7-actions :class="`${actionsOpenName}`">
    <f7-actions-group>
      <f7-actions-button color="blue" @click="()=>handleSharePost()">Share to...</f7-actions-button>
      <f7-actions-button color="blue">
        <f7-link
          v-clipboard:copy="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.POST + '/' + actionsId}`"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
        >Copy Link</f7-link>
      </f7-actions-button>
      <input
        type="hidden"
        :v-model="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.POST + '/' + actionsId}`"
      >
    </f7-actions-group>
    <f7-actions-group>
      <f7-actions-button color="blue" bold>Cancel</f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    actionsOpenName: {
      required: false,
      type: String
    },
    actionsId: {
      required: false,
      type: String
    },
    handleSharePost: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onCopySuccess: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onCopyError: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

