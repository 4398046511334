import gql from "graphql-tag";

const createRate = gql `
mutation createRate($data: CreateRateInput!) {
  createRate(data: $data) {
    id
    action
  }
}
`

export  {
  createRate

};
