<template>
  <f7-page id="page-login" name="login" no-toolbar>
    <span class="overlay"></span>
    <div class="page-inner" :style="{ backgroundImage: `url(${bgImage})` }">
      <div class="title-block">
        <div class="logo">
          <img class="favs-logo" src="@/static/icons/favs-beta-logo-centered.png" alt>
        </div>
      </div>

      <div class="content-block">
        <!-- Login -->
        <div class="block block-login" v-if="isActive == constants.IS_FORM.LOGIN">
          <div type="button" class="button-facebook">
            <v-facebook-login
              v-model="facebook.model"
              :app-id="facebook.appId"
              :transition="['padding-right 0.15s ease-in-out']"
              @sdk-init="handleSdkInit"
              @connect="handleConnect"
              @logout="handleLogout"
            >
              <span slot="login">
                Continue with Facebook
                <img src="@/static/images/f_logo.png" class="icon-facebook">
              </span>
              <span slot="logout">
                Sign out with Facebook
                <img src="@/static/images/f_logo.png" class="icon-facebook">
              </span>

              <span slot="working">
                <span class="text-please-wait">Please wait...</span>
                <img src="@/static/images/f_logo.png" class="icon-facebook">
              </span>
            </v-facebook-login>
          </div>

          <ul class="tab-login-signup">
            <li class="tab-login active">Log in</li>
            <li class="tab-signup" @click="toggleTab(constants.IS_FORM.REGISTER)">Sign up</li>
          </ul>

          <div class="wrap-input">
            <input
              type="email"
              class="form-control"
              placeholder="email"
              v-on:keypress="handleEnter"
              v-model="valueEmail"
            >
          </div>
          <div class="wrap-input">
            <input
              type="password"
              class="form-control"
              placeholder="password...shhh"
              v-on:keypress="handleEnter"
              v-model="valuePassword"
            >
          </div>
          <p class="forgot-password" @click="handleForgotPassword()">Forgot Password?</p>
          <f7-button large outline @click="signIn(valueEmail, valuePassword)">Log in</f7-button>
          <p class="term-privacy-text">
            <span>By signing in, you accept our</span>
            <span class="bold"><a class="sheet-open" href="#" data-sheet=".terms">terms of use</a></span>
            <span>and</span>
            <span class="bold"><a class="sheet-open" href="#" data-sheet=".privacy">privacy policy</a></span>
          </p>
        </div>
        <!-- End Login -->

        <!-- Register -->
        <div class="block block-register" v-if="isActive == constants.IS_FORM.REGISTER">
          <div type="button" class="button-facebook">
            <v-facebook-login
              v-model="facebook.model"
              :app-id="facebook.appId"
              :transition="['padding-right 0.15s ease-in-out']"
              @sdk-init="handleSdkInit"
              @connect="handleConnect"
              @logout="handleLogout"
            >
              <span slot="login">
                Continue with Facebook
                <img src="@/static/images/f_logo.png" class="icon-facebook">
              </span>
              <span slot="logout">
                Sign out with Facebook
                <img src="@/static/images/f_logo.png" class="icon-facebook">
              </span>

              <span slot="working">
                <span class="text-please-wait">Please wait...</span>
                <img src="@/static/images/f_logo.png" class="icon-facebook">
              </span>
            </v-facebook-login>
          </div>

          <ul class="tab-login-signup">
            <li class="tab-login" @click="toggleTab(constants.IS_FORM.LOGIN)">Log in</li>
            <li class="tab-signup active">Sign up</li>
          </ul>

          <div class="wrap-input">
            <input
              type="email"
              class="form-control"
              placeholder="email"
              v-on:keypress="handleEnter"
              v-model="valueEmail"
            >
          </div>
          <div class="wrap-input">
            <input
              type="password"
              class="form-control"
              placeholder="password...shhh"
              v-on:keypress="handleEnter"
              v-model="valuePassword"
            >
          </div>
          <p class="forgot-password"></p>
          <f7-button large outline @click="signUp(valueEmail, valuePassword)">Sign up</f7-button>
          <p class="term-privacy-text">
            <span>By signing up, you accept our</span>
            <span class="bold"><a class="sheet-open" href="#" data-sheet=".terms">terms of use</a></span>
            <span>and</span>
            <span class="bold"><a class="sheet-open" href="#" data-sheet=".privacy">privacy policy</a></span>
          </p>
        </div>
        <!-- End Register -->
        <!-- </div> -->

        <!-- Almost there! -->
        <div class="block almost-there" v-if="isActive == constants.IS_FORM.REGISTER_SUCCESS">
          <div @click="handleToHome()">
            <f7-icon f7="close_round_fill" class="close"></f7-icon>
          </div>
          <div class="title">Congrats!</div>
          <p class="almost-there-text">
            You have been pre-approved to join our BETA. Log in to join the adventure!
          </p>
        </div>
        <!-- End Almost there! -->

        <!-- Almost there! -->
        <div class="block almost-there" v-if="isActive == constants.IS_FORM.LOGIN_ERROR">
          <div @click="handleToHome()">
            <f7-icon f7="close_round_fill" class="close"></f7-icon>
          </div>
          <div class="title">Almost there!</div>
          <p class="almost-there-text">Thank you for joining our adventure! Your waitlist number is:</p>
          <p class="number">
            <span>1,190</span>
          </p>
          <p class="almost-there-text">
            When you are accepted to our BETA program, you will receive a confirmation email.
          </p>
        </div>
        <!-- End Almost there! -->
      </div>
    </div>
    <!-- Default bottom sheet -->
    <!-- Sheet Push -->
    <div class="sheet-modal sheet-modal-push terms" style="height:100%;">
      <div class="statusbar-overlay"></div>
      <div class="toolbar">
        <div class="toolbar-inner">
          <div class="left"></div>
          <div class="right">
            <a class="link sheet-close">Close</a>
          </div>
        </div>
      </div>
      <TermAndCondition />
    </div>
    <div class="sheet-modal sheet-modal-push privacy" style="height:100%;">
      <div class="statusbar-overlay"></div>
      <div class="toolbar">
        <div class="toolbar-inner">
          <div class="left"></div>
          <div class="right">
            <a class="link sheet-close">Close</a>
          </div>
        </div>
      </div>
      <Privacy />
    </div>
  </f7-page>
</template>

<script>
import VFacebookLogin from "vue-facebook-login-component";
import C from "../_helpers/constants";
import { mapGetters, mapState, mapActions } from "vuex";
import Privacy from "./Privacy";
import TermAndCondition from "./TermAndCondition";
import { gaSetUser } from "../utils/gaHelpers"

let fbConfig = "";
switch (process.env.NODE_ENV) {
  case "production":
    fbConfig = C.ENV_APP_ID_FACEBOOK.PRODUCTION;
    break;
  case "development":
    fbConfig = C.ENV_APP_ID_FACEBOOK.DEVELOPMENT;
    break;
  default:
    fbConfig = C.ENV_APP_ID_FACEBOOK.STAGING;
    break;
}

export default {
  components: {
    VFacebookLogin,
    Privacy,
    TermAndCondition
  },
  data() {
    return {
      constants: C,
      isActive: C.IS_FORM.LOGIN,
      valuePassword: null,
      valueEmail: null,
      isResponseFb: true,
      user: {},
      facebook: {
        FB: {},
        model: {},
        appId: fbConfig
      },
      bgImage: "../static/images/bg_login_v2.jpg"
    };
  },

  beforeMount() {
    const uri = window.location.search.substring(1);
    if (uri == C.PARAMS_URL.PRIVACY){
      this.$f7router.navigate(C.ROUTER_NAVIGATE.PRIVACY);
      return;
    }
    if (uri == C.PARAMS_URL.TERMS){
      this.$f7router.navigate(C.ROUTER_NAVIGATE.TERMS);
      return;
    }
    const token = {
      Authorization: localStorage.getItem("token")
    };

    const self = this
    if (token.Authorization != null) {
      self.$store.dispatch("auth/ACTION_GET_ME", token).then(res => {
        if (res.data.me) {
          // log user info
          gaSetUser(res.data.me.id)
          const skipTutorial = localStorage.getItem('skipTutorial')

          if (localStorage.getItem('cityId') && !skipTutorial) {
            self.currentPagePosition(res.data);
            return self.$f7.welcomescreen.open()
          }

          if (!skipTutorial) {
            return self.$f7router.navigate("/home/");
          }

          self.currentPagePosition(res.data);
        }
      });
    } else {
      // check current URL to render
      self.currentPagePosition(null);
    }
  },
  computed: {
    ...mapGetters("auth", {
      me: "me",
      message: "message",
      isLogout: "isLogout"
    })
  },
  watch: {
    message(news, old) {
      const self = this;
      if (news.status == C.IS_FORM.LOGIN_ERROR) {
        if (self.toastBottom) {
          self.toastBottom = null;
        }
        this.showToastBottomLogin(news.text);
      } else if (news.status == C.IS_FORM.REGISTER_ERROR) {
        if (self.toastTop) {
          self.toastTop = null;
        }
        this.showToastBottomRegister(news.text);
      }
    }
  },

  methods: {
    toggleTab(type) {
      const self = this;
      self.resetValue();
      switch (type) {
        case `${C.IS_FORM.LOGIN}`:
          return (self.isActive = C.IS_FORM.LOGIN);
          break;
        case `${C.IS_FORM.REGISTER}`:
          return (self.isActive = C.IS_FORM.REGISTER);
          break;
        default:
          return (self.isActive = C.IS_FORM.LOGIN);
      }
    },
    handleToHome() {
      const self = this;
      self.isActive = C.IS_FORM.LOGIN;
    },
    showToastBottomLogin(text) {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: text,
          closeTimeout: 5000,
          cssClass: "toast-login"
        });
      }
      // Open it
      self.toastBottom.open();
    },
    showToastBottomRegister(text) {
      const self = this;
      // Create toast
      if (!self.toastTop) {
        self.toastTop = self.$f7.toast.create({
          text: text,
          closeTimeout: 5000,
          cssClass: "toast-login"
        });
      }
      // Open it
      self.toastTop.open();
    },
    handleEnter(e) {
      if (e.which === 13) {
      }
    },
    handleForgotPassword() {
      this.$f7.dialog
        .create({
          text: "Sorry! This feature is not implemented yet. Email 'Favs password reset' to info@nativeprojex.com and we will reset your password for that email on Favs.",
          buttons: [
            {
            text: "Got it",
            onClick: function() {}
            }
          ]
        })
        .open();
    },
    resetValue() {
      this.valuePassword = null;
      this.valueEmail = null;
    },
    signUp(email, password) {
      const self = this;
      const data = {
        email: email,
        password: password
      };
      if (email != null && password != null) {
        self.$store.dispatch("auth/ACTION_REGISTER_EMAIL", data).then(res => {
          if (res && res.token) {
            this.isActive = C.IS_FORM.REGISTER_SUCCESS;
          }
        });
      }
    },
    signIn(email, password) {
      const self = this;
      const data = {
        email: email,
        password: password
      };
      if (email != null && password != null) {
        self.$store.dispatch("auth/ACTION_LOGIN_EMAIL", data).then(res => {
          if (res.token) {
            const data = {
              Authorization: res.token
            };
            // redirect affter login
            if (process.env.NODE_ENV === "production") {
              self.$gtag.event("login", {method: "Email"})
            }
            self.$store.dispatch("auth/ACTION_GET_ME", data).then(res => {
              if (!localStorage.getItem('skipTutorial')) {
                return self.$f7router.navigate("/home/");
              }
              self.currentPagePosition(res.data);
              self.$f7router.navigate("/home/");
            });
          }
        });
      }
    },
    urlSearchParams(page) {
      const uri = window.location.search.substring(1);
      const urlParams = new URLSearchParams(uri);
      const getPostId = urlParams.get(C.PARAMS_URL.POSTID);
      const getPlaceId = urlParams.get(C.PARAMS_URL.PLACEID);
      const getUid = urlParams.get(C.PARAMS_URL.UID);
      const getCid = urlParams.get(C.PARAMS_URL.CID);
      const getShare = urlParams.get(C.PARAMS_URL.SHARE);
      const getAmp_cid = urlParams.get(C.PARAMS_URL.AMP_CID);
      const getAmp_uid = urlParams.get(C.PARAMS_URL.AMP_UID);
      const getEventId = urlParams.get(C.PARAMS_URL.EVENTID);
      if (getPostId) {
        // Post
        this.checkSocial(
          page,
          C.ROUTES_NAME.POST_DETAIL_PAGE,
          // urlParams.get(C.PARAMS_URL.POSTID),
          { id: getPostId, type: "post" }
        );
      } else if (getPlaceId) {
        // Place
        this.checkSocial(
          page,
          C.ROUTES_NAME.PLACE_PAGE,
          // urlParams.get(C.PARAMS_URL.PLACEID)
          { id: getPlaceId, type: "place" }
        );
      } else if (getEventId) {
        // Event
        this.checkSocial(
          page,
          C.ROUTES_NAME.EVENT_DETAIL,
          { id: getEventId, type: "event" }
        );
      } else if (getUid && getAmp_cid) {
        // Collection
        this.checkSocial(
          page,
          C.ROUTES_NAME.COLLECTION_DETAIL_PLACE,
          // urlParams.get(C.PARAMS_URL.UID),
          {
            uid: getUid,
            cid: getAmp_cid,
            type: "collection"
          }
        );
      } else if (getShare && getAmp_uid && getAmp_cid) {
        // Magazine
        this.checkSocial(
          page,
          C.ROUTES_NAME.PASSPORT_LIST_PAGE,
          // urlParams.get(C.PARAMS_URL.CID)
          {
            uid: getAmp_uid,
            cid: getAmp_cid,
            share: getShare,
            type: "magazine"
          }
        );
      } else if (getShare && getAmp_uid) {
        // Passport
        this.checkSocial(
          page,
          C.TYPE_URL.PASSPORT,
          // null
          { uid: getAmp_uid, cid: null, share: getShare, type: "passport" }
        );
      }
    },
    checkSocial(redirect, namePage, urlParams) {
      const self = this;
      if (redirect == C.REDIRECT_PAGE.FEEDS) {
        self.$store.dispatch("social/ACTION_REDIRECT_PAGE", {
          status: true,
          redirect: C.REDIRECT_PAGE.FEEDS
        });
        self.handlePage(namePage, urlParams);
      } else if (redirect == C.REDIRECT_PAGE.HOME) {
        self.$store.dispatch("social/ACTION_REDIRECT_PAGE", {
          status: true,
          redirect: C.REDIRECT_PAGE.HOME
        });
        self.handlePage(namePage, urlParams);
      } else if (redirect == C.REDIRECT_PAGE.LOGIN) {
        self.$store.dispatch("social/ACTION_REDIRECT_PAGE", {
          status: true,
          redirect: C.REDIRECT_PAGE.LOGIN
        });
        self.handlePage(namePage, urlParams);
      }
    },
    facebookLogin() {
      const self = this;

      FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          // TODO: BE needs to verify this BE token
          const accessToken = response.authResponse.accessToken;

          FB.api(
            "/me",
            "GET",
            // other available fields are such as: last_name,name
            { fields: "id,first_name,email" },
            function(FBResponse) {
              const data = {
                fbId: FBResponse.id,
                email: FBResponse.email,
                nickname: FBResponse.first_name,
                accessToken
              };

              if (!data.email) {
                return alert("Cannot sign in with Facebook because no email is found")
              }

              if (!data.fbId) {
                return alert("Cannot sign in with Facebook because profile is not found")
              }

              self.$store.dispatch("auth/ACTION_LOGIN", data).then(res => {
                if (res.token && res.user.isActive == false) {
                  // Comment
                  self.isResponseFb = false;
                  self.isActive = C.IS_FORM.LOGIN_ERROR;
                } else if (res.token && res.user.isActive == true) {
                  self.isResponseFb = true;
                  // Comment
                  const data = {
                    Authorization: res.token
                  };
                  if (process.env.NODE_ENV === "production") {
                    self.$gtag.event("login", {method: "Facebook"})
                  }
                  self.$store.dispatch("auth/ACTION_GET_ME", data).then(res => {
                    self.currentPagePosition(res.data);
                  });
                }
              });
            }
          );
        }
      });
    },
    handlePage(page, urlParams) {
      const self = this;
      switch (page) {
        case "place-detail":
          if (self.me.id) {
            const params = {
              id: urlParams.id,
              form: null
            };
            self.$store.dispatch("placeDetail/ACTION_GET", params);
          } else {
            const params = {
              id: urlParams.id,
              form: null
            };
            self.$store.dispatch("placeDetail/ACTION_GET_SHARE", params);
          }
          break;
        case "event-detail":
          const eventParams = {
            id: urlParams.id,
            pageType: C.PAGE_CONTENT_TYPE.EVENT_DETAIL
          };
          self.$store.dispatch("eventDetail/ACTION_GET", eventParams);
          break;
        case "post-detail":
          const postParams = {
            id: urlParams.id,
            pageType: C.PAGE_CONTENT_TYPE.FEED
          };
          if (self.me.id) {
            self.$store.dispatch("detailPost/ACTION_GET", postParams);
          } else {
            self.$store.dispatch("detailPost/ACTION_GET_SHARE", postParams);
          }
          break;
        case "passport":
          if (self.me.id) {
            self.$store.dispatch(
              "passport/actionFetchAuthProfile",
              urlParams.uid
            );
          } else {
            self.$store.dispatch(
              "passport/ACTION_FETCH_DATA_SHARE",
              urlParams.uid
            );
          }
          break;
        case "collection-detail-place":
          const paramsUrl = {
            uid: urlParams.uid,
            cid: urlParams.cid
          };
          this.$store.dispatch(
            "collection/ACTION_GET_URL_SEARCH_PARAMS",
            paramsUrl
          );
          self.$f7router.navigate({
            name: C.ROUTES_NAME.COLLECTION_DETAIL_PLACE,
            params: { id: urlParams.uid }
          });
          break;
        case "passport-detail-magazine":
          const params = {
            cityId: urlParams.cid,
            userId: urlParams.uid
          };
          this.$store.dispatch(
            "currentUrl/ACTION_GET_CURRENT_URL",
            C.ROUTES_NAME.PASSPORT_LIST_PAGE
          );
          if (self.me.id) {
            self.$store.dispatch("passport/ACTION_GET_INFO", params);
          } else {
            self.$store.dispatch("passport/ACTION_GET_INFO_SHARE", params);
          }
          break;

        default:
          this.$f7router.navigate(C.ROUTER_NAVIGATE.LOGIN);
      }
    },

    currentPagePosition(auth) {
      const self = this;
      const uri = window.location.search.substring(1);
      const urlParams = new URLSearchParams(uri);
      const getPostId = urlParams.get(C.PARAMS_URL.POSTID);
      const getPlaceId = urlParams.get(C.PARAMS_URL.PLACEID);
      const getEventId = urlParams.get(C.PARAMS_URL.EVENTID);
      const getUid = urlParams.get(C.PARAMS_URL.UID);
      const getCid = urlParams.get(C.PARAMS_URL.CID);
      const getShare = urlParams.get(C.PARAMS_URL.SHARE);
      const getAmp_cid = urlParams.get(C.PARAMS_URL.AMP_CID);
      const getAmp_uid = urlParams.get(C.PARAMS_URL.AMP_UID);

      const isUrlParams =
        getPostId ||
        getPlaceId ||
        getUid ||
        getCid ||
        getShare ||
        getAmp_cid ||
        getAmp_uid ||
        getEventId;

      const city = localStorage.getItem("city");
      const country = localStorage.getItem("country");
      if (auth != null && city && country) {
        this.$store.dispatch(
          "currentUrl/ACTION_GET_CURRENT_URL",
          C.ROUTES_NAME.FEED_PAGE
        );
        // Login -> Select City -> Feeds
        const cityCountry = {
          city: JSON.parse(city),
          country: JSON.parse(country)
        };
        self.$store.dispatch(
          "cities/ACTION_UPDATE_COUNTRY_SELECT",
          cityCountry
        );
        if (isUrlParams) {
          self.urlSearchParams(C.REDIRECT_PAGE.FEEDS);
        } else {
          self.$f7router.navigate(C.ROUTER_NAVIGATE.FEEDS);
        }
      } else {
        if (city == null && country == null && auth != null) {
          this.$store.dispatch(
            "currentUrl/ACTION_GET_CURRENT_URL",
            C.ROUTES_NAME.HOME_PAGE
          );
          // Login -> Select City
          if (isUrlParams) {
            self.urlSearchParams(C.REDIRECT_PAGE.HOME);
          } else {
            self.$f7router.navigate(C.ROUTER_NAVIGATE.HOME);
          }
        } else {
          // Login || Social share
          self.$store.dispatch("social/ACTION_REDIRECT_PAGE", {
            status: true,
            redirect: C.REDIRECT_PAGE.LOGIN
          });
          self.urlSearchParams(C.REDIRECT_PAGE.LOGIN);
        }
      }
    },
    handleSdkInit({ FB }) {
      this.facebook.FB = FB;
    },
    handleConnect() {
      this.facebookLogin();
    },
    handleLogout() {
      const self = this;
      self.$store.dispatch("auth/ACTION_LOGOUT").then(res => {
        if (res) {
          FB.getLoginStatus(function(response) {
            if (response && response.status === "connected") {
              FB.logout(function(response) {
                console.log("Logged out of FB", response)
              })
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
