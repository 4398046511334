import gql from "graphql-tag";

const eventDetail = gql`
  query eventDetail($id: ID!) {
    eventDetail(id: $id) {
      id
      name
      description
      cover
      createdAt
      eventDate
      startTime
      endTime
      bookmarkStatus
      place {
        id
        name
        coverUrl
        address
        photos {
          name
          url
        }
        location {
          coordinates
        }
      }
      categories {
        name
      }
    }
  }
`;
export { eventDetail };
