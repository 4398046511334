import gql from "graphql-tag";

const updateRate = gql `
mutation updateRate($data: UpdateRateInput!) {
  updateRate(data: $data) {
    id
    action
  }
}
`

export {
  updateRate

};
