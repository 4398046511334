import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

function initialState() {
  return {
    data: [],
    post: [],
    city: {},
    user: {}
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => state.data,
    post: state => state.post,
    city: state => state.city,
    user: state => state.user,
  },

  actions: {
    ACTION_RESET_STATE({
      commit
    }) {
      commit('MUTATIONS_RESET_STATE');
    },

    ACTION_GET_DETAIL_COLLECTION_POST({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.collectionDetailPost
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_DETAIL_COLLECTION_POST', res.data.collection.posts);
            commit('MUTATION_GET_DETAIL_COLLECTION_POST_CITY', res.data.collection.city);
            commit('MUTATION_GET_DETAIL_COLLECTION_POST_USER', res.data.collection.user);
            resolve(res.data.collection);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_GET_DETAIL_COLLECTION_POST_SHARE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.collectionDetailPostShare
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_DETAIL_COLLECTION_POST', res.data.collection.posts);
            commit('MUTATION_GET_DETAIL_COLLECTION_POST_CITY', res.data.collection.city);
            commit('MUTATION_GET_DETAIL_COLLECTION_POST_USER', res.data.collection.user);
            resolve(res.data.collection);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

  },

  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.data.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATIONS_RESET_STATE(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },

    MUTATION_GET_DETAIL_COLLECTION_POST(state, items) {
      state.post.push(...items)
      _.forEach(state.post, function (item, key) {
        item.isActiveBookmark = item.bookmarkStatus; // Add field active isActiveBookmark
        item.isActiveLikeStatus = item.likeStatus; // Add field active isActiveLikeStatus
        if (item.places.length > 0) {
          _.forEach(item.places, function (place, placeIdx) {
            place.isActiveBookmark = place.bookmarkStatus; // Add field active isActiveBookmark
          });
        }
      });
    },

    MUTATION_GET_DETAIL_COLLECTION_POST_CITY(state, data) {
      state.city = data
    },

    MUTATION_GET_DETAIL_COLLECTION_POST_USER(state, data) {
      state.user = data
    },

    // Increment like
    MUTATION_INCREMENT_LIKE(state, {
      id
    }) {
      const cartItem = state.post.find(item => item.id === id)
      if (cartItem) {
        cartItem.statsLike++
        cartItem.isActiveLikeStatus = true
        cartItem.likeStatus = true
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE(state, {
      id
    }) {
      const cartItem = state.post.find(item => item.id === id)
      if (cartItem) {
        cartItem.statsLike--
        cartItem.isActiveLikeStatus = false
        cartItem.likeStatus = false
      }
    },

    // Increment comment
    MUTATION_INCREMENT_COMMENT(state, {
      id
    }) {
      const cartItem = state.post.find(item => item.id === id)
      if (cartItem) {
        cartItem.statsComment++
      }
    },

    // Bookmark
    MUTATION_BOOKMARK(state, {
      id
    }) {
      const cartItem = state.post.find(item => item.id === id)
      if (cartItem) {
        cartItem.bookmarkStatus = true
        cartItem.isActiveBookmark = true
      }
    },

     // UnBookmark
     MUTATION_UNBOOKMARK(state, {
      id
    }) {
      const cartItem = state.post.find(item => item.id === id)
      if (cartItem) {
        cartItem.isActiveBookmark = false
        cartItem.bookmarkStatus = false
      }
    },

     // Bookmark
     MUTATION_BOOKMARK_PLACE(state, {
      id
    }) {
      return _.forEach(state.post, function (item, key) {
        const cartItem = item.places.find(it => it.id === id)
        if (cartItem) {
          cartItem.isActiveBookmark = true
          cartItem.bookmarkStatus = true
        }
      });
    },

     // UnBookmark
     MUTATION_UNBOOKMARK_PLACE(state, {
      id
    }) {
      return _.forEach(state.post, function (item, key) {
        const cartItem = item.places.find(it => it.id === id)
        if (cartItem) {
          cartItem.isActiveBookmark = false
          cartItem.bookmarkStatus = false
        }
      });
    },

  }
}
