<template>
  <f7-page id="page-post" name="home">
    <header-navbar-action-component
      :share="() => addNewPost()"
      :actionType="constants.ACTION_TYPE.SHARE"
    />
    <select-a-photo-component
      :items="imagesUpload"
      :fileSelected="fileSelected"
      :handleRemoveImage="(key) => handleRemoveImage(key)"
    />

    <f7-list no-hairlines class="no-margin-vertical">
      <f7-list-input
        label="Add Caption"
        type="text"
        :value="post.title"
        @input="handleTitle($event)"
        :maxlength="maxTitle"
        placeholder="Title"
        clear-button
      >
        <div slot="label" class="input-group-addon" v-text="(maxTitle - post.title.length)"></div>
      </f7-list-input>

      <li class="add-topic">
        <add-topic-component
          :onHandle="(item) => handleTopic(item)"
          :topics="topics"
          :onDelete="(value) => onDeleteTopic(value)"
        />
      </li>

      <li class="select-city">
        <select-city-component
          :items="cities"
          :cityId="post.cityId"
          :onChange="(e) => onChangeCity(e)"
        />
      </li>

      <li class="add-location">
        <add-location-component
          :onHandle="(item) => handlePlace(item)"
          :places="places"
          :onDelete="(value) => onDeletePlace(value)"
        />
      </li>

      <f7-list-input
        label="Add Body"
        type="textarea"
        placeholder="Tell your story..."
        :resizable="true"
        clear-button
        :value="post.content"
        @input="handleBody($event)"
      ></f7-list-input>
    </f7-list>

    <f7-block class="wrapper-content-graph" v-if="post.externalUrl != null">
      <f7-card class="card-header-pic-link">
        <span @click="handleCloseGraph">
          <f7-icon f7="close_round_fill"></f7-icon>
        </span>
        <f7-card-header class="no-border" valign="bottom" v-if="post.externalSource == 'YOUTUBE'">
          <iframe width="100%" height="315" :src="post.externalUrl"></iframe>
        </f7-card-header>
        <f7-card-header class="no-border" valign="bottom" v-else>
          <img :src="graph.image">
        </f7-card-header>
        <f7-card-content>
          <f7-link class="title" :href="post.externalUrl" target="_blank" external>{{post.title}}</f7-link>
          <p class="content" v-if="post.content">
            {{post.content}}
            <span class="line-between"></span>
          </p>
          <p>
            <span class="site-name" v-if="post.siteName">{{post.siteName}}</span>
            {{post.siteDescription}}
            <span
              class="site-id"
            >{{handleExtractHostName(post.externalUrl)}}</span>
          </p>
        </f7-card-content>
      </f7-card>
    </f7-block>

    <div v-if="isLoading">
      <loading/>
    </div>
    <toolbar
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="true"
      :current_collection="false"
      :current_passport="false"
      :handlePost="handleScrollPost"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import _ from "lodash";
import validator from "validator";
import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';

import { UploadImages } from "../../utils/cloundinary-upload";
import { extractHostName } from "../../utils/functions";
import { resizeImage } from "../../utils/resize-image";
import C from "../../_helpers/constants";

import HeaderNavbarActionComponent from "../../components/HeaderNavbarAction";
import SelectAPhotoComponent from "../../components/SelectAPhoto";
import SelectCityComponent from "../../components/SelectCity";
import AddLocationComponent from "../../components/AddLocation";
import AddTopicComponent from "../../components/AddTopic";
import HeaderNavbar from "../../components/HeaderNavbar";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
export default {
  components: {
    HeaderNavbar,
    Loading,
    Toolbar,
    HeaderNavbarActionComponent,
    SelectAPhotoComponent,
    SelectCityComponent,
    AddLocationComponent,
    AddTopicComponent,
  },

  data() {
    return {
      isShowLocation: false,
      constants: C,
      maxTitle: 200,
      imagesUpload: [],
      places: [],
      topics: [],
      post: {
        cityId: null,
        title: "",
        content: "",
        placeIds: [],
        topicIds: [],
        externalUrl: null,
        externalSource: null,
        photos: null,
        siteName: null,
        siteDescription: null
      },
      graph: {
        image: null
      },
      isLoading: false
    };
  },

  computed: {
    ...mapGetters("cities", {
      cities: "published",
      city: "currentCity"
    }),

    ...mapState("post", {
      loading: "status"
    })
  },

  mounted() {
    this.post.cityId = this.city.id;
  },

  methods: {
    // Scroll to top
    handleScrollPost() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Add place
    handlePlace(item) {
      this.places.push(item);
      this.post.placeIds.push({ placeId: item.place_id });
    },

    // Add place
    handleTopic(item) {
      if (this.topics.indexOf(item) != -1) return
      this.topics.push(item);
      this.post.topicIds.push(item.id);
    },

    handleExtractHostName(url) {
      return extractHostName(url);
    },

    // Reset data
    handleCloseGraph() {
      Object.assign(this.$data, this.$options.data());
      this.post.cityId = this.city.id;
    },

    // Wait call facebook graph
    waitFbGraph(e, timeout) {
      setTimeout(() => {
        this.fbGraph(e);
      }, timeout);
    },

    // Get value and validator url body
    handleBody(e) {
      this.post.content = e.target.value;
      this.validatorUrl(e); // Validate
    },

    // Get value and validator url title
    handleTitle(e) {
      this.post.title = e.target.value;
      this.validatorUrl(e); // Validate
    },

    // Validator Url
    validatorUrl(e) {
      const isURL = validator.isURL(e.target.value); // Check url
      if (e.inputType == "insertFromPaste") {
        if (isURL) {
          this.waitFbGraph(e.target.value, 0);
        }
      }
    },

    // Facebook Response (set data)
    facebookResponse(data, url) {
      this.post.title = data.title ? data.title : url;
      this.post.siteName = data.site_name ? data.site_name : null;
      this.post.siteDescription = data.description ? data.description : null;
      this.imagesUpload = [data.image] ? [data.image] : [];
      this.post.externalUrl =
        data.type === "video.other" && data.video != null ? data.video : url;
      this.post.externalSource =
        data.type === "video.other" && data.video != null ? "YOUTUBE" : "LINK";
      this.graph.image = [data.image] ? [data.image] : [];
      this.isLoading = false;
    },

    // Call api facebook
    async fbGraph(url) {
      const self = this

      try {
        self.isLoading = true;
        const resp = await gql.query({
          query: gqlQuery.fetchFromLink, variables: { url }
        })

        const data = resp.data.fetchFromLink
        self.facebookResponse(data, url)
      } catch(err) {
        self.isLoading = false
        self.$f7.dialog.alert("Not all links can be imported. Try another?", "Cannot import this link 😥")
      }
    },


    // On change city
    onChangeCity(id) {
      const self = this;
      const citySelected = _.find(self.cities, { id: id });
      self.post.cityId = id;
    },

    // Delete place
    onDeletePlace(id) {
      this.places.splice(id, 1);
      this.post.placeIds.splice(id, 1);
    },

    // Delete topic
    onDeleteTopic(id) {
      this.topics.splice(id, 1);
      this.post.topicIds.splice(id, 1);
    },

    // Select image
    fileSelected(e) {
      const self = this;
      if (self.imagesUpload.length < 10) {
        if (e.target.files.length > 10) {
          self.$f7.dialog.alert("Sorry, you’ve exceeded our max of 10 photos.");
        } else {
          const selectedFiles = Array.prototype.slice.call(e.target.files);
          selectedFiles.forEach(file => {
            let reader = new FileReader();
            const self = this;
            reader.onload = e => {
              var img = document.createElement("img");
              img.onload = () => {
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var MAX_WIDTH = 1024;
                var MAX_HEIGHT = 1024;
                var width = img.width;
                var height = img.height;
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                var exifOrientation = "";
                // Check orientation in EXIF metadatas
                EXIF.getData(img, function() {
                  var allMetaData = EXIF.getAllTags(this);
                  exifOrientation = allMetaData.Orientation;
                });
                // set proper canvas dimensions before transform & export
                if (jQuery.inArray(exifOrientation, [5, 6, 7, 8]) > -1) {
                  canvas.width = height;
                  canvas.height = width;
                } else {
                  canvas.width = width;
                  canvas.height = height;
                }
                // transform context before drawing image
                switch (exifOrientation) {
                  case 2:
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break;
                  case 3:
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break;
                  case 4:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                  case 5:
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                  case 6:
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                  case 7:
                    ctx.transform(0, -1, -1, 0, height, width);
                    break;
                  case 8:
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                  default:
                    ctx.transform(1, 0, 0, 1, 0, 0);
                }
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/jpeg");
                if (this.imagesUpload.length < 10) {
                  this.imagesUpload.push(dataurl);
                }
              };
              img.src = e.target.result;
            };
            reader.readAsDataURL(file);
          });
        }
      } else {
        self.$f7.dialog.alert("Sorry, you’ve exceeded our max of 10 photos.");
      }
    },

    // Remove image
    handleRemoveImage(index) {
      this.imagesUpload.splice(index, 1);
    },

    // Add new post
    async addNewPost() {
      const self = this;
      if (
        self.post.title.length < 1 &&
        self.post.content.length < 1 &&
        self.imagesUpload.length < 1
      ) {
        self.$f7.dialog.alert("A photo or title is required");
      } else {
        self.$f7.dialog.progress("Posting...");
        if (self.imagesUpload.length > 0 && self.imagesUpload[0] != null) {
          let images
          // If image upload > 0
          try {
            images = await UploadImages(this.imagesUpload); // Upload
          } catch(e) {
            self.$f7.dialog.close();
            return self.$f7.dialog.alert(e);
          }

          if (images.some(image => image.url == undefined)) {
            // Error
            self.$f7.dialog.close();
            return self.$f7.dialog.alert("Error, image size has exceeded 10MB");
          } else {
            // Success
            // let arrImage = _.map(
            //   images,
            //   _.partialRight(_.pick, ["name", "width", "height", "url"])
            // );
            let arrImage = _.map(images);
            this.post.photos = arrImage;
          }
        }
        if (this.post) {
          const self = this;
          // Params create a new post
          const params = {
            cityId: self.post.cityId,
            title: self.post.title,
            content: self.post.content,
            placeIds: self.post.placeIds,
            topicIds: self.post.topicIds,
            photos: self.post.photos,
            siteName: self.post.siteName,
            siteDescription: self.post.siteDescription,
            externalUrl: self.post.externalUrl,
            externalSource: self.post.externalSource
          };

          self.$f7.dialog.close();
          // this.$store.dispatch("post/ACTION_CREATE_POST_SUCCESS", true);
          // Call api
          this.$store.dispatch("post/ACTION_CREATE_POST", params).then(res => {
            if (res.id) {
              // Success
              Object.assign(this.$data, this.$options.data());
              this.$store.dispatch("topics/ACTION_RELOAD_PAGE")
              this.$f7router.navigate(C.ROUTER_NAVIGATE.FEEDS)
            } else {
              // Error
              self.$f7.dialog.alert("Error");
            }
          });
        }
      }
    }
  }
};
</script>
