<template>
  <f7-page id="page-friends" name="friends" hide-bars-on-scroll>
    <header-navbar title="Friends"/>

    <f7-block class="vendor-list list-notification" no-padding>
      <f7-row v-for="(item, key) in profile.friends" :key="key">
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item
              @click="actionFetchAuthProfile(item.receive.id)"
              :title="`${item.receive.nickname }`"
            >
              <img
                slot="media"
                v-if="item.receive.avatar && item.receive.avatar.name"
                :src="getAvatarAuthImg(item.receive.avatar.name)"
              >
              <span v-else slot="media" class="text">{{item.receive.nickname.charAt(0)}}</span>
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30">
          <f7-button raised class="friends">FRIENDS</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
import HeaderNavbar from "../../components/HeaderNavbar";
import { mapGetters, mapState, mapActions } from "vuex";
import { getUrlImg } from "../../utils/cloundinary-image";
export default {
  components: { HeaderNavbar },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("passport", {
      profile: "profile"
    })
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    getAvatarAuthImg(name) {
      return getUrlImg(name);
    }
  }
};
</script>
