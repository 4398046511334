import gql from "graphql-tag";
const loginFB = gql `
  mutation loginFB($data: LoginFBInput!) {
    loginFB(data: $data) {
      token
      user {
        id
        username
        # password
        nickname
        isActive
      }
    }
  }
`
export  {
  loginFB
};
