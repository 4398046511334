<template>
  <f7-toolbar position="top">
    <f7-nav-left v-if="backButtonEnabled">
      <f7-link back v-if="link == null">
        <img src="@/static/icons/back.svg" alt="Back">
      </f7-link>
      <f7-link v-else :href="`/${link}/`">
        <img src="@/static/icons/back.svg" alt="Back">
      </f7-link>
    </f7-nav-left>
    <f7-nav-left v-else></f7-nav-left>
    <f7-nav-title>
      <h2 class="logo">
        <img src="@/static/icons/favs.png" alt="Favs">
      </h2>
    </f7-nav-title>
    <f7-link>
      <div class="navbar-right">
      <f7-link href="/search/" icon-f7="search" v-if="me.id"></f7-link>
      <f7-link href="/notification/" icon-only active v-if="me.id">
        <f7-icon f7="bell">
          <f7-badge v-if="notifications.length > 0">{{notifications.length}}</f7-badge>
        </f7-icon>
      </f7-link>
      </div>
    </f7-link>
  </f7-toolbar>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: {
    link: {
      required: false,
      type: String,
      default: null
    },
    backButtonEnabled: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("auth", {
      me: "me",
    }),
    ...mapGetters("notification", {
      refetch: "refetch"
    })
  },
  data() {
    return {
      notifications: []
    }
  },
  created() {
    this.fetchNotification()
  },
  watch: {
    refetch(isRefetch) {
      if (!isRefetch) return
      this.fetchNotification()
    }
  },
  methods: {
    fetchNotification() {
      const self = this

      this.$store.dispatch("notification/ACTION_GET_NOTIFICATION").then(res => {
        self.notifications = res
      })
    }
  }
}
</script>
