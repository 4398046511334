<template>
  <div :class="`${colorTheme} navbar-inner navbar-passport-page sliding navbar-current ${activeNavBar ? 'active' : ''}`">
    <f7-nav-left>
      <f7-link href="/settings/" v-if="meId == profileId">
        <img
          v-if="colorTheme == constants.COLORS.DARK"
          src="@/static/icons/settings_white.svg"
          alt="Settings"
        >
        <img
          v-if="colorTheme == constants.COLORS.LIGHT"
          src="@/static/icons/settings_black.svg"
          alt="Settings"
        >
      </f7-link>
      <f7-link back v-else>
          <img src="@/static/icons/back.svg" alt="Back">
        </f7-link>
    </f7-nav-left>
    <f7-nav-title>
      <h2 class="logo">
        <img
          v-if="colorTheme == constants.COLORS.DARK"
          src="@/static/icons/favs_white.png"
          alt="Favs"
        >
        <img
          v-if="colorTheme == constants.COLORS.LIGHT"
          src="@/static/icons/favs_black.png"
          alt="Favs"
        >
      </h2>
    </f7-nav-title>
    <f7-nav-right>
      <f7-link :actions-open="actionsOpen">
        <img
          v-if="colorTheme == constants.COLORS.DARK"
          src="@/static/icons/share_white.svg"
          alt="Share"
        >
        <img
          v-if="colorTheme == constants.COLORS.LIGHT"
          src="@/static/icons/share_black.svg"
          alt="Share"
        >
      </f7-link>
      <f7-link href="/search/" v-if="me.id">
        <img
          v-if="colorTheme == constants.COLORS.DARK"
          src="@/static/icons/search_white.svg"
          alt="Search"
        >
        <img
          v-if="colorTheme == constants.COLORS.LIGHT"
          src="@/static/icons/search_black.svg"
          alt="Search"
        >
      </f7-link>
      <f7-link href="/notification/" icon-only active v-if="me.id">
        <f7-icon f7="bell_fill" style="color: white;" v-if="colorTheme == constants.COLORS.DARK">
          <f7-badge v-if="notifications.length > 0">{{notifications.length}}</f7-badge>
        </f7-icon>
        <f7-icon f7="bell_fill" style="color: black;" v-else>
          <f7-badge v-if="notifications.length > 0">{{notifications.length}}</f7-badge>
        </f7-icon>
      </f7-link>
    </f7-nav-right>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
export default {
  props: {
    colorTheme: {
      required: false,
      type: String,
      default: "dark"
    },
    activeNavBar: {
      required: false,
      type: Boolean,
      default: false
    },
    actionsOpen: {
      required: false,
      type: String,
      default: ""
    },
    meId: {
      required: false,
      type: String,
    },
    profileId: {
      required: false,
      type: String,
    }
  },
  data() {
    return {
      constants: C,
      notifications: []
    };
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("notification", {
      refetch: "refetch"
    })
  },
  created() {
    this.fetchNotification()
  },
  watch: {
    refetch(isRefetch) {
      if (!isRefetch) return
      this.fetchNotification()
    }
  },
  methods: {
    fetchNotification() {
      const self = this

      this.$store.dispatch("notification/ACTION_GET_NOTIFICATION").then(res => {
        self.notifications = res
      })
    }
  }
};
</script>
