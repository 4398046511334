import gql from "graphql-tag";

const like = gql`
  mutation like($data: LikeInput!) {
    like(data: $data)
    # {
    #   # id
    #   post {
    #     id
    #     title
    #     content
    #     createdAt
    #     statsLike
    #     statsComment
    #     likeStatus
    #     bookmarkStatus
    #     author {
    #       id
    #       nickname
    #       avatar {
    #         name
    #         url
    #       }
    #     }
    #     photos {
    #       name
    #       url
    #     }
    #   }
    # }
  }
`;
export { like };
