<template>
  <f7-page id="page-event-detail" name="event-detail">
    <header-navbar-action-component
      :type="constants.BOOKMARK_CONTENT_TYPE.EVENT"
      :item="event"
      :id="event.id"
      :actionType="constants.ACTION_TYPE.BOOKMARK"
      :isActiveBookmarkStatus="event.place.isActiveBookmark"
    />

    <p class="head-background">
      <img :src="event.cover" v-if="event.cover != ''" />
      <img
        :src="event.place.coverUrl"
        v-else-if="event.place && event.place.coverUrl"
      />
      <img :src="headingImg" v-else />
    </p>

    <f7-block strong inset class="block-content">
      <header-event-detail-component :item="event" />

      <div class="event-date">
        <span class="date">
          <b>
            <span class="event-date">{{ showEventDate(event) }}</span>
            <span class="event-time">{{ showEventTime(event) }}</span>
          </b>
        </span>
      </div>

      <div class="event-description">
        <p>{{ event.description }}</p>
      </div>

      <div class="media-block">
        <f7-block-title class="bearer">VENUE</f7-block-title>
        <f7-block-title class="bearer"
          ><b>{{ event.place.name }}</b></f7-block-title
        >
        <f7-swiper :params="{ speed: 500, slidesPerView: 2 }" class="photo-swiper">
          <f7-swiper-slide
            v-for="(photo, index) in event.place.photos"
            :key="index"
          >
            <f7-card >
              <f7-card-header class="each-photo" :style="`background: url(` + photo.url + `)`">
                <!-- <f7-block class="user-info">
                  <img
                    src="https://randomuser.me/api/portraits/women/18.jpg"
                  />
                  <span>Jenny</span>
                </f7-block> -->
                <!-- <i class="f7-icons play-btn">play_round_fill</i> -->
              </f7-card-header>
            </f7-card>
          </f7-swiper-slide>
        </f7-swiper>
        <see-more-component :isShow="true" :toggleTip="handleSeeMore" />
      </div>

      <div class="address-block">
        <f7-block-title class="bearer">ADDRESS</f7-block-title>
        <address-component :item="event.place.address" />
      </div>

      <div class="map-block">
      <gmap-map
        :center="{
          lat: event.place.location.coordinates[1],
          lng: event.place.location.coordinates[0]
        }"
        :zoom="15"
        style="width: 100%; height: 300px; margin-top: 30px; margin-left: 0px; margin-right: 0px;"
      >
        <gmap-marker
          :position="{
            lat: event.place.location.coordinates[1],
            lng: event.place.location.coordinates[0]
          }"
        />
      </gmap-map>
      </div>
    </f7-block>
    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import Toolbar from "../components/Toolbar";
import HeaderNavbarActionComponent from "../components/HeaderNavbarAction";
import HeaderEventDetailComponent from "../components/HeaderEventDetail";
import PublicFriendsRatingComponent from "../components/PublicFriendsRating";
import TextArticleComponent from "../components/Text";
import RatingComponent from "../components/Rating";
import AddressComponent from "../components/Address";
import HoursOpenComponent from "../components/HoursOpen";
import SharingComponent from "../components/Sharing";
import PlaceImageComponent from "../components/PlaceImage";
import BookmarkComponent from "../components/Bookmark";
import StatsComment from "../components/StatsComment";
import CreatedAtComment from "../components/CreatedAt";
import SeeMoreComponent from "../components/SeeMore";
import { showEventDate, showEventTime } from "../utils/eventHelpers";
import RatingAddTipView from "../views/RatingAddTip";
import TipsView from "../views/Tips";

export default {
  components: {
    Toolbar,
    HeaderNavbarActionComponent,
    HeaderEventDetailComponent,
    TextArticleComponent,
    PublicFriendsRatingComponent,
    RatingComponent,
    AddressComponent,
    HoursOpenComponent,
    SharingComponent,
    PlaceImageComponent,
    BookmarkComponent,
    StatsComment,
    CreatedAtComment,
    SeeMoreComponent,
    RatingAddTipView,
    TipsView
  },
  name: "eventDetail",
  data() {
    return {
      constants: C,
      headingImg:
        "https://i1.wp.com/www.kesaksian.org/wp-content/uploads/2018/06/6809e5b65772abb5a6305a36b5e9c0c82ccc20b5.jpeg?fit=1000%2C669"
    };
  },
  watch: {
    bookmarkStatus(news) {
      if (news) {
        this.showToastBottom();
      }
    }
  },
  computed: {
    ...mapGetters("eventDetail", {
      event: "event"
    }),
    ...mapGetters("auth", {
      me: "me"
    })
  },
  mounted() {
    this.$store.dispatch(
      "currentUrl/ACTION_GET_CURRENT_URL",
      C.ROUTES_NAME.EVENT_DETAIL
    );
  },

  methods: {
    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Saved to plan",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    },
    photoBrowser(photo) {
      return photo;
    },
    onPhotoBrowser() {
      this.$refs.photoBrowser.open();
    },
    handleSeeMore() {
      const params = {
        id: this.event.place.id,
        form: null
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params);
    },
    showEventDate,
    showEventTime
  }
};
</script>
