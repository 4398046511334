import {me} from './me';
import {cities} from './cities';
import {feeds} from './feeds';
import {trendings} from './trendings';
import {trendingEvents} from './trendingEvents';
import {detail} from './detail';
import {place} from './place';
import {placeFromShare} from './placeFromShare';
import {detailFromShare} from './detailFromShare';
import {recs} from './recs';
import {friendFavs} from './friendFavs';
import {summaryFeed} from './summaryFeed';
import {categories} from './categories';
import {categoriesEvents} from './categoriesEvents';
import { searchFriendFavs, searchPlaces, searchUsers, searchEvents } from './search';
import {collections} from './collections';
import {collectionDetailPost} from './collectionDetailPost';
import {collectionDetailPlace} from './collectionDetailPlace';
import {collectionDetailEvent} from './collectionDetailEvent';
import {collectionDetailEventShare} from './collectionDetailEventShare';
import {collectionDetailPostShare} from './collectionDetailPostShare';
import {collectionDetailPlaceShare} from './collectionDetailPlaceShare';
import {passport} from './passport';
import {passportShare} from './passportShare';
import {magazine} from './magazine';
import {magazineShare} from './magazineShare';
import {events} from './events';
import {notification} from './notification';
import {topics} from './topics';
import {fetchFromLink} from "./fetchFromLink";
import { topicPosts } from "./topicPosts";
import { friendSuggestion } from "./friendSuggestion";
import { creatorSuggestion } from "./creatorSuggestion";
import { pendingFriends } from "./pendingFriends";
import { eventDetail } from "./eventDetail";

export default {
  me,
  cities,
  feeds,
  trendings,
  trendingEvents,
  detail,
  place,
  placeFromShare,
  detailFromShare,
  recs,
  friendFavs,
  summaryFeed,
  categories,
  categoriesEvents,
  searchFriendFavs,
  searchPlaces,
  searchUsers,
  searchEvents,
  collections,
  collectionDetailPost,
  collectionDetailPlace,
  collectionDetailEvent,
  collectionDetailEventShare,
  collectionDetailPostShare,
  collectionDetailPlaceShare,
  passport,
  passportShare,
  magazine,
  magazineShare,
  events,
  notification,
  topics,
  fetchFromLink,
  topicPosts,
  friendSuggestion,
  pendingFriends,
  eventDetail,
  creatorSuggestion
};
