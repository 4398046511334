import gql from "graphql-tag";

const updateComment = gql `

mutation updateComment($data: CommentInput!) {
  updateComment(data: $data) {
    id
    content
    author {
      id
      nickname
      avatar {
        url
        name
      }
    }
    createdAt
  }
}
`

export {
  updateComment

};
