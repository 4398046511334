<template>
  <span
    :class="className"
    @click="onToggleBookMark(item, id, type, pageType)"
    v-if="pageType == undefined ? !item.bookmarkStatus : !isActiveBookmarkStatus"
  >
    <img src="@/static/icons/bookmark.svg">
  </span>
  <span :class="className" v-else @click="onToggleBookMark(item, id, type, pageType)">
    <img src="@/static/icons/bookmark_active.svg">
  </span>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    className: {
      required: false,
      type: String,
      default: ""
    },
    item: {
      required: false,
      type: Object
    },
    id: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    pageType: {
      required: false,
      type: String
    },
    isActiveBookmarkStatus: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      constants: C
    };
  },

  methods: {
    onToggleBookMark(item, id, type, page) {
      const self = this;
      item.isActiveBookmark = !item.isActiveBookmark; // Add virtual fields to handle acceleration
      if (item.isActiveBookmark == false) {
        // If status bookmark
        const params = {
          id: id,
          type: type,
          status: C.BOOKMARK_STATUS.UNBOOKMARK
        };
        const pageType = {
          type: page
        };
        this.$store
          .dispatch("bookMark/ACTION_BOOK_MARK", { params, pageType })
          .then(res => {
            // Call api bookmark (unbookmark)
            item.bookmarkStatus = false; // Set virtual fields
          });
      } else {
        // If status unbookmark
        const params = {
          id: id,
          type: type,
          status: C.BOOKMARK_STATUS.BOOKMARK
        };
        const pageType = {
          type: page
        };
        this.$store
          .dispatch("bookMark/ACTION_BOOK_MARK", { params, pageType })
          .then(res => {
            // Call api bookmark (bookmark)
            item.bookmarkStatus = true; // Set virtual fields
          });
      }
      this.$forceUpdate();
    }
  }
};
</script>

