import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';
import C from "../../_helpers/constants";
export default {
  namespaced: true,
  state: {
    data: [],
    info: [],
    pageType: null
  },

  getters: {
    info: state => state.info,
    pageType: state => state.pageType,
    data: (state, getters, rootState) => {
      state.data.isActiveBookmark = state.data.bookmarkStatus; // Add field active isActiveBookmark
      state.data.isActiveLikeStatus = state.data.likeStatus; // Add field active isActiveLikeStatus
      if (state.data && state.data.places && state.data.places.length > 0) {
        _.forEach(state.data.places, function (place, placeIdx) {
          place.isActiveBookmark = place.bookmarkStatus; // Add field active isActiveBookmark
        });
      }
      return state.data
    }
  },

  actions: {

    ACTION_GET({
      commit
    }, params) {
      const id = params.id
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              id
            },
            mutation: gqlQuery.detail
          })

          .then(res => {
            // commit('STATUS_SUCCESS')
            commit('MUTATION_GET', res.data.post)
            commit('MUTATION_DEATAIL_PAGE_TYPE', params.pageType)
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.POST_DETAIL_PAGE,
              params: {
                id: id
              }
            });
            resolve(res.data)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_GET_SHARE({
      commit
    }, params) {
      const id = params.id
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              id
            },
            mutation: gqlQuery.detailFromShare
          })

          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_GET', res.data.post)
            commit('MUTATION_DEATAIL_PAGE_TYPE', params.pageType)
            resolve(res.data)
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.POST_DETAIL_PAGE,
              params: {
                id: id
              }
            });
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },



  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATION_GET(state, details) {
      state.data = details
    },

    MUTATION_GET_COMMENT(state, details) {
      state.data = details
    },
    MUTATION_DEATAIL_PAGE_TYPE(state, item) {
      state.pageType = item
    },
    // Post like
    MUTATION_POST_LIKE(state, data) {
      state.data = data.like
    },

    // Put like
    MUTATION_PUT_LIKE(state, data) {
      state.data = data.like
    },

    MUTATIONS_GET_INDEX(state, data) {
      state.info = data
    },

    // Post comment
    MUTATION_POST_COMMENT(state, items) {
      state.data.comments.push(items)
    },

    // Update comment
    MUTATION_PUT_COMMENT(state, items) {
      const arr = _.map(state.data.comments, function (a) { // Change new value with item
        return a.id === items.id ?
          items :
          a;
      });
      state.data.comments = arr;
    },

    // Increment like
    MUTATION_INCREMENT_LIKE(state, {
      id
    }) {
      if (state.data.id === id) {
        state.data.statsLike++
        state.data.isActiveLikeStatus = true
        state.data.likeStatus = true
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE(state, {
      id
    }) {
      if (state.data.id === id) {
        state.data.statsLike--
        state.data.isActiveLikeStatus = false
        state.data.likeStatus = false
      }
    },

    // Increment comment
    MUTATION_INCREMENT_COMMENT(state, {
      id
    }) {
      if (state.data.id === id) {
        state.data.statsComment++
      }
    },

    // Bookmark
    MUTATION_BOOKMARK(state, {
      id
    }) {
      if (state.data.id === id) {
        state.data.bookmarkStatus = true
        state.data.isActiveBookmark = true
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK(state, {
      id
    }) {
      if (state.data.id === id) {
        state.data.bookmarkStatus = false
        state.data.isActiveBookmark = false
      }
    },

    MUTATION_BOOKMARK_PLACE_POST(state, {
      id
    }) {

      const cartItem = state.data.places.find(item => item.id === id)
      if (cartItem) {
        cartItem.bookmarkStatus = true
        cartItem.isActiveBookmark = true
      }

    },

    MUTATION_UNBOOKMARK_PLACE_POST(state, {
      id
    }) {

      const cartItem = state.data.places.find(item => item.id === id)
      if (cartItem) {
        cartItem.bookmarkStatus = false
        cartItem.isActiveBookmark = false
      }

    },



  }
}
