import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    data: []
  },

  getters: {
    data: (state, getters, rootState) => {
      let arr =
        state.data ? state.data : [];
      _.forEach(arr.posts, function (item, key) {
        item.isActiveLikeStatus = item.likeStatus; // Add field active isActiveBookmark
      });
      return arr;
    },

  },

  actions: {
    ACTION_FETCH_SUMMARY_FEED({
      commit
    }) {

      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.summaryFeed
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_SUMMARY_FEED', res.data.summaryFeed)
            resolve(res.data.summaryFeed)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
    MUTATION_SUMMARY_FEED(state, items) {
      state.data = items;
    },

    // Increment like
    MUTATION_INCREMENT_LIKE(state, {
      id
    }) {
      const cartItem = state.data.posts.find(item => item.id === id)
      if (cartItem) {
        cartItem.statsLike++
        cartItem.isActiveLikeStatus = true
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE(state, {
      id
    }) {
      const cartItem = state.data.posts.find(item => item.id === id)
      if (cartItem) {
        cartItem.statsLike--
        cartItem.isActiveLikeStatus = false
      }
    },

     // Increment comment
     MUTATION_INCREMENT_COMMENT(state, {
      id
    }) {
      const cartItem = state.data.posts.find(item => item.id === id)
      if (cartItem) {
        cartItem.statsComment++
      }
    },

  }
}
