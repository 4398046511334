<template>
  <f7-row no-gap>
    <f7-col width="65">
      <p>{{item}}</p>
      <input type="hidden" v-model="item">
    </f7-col>
    <f7-col width="35" class="col-right address-copy">
      <f7-link
        v-clipboard:copy="item"
        v-clipboard:success="onCopySuccess"
        v-clipboard:error="onCopyError"
      >Copy address</f7-link>
    </f7-col>
  </f7-row>
</template>
<script>
import C from "../_helpers/constants";
import { copySuccess, copyError } from "../utils/copy";
export default {
  props: {
    item: {
      required: false,
      type: String
    }
  },

  methods: {
    onCopySuccess(e) {
      return copySuccess(e, this);
    },
    onCopyError(e) {
      return copyError(e);
    }
  }
};
</script>
