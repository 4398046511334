<template>
  <f7-page
    id="page-detail-collection"
    name="detail-collection-place"
    infinite
    :infinite-preloader="false"
    @infinite="loadMore"
  >
    <header-navbar-action-component
      :onChange="(event)=>handleOnChangePlace(event)"
      :actionType="constants.ACTION_TYPE.ACTIONS"
      :actionsOpenName="`actions-share-collection-${city.id}`"
      :id="city.id"
      :userId="me.id"
      :selectedEvent="true"
    />

    <f7-block class="block-article page-detail-collection__user-info" v-if="!me.id">
      <user-info-component
        :onAuthProfile="()=> onAuthProfile()"
        :getAvatar="getAvatarAuthImg(user && user.avatar && user.avatar.name)"
        :nickname="user.nickname"
        :isAction="false"
      />
    </f7-block>

    <f7-block-title class="city-selected" no-padding>{{city.name}}</f7-block-title>
    <sort-by-component
      :handleSortBy="(type, text)=>handleSortBy(type, text)"
      :toggleSorting="()=>toggleSorting()"
      :textSortBy="itemSortBy"
      :isSorting="isSorting"
      :indexSortBy="indexSortBy"
      :textFriendRating="textFriendRating"
    />

    <f7-tabs>
      <f7-tab :class="`tab-event-all`" tab-active>
        <div v-if="isLoading">
          <recs-skeleton-component
            v-for="n in 10"
            :key="n"
            :className="`skeleton-text skeleton-effect-${effect}`"
          />
        </div>
        <div v-if="!isLoading">
          <event-list-view :items="events"/>
        </div>
      </f7-tab>
      <f7-tab :class="`tab-event-category`">
        <div v-if="isLoading">
          <recs-skeleton-component
            v-for="n in 10"
            :key="n"
            :className="`skeleton-text skeleton-effect-${effect}`"
          />
        </div>
        <div v-if="!isLoading">
          <event-list-view :items="events"/>
        </div>
      </f7-tab>
    </f7-tabs>

    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="true"
      :current_passport="false"
      :handleCollection="handleScrollCollection"
    />
    <div class="preloader infinite-scroll-preloader" v-show="hasMoreItems"></div>
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import HeaderNavbar from "../../components/HeaderNavbar";
import Toolbar from "../../components/Toolbar";
import ToolbarSocial from "../../components/ToolbarSocial";
import HeaderNavbarActionComponent from "../../components/HeaderNavbarAction";
import UserInfoComponent from "../../components/UserInfo";
import Vue from "vue";
import C from "../../_helpers/constants";
import _ from "lodash";
import { getUrlImg } from "../../utils/cloundinary-image";

import SortByComponent from "../../components/SortBy";

import RecsSkeletonComponent from "../../components/RecsSkeleton";
import EventListView from "../../views/EventList";
export default {
  components: {
    HeaderNavbar,
    Toolbar,
    ToolbarSocial,
    HeaderNavbarActionComponent,
    UserInfoComponent,
    SortByComponent,
    RecsSkeletonComponent,
    EventListView
  },
  data() {
    return {
      constants: C,
      isLoading: false,
      nextItem: 0,
      allowInfinite: true,
      hasMoreItems: true,

      isSorting: false,
      itemSortBy: C.FILTER.TEXT_MY_RATE,
      indexSortBy: C.FILTER.FRIEND_RATE,
      textFriendRating: C.FILTER.TEXT_MY_RATE
    };
  },

  mounted() {
    this.$store.dispatch("collectionDetailEvent/ACTION_RESET_STATE");
    this.loadMore();
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("collectionDetailEvent", {
      city: "city",
      user: "user",
      events: "events"
    }),
    ...mapGetters("collection", {
      urlSearchParams: "url_search_params"
    })
  },
  methods: {
    // Scroll to top
    handleScrollCollection() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Get avatar
    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    // Load more
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      self.nextItem++;
      // Params
      const params = {
        filterType: self.indexSortBy,
        type: C.COLLECTION_TYPE.EVENT,
        cityId: this.urlSearchParams.cid,
        userId: this.urlSearchParams.uid,
        page: self.nextItem
      };
      // If exist auth
      if (self.me.id) {
        // Call api
        this.$store
          .dispatch(
            "collectionDetailEvent/ACTION_GET_DETAIL_COLLECTION_EVENT",
            params
          )
          .then(res => {
            this.textFriendRating = C.FILTER.TEXT_MY_RATE;
            this.itemSortBy = C.FILTER.TEXT_MY_RATE;
            if (res.events.length > C.PAGE_SIZE.DEFAULT) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false;
              self.hasMoreItems = false;
            }
          });
      } else {
        // If not auth
        this.$store
          .dispatch(
            "collectionDetailEvent/ACTION_GET_DETAIL_COLLECTION_EVENT_SHARE",
            params
          )
          .then(res => {
            this.textFriendRating =
              res.user &&
              res.user.nickname +
                `${res.user.nickname.slice(-1) == "s" ? "'" : "'s"}` +
                " Ratings";
            this.itemSortBy =
              res.user &&
              res.user.nickname +
                `${res.user.nickname.slice(-1) == "s" ? "'" : "'s"}` +
                " Ratings";
            if (res.events.length > C.PAGE_SIZE.DEFAULT) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false;
              self.hasMoreItems = false;
            }
          });
      }
    },

    // Change select
    handleOnChangePlace(e) {
      const self = this;
      if (e.target.value == C.DETAIL_COLLECTION_TYPE.POST) {
        self.$f7router.navigate({
          name: C.ROUTES_NAME.COLLECTION_DETAIL_POST,
          params: { id: self.urlSearchParams.uid }
        });
      }
      if (e.target.value == C.DETAIL_COLLECTION_TYPE.PLACE) {
        self.$f7router.navigate({
          name: C.ROUTES_NAME.COLLECTION_DETAIL_PLACE,
          params: { id: self.urlSearchParams.uid }
        });
      }
    },

    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    // Status show/hide sortby
    toggleSorting() {
      this.isSorting = !this.isSorting;
    },

    // Handle sort by
    handleSortBy(type, item) {
      const self = this;
      self.itemSortBy = item; // Set text
      self.indexSortBy = type; // Set type
      self.isSorting = false; // Set status
      if (type) {
        self.nextItem = 0;
        // Reset place
        this.$store.dispatch("collectionDetailEvent/ACTION_RESET_STATE");
        if (type == C.FILTER.DISTANCE) {
          this.geolocation();
        }
        this.loadMore(); // get data
      }
    },

    // Get location
    geolocation: function() {
      const self = this;
      const options = {
        timeout: 1000,
        maximumAge: 10000,
        enableHighAccuracy: true
      };
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            //do succes handling
            if (position.coords.latitude && position.coords.longitude) {
              localStorage.setItem(
                "currentLatLocation",
                position.coords.latitude
              );
              localStorage.setItem(
                "currentLngLocation",
                position.coords.longitude
              );
            }
          },
          function errorCallback(error) {
            self.$f7.dialog.alert(error.message, "Geolocation Error");
          },
          options
        );
      } else {
        self.$f7.dialog.alert(
          "Geolocation is not available",
          "Geolocation Error"
        );
      }
    }
  }
};
</script>
