<template>
  <f7-toolbar tabbar labels bottom>
    <f7-link href="/" tab-link v-if="current_feeds" @click="handleFeeds">
      <span class="img">
        <img src="@/static/icons/feed_active.svg" alt="Feed">
      </span>
      <span :class="`active text`">{{feeds}}</span>
    </f7-link>
    <f7-link href="/" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/feed.svg" alt="Feed">
      </span>
      <span :class="`text`">{{feeds}}</span>
    </f7-link>

    <f7-link href="/" tab-link v-if="current_recs" @click="handleRecs">
      <span class="img">
        <img src="@/static/icons/recs_active.svg" alt="Recs">
      </span>
      <span :class="`active text`">{{recs}}</span>
    </f7-link>

    <f7-link href="/" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/recs.svg" alt="Recs">
      </span>
      <span :class="`text`">{{recs}}</span>
    </f7-link>

    <f7-link href="/" tab-link v-if="current_post" @click="handlePost">
      <span class="img">
        <img src="@/static/icons/post_active.svg" alt="Post">
      </span>
      <span :class="`active text`">{{post}}</span>
    </f7-link>
    <f7-link href="/" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/post.svg" alt="Post">
      </span>
      <span :class="`text`">{{post}}</span>
    </f7-link>

    <f7-link href="/" tab-link v-if="current_collection" @click="handleCollection">
      <span class="img">
        <img src="@/static/icons/collection_active.svg" alt="Collection">
      </span>
      <span :class="`active text`">{{collection}}</span>
    </f7-link>

    <f7-link href="/" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/collection.svg" alt="Collection">
      </span>
      <span :class="`text`">{{collection}}</span>
    </f7-link>
    <f7-link @click="handlePassport" tab-link v-if="current_passport">
      <span class="img">
        <img src="@/static/icons/passport_active.svg" alt="Passport">
      </span>
      <span :class="`active text`">{{passport}}</span>
    </f7-link>

  </f7-toolbar>
</template>
<script>
export default {
  props: {
    feeds: {
      required: true,
      type: String
    },
    recs: {
      required: true,
      type: String
    },
    post: {
      required: true,
      type: String
    },
    collection: {
      required: true,
      type: String
    },
    passport: {
      required: true,
      type: String
    },
    current_feeds: {
      type: Boolean,
      default: true
    },
    current_recs: {
      type: Boolean
    },
    current_post: {
      type: Boolean
    },
    current_collection: {
      type: Boolean
    },
    current_passport: {
      type: Boolean
    },
    handleFeeds: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleRecs: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handlePost: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleCollection: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handlePassport: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },

  methods: {

    onHandleDefault() {
      return null;
    }
  }
};
</script>
