<template>
  <div>
    <div id="map" style="height:0px"></div>
    <div class="item-content item-input location-search">
      <div class="item-inner">
        <div class="item-title item-label">
          {{title}}
          <span>(S)</span>*
        </div>
        <div class="item-input-wrap">
          <input
            type="text"
            class="search-location"
            placeholder="Search topics"
            @input="handleSearch"
            v-model="keyWord"
          >
          <span class="input-clear-button"></span>
        </div>
        <ul class="media-list" v-if="isShow">
          <li v-for="(item, key) in items" :key="key">
            <span class="wrap-name-city" slot="text" @click="onHandle(item), handleSelect()">
              <i class="f7-icons">tag</i>
              <span class="name">{{ item.name }}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="list-chip">
      <div class="chip-place" v-for="(item, key) in topics" :key="key">
        <span class="text">
          {{item.name}}
          <span class="chip-delete link" @click="onDelete(key)"></span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapSetters, mapState, mapActions } from "vuex";
import $Scriptjs from "scriptjs";
import { eventBus } from "../js/event-bus.js";
import C from "../_helpers/constants"

export default {
  props: {
    title: {
      required: false,
      type: String,
      default: "Add Topic"
    },
    topics: {
      required: false,
      type: Array,
      default: []
    },
    onDelete: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  data() {
    return {
      keyWord: "",
      currentLocation: { lat: 0, lng: 0 },
      cityLocation: { lat: 0, lng: 0 },
      geolocationAvailable: true,
      getCurrentPosition: true,
      items: [],
      selectableTopic: [],
      isShow: true
    };
  },

  computed: {
    ...mapGetters("topics", {
      topicList: "data",
    }),
  },
  mounted: function() {
    this.selectableTopic = this.topicList.filter(item => 
      (item.name.indexOf("WKND") == -1) && (item.name.indexOf("FOR YOU") == -1)
    )
    this.items = this.selectableTopic
    eventBus.$on("launchKeywordSearchTopic", payload => {
      this.searchTopic(payload)
    });
  },
  methods: {
    handleSelect() {
      this.isShow = false;
    },
    onHandleDefault() {
      return null;
    },
    searchTopic(keyword) {
      if (keyword == "") {
        this.items = this.selectableTopic
        return
      }
      const result = this.items.filter(item => item.name.toLowerCase().indexOf(keyword) != -1)
      this.items = result
    },
    launchTopicSearch() {
      eventBus.$emit("launchKeywordSearchTopic", this.keyWord);
    },
    handleSearch() {
      this.isShow = true;
      this.launchTopicSearch();
    },
  }
};
</script>

