import gql from "graphql-tag";

const friendSuggestion = gql`
  query friendSuggestion {
    friendSuggestion {
      id
      nickname
      email
      username
      role
      avatar {
        name
        url
      }
      background {
        name
        url
      }
      isActive
    }
  }
`;

export { friendSuggestion };
