import gql from "../../utils/apollo-client";
import gqlMutation from "../../_gql/mutation/index.js";
import gqlQuery from "../../_gql/query/index.js";
import _ from "lodash";
import C from "../../_helpers/constants";

function initialState() {
  return {
    profile: [],
    city: [],
    tip: [],
    magazineSelected: {},
    timelines: [],
    places: []
  };
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    profile: state => state.profile,
    city: state => state.city,
    tip: state => state.tip,
    magazineSelected: state => state.magazineSelected,
    places: (state, getters, rootState) => {
      return _.forEach(state.places, function (item, key) {
        item.isActiveBookmark = item.bookmarkStatus;
        item.isActiveLikeStatus = item.likeStatus;
        if (item.length > 0) {
          _.forEach(item, function (place, placeIdx) {
            place.isActiveBookmark = place.bookmarkStatus;
          });
        }
      });
    },
    timelines: (state, getters, rootState) => {
      const timelines = state.timelines.filter(e => e.relationship)
      return _.forEach(timelines, function (item, key) {
        if (item.relationship.__typename === "Post") {
          if (item.relationship) {
            item.relationship.isActiveBookmark =
              item.relationship.bookmarkStatus;
            item.relationship.isActiveLikeStatus = item.relationship.likeStatus;
          }
          if (
            item.relationship &&
            item.relationship.places &&
            item.relationship.places.length > 0
          ) {
            _.forEach(item.relationship.places, function (place, placeIdx) {
              place.isActiveBookmark = place.bookmarkStatus;
            });
          }
        }
        if (item.relationship.__typename === "Bookmark") {
          if (item.relationship && item.relationship.post) {
            item.relationship.post.isActiveBookmark =
              item.relationship.post.bookmarkStatus;
            item.relationship.post.isActiveLikeStatus =
              item.relationship.post.likeStatus;
          }
          if (item.relationship && item.relationship.event) {
            item.relationship.event.isActiveBookmark =
              item.relationship.event.bookmarkStatus;
          }
          if (item.relationship && item.relationship.place) {
            item.relationship.place.isActiveBookmark =
              item.relationship.place.bookmarkStatus;
            item.relationship.place.isActiveLikeStatus =
              item.relationship.place.likeStatus;
          }
          if (
            item.relationship &&
            item.relationship.post &&
            item.relationship.post.places.length > 0
          ) {
            _.forEach(item.relationship.post.places, function (place, placeIdx) {
              place.isActiveBookmark = place.bookmarkStatus;
            });
          }
        }
        if (item.relationship.__typename === "Rate") {
          if (item.relationship && item.relationship.place) {
            item.relationship.place.isActiveBookmark =
              item.relationship.place.bookmarkStatus;
          }
        }
        if (item.relationship.__typename === "Tip") {
          item.relationship.isActiveLikeStatus =
            item.relationship.tipLikeStatus;
          item.relationship.tipPlace.isActiveBookmark =
            item.relationship.tipPlace.bookmarkStatus;
        }
      });
    }
  },

  actions: {
    ACTION_SET_COMMENTS_TIP({
      commit
    }, data) {
      commit("MUTATION_SET_COMMENTS_TIP", data);
    },

    ACTION_POST_COMMENT({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.createComment
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_POST_COMMENT", res.data.createComment);
            resolve(res);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_PUT_COMMENT({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.updateComment
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_PUT_COMMENT", res.data.updateComment);
            resolve(res);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_FETCH_DATA({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              ...params,
              IDFriend: params.where.userId
            },
            mutation: gqlQuery.magazine
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit(
              "MUTATION_FETCH_DATA_MAGAZINES",
              res.data.magazine.magazines
            );
            commit("MUTATION_FETCH_DATA_PROFILE", res.data.magazine.profile);
            commit("MUTATION_FETCH_DATA_CITY", res.data.magazine.city);
            commit(
              "currentUrl/MUTATIONS_GET_CURRENT_URL",
              C.ROUTES_NAME.PASSPORT_LIST_PAGE, {
                root: true
              }
            );
            resolve(res.data);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_FETCH_DATA_SHARE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.magazineShare
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit(
              "MUTATION_FETCH_DATA_MAGAZINES",
              res.data.magazine.magazines
            );
            commit("MUTATION_FETCH_DATA_PROFILE", res.data.magazine.profile);
            commit("MUTATION_FETCH_DATA_CITY", res.data.magazine.city);
            commit(
              "currentUrl/MUTATIONS_GET_CURRENT_URL",
              C.ROUTES_NAME.PASSPORT_LIST_PAGE, {
                root: true
              }
            );
            resolve(res.data);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_GET_MAGAZINE_SELECTED({
      commit
    }, params) {
      if (params) {
        commit("MUTATIONS_GET_MAGAZINE_SELECTED", params);
      }
    },

    ACTION_RESET_STATE({
      commit
    }) {
      commit("MUTATION_RESET_STATE");
    }
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
    },
    STATUS_SUCCESS(state) {
      state.status = "success";
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", {
        error
      });
    },

    MUTATION_RESET_STATE(state) {
      const initial = initialState();
      const obj = {
        magazine: [],
        timelines: [],
        places: null
      };
      Object.keys(obj).forEach(key => {
        state[key] = initial[key];
      });
    },

    MUTATION_FETCH_DATA_MAGAZINES(state, items) {
      if (items.timelines != null && items.timelines.length > 0) {
        state.timelines.push(...items.timelines);
      }
      if (items.places != null && items.places.length > 0) {
        state.places.push(...items.places);
      }
    },
    MUTATION_FETCH_DATA_CITY(state, items) {
      state.city = items;
    },
    MUTATION_FETCH_DATA_PROFILE(state, items) {
      state.profile = items;
    },

    MUTATIONS_GET_MAGAZINE_SELECTED(state, items) {
      state.magazineSelected = items;
      this._vm.$f7.views.main.router.navigate({
        name: C.ROUTES_NAME.PASSPORT_LIST_PAGE,
        params: {
          id: items.cityId
        }
      });
    },

    MUTATION_SET_COMMENTS_TIP(state, items) {
      state.tip = items;
    },

    MUTATION_POST_COMMENT(state, items) {
      state.tip.tipComments.push(items);
    },

    MUTATION_PUT_COMMENT(state, items) {
      const arr = _.map(state.tip.tipComments, function (a) {
        return a.id === items.id ? items : a;
      });
      state.tip.tipComments = arr;
    },

    // Increment like
    MUTATION_INCREMENT_LIKE_ADD_TO_PLANS(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item =>
        item.relationship.post != null && item.relationship.post.id === id
      );
      if (cartItem) {
        cartItem.relationship.post.statsLike++;
        cartItem.relationship.post.isActiveLikeStatus = true;
        cartItem.relationship.post.likeStatus = true;
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE_ADD_TO_PLANS(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item =>
        item.relationship.post != null && item.relationship.post.id === id
      );
      if (cartItem) {
        cartItem.relationship.post.statsLike--;
        cartItem.relationship.post.isActiveLikeStatus = false;
        cartItem.relationship.post.likeStatus = false;
      }
    },

    // Increment like
    MUTATION_INCREMENT_LIKE_POSTED(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.statsLike++;
        cartItem.relationship.isActiveLikeStatus = true;
        cartItem.relationship.likeStatus = true;
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE_POSTED(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.statsLike--;
        cartItem.relationship.isActiveLikeStatus = false;
        cartItem.relationship.likeStatus = false;
      }
    },

    // Increment like
    MUTATION_INCREMENT_LIKE_ADD_TIP(
      state, {
        // Chua co api
        id
      }
    ) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.statsLike++;
        cartItem.relationship.isActiveLikeStatus = true;
        cartItem.relationship.likeStatus = true;
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE_ADD_TIP(
      state, {
        // Chua co api
        id
      }
    ) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.statsLike--;
        cartItem.relationship.isActiveLikeStatus = false;
        cartItem.relationship.likeStatus = false;
      }
    },

    // Decrement like
    MUTATION_INCREMENT_COMMENT_POSTED(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.statsComment++;
      }
    },

    // Decrement like
    MUTATION_INCREMENT_COMMENT_ADD_TO_PLANS(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item =>
        item.relationship.post != null && item.relationship.post.id === id
      );
      if (cartItem) {
        cartItem.relationship.post.statsComment++;
      }
    },

    // Bookmark
    MUTATION_BOOKMARK(state, {
      id
    }) {
      const cartItem = state.places.find(item => item.id === id);
      if (cartItem) {
        cartItem.bookmarkStatus = true;
        cartItem.isActiveBookmark = true;
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK(state, {
      id
    }) {
      const cartItem = state.places.find(item => item.id === id);
      if (cartItem) {
        cartItem.isActiveBookmark = false;
        cartItem.bookmarkStatus = false;
      }
    },

    // Bookmark Posted
    MUTATION_BOOKMARK_POSTED(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.bookmarkStatus = true;
        cartItem.relationship.isActiveBookmark = true;
      }
    },

    // Bookmark Posted
    MUTATION_UNBOOKMARK_POSTED(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item => item.relationship != null && item.relationship.id === id
      );
      if (cartItem) {
        cartItem.relationship.bookmarkStatus = false;
        cartItem.relationship.isActiveBookmark = false;
      }
    },

    // Bookmark Posted
    MUTATION_BOOKMARK_ADD_TO_PLANS(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item =>
        item.relationship.post != null && item.relationship.post.id === id
      );
      if (cartItem) {
        cartItem.relationship.post.bookmarkStatus = true;
        cartItem.relationship.post.isActiveBookmark = true;
      }
    },

    // Bookmark Posted
    MUTATION_UNBOOKMARK_ADD_TO_PLANS(state, {
      id
    }) {
      const cartItem = state.timelines.find(
        item =>
        item.relationship.post != null && item.relationship.post.id === id
      );
      if (cartItem) {
        cartItem.relationship.post.bookmarkStatus = false;
        cartItem.relationship.post.isActiveBookmark = false;
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK_ADD_TO_PLANS_PLACE(state, {
      id
    }) {
      return _.forEach(state.timelines, function (item, key) {
        const cartItem =
          item.relationship != null &&
          item.relationship.post != null &&
          item.relationship.post.places.find(it => it.id === id);
        if (cartItem) {
          cartItem.bookmarkStatus = false;
          cartItem.isActiveBookmark = false;
        }
      });
    },

    // Bookmark
    MUTATION_BOOKMARK_ADD_TO_PLANS_PLACE(state, {
      id
    }) {
      return _.forEach(state.timelines, function (item, key) {
        const cartItem =
          item.relationship != null &&
          item.relationship.post != null &&
          item.relationship.post.places.find(it => it.id === id);
        if (cartItem) {
          cartItem.bookmarkStatus = true;
          cartItem.isActiveBookmark = true;
        }
      });
    },
    // UnBookmark
    MUTATION_UNBOOKMARK_POSTED_PLACE(state, {
      id
    }) {
      return _.forEach(state.timelines, function (item, key) {
        const cartItem =
          item.relationship != null &&
          item.relationship.__typename === "Post" &&
          item.relationship.places.find(it => it.id === id);
        if (cartItem) {
          cartItem.bookmarkStatus = false;
          cartItem.isActiveBookmark = false;
        }
      });
    },

    // Bookmark
    MUTATION_BOOKMARK_POSTED_PLACE(state, {
      id
    }) {
      return _.forEach(state.timelines, function (item, key) {
        const cartItem =
          item.relationship != null &&
          item.relationship.__typename === "Post" &&
          item.relationship.places.find(it => it.id === id);
        if (cartItem) {
          cartItem.bookmarkStatus = true;
          cartItem.isActiveBookmark = true;
        }
      });
    },

    // UnBookmark
    MUTATION_UNBOOKMARK_ADD_TO_PLANS_PLACE_ONLY(state, {
      id
    }) {
      return _.forEach(state.timelines, function (item, key) {
        if (
          item.relationship != null &&
          item.relationship.__typename === "Bookmark" &&
          item.relationship.place &&
          item.relationship.place.id === id
        ) {
          item.relationship.place.bookmarkStatus = false;
          item.relationship.place.isActiveBookmark = false;
        }
      });
    },

    // Bookmark
    MUTATION_BOOKMARK_ADD_TO_PLANS_PLACE_ONLY(state, {
      id
    }) {
      return _.forEach(state.timelines, function (item, key) {
        if (
          item.relationship != null &&
          item.relationship.__typename === "Bookmark" &&
          item.relationship.place &&
          item.relationship.place.id === id
        ) {
          item.relationship.place.bookmarkStatus = true;
          item.relationship.place.isActiveBookmark = true;
        }
      });
    },

    MUTATION_UPDATE_RATE(state, rate) {
      return _.forEach(state.timelines, function (item, key) {
        if (
          item.relationship != null &&
          item.relationship.__typename === "Rate" &&
          item.relationship.place &&
          item.relationship.place.id === rate.place &&
          state.profile.id === rate.me
        ) {
          item.relationship.action = rate.data.updateRate.action
        }
      });
    }

  }
};
