import gql from "graphql-tag";

const trendings = gql `query trendings {
  trendings {
    id
    title
    description
    places {
      id
      placeId
      name
      photos {
        name
        url
      }
      rateStarts {
        publicStatus
        publicScore
        publicStats
        friendStatus
        friendScore
        friendStats
      }
      categories {
        id
        name
      }
      bookmarkStatus
    }
  }
}`

export {
  trendings
};
