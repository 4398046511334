<template>
  <f7-actions :class="`${actionsOpenName}`">
    <f7-actions-group>
      <f7-actions-button color="blue" @click="()=>handleShareCollection(userId, typeId)">Share to...</f7-actions-button>
      <f7-actions-button color="blue">
        <f7-link
          v-clipboard:copy="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.COLLECTIONS + '/' + userId + '/' + typeId}`"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
        >Copy Link</f7-link>
      </f7-actions-button>
      <input
        type="hidden"
        :v-model="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.COLLECTIONS + '/' + userId + '/' + typeId}`"
      >
    </f7-actions-group>
    <f7-actions-group>
      <f7-actions-button color="blue" bold>Cancel</f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>
<script>
import C from "../_helpers/constants";
import { shareCollection } from "../utils/share";
import { copySuccess, copyError } from "../utils/copy";
export default {
  props: {
    actionsOpenName: {
      required: false,
      type: String
    },
    userId: {
      required: false,
      type: String
    },
    typeId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    handleShareCollection(userId, id) {
      return shareCollection(userId, id);
    },
    onCopySuccess(e) {
      return copySuccess(e, this);
    },
    onCopyError(e) {
      return copyError(e);
    }
  }
};
</script>

