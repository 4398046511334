export default {
  // Color Theme Passport
  COLORS: {
    DARK: "dark",
    LIGHT: "light"
  },

  // LOGIN
  IS_FORM: {
    DEFAULT: "DEFAULT",
    DEFAULT_ERROR: "DEFAULT_ERROR",
    LOGIN: "LOGIN",
    LOGIN_ERROR: "LOGIN_ERROR",
    REGISTER: "REGISTER",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR"
  },

  // Bookmark/Unbookmar
  BOOKMARK_STATUS: {
    BOOKMARK: "BOOKMARK",
    UNBOOKMARK: "UNBOOKMARK"
  },

  // Button type
  BUTTON_TYPE: {
    ALL: "ALL",
    FRIEND: "FRIEND",
    ADD_FRIEND: "ADD_FRIEND"
  },

  // Bookmark content type
  BOOKMARK_CONTENT_TYPE: {
    POST: "POST",
    PHOTO: "PHOTO",
    PLACE: "PLACE",
    TIP: "TIP",
    EVENT: "EVENT"
  },

  // Like/Unlike
  LIKE_STATUS: {
    LIKE: "LIKE",
    UNLIKE: "UNLIKE"
  },

  // Like content type
  PAGE_CONTENT_TYPE: {
    POST: "POST",
    PHOTO: "PHOTO",
    COMMENT: "COMMENT",
    FEED: "FEED",
    PLACE_POST: "PLACE_POST",
    FEED_POST_PLACE: "FEED_POST_PLACE",
    PLACE_POST_DETAIL: "PLACE_POST_DETAIL",
    PLACE_DETAIL: "PLACE_DETAIL",
    EVENT_DETAIL: "EVENT_DETAIL",
    RECS: "RECS",
    DETAIL_POST: "DETAIL_POST",
    SUMMARY: "SUMMARY",
    SEARCH: "SEARCH",
    COLLECTION_PLACES: "COLLECTION_PLACES",
    COLLECTION_POST: "COLLECTION_POST",
    COLLECTION_POST_PLACE: "COLLECTION_POST_PLACE",
    MAGAZINE_PLACE: "MAGAZINE_PLACE",
    MAGAZINE_ADD_TIP: "MAGAZINE_ADD_TIP",
    MAGAZINE_POSTED: "MAGAZINE_POSTED",
    MAGAZINE_POSTED_PLACE: "MAGAZINE_POSTED_PLACE",
    MAGAZINE_ADD_TO_PLANS: "MAGAZINE_ADD_TO_PLANS",
    MAGAZINE_ADD_TO_PLANS_PLACE: "MAGAZINE_ADD_TO_PLANS_PLACE",
    MAGAZINE_ADD_TO_PLANS_PLACE_ONLY: "MAGAZINE_ADD_TO_PLANS_PLACE_ONLY",
    TOPIC: "TOPIC"
  },

  // Params Share
  PARAMS_SHARE: {
    PLACEID: "?placeId=",
    POSTID: "?postId=",
    CPLACEID: "?cplace=",
    CPOSTID: "?cpost=",
    UID: "?uid=",
    CID: ";cid=",
    EVENTID: "?eventId="
  },

  // Params Url
  PARAMS_URL: {
    PLACEID: "placeId",
    EVENTID: "eventId",
    POSTID: "postId",
    UID: "uid",
    CID: "cid",
    CPOST: "cpost",
    SHARE: "share",
    AMP_CID: "amp;cid",
    AMP_UID: "amp;uid",
    PRIVACY: "privacy",
    TERMS: "terms"
  },

  // Redirect
  REDIRECT_PAGE: {
    FEEDS: "FEEDS",
    HOME: "HOME",
    LOGIN: "LOGIN",
    POST: "POST",
    RECS: "RECS",
    COLLECTION: "COLLECTION",
    COLLECTION_PLACE: "COLLECTION_PLACE",
    COLLECTION_POST: "COLLECTION_POST",
    PASSPORT: "PASSPORT",
    SEARCH: "SEARCH",
    EVENT: "EVENT"
  },

  // Domain share
  DOMAIN_SHARE: {
    DOMAIN:
      (process.env.NODE_ENV == "production" && "https://share.myfavs.app") ||
      (process.env.NODE_ENV == "development" && "http://localhost:4100") ||
      "https://share-staging.myfavs.app"
  },

  // Sentry
  SENTRY_DSN:
    (process.env.NODE_ENV == "production" &&
      "https://f1071d64962246be9d071da97e14424e@o390878.ingest.sentry.io/5238895") ||
    (process.env.NODE_ENV == "development" &&
      "https://9d8e1e44c63d4e4f958de2ada4d2738c@o390878.ingest.sentry.io/5238855") ||
    "https://b6b5790fec0245bfa54ea8bbd791fea8@o390878.ingest.sentry.io/5238864",

  // Env App
  ENV_APP_ID_FACEBOOK: {
    PRODUCTION: "356092528675177",
    STAGING: "537850710236201",
    DEVELOPMENT: "453628038773422"
  },

  // API
  API: {
    PRODUCTION: "https://api.myfavs.app",
    STAGING: "https://api-staging.myfavs.app",
    DEVELOPMENT: "https://api-staging.myfavs.app"
  },

  // Access Token
  ACCESS_TOKEN_FACEBOOK:
    "EAAFD3UCtyWkBAPe3vVks6ajp3XkTHY3dOhg9pw9AZCswqCedNF2a23cZCqZBCiJzJfIZBudh8HoL3jvEY0NTaSUOChNXidCvxBeRXxFGfvsWAprlNUuVExjvToEaIkpUUd7nYKtkI5MODMAMInRY6rVtPRwUAOPaxPbcQd5a2jWzgkz7QdKQ9S1R1u2WDZBnzChyUDcU8vQYFVnXTK7kESU2KmylcBXPJ5CCu19ZAnvtmg6NWny92s",

  // Segment Analytics Key
  SEGMENT_KEY: "y74Dk12QBHxaupJWiV2Cln0XnsSIiYrh",

  // POPUP SELECT ACTION *
  POPUP_PAGE: {
    FEEDS: "FEEDS",
    PLACE_DETAIL: "PLACE_DETAIL",
    POST_DETAIL: "POST_DETAIL",
    RECS: "RECS",
    COLLECTION_LIST: "COLLECTION_LIST",
    COLLECTION_POST: "COLLECTION_POST",
    COLLECTION_PLACE: "COLLECTION_PLACE",
    COLLECTION_DETAIL: "COLLECTION_DETAIL",
    COLLECTION_DETAIL_PLACE: "COLLECTION_DETAIL_PLACE",
    COLLECTION_DETAIL_POST: "COLLECTION_DETAIL_POST",
    PASSPORT: "PASSPORT",
    MAGAZINES: "MAGAZINES",
    SETTINGS: "SETTINGS"
  },

  // Type Share
  TYPE_SHARE: {
    POST: "POST",
    PLACE: "PLACE",
    EVENT: "EVENT",
    PASSPORT: "PASSPORT",
    MAGAZINES: "MAGAZINES",
    COLLECTION: "COLLECTION",
    COLLECTION_PLACE: "COLLECTION_PLACE",
    COLLECTION_POST: "COLLECTION_POST",
    SETTINGS: "SETTINGS"
  },

  // Type Search
  TYPE_SEARCH: {
    ALL: "All",
    PLACES: "Places",
    EVENT: "Events",
    MAGAZINES: "Mags",
    CITIES: "Cities",
    PEOPLE: "People",
    FRIENDFAVS: "Favs"
  },

  // Type Url
  TYPE_URL: {
    POST: "posts",
    PLACE: "places",
    PASSPORT: "passport",
    COLLECTIONS: "collections",
    EVENT: "event"
  },

  // Type Rate
  RATE_STARTS: {
    NO_RATING: "NO_RATING",
    OK: "OK",
    LIKE: "LIKE",
    FAVS: "FAVS"
  },

  // Type ToolBar
  TOOLBAR: {
    EXPLORE: "Explore",
    FAVS: "Favs",
    POST: "Post",
    PLANS: "Plans",
    PASSPORT: "Passport"
  },

  // Type Collection
  COLLECTION_TYPE: {
    POST: "POST",
    PLACE: "PLACE",
    EVENT: "EVENT"
  },

  // Type Detail Collection
  DETAIL_COLLECTION_TYPE: {
    POST: "Posts",
    PLACE: "Places",
    EVENT: "Events"
  },

  // Filter
  FILTER: {
    MY_RATE: "MY_RATE",
    TEXT_MY_RATE: "My Ratings",
    DISTANCE: "DISTANCE",
    TEXT_DISTANCE: "Distance",
    PUBLIC_RATE: "PUBLIC_RATE",
    TEXT_PUBLIC_RATE: "Public Ratings",
    FRIEND_RATE: "FRIEND_RATE",
    TEXT_FRIEND_RATE: "Friends' Ratings",
    CREATED_ASC: "CREATED_ASC",
    TEXT_CREATED_ASC: "Create Asc",
    CREATED_DESC: "CREATED_DESC",
    TEXT_CREATED_DESC: "Create Desc",
    POPULARITY: "POPULAR",
    TEXT_POPULARITY: "Popularity",
    FRIEND_INTEREST: "FRIEND_INTEREST",
    TEXT_FRIEND_INTEREST: "Friends' interest",
    SOONEST: "SOONEST",
    TEXT_SOONEST: "Soonest",
    ANYTIME: "ANYTIME",
    TEXT_ANYTIME: "Anytime",
    TODAY: "TODAY",
    TEXT_TODAY: "Today",
    TOMORROW: "TOMORROW",
    TEXT_TOMORROW: "Tomorrow",
    THIS_WEEK: "THIS_WEEK",
    TEXT_THIS_WEEK: "This week",
    THIS_WEEKEND: "THIS_WEEKEND",
    TEXT_THIS_WEEKEND: "This weekend",
    NEXT_WEEK: "NEXT_WEEK",
    TEXT_NEXT_WEEK: "Next week",
    TEXT_CHRIS_S_RATINGS: "Chris's Ratings",
    CHRIS_S_RATINGS: "CHRIS_RATINGS",
    TEXT_PRICE: "$ $$ $$$ $$$$",
    PRICE: "PRICE"
  },
  POPULAR_FILTER: {
    eat: {
      TEXT_OPEN_NOW: "Open now",
      TEXT_VIETNAMESE: "Vietnamese",
      TEXT_JAPANESE: "Japanese",
      TEXT_KOREAN: "Korean",
      TEXT_SUSHI: "Sushi",
      TEXT_CHINESE: "Chinese",
      TEXT_STREET_FOOD: "Street food",
      TEXT_BURGERS: "Burgers",
      TEXT_INDIAN: "Indian",
      TEXT_PHO: "Pho",
      TEXT_BANH_MI: "Banh Mi",
      TEXT_BUFFET: "Buffet",
      TEXT_BBQ: "BBQ",
      TEXT_FAST_FOOD: "Fast Food",
      TEXT_MEXICAN: "Mexican"
    },

    drink: {
      TEXT_CLUB: "Club",
      TEXT_BAR: "Bar",
      TEXT_Dance: "Dance",
      TEXT_COCKTAIL: "Cocktails",
      TEXT_LOUNGE: "Lounge",
      TEXT_Shisha: "Shisha"
    },

    sleep: {
      TEXT_LUXURY: "Luxury",
      TEXT_GYM: "Gym",
      TEXT_KITCHEN: "Kitchen",
      TEXT_BEACH: "Beach",
      TEXT_SWIM: "Swimming Pool",
      TEXT_WIFI: "Wifi"
    }
  },

  // Type Detail Post
  DETAIL_POST_TYPE: {
    POST: "POST",
    SUMMARY: "SUMMARY",
    TIMELINE: "TIMELINE"
  },

  // Type Comment
  COMMENT_TYPE: {
    POST: "POST",
    TIP: "TIP"
  },

  // Page size
  PAGE_SIZE: {
    DEFAULT: 5,
    POSITION_TRENDING: 4,
    POSITION_EVENT: 2,
    POSTS_IN_TOPIC: 10
  },

  // Routes Name
  ROUTES_NAME: {
    APP: "app",
    LOGIN_PAGE: "login-page",
    HOME_PAGE: "home-page",
    FEED_PAGE: "feeds",
    FRIENDS_PAGE: "friends",
    SEE_FRIENDS_PAGE: "see-friends",
    RECS_PAGE: "recs",
    EVENT_PAGE: "event",
    SEARCH_PAGE: "search",
    NOTIFICATION: "notification",
    SETTINGS: "settings",
    POST_PAGE: "post",
    POST_DETAIL_PAGE: "post-detail",
    PLACE_PAGE: "place-detail",
    POST_COMMENT: "post-comment",
    COLLECTION_PAGE: "collection",
    COLLECTION_DETAIL_PAGE: "collection-detail",
    COLLECTION_DETAIL_POST: "collection-detail-post",
    COLLECTION_DETAIL_EVENT: "collection-detail-event",
    COLLECTION_DETAIL_POST_DETAIL: "collection-detail-post-detail",
    COLLECTION_DETAIL_PLACE: "collection-detail-place",
    PASSPORT_PAGE: "passport",
    PASSPORT_FRIEND_PROFILE_PAGE: "passport-friend",
    PASSPORT_LIST_PAGE: "passport-detail-magazine",
    MAGAZINE_TIP_COMMENT: "passport-comment-magazine-tip",
    PRIVACY_PAGE: "privacy",
    TERMS_PAGE: "terms",
    TOPIC_POSTS: "topic-posts",
    EVENT_DETAIL: "event-detail"
  },

  // Cloudinary Cloud Name
  CLOUD: {
    NAME: "myfavsapp"
  },

  API_KEYS: {
    GOOGLE_MAPS: "AIzaSyBY1cLreEc-allyO84hozDJD6NC6-Qpal8",
    // TODO: create another Measurement ID for Prod
    GA_MEASUREMENT_ID: "G-4PR0J44F5N"
  },

  // Action Type
  ACTION_TYPE: {
    SAVE: "SAVE",
    SHARE: "SHARE",
    BOOKMARK: "BOOKMARK",
    ACTIONS: "ACTIONS"
  },

  //Router Navigate
  ROUTER_NAVIGATE: {
    HOME: "/home/",
    FEEDS: "/feeds/",
    RECS: "/recs/",
    SEARCH: "/search/",
    POST: "/post/",
    COLLECTION: "/collection/",
    PASSPORT: "/passport/",
    SEE_FRIENDS: "/see-friends/",
    EVENT: "/event/",
    LOGIN: "/",
    PRIVACY: "/privacy/",
    TERMS: "/terms/",
    SETTINGS: "/settings/"
  }
};
