<template>
  <p v-show="showExternalUrl">
    <a :href="externalUrl" target="_blank" external class="external link">Read More</a>
  </p>
</template>
<script>
export default {
  props: {
    showExternalUrl: {
      required: false,
      type: Boolean,
      default: false
    },
    externalUrl: {
      required: false,
      type: String,
      default: null
    }
  },
  methods: {}
};
</script>

