import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';
import gqlQuery from '../../_gql/query/index.js';
import C from "../../_helpers/constants";
import _ from 'lodash';
import { gaSetUser } from "../../utils/gaHelpers";

export default {
  namespaced: true,
  state: {
    data: {},
    message: null,
    isLogout: false
  },

  getters: {
    data: state => state.data,
    message: state => state.message,
    isLogout: state => state.isLogout,
    me: (state, getters, rootState) => {
      return state.data
    },
    friends: (state, getters, rootState) => {
      return _.filter(state.data.friends, {
        status: "ACCEPTED"
      });
    }
  },

  actions: {

    ACTION_LOGIN({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.loginFB
          })
          .then(resp => {
            const {
              token,
              user
            } = resp.data.loginFB;
            localStorage.setItem('token', token);
            commit('STATUS_SUCCESS');
            commit('MUTATION_LOGIN', {
              token,
              user
            });
            commit('MUTATION_LOGOUT', false);
            resolve(resp.data.loginFB);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_RESET_LOGOUT({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit("MUTATION_LOGOUT", false)
        resolve(true);
      });
    },

    ACTION_LOGOUT({
      commit
    }) {
      return new Promise((resolve, reject) => {
        localStorage.clear();
        localStorage.setItem('skipTutorial', true)
        sessionStorage.clear();
        commit("MUTATION_LOGOUT", true)
        resolve(true);
      });
    },

    ACTION_REGISTER_EMAIL({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.registerEmail
          })
          .then(resp => {
            commit('STATUS_SUCCESS');
            resolve(resp.data.registerEmail);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            commit('MUTATION_MESSAGE_ERROR', {
              status: C.IS_FORM.REGISTER_ERROR,
              text: error.message.replace('GraphQL error:', '').trim()
            });
            resolve(error);
          });
      });
    },


    ACTION_LOGIN_EMAIL({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.loginEmail
          })
          .then(resp => {
            const {
              token,
              user
            } = resp.data.loginEmail;
            localStorage.setItem('token', token);
            commit('STATUS_SUCCESS');
            commit('MUTATION_LOGOUT', false);
            resolve(resp.data.loginEmail);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            commit('MUTATION_MESSAGE_ERROR', {
              status: C.IS_FORM.LOGIN_ERROR,
              text: error.message.replace('GraphQL error:', '').trim()
            });
            resolve(error);
          });
      });
    },

    ACTION_GET_ME({
      commit
    }, token) {

      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: token,
            mutation: gqlQuery.me
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_ME', res.data.me);
            localStorage.setItem('meId', res.data.me.id);
            gaSetUser(res.data.me.id)
            resolve(res);
          })
          .catch(error => {
            localStorage.removeItem("token")
            resolve(error);
          });
      });
    },
  },

  mutations: {

    STATUS_REQUEST(state) {
      state.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATION_LOGIN(state, {
      token,
      user
    }) {
      state.data = {
        token,
        user
      };
    },
    MUTATION_LOGOUT(state, status) {
      state.isLogout = status
    },
    MUTATION_GET_ME(state, data) {
      state.data = Object.assign(state.data, data)
    },
    MUTATION_MESSAGE_ERROR(state, data) {
      state.message = data
    }
  }
}
