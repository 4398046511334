import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';

function initialState() {
  return {
    data: [],
    message: null,
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => state.data,
    message: state => state.message,
  },

  actions: {

    ACTION_UPDATE_PROFILE({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.updateProfile
          })

          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_UPDATE_PROFILE', res.data.updateProfile)
            commit('passport/MUTATION_FETCH_DATA_AUTH_PROFILE', res.data.updateProfile, { root: true })
            commit('auth/MUTATION_GET_ME', res.data.updateProfile, { root: true })
            commit('topics/MUTATION_RELOAD_PAGE', null, { root: true })
            resolve(res.data.updateProfile)
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            commit('MUTATION_MESSAGE_ERROR', {
              text: error.message.replace('GraphQL error:', '').trim()
            });
            resolve(error)
          })
      })
    },
  },


  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.data.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
    MUTATION_UPDATE_PROFILE(state, item) {
      state.data = item
    },
    MUTATION_MESSAGE_ERROR(state, data) {
      state.message = data
    }
  }
}
