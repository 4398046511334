import gql from "graphql-tag";

const deleteRate = gql `
  mutation deleteRate($id: ID!, $tipIds: [ID]) {
    deleteRate(id: $id, tipIds: $tipIds) 
  }
`

export  {
  deleteRate
};
