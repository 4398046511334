import gql from "graphql-tag";

const passportShare = gql `
query passport ($userId: ID) {
  passport(userId: $userId) {
    profile {
      id
      nickname
      email
      avatar {
        name,
        url
      }
      background {
        name
        url
      }
    }
    statsFriend
    statsCity
    statsPlace
    statsPost
    magazines {
      id
      name
      cover {
        name
        url
      }
      cities {
        id
        name
        cover {
          name
          url
        }
        published
        location {
          type
          coordinates
        }
      }
    }
  }
}
`

export {
  passportShare
};
