<template>
  <div class="topic-section" :style="{ backgroundImage: `linear-gradient(${topic.theme_color + '0D'}, rgb(255,255,255,0) 58%)` }" :topic="topic.id">
    <topic-header :topic="topic" />
    <event-post-view :trendingEvents="topic.trendingEvents" :indexEvent="0" v-show="true"/>
    <stories-view :stories="topic.stories" v-if="topic.name === 'FOR YOU'" />
    <friend-suggestion-view :suggestions="users" :suggestIndex="0" :city="city" :pendingFriends="pendingFriends" v-if="topic.name === 'FOR YOU'" />
    <summary-view :showSeeMore="true" :allPosts="topic.posts" :topicId="topic.id" :topicName="topic.name"/>
    <trending-view :trendings="topic.trendings" :indexTrending="0" v-show="true"/>
    <friend-favorites-view :friendFavs="topic.friendFavs.slice(0, 1)" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import C from "../_helpers/constants"
import EventPostView from "../views/EventPost"
import TrendingView from "../views/Trending"
import SummaryView from "../views/Summary"
import FriendFavoritesView from "../views/FriendFavorites"
import StoriesView from "../views/Stories"
import FriendSuggestionView from "../views/FriendSuggestion"

import PublicFriendsRatingComponent from "../components/PublicFriendsRating"
import PlaceImageComponent from "../components/PlaceImage"
import PlaceTitleComponent from "../components/PlaceTitle"
import BookmarkComponent from "../components/Bookmark"
import ShareComponent from "../components/Share"
import TopicHeader from '../components/TopicHeader'

import { copySuccess, copyError } from "../utils/copy"
import { sharePost, sharePlace } from "../utils/share"
import { getUrlImg, getUrlImgArticle } from "../utils/cloundinary-image"

export default {
  components: {
    EventPostView,
    TrendingView,
    SummaryView,
    FriendFavoritesView,
    StoriesView,
    FriendSuggestionView,
    PublicFriendsRatingComponent,
    PlaceImageComponent,
    PlaceTitleComponent,
    BookmarkComponent,
    ShareComponent,
    TopicHeader
  },

  data() {
    return {
      constants: C,
    }
  },

  props: {
    topic: {
      required: true,
      type: Object
    },
    users: {
      required: false,
      type: Array
    },
    city: {
      required: true,
      type: Object
    },
    pendingFriends: {
      required: true,
      type: Array
    },
  },

  watch: {
    bookmarkStatus(news, old) {
      if (news) {
        this.showToastBottom();
      }
    },
  },

  computed: {
    ...mapGetters("bookMark", {
      bookmarkStatus: "bookmark"
    }),
  },

  methods: {

    ...mapActions("passport", ["actionFetchAuthProfile"]),

    toPostDetail(id, pageType) {
      this.$store.dispatch("detailPost/ACTION_GET", {
        id: id,
        pageType: pageType
      })
    },

    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Saved to plan",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    },
  }
};
</script>
