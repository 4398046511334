export default {
  namespaced: true,
  state: {
    data: {}
  },

  getters: {
    data: state => state.data
  },

  actions: {

    ACTION_SET_COLOR_THEME({
      commit
    }, data) {
      if (data) {
        localStorage.setItem('colorTheme', data);
        commit('MUTATIONS_SET_COLOR_THEME', {
          data
        });
      }
    },

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
    MUTATIONS_SET_COLOR_THEME(state, data) {
      state.color = data
    }
  }
}
