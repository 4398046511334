<template>
  <div>
    <f7-block class="wrapper-summary-of-post block-article"
      v-if="allPosts.length > 0"
    >
      <div
      v-for="(summary, summaryIdx) in allPosts"
      
      :key="summaryIdx">
        <f7-row
        :class="`summary-of-post post-${summaryIdx}`"
        >
          <f7-col width="40">
            <f7-card class="wrapper-content-card">
              <div>
                <f7-photo-browser
                  theme="dark"
                  :photos="photoBrowserSummary(summary.photos)"
                  ref="photoBrowserSummary"
                ></f7-photo-browser>
                <f7-button
                  v-if="summary.externalSource == null || summary.externalSource == 'LINK'"
                  raised
                  @click="$refs.photoBrowserSummary[summaryIdx].open()"
                ></f7-button>
                <div
                  :class="`${summary.photos.length <= 1 ? 'grid1Col' : (summary.photos.length <= 2 ? 'grid2Col' : 'grid3Col')} wrapper-photo`"
                >
                  <iframe
                    v-if="summary.externalSource == 'YOUTUBE'"
                    width="100%"
                    height="90"
                    :src="summary.externalUrl"
                  ></iframe>
                  <div
                    v-else
                    v-for="(photo, idx) in summary.photos.slice(0, 3)"
                    :key="idx"
                    :class="`no-border card-header photo-${idx}`"
                    valign="bottom"
                    :style="`background-image:url(`+handleImgArticle(photo, idx)+`)`"
                  ></div>
                </div>
                <span
                  v-if="summary.photos.length > 3"
                  class="numberPhotos"
                >+{{summary.photos.length - 3}}</span>
              </div>
            </f7-card>
            <div class="wrapper-content-card">
              <bookmark-component
                class="icon-bookmark"
                :type="constants.BOOKMARK_CONTENT_TYPE.POST"
                :item="summary"
                :id="summary.id"
                :isActiveBookmarkStatus="summary.bookmarkStatus"
                :pageType="constants.PAGE_CONTENT_TYPE.POST"
              />
              <share-component
                :actionsOpenId="`actions-summary-post-${summary.id}`"
                :type="constants.TYPE_SHARE.POST"
                :typeId="summary.id"
              />
            </div>
          </f7-col>
          <f7-col width="60" class="post-details">
            <f7-row>
              <div class="avatarInSummary">
              <img
                slot="media"
                v-if="summary.author && summary.author.avatar && summary.author.avatar.name"
                :src="getAvatarAuthImg(summary.author.avatar.name)"
              >
              <span
                @click="actionFetchAuthProfile(summary.author.id)"
                slot="media"
                v-else
                class="text"
              >{{summary.author && summary.author.nickname && summary.author.nickname.charAt(0)}}</span>
              <span
                slot="title"
                @click="actionFetchAuthProfile(summary.author.id)"
              >{{ summary.author && (summary.author.nickname || summary.author.username) }}</span>
              </div>
            </f7-row>
            <a v-if="summary.externalUrl" :href="summary.externalUrl" @click="track(summary.externalUrl, summary.title, summary.author)" target="_blank" external class="external link">
              <f7-row class="post-title">{{summary.title}}</f7-row>
            </a>
            <f7-row v-else
              class="post-title"
              @click="toPostDetail(summary.id, constants.PAGE_CONTENT_TYPE.SUMMARY)"
            >{{summary.title}}
            </f7-row>
          </f7-col>
        </f7-row>
      </div>
    </f7-block>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import { handleSharePost, handleSharePlace } from "../utils/share";
import { getUrlImg, getUrlImgArticle } from "../utils/cloundinary-image";
import UserInfoComponent from "../components/UserInfo";
import PlaceTitleComponent from "../components/PlaceTitle";
import StatsCommentComponent from "../components/StatsComment";
import CreatedAtComponent from "../components/CreatedAt";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import analyticsService from "../utils/analyticsService"

export default {
  components: {
    UserInfoComponent,
    PlaceTitleComponent,
    StatsCommentComponent,
    CreatedAtComponent,
    BookmarkComponent,
    ShareComponent,
  },
  data() {
    return {
      constants: C
    };
  },
  computed: {
    ...mapGetters("auth", {
      data: "data"
    })
  },
  props: {
    allPosts: {
      required: true,
      type: Array
    },
    externalUrl: {
      required: false,
      type: String
    },
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    handleImg(name) {
      return getUrlImg(name);
    },
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return null;
      }
    },
    handleImgArticle(photo, index) {
      return getUrlImgArticle(photo, index);
    },
    toPostDetail(id, pageType) {
      const params = {
        id: id,
        pageType: pageType
      };
      this.$store.dispatch("detailPost/ACTION_GET", params);
    },
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params);
    },
    track(url, title, author) {
      console.log("allPosts", this.$props.allPosts)
      const info = {
        url,
        title,
        authorNickname: author.nickname,
        authorId: author.id,
      }
      analyticsService.trackThing("articleTrack", info)
    },
    photoBrowserSummary(photo) {
      return photo;
    },
  }
};
</script>

