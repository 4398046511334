<template>
  <p :class="className">{{ createdAt | moment("from", true) }} ago</p>
</template>
<script>
export default {
  props: {
    createdAt: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String,
      default: "time"
    }
  }
};
</script>

