<template>
  <f7-row class="title-block">
    <f7-col width="70" class="col-left">
      <f7-block-title class="title">{{items.name}}</f7-block-title>
      <f7-block-header class="sub-title">
        $ {{items.priceLever}}
        <span v-for="(item, key) in items.categories" :key="key">
          <span>•</span>
          <span>{{item.name}}</span>
        </span>
      </f7-block-header>
    </f7-col>
    <f7-col width="30" class="col-right">
      <f7-button :actionsOpen="`.actions-header-place-detail-${items.id}`">Share</f7-button>
    </f7-col>
    <actions-place-component
      :actionsOpenId="`actions-header-place-detail-${items.id}`"
      :type="constants.TYPE_SHARE.PLACE"
      :typeId="items.id"
    />
  </f7-row>
</template>
<script>
import ActionsPlaceComponent from "./ActionsPlace";
import C from "../_helpers/constants";
export default {
  components: {
    ActionsPlaceComponent
  },
  data() {
    return {
      constants: C
    };
  },
  props: {
    items: {
      required: false,
      type: Object
    }
  }
};
</script>
