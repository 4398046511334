<template>
  <f7-block class="thumbs">
    <div class="item-title select-a-photo">{{title}}</div>
    <input hidden ref="fileInput" type="file" accept="image/*" @change="fileSelected" multiple>
    <div
      class="cell link"
      v-for="(value, key) in items"
      :key="key"
    >
      <img :src="value" :alt="value">
      <f7-link
        class="close"
        icon-f7="close_round_fill"
        icon-size="20"
        @click="()=>handleRemoveImage(key)"
      ></f7-link>
    </div>

    <div class="cell link btn-upload" @click="$refs.fileInput.click()">
      <f7-icon size="10vw" f7="camera"></f7-icon>
    </div>
  </f7-block>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
     items: {
      required: false,
      type: Array,
      default: null
    },
    title: {
      required: false,
      type: String,
      default: "Select a photo"
    },
    fileSelected: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleRemoveImage: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

