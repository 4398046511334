import gql from "graphql-tag";

const notification = gql `query notification {
  latestNotifications {
    id
    isRead
    users {
      nickname
      avatar {
        url
      }
    }
    actionText
    post {
      url
    }
    type
    ago
  }
}`


export { notification };
