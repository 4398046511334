import gql from "../../utils/apollo-client";
import gqlQuery from "../../_gql/query/index.js";
import _ from "lodash";
import C from "../../_helpers/constants";

function initialState() {
  return {
    posts: []
  };
}

function fetchPosts(commit, params, mutationType) {
  return new Promise((resolve, reject) => {
    gql
      .mutate({
        variables: {
          id: params.id,
          name: params.name,
          page: params.page
        },
        mutation: gqlQuery.topicPosts
      })
      .then(res => {
        commit("STATUS_SUCCESS");
        commit(mutationType, res.data.postsInTopic);
        resolve(res.data.postsInTopic);
      })
      .catch(error => {
        commit("STATUS_ERROR", error);
        resolve(error);
      });
  });
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    posts: state => state.posts,
  },

  actions: {
    ACTION_GET({ commit }, params) {
      commit("STATUS_REQUEST");
      this._vm.$f7.views.main.router.navigate({
        name: C.ROUTES_NAME.TOPIC_POSTS,
        params
      });
      commit(
        "currentUrl/MUTATIONS_GET_CURRENT_URL",
        C.ROUTES_NAME.TOPIC_POSTS,
        {
          root: true
        }
      );
    },
    ACTION_FETCH_TOPIC_POSTS({ commit }, params) {
      return fetchPosts(commit, params, "MUTATION_FETCH_TOPIC_POSTS");
    }
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    STATUS_SUCCESS(state) {
      state = Object.assign(state, { status: "success", reload: false });
    },
    MUTATION_FETCH_TOPIC_POSTS(state, items) {
      let arr = state.posts
      arr.push(...items)
      state.posts = arr
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", { error });
    },
    MUTATION_RELOAD_PAGE(state) {
      state.reload = true;
    }
  }
};
