import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

function initialState() {
  return {
    data: [],
    status: "",
    users: [],
    pendingFriends: [],
    isLoading: true
  };
}

function fetchUsers(commit, params, mutationType) {
  return new Promise((resolve, reject) => {
    commit("STATUS_REQUEST");
    gql
      .query({
        variables: {},
        query: gqlQuery.friendSuggestion
      })
      .then(({ data }) => {
        commit("STATUS_SUCCESS");
        commit("MUTATION_FETCH_USERS", data.friendSuggestion);
        resolve(data.friendSuggestion);
      })
      .catch(error => {
        commit("STATUS_ERROR", error);
        resolve(error);
      });
  });
}

function fetchPendingFriends(commit, params, mutationType) {
  return new Promise((resolve, reject) => {
    commit("STATUS_REQUEST");
    gql
      .query({
        variables: {},
        query: gqlQuery.pendingFriends
      })
      .then(({ data }) => {
        commit("STATUS_SUCCESS");
        commit("MUTATION_FETCH_PENDING_FRIENDS", data.pendingFriends);
        resolve(data.pendingFriends);
      })
      .catch(error => {
        commit("STATUS_ERROR", error);
        resolve(error);
      });
  });
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    status: state => state.status,
    users: ({ users }) => users,
    pendingFriends: ({ pendingFriends }) => pendingFriends,
    data: (state, getters, rootState) => {
      return _.forEach(state.data, function(item, key) {
        item.isActiveBookmark = item.bookmarkStatus; // Add field active isActiveBookmark
        item.isActiveLikeStatus = item.likeStatus; // Add field active isActiveLikeStatus
        if (item.places.length > 0) {
          _.forEach(item.places, function(place, placeIdx) {
            place.isActiveBookmark = place.bookmarkStatus; // Add field active isActiveBookmark
          });
        }
      });
    }
  },

  actions: {
    ACTION_FETCH_FEED({ commit }, page) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              page
            },
            mutation: gqlQuery.feeds
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_FETCH_FEED", res.data.feeds);
            resolve(res.data.feeds);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },
    ACTION_FETCH_USERS({ commit }, params) {
      return fetchUsers(commit, params, "MUTATION_FETCH_USERS");
    },
    ACTION_FETCH_PENDING_FRIENDS({ commit }, params) {
      return fetchPendingFriends(
        commit,
        params,
        "MUTATION_FETCH_PENDING_FRIENDS"
      );
    },
    ACTION_RESET_STATE({ commit }) {
      commit("MUTATION_RESET_STATE");
    }
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
    },
    STATUS_SUCCESS(state) {
      state.status = "success";
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", {
        error
      });
    },

    MUTATION_RESET_STATE(state) {
      const initial = initialState();
      state = Object.assign(state, initial);
    },

    MUTATION_FETCH_FEED(state, items) {
      state.data.push(...items);
    },
    MUTATION_FETCH_USERS(state, users) {
      state.users = users;
    },
    MUTATION_FETCH_PENDING_FRIENDS(state, pendingFriends) {
      state.pendingFriends = pendingFriends;
    },
    // Increment like
    MUTATION_INCREMENT_LIKE(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.statsLike++;
        cartItem.isActiveLikeStatus = true;
        cartItem.likeStatus = true;
      }
    },

    // Decrement like
    MUTATION_DECREMENT_LIKE(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.statsLike--;
        cartItem.isActiveLikeStatus = false;
        cartItem.likeStatus = false;
      }
    },

    // Increment comment
    MUTATION_INCREMENT_COMMENT(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.statsComment++;
      }
    },

    // Bookmark
    MUTATION_BOOKMARK(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.bookmarkStatus = true;
        cartItem.isActiveBookmark = true;
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK(state, { id }) {
      const cartItem = state.data.find(item => item.id === id);
      if (cartItem) {
        cartItem.isActiveBookmark = false;
        cartItem.bookmarkStatus = false;
      }
    },

    // Bookmark
    MUTATION_BOOKMARK_PLACE_POST(state, { id }) {
      return _.forEach(state.data, function(item, key) {
        const cartItem = item.places.find(it => it.id === id);
        if (cartItem) {
          cartItem.isActiveBookmark = true;
          cartItem.bookmarkStatus = true;
        }
      });
    },

    // UnBookmark
    MUTATION_UNBOOKMARK_PLACE_POST(state, { id }) {
      return _.forEach(state.data, function(item, key) {
        const cartItem = item.places.find(it => it.id === id);
        if (cartItem) {
          cartItem.isActiveBookmark = false;
          cartItem.bookmarkStatus = false;
        }
      });
    }
  }
};
