<template>
  <f7-toolbar tabbar labels bottom>
    <f7-link @click="handleFeeds" tab-link v-if="current_feeds">
      <span class="img">
        <img src="@/static/icons/feed_active.svg" alt="Feed">
      </span>
      <span :class="`active text`">{{feeds}}</span>
    </f7-link>
    <f7-link @click="handleUrlFeeds" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/feed.svg" alt="Feed">
      </span>
      <span :class="`text`">{{feeds}}</span>
    </f7-link>

    <f7-link @click="handleRecs" tab-link v-if="current_recs">
      <span class="img">
        <img src="@/static/icons/recs_active.svg" alt="Recs">
      </span>
      <span :class="`active text`">{{recs}}</span>
    </f7-link>

    <f7-link @click="handleUrlRecs" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/recs.svg" alt="Recs">
      </span>
      <span :class="`text`">{{recs}}</span>
    </f7-link>

    <f7-link @click="handlePost" tab-link v-if="current_post ">
      <span class="img">
        <img src="@/static/icons/post_active.svg" alt="Post">
      </span>
      <span :class="`active text`">{{post}}</span>
    </f7-link>
    <f7-link @click="handleUrlPost" tab-link v-else-if="me.featureFlags && me.featureFlags.showPostTab">
      <span class="img">
        <img src="@/static/icons/post.svg" alt="Post">
      </span>
      <span :class="`text`">{{post}}</span>
    </f7-link>

    <f7-link @click="handleCollection" tab-link v-if="current_collection">
      <span class="img">
        <img src="@/static/icons/collection_active.svg" alt="Collection">
      </span>
      <span :class="`active text`">{{collection}}</span>
    </f7-link>

    <f7-link @click="handleUrlCollection" tab-link v-else-if="me.featureFlags && me.featureFlags.showPlansTab">
      <span class="img">
        <img src="@/static/icons/collection.svg" alt="Collection">
      </span>
      <span :class="`text`">{{collection}}</span>
    </f7-link>
    <f7-link @click="handlePassport" tab-link v-if="current_passport">
      <span class="img">
        <img src="@/static/icons/passport_active.svg" alt="Passport">
      </span>
      <span :class="`active text`">{{passport}}</span>
    </f7-link>
    <f7-link @click="actionFetchAuthProfile(me.id)" tab-link v-else>
      <span class="img">
        <img src="@/static/icons/passport.svg" alt="Passport">
      </span>
      <span :class="`text`">{{passport}}</span>
    </f7-link>
  </f7-toolbar>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
export default {
  props: {
    feeds: {
      required: true,
      type: String
    },
    recs: {
      required: true,
      type: String
    },
    post: {
      required: true,
      type: String
    },
    collection: {
      required: true,
      type: String
    },
    passport: {
      required: true,
      type: String
    },
    current_feeds: {
      type: Boolean,
      default: true
    },
    current_recs: {
      type: Boolean
    },
    current_post: {
      type: Boolean
    },
    current_collection: {
      type: Boolean
    },
    current_passport: {
      type: Boolean
    },
    handleFeeds: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleRecs: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handlePost: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleCollection: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handlePassport: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    })
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    onHandleDefault() {
      return null;
    },
    handleUrlFeeds() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.FEEDS, { animate: false });
      window.history.pushState("", "", "/");
      this.$store.dispatch(
        "currentUrl/ACTION_GET_CURRENT_URL",
        C.ROUTES_NAME.FEED_PAGE
      );
    },
    handleUrlRecs() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.RECS, { animate: false });
      window.history.pushState("", "", "/");
      this.$store.dispatch(
        "currentUrl/ACTION_GET_CURRENT_URL",
        C.ROUTES_NAME.RECS_PAGE
      );
    },
    handleUrlPost() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.POST, { animate: false });
      window.history.pushState("", "", "/");
      this.$store.dispatch(
        "currentUrl/ACTION_GET_CURRENT_URL",
        C.ROUTES_NAME.POST_PAGE
      );
    },
    handleUrlCollection() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.COLLECTION, { animate: false });
      window.history.pushState("", "", "/");
      this.$store.dispatch(
        "currentUrl/ACTION_GET_CURRENT_URL",
        C.ROUTES_NAME.COLLECTION_PAGE
      );
    },
    handleUrlPassport() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.PASSPORT, { animate: false });
      window.history.pushState("", "", "/");
      this.$store.dispatch(
        "currentUrl/ACTION_GET_CURRENT_URL",
        C.ROUTES_NAME.PASSPORT_PAGE
      );
    }
  }
};
</script>
