<template>
  <f7-list media-list no-hairlines>
    <f7-list-item>
      <span
        class="title-tip"
        slot="title"
      >{{`${item.tips[2].author && item.tips[2].author.nickname ? item.tips[2].author.nickname : ''}`}}</span>
      <img
        v-if="item.tips[2].author && item.tips[2].author.avatar != null"
        slot="media"
        :src="getUrlImg"
      >
      <span
        v-else
        slot="media"
        class="text no-avatar"
      >{{item.tips[2].author && item.tips[2].author.nickname.charAt(0)}}</span>
      <span class="time">{{ item.tips[2].createdAt | moment("from", true) }} ago</span>

      <f7-link class="like-comment">
        <!-- <img src="@/static/icons/rating_3_active.png" alt> -->
      </f7-link>
    </f7-list-item>
  </f7-list>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    getUrlImg: {
      required: true,
      type: String
    },
  }
};
</script>

