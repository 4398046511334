import gql from "graphql-tag";

const summaryFeed = gql`query summaryFeed {
    summaryFeed {
      posts {
        id
        title
        content
        createdAt
        statsLike
        statsComment
        likeStatus
        bookmarkStatus
        externalUrl
        externalSource
        places {
          id
          placeId
          name
        }
        city {
          name
          country {
            name
          }
        }
        author {
          id
          nickname
          avatar {
            url
            name
          }
        }
        photos {
          name
          url
        }
      }
    }
  }
`;

export {
  summaryFeed
};
