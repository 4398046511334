<template>
  <f7-toolbar position="top" class="navbar-action-top">
    <f7-nav-left>
      <f7-link back class="action-disabled" v-if="cancel">Cancel</f7-link>
      <f7-link back v-else>
        <img src="@/static/icons/back.svg" alt="Back">
      </f7-link>
    </f7-nav-left>

    <div class="select-city-collection" v-if="constants.ACTION_TYPE.ACTIONS == actionType">
      <select
        name="type-detail-collection"
        @change="($event) => onChange($event)"
        class="form-control"
        :defaultValue="constants.DETAIL_COLLECTION_TYPE.PLACE"
      >
        <option
          :selected="selectedPlace"
          :value="constants.DETAIL_COLLECTION_TYPE.PLACE"
        >{{constants.DETAIL_COLLECTION_TYPE.PLACE}}</option>
        <option
          :selected="selectedPost"
          :value="constants.DETAIL_COLLECTION_TYPE.POST"
        >{{constants.DETAIL_COLLECTION_TYPE.POST}}</option>
        <option
          :selected="selectedEvent"
          :value="constants.DETAIL_COLLECTION_TYPE.EVENT"
        >{{constants.DETAIL_COLLECTION_TYPE.EVENT}}</option>
      </select>

      <span class="icon-chevron">
        <i class="icon f7-icons">chevron_down</i>
      </span>
    </div>
    <f7-nav-title v-else>
      <h2 class="logo">
        <img src="@/static/icons/favs.png" alt="Favs">
      </h2>
    </f7-nav-title>
    <f7-nav-right>
      <bookmark-component
        :type="type"
        :item="item"
        :id="id"
        class="icon-bookmark-place-detail"
        v-if="constants.ACTION_TYPE.BOOKMARK == actionType && userId"
        :isActiveBookmarkStatus="isActiveBookmarkStatus"
        :pageType="pageType"
      />

      <f7-link
        class="action-share"
        @click.native="save"
        v-if="constants.ACTION_TYPE.SAVE == actionType"
      >Save</f7-link>
      <f7-link
        class="action-share"
        @click.native="share"
        v-if="constants.ACTION_TYPE.SHARE == actionType"
      >Share</f7-link>
      <div
        class="action more-fill-detail-collection"
        v-if="constants.ACTION_TYPE.ACTIONS == actionType"
      >
        <f7-link icon-ios="f7:more_fill" raised :actionsOpen="`.${actionsOpenName}`"></f7-link>
      </div>
      <actions-collection-component
        v-if="actionsOpenName"
        :actionsOpenName="actionsOpenName"
        :userId="userId"
        :typeId="id"
      />
    </f7-nav-right>
  </f7-toolbar>
</template>
<script>
import BookmarkComponent from "./Bookmark";
import ActionsCollectionComponent from "./ActionsCollection";
import C from "../_helpers/constants";
export default {
  components: {
    BookmarkComponent,
    ActionsCollectionComponent
  },
  props: {
    pageType: {
      required: false,
      type: String
    },
    isActiveBookmarkStatus: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedPost: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedPlace: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedEvent: {
      required: false,
      type: Boolean,
      default: false
    },
    item: {
      required: false,
      type: Object,
      default: null
    },
    cancel: {
      required: false,
      type: Boolean,
      default: false
    },
    id: {
      required: false,
      type: String,
      default: null
    },
    userId: {
      required: false,
      type: String,
      default: null
    },
    type: {
      required: false,
      type: String,
      default: null
    },
    actionType: {
      required: false,
      type: String
    },
    actionsOpenName: {
      required: false,
      type: String
    },
    save: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onChange: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    share: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>
