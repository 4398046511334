import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';
import gqlQuery from '../../_gql/query/index.js';

export default {
  namespaced: true,
  state: {
    data: [],
    refetch: false
  },

  getters: {
    data: state => state.data,
    refetch: state => state.refetch
  },

  actions: {
    ACTION_GET_NOTIFICATION({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.notification
          })
          .then(({ data }) => (data))
          .then(({ latestNotifications }) => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_GET_NOTIFICATION', latestNotifications)
            resolve(latestNotifications)
          }).catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_CONFIRM_NOTIFICATION({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.requestFriend
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_REFETCH_NOTIFICATION', null)
            commit('me/MUTATION_REFETCH_ME', null, {
              root: true
            })
            resolve(res.data)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state = Object.assign(state, { status: 'success', refetch: false })
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },

    MUTATION_GET_NOTIFICATION(state, data) {
      state.data = data
    },
    MUTATION_REFETCH_NOTIFICATION(state) {
      state.refetch = true
    }
  }
}
