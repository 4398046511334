import gql from "graphql-tag";

const topics = gql `
query topics {
    topics {
      id
      name
      title
      description
      theme_color
      isLike
      trendings {
        id
        title
        description
        places {
          id
          placeId
          name
          photos {
            name
            url
          }
          rateStarts {
            publicStatus
            publicScore
            publicStats
            friendStatus
            friendScore
            friendStats
          }
          categories {
            id
            name
          }
          bookmarkStatus
        }
      }
      trendingEvents {
        title
        events {
          id
          name
          cover
          createdAt
          eventDate
          startTime
          endTime
          categories {
            name
          }
          bookmarkStatus
          place {
            id
            name
            photos {
              name
              url
            }
          }
        }
      }
      posts {
        id
        title
        content
        externalUrl
        externalSource
        siteName
        siteDescription
        city {
          name
        }
        author {
          id
          nickname
          avatar {
            url
            name
          }
        }
        photos{
          name
          url
        }
        likeStatus
        bookmarkStatus
        createdAt
        places {
          id
          placeId
          name
          photos{
            name
            url
          }
        }
      }
      friendFavs {
        category{
          id
          name
        }
        places{
          id
          placeId
          name
          photos {
            name
            url
          }
          rateStarts {
            publicStatus
            publicScore
            publicStats
            friendStatus
            friendScore
            friendStats
          }
          bookmarkStatus
        }
      }
      stories {
        id
        title
        content
        externalUrl
        externalSource
        siteName
        siteDescription
        author {
          id
          nickname
          avatar {
            url
            name
          }
        }
        photos{
          name
          url
        }
        bookmarkStatus
        createdAt
        places {
          id
          placeId
          name
          photos{
            name
            url

          }
          bookmarkStatus
        }
      }
    }
  }
`;

export {
  topics
};
