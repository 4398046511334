<template>
  <div class="rating-block">
    <f7-block-title class="bearer">Rating</f7-block-title>
    <f7-row no-gap>
      <f7-col width="50">
        <p class="description">{{places.name}} is a must do!</p>
      </f7-col>
      <f7-col width="50">
        <public-friends-rating-component :item="places" :constants="constants"/>
      </f7-col>
    </f7-row>

    <rating-component/>

    <f7-list no-hairlines form class="no-margin" v-if="rateStatus">
      <f7-list-input
        :value="contentTip"
        @input="handleContentTip($event)"
        type="textarea"
        placeholder="Add a tip..."
        clear-button
        :resizable="true"
      ></f7-list-input>

      <span class="icon-location text-post" @click="addNewTip" v-if="!flag">Add</span>
      <span class="icon-location text-post" v-if="flag">Add</span>
    </f7-list>

    <f7-list no-hairlines form class="no-margin" v-else>
      <f7-list-input
        type="textarea"
        placeholder="Add a tip..."
        clear-button
        disabled
        :resizable="true"
      ></f7-list-input>
    </f7-list>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";

import RatingComponent from "../components/Rating";
import PublicFriendsRatingComponent from "../components/PublicFriendsRating";

export default {
  components: {
    RatingComponent,
    PublicFriendsRatingComponent
  },
  data() {
    return {
      constants: C,
      contentTip: "",
      flag: true,
      clicks: 0
    };
  },
  beforeMount() {},
  computed: {
    ...mapGetters("placeDetail", {
      places: "data",
      rateStatus: "rateStatus"
    })
  },

  methods: {
    addNewTip() {
      const self = this;
      this.clicks++;
      if (this.contentTip && this.clicks === 1) {
        // self.flag = false;
        const params = {
          id: self.places.id,
          content: this.contentTip
        };
        this.$store
          .dispatch("placeDetail/ACTION_POST_TIP", params)
          .then(res => {
            self.contentTip = "";
            self.clicks = 0;
          })
          .catch(error => {
            this.$f7.dialog.alert("Not Responding");
            self.clicks = 0;
          });
      }
      this.showToastBottom()
    },

    // Get value and validator url title
    handleContentTip(e) {
      const self = this;
      self.contentTip = e.target.value;
      self.flag = false;
      if (e.target.value == "") {
        self.flag = true;
      }
    },

    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Tip added 🙌",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    }
  }
};
</script>

