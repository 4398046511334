<template>
  <div @click="actionFetchAuthProfile(userId)">{{nickname}}</div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: {
    userId: {
      required: false,
      type: String,
      default: ""
    },
    nickname: {
      required: false,
      type: String,
      default: ""
    },
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"])
  }
};
</script>

