<template>
  <f7-block-header>
    <div class="avatar" @click="()=> onAuthProfile()">
      <div>
        <img v-if="getAvatar" :src="getAvatar">
        <span v-else class="text">{{nickname && nickname.charAt(0)}}</span>
      </div>
      <div class="name">{{ nickname }}</div>
    </div>
    <div class="action" v-if="isAction">
      <f7-link icon-ios="f7:more_fill" raised :actions-open="actionsOpen"></f7-link>
    </div>
  </f7-block-header>
</template>
<script>
export default {
  props: {
    actionsOpen: {
      required: false,
      type: String
    },
    isAction: {
      required: false,
      type: Boolean,
      default: true
    },
    nickname: {
      type: String,
      default: null
    },
    getAvatar: {
      required: false,
      type: String
    },
    onAuthProfile: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

