<template>
  <div class="block-users block" v-if="suggestions && suggestions.length > 0">
    <f7-block class="heading">
      <f7-block-title medium>Recommended for you</f7-block-title>
      <p>Recently joined with favs in {{ city.name }}</p>
    </f7-block>
    <f7-swiper :params="{ speed: 500, slidesPerView: 'auto', spaceBetween: 10 }" ref="usersSwiper">
      <f7-swiper-slide v-for="(user, index) in suggestions" :key="index" class="user-recs">
        <div v-if="(user.background && user.avatar)" class="friend-rec-card">
          <friend-rec-image-component 
            :url="user.background == null ? user.avatar.url : user.background.url"
            :avaUrl="user.avatar.url"
            :nickname="user.nickname"
            :id="user.id"
            />
          <follow-button-component :requested="pendingFriends.includes(user.id)" :userId="user.id" :role="user.role"/>
      </div>
      </f7-swiper-slide>
    </f7-swiper>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { getUrlImg, getUrlImgArticle } from "../utils/cloundinary-image";
import C from "../_helpers/constants";
import UserInfoComponent from "../components/UserInfo";
import FriendRecImageComponent from "../components/FriendRecImage"
import FollowButtonComponent from "../components/FollowButton"
export default {
  components: {
    UserInfoComponent,
    FriendRecImageComponent,
    FollowButtonComponent
  },
  
  data() {
    return {
      constants: C
    };
  },

  computed: {
  },

  props: {
    suggestions: {
      required: false,
      type: Array
    },
    suggestIndex: {
      required: false,
      type: Number
    },
    city: {
      required: true,
      type: Object
    },
    pendingFriends: {
      required: false,
      type: Array
    },
  },

  methods: {
  }
};
</script>

