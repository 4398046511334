<template>
  <f7-page
    class="page-event"
    name="event"
    infinite
    :infinite-preloader="false"
    @infinite="loadMore"
  >
    <header-navbar-component title="Recs" />
    <nav-city-component :name="city.name" />

    <!-- Page content-->
    <f7-block class="block-category">
      <f7-swiper :params="{speed:500, slidesPerView: 4, spaceBetween: 5}">
        <f7-swiper-slide>
          <category-component
            name="All"
            :active="indexCategories.index == null"
            :onHandle="() => handleCategories(null, '')"
          />
        </f7-swiper-slide>
        <f7-swiper-slide v-for="(item, index) in categories" :key="index">
          <category-component
            :name="item.name"
            :active="index == indexCategories.index"
            :onHandle="() => handleCategories(index, item.id)"
          />
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>

    <f7-block-title class="sort-by-menu" @click.native="toggleSorting">
      Sort by:
      <span class="text-value">{{textSortBy}}, {{textWhen}}</span>
      <span class="icon-chevron">
        <f7-icon f7="chevron_up" :class="{'active': isSorting}"></f7-icon>
      </span>
    </f7-block-title>
    <transition>
      <f7-list class="sort-list" v-show="isSorting">
        <f7-list-group>
          <f7-list-item class="active title" title="+ Sort order"></f7-list-item>
          <f7-list-item
            :class="indexSortBy == constants.FILTER.POPULARITY ? 'active' : ''"
            :title="constants.FILTER.TEXT_POPULARITY"
            @click="handleSortBy(constants.FILTER.POPULARITY, constants.FILTER.TEXT_POPULARITY)"
          ></f7-list-item>
          <f7-list-item
            :class="indexSortBy == constants.FILTER.FRIEND_INTEREST ? 'active' : ''"
            :title="constants.FILTER.TEXT_FRIEND_INTEREST"
            @click="handleSortBy(constants.FILTER.FRIEND_INTEREST, constants.FILTER.TEXT_FRIEND_INTEREST)"
          ></f7-list-item>
          <f7-list-item
            :class="indexSortBy == constants.FILTER.SOONEST ? 'active' : ''"
            :title="constants.FILTER.TEXT_SOONEST"
            @click="handleSortBy(constants.FILTER.SOONEST, constants.FILTER.TEXT_SOONEST)"
          ></f7-list-item>
          <f7-list-item class="active title" title="+ When"></f7-list-item>
          <f7-list-item
            :class="indexWhen == constants.FILTER.ANYTIME ? 'active' : ''"
            :title="constants.FILTER.TEXT_ANYTIME"
            @click="handleWhen(constants.FILTER.ANYTIME, constants.FILTER.TEXT_ANYTIME)"
          ></f7-list-item>
          <f7-list-item
            :class="indexWhen == constants.FILTER.TODAY ? 'active' : ''"
            :title="constants.FILTER.TEXT_TODAY"
            @click="handleWhen(constants.FILTER.TODAY, constants.FILTER.TEXT_TODAY)"
          ></f7-list-item>
          <f7-list-item
            :class="indexWhen == constants.FILTER.TOMORROW ? 'active' : ''"
            :title="constants.FILTER.TEXT_TOMORROW"
            @click="handleWhen(constants.FILTER.TOMORROW, constants.FILTER.TEXT_TOMORROW)"
          ></f7-list-item>
          <f7-list-item
            :class="indexWhen == constants.FILTER.THIS_WEEK ? 'active' : ''"
            :title="constants.FILTER.TEXT_THIS_WEEK"
            @click="handleWhen(constants.FILTER.THIS_WEEK, constants.FILTER.TEXT_THIS_WEEK)"
          ></f7-list-item>
          <f7-list-item
            :class="indexWhen == constants.FILTER.THIS_WEEKEND ? 'active' : ''"
            :title="constants.FILTER.TEXT_THIS_WEEKEND"
            @click="handleWhen(constants.FILTER.THIS_WEEKEND, constants.FILTER.TEXT_THIS_WEEKEND)"
          ></f7-list-item>
          <f7-list-item
            :class="indexWhen == constants.FILTER.NEXT_WEEK ? 'active' : ''"
            :title="constants.FILTER.TEXT_NEXT_WEEK"
            @click="handleWhen(constants.FILTER.NEXT_WEEK, constants.FILTER.TEXT_NEXT_WEEK)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </transition>

    <!-- <sort-by-component
      :handleSortBy="(type, text)=>handleSortBy(type, text)"
      :toggleSorting="()=>toggleSorting()"
      :textSortBy="textSortBy"
      :isSorting="isSorting"
    />-->
    <f7-tabs>
      <f7-tab :class="`tab-event-all`" tab-active>
        <div v-if="isLoading">
          <recs-skeleton-component
            v-for="n in 10"
            :key="n"
            :className="`skeleton-text skeleton-effect-${effect}`"
          />
        </div>
        <div v-if="!isLoading">
          <event-list-view :items="events" />
        </div>
      </f7-tab>
      <!-- <f7-tab :class="`tab-event-category`">
        <div v-if="isLoading">
          <recs-skeleton-component
            v-for="n in 10"
            :key="n"
            :className="`skeleton-text skeleton-effect-${effect}`"
          />
        </div>
        <div v-if="!isLoading">
          <event-list-view :items="recs" />
        </div>
      </f7-tab> -->
    </f7-tabs>

    <toolbar-component
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />

    <div class="preloader infinite-scroll-preloader" v-show="hasMoreItems"></div>
  </f7-page>
</template>

<script>
import HeaderNavbarComponent from "../components/HeaderNavbar";
import ToolbarComponent from "../components/Toolbar";

import RecsSkeletonComponent from "../components/RecsSkeleton";

import NavCityComponent from "../components/NavCity";
import CategoryComponent from "../components/Category";
import ButtonFriendComponent from "../components/ButtonFriend";
import SortByComponent from "../components/SortBy";

import EventListView from "../views/EventList";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
import C from "../_helpers/constants";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  components: {
    HeaderNavbarComponent,
    ToolbarComponent,
    RecsSkeletonComponent,

    NavCityComponent,
    CategoryComponent,
    ButtonFriendComponent,
    SortByComponent,

    EventListView
  },
  data() {
    return {
      constants: C,
      allowInfinite: true,
      hasMoreItems: true,
      nextItem: 0,
      isActiveTip: false,
      indexCategories: { index: null, id: "" },
      indexFriends: { index: "me", id: "me", name: null },

      typeSortBy: C.FILTER.FRIEND_RATE,
      textSortBy: C.FILTER.TEXT_POPULARITY,
      typeWhen: C.FILTER.ANYTIME,
      textWhen: C.FILTER.TEXT_ANYTIME,
      indexSortBy: C.FILTER.POPULARITY,
      indexWhen: C.FILTER.ANYTIME,

      isSorting: false,

      isLoading: false,
      effect: null
    };
  },

  mounted() {
    this.fetchCategory();
    this.fetchData();
  },

  computed: {
    ...mapGetters("cities", {
      city: "currentCity"
    }),
    ...mapGetters("auth", {
      friends: "friends",
      me: "me"
    }),
    ...mapGetters("categoriesEvents", {
      categories: "data"
    }),
    ...mapGetters("recs", {
      recs: "data"
    }),
    ...mapGetters("events", {
      events: "data"
    })
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    // Fetch category
    fetchCategory() {
      this.$store.dispatch("categoriesEvents/ACTION_FETCH_CATEOGRY");
    },

    // Load more (when scroll to bottom)
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return; // Return (not call api)
      self.allowInfinite = false; // Set status allow Infinite
      if (self.recs.length >= 500) {
        self.hasMoreItems = false; // Remove icon load more
        self.allowInfinite = false; // Set status allow Infinite
        return;
      }
      self.nextItem++; // Count +1 page (pagination)
      this.actionFetchData(); // Call api fetch data recs
    },

    // Fetch data first load
    fetchData() {
      const self = this;
      self.nextItem = 1; // Set page 1
      self.hasMoreItems = true; // Remove icon load more
      self.allowInfinite = true; // Set status allow Infinite
      self.isLoading = true;
      this.$store.dispatch("events/ACTION_RESET_STATE"); // Reset state in the store recs
      this.actionFetchData(); // Call api fetch data recs
    },

    // Function call api fetch data recs
    actionFetchData() {
      const self = this;
      // params
      const params = {
        where: {
          categoryId: self.indexCategories.id,
          filterType: self.typeSortBy,
          cityId: self.city.id
        },
        page: self.nextItem
      };
      // Call api recs
      this.$store
        .dispatch("events/ACTION_GET", params)
        .then(res => {
          self.isLoading = false; // // Check status data return form (api)
          if (res && res.data.events.length > 0) {
            self.allowInfinite = true; // Set status allow Infinite
          } else {
            // When data null
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          }
        })
        .catch(error => {
          self.isLoading = false; // Check status data return form (api)
          self.allowInfinite = false; // Set status allow Infinite
          self.hasMoreItems = false; // Remove icon load more
        });
    },

    // Get avatar auth
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return "";
      }
    },

    // Toggle sort by
    toggleSorting() {
      this.isSorting = !this.isSorting;
    },

    // Select category
    handleCategories(index, id) {
      const self = this;
      self.indexCategories.index = index; // Set index
      self.indexCategories.id = id; // Set id
      this.fetchData(); //Fetch data
    },

    // Sort by
    handleSortBy(type, text) {
      const self = this;
      if (type) {
        self.textSortBy = text; // Set text
        self.typeSortBy = type; // Set type
        // self.isSorting = !self.isSorting; // Set toggle sort by
        self.indexSortBy = type;
        const params = {
          where: {
            categoryId: self.indexCategories.id,
            friendId: self.indexFriends.id,
            filterType: type
          },
          page: 1
        };

        // Call api recs
        this.$store
          .dispatch("recs/ACTION_FETCH_REC", params)
          .then(res => {
            self.isLoading = false; // // Check status data return form (api)
            if (res && res.recs.length > 0) {
              self.allowInfinite = true; // Set status allow Infinite
            } else {
              // When data null
              self.allowInfinite = false; // Set status allow Infinite
              self.hasMoreItems = false; // Remove icon load more
            }
          })
          .catch(error => {
            self.isLoading = false; // Check status data return form (api)
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          });
      }
    },

    // When
    handleWhen(type, text) {
      const self = this;
      if (type) {
        self.textWhen = text;
        self.typeSortBy = type; // Set type
        // self.isSorting = !self.isSorting; // Set toggle sort by
        self.typeWhen = type;
        const params = {
          where: {
            categoryId: self.indexCategories.id,
            friendId: self.indexFriends.id,
            filterType: type
          },
          page: 1
        };

        // Call api recs
        this.$store
          .dispatch("recs/ACTION_FETCH_REC", params)
          .then(res => {
            self.isLoading = false; // // Check status data return form (api)
            if (res && res.recs.length > 0) {
              self.allowInfinite = true; // Set status allow Infinite
            } else {
              // When data null
              self.allowInfinite = false; // Set status allow Infinite
              self.hasMoreItems = false; // Remove icon load more
            }
          })
          .catch(error => {
            self.isLoading = false; // Check status data return form (api)
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          });
      }
    }
  }
};
</script>
