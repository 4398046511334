<template>
  <f7-button
    v-if="buttonType == constants.BUTTON_TYPE.FRIEND"
    :class="`${className} avatar f7-button-friends f7-button-friends--background-none f7-button-friends--height-auto`"
    :tab-link="tabLink"
    @click="()=> onClick()"
  >
    <div class="isAvatar" v-if="avatar">
      <img :src="onHandleAvatar">
    </div>
    <div v-else class="text">{{nickname}}</div>
    <div class="name">{{name}}</div>
  </f7-button>

  <f7-button
    v-else-if="buttonType == constants.BUTTON_TYPE.ALL"
    :class="`${className} avatar all f7-button-friends f7-button-friends--background-none f7-button-friends--height-auto`"
    :tab-link="tabLink"
    @click="()=> onClick()"
  >
    <div class="f7-button-friends__all">
      <img v-if="active" src="@/static/icons/all.svg" alt>
      <img v-else src="@/static/icons/user_all_gray.svg" alt>
    </div>
    <div class="name f7-button-friends__all--name">{{name}}</div>
  </f7-button>
  <f7-button
    v-else-if="buttonType == constants.BUTTON_TYPE.ADD_FRIEND"
    :class="`${className} f7-button-friends--height-auto f7-button-friends--padding-0`"
  >
    <div class="avatar add-friends" @click="handleAddFriends()">
      <div class="img">
        <img src="@/static/icons/add-friends.svg" alt>
      </div>
      <div class="name">Add</div>
    </div>
  </f7-button>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    className: {
      required: false,
      type: String
    },
    buttonType: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    nickname: {
      required: false,
      type: String
    },
    avatar: {
      required: false,
      type: String
    },
    active: {
      required: false,
      type: Boolean
    },
    tabLink: {
      required: false,
      type: String
    },
    onClick: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onHandleAvatar: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    onHandleDefault() {
      return null;
    },
    handleAddFriends() {
      localStorage.setItem("ADD_FRIENDS_FROM_RECS", "active");
      this.$f7router.navigate(C.ROUTER_NAVIGATE.SEARCH);
    }
  }
};
</script>

