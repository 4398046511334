import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';

export default {
  namespaced: true,
  state: {
    data: {},
  },

  getters: {
    data: state => state.data,
    uploadPhotosSuccess: state => state.uploadPlacePhotos
  },

  actions: {
    ACTION_UPLOAD_PLACE_PHOTOS({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.uploadPlacePhotos
          })
          .then(res => {
            commit('STATUS_SUCCESS', res.data.uploadPlacePhotos)
            resolve(res.data.uploadPlacePhotos)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state, place) {
      state.data.photos = place.photos;
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
  }
}
