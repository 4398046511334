import gql from "graphql-tag";

const passport = gql`
  query passport($userId: ID) {
    passport(userId: $userId) {
      profile {
        id
        nickname
        email
        noOfFollowers
        noOfRecs
        avatar {
          name
          url
        }
        background {
          name
          url
        }
        friends {
          id
          status
          createdAt
          receive {
            id
            nickname
            avatar {
              url
              name
            }
          }
        }
      }
      statsFriend
      statsCity
      statsPlace
      statsPost
      magazines {
        id
        name
        cover {
          name
          url
        }
        cities {
          id
          name
          statsPlace
          statsPost
          cover {
            name
            url
          }
          published
          location {
            type
            coordinates
          }
        }
      }
    }
  }
`;

export {
  passport
};
