<template>
  <f7-page class="page-detail-post" name="detail" hide-bars-on-scroll>
    <header-navbar-component title="Detail Post"/>

    <div v-if="posts && posts.photos" :class="`block-article`">
      <article-view
        :onAuthProfile="()=>actionFetchAuthProfile(posts.author.id)"
        :nickname="posts.author.nickname"
        :getAvatar="getAvatarAuthImg(posts.author.avatar ? posts.author.avatar.name : false)"
        :actionsOpen="`.actions-article-${posts.id}`"
        :title="posts.title"
        :photos="posts.photos"
        :onGetUrlImg="(photo, idx) => getUrlImg(photo, idx)"
        :cityName="posts.city.name"
        :places="posts.places"
        :city="posts.city"
        :siteName="posts.siteName"
        :siteDescription="posts.siteDescription"
        :showExternalUrl="true"
        :isDetail="true"
        :externalUrl="posts.externalUrl"
        :externalSource="posts.externalSource"
        :content="posts.content"
        :createdAt="posts.createdAt"
        :statsLike="posts.statsLike"
        :statsComment="posts.statsComment"
        :onPhotoBrowser="()=>onPhotoBrowser()"
        :type="constants.BOOKMARK_CONTENT_TYPE.POST"
        :item="posts"
        :id="posts.id"
        :pageType="pageType"
        :isActiveBookmarkStatus="posts.isActiveBookmark"
      />
      <f7-photo-browser theme="dark" :photos="photoBrowser(posts.photos)" ref="photoBrowser"></f7-photo-browser>

      <actions-article-component
        :actionsOpenName="`actions-article-${posts.id}`"
        :actionsId="posts && posts.id ? posts.id : null"
        :handleSharePost="()=>handleShareToArticle(posts.id)"
        :onCopySuccess="onCopySuccess"
        :onCopyError="onCopyError"
      />

      <f7-block
        :class="`${index == 2 ? 'border-bottom-none' : ''} vendor-list wrap-places`"
        no-padding
        v-for="(place, index) in posts.places.slice(0,3)"
        :key="index"
      >
        <f7-row>
          <f7-col width="40">
            <f7-card>
              <place-image-component :url="place.photos.length > 0 ? place.photos[0].url : ''"/>
              <div class="wrap-icon-bookmark-share">
                <bookmark-component
                  class="icon-bookmark"
                  :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                  :item="place"
                  :id="place.id"
                  :isActiveBookmarkStatus="place.isActiveBookmark"
                  :pageType="constants.PAGE_CONTENT_TYPE.PLACE_POST_DETAIL"
                />
                <share-component
                  :actionsOpenId="`actions-detail-post-place-${place.id}`"
                  :type="constants.TYPE_SHARE.PLACE"
                  :typeId="place.id"
                />
              </div>
            </f7-card>
          </f7-col>
          <f7-col width="60">
            <div class="vendor-title">{{index+1}}. {{ place.name }}</div>

            <place-title-component
              class="sub-title"
              v-if="place.city != null"
              :title="place.city != null ? place.city.name : null"
            />
            <public-friends-rating-component :item="place" :constants="constants"/>
          </f7-col>
          <div
            class="wrapper-place"
            v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.PLACE_POST_DETAIL)"
          ></div>
        </f7-row>
      </f7-block>
      <transition-expand-component>
        <div v-if="posts.isActiveTip" class="wrapper-expanded">
          <f7-block
            class="vendor-list wrap-places"
            no-padding
            v-for="(place, index) in posts.places.slice(3)"
            :key="index"
          >
            <f7-row>
              <f7-col width="40">
                <f7-card>
                  <place-image-component :url="place.photos.length > 0 ? place.photos[0].url : ''"/>
                  <div class="wrap-icon-bookmark-share">
                    <bookmark-component
                      class="icon-bookmark"
                      :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                      :item="place"
                      :id="place.id"
                      :isActiveBookmarkStatus="place.isActiveBookmark"
                      :pageType="constants.PAGE_CONTENT_TYPE.PLACE_POST_DETAIL"
                    />
                    <share-component
                      :actionsOpenId="`actions-detail-post-place-${place.id}`"
                      :type="constants.TYPE_SHARE.PLACE"
                      :typeId="place.id"
                    />
                  </div>
                </f7-card>
              </f7-col>
              <f7-col width="60">
                <div class="vendor-title">{{index+4}}. {{ place.name }}</div>

                <place-title-component
                  v-if="place.city != null"
                  :title="place.city != null ? place.city.name : null"
                />

                <public-friends-rating-component :item="place" :constants="constants"/>
              </f7-col>
              <div
                class="wrapper-place"
                v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.PLACE_POST_DETAIL)"
              ></div>
            </f7-row>
          </f7-block>
        </div>
      </transition-expand-component>

      <see-more-component
        :item="posts"
        :isShow="posts.places && posts.places.length > 3"
        :isActive="posts.isActiveTip"
        :toggleTip="()=>toggleTip()"
      />
    </div>
    <toolbar-component
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
    <toolbar-social
      v-else
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../../_helpers/constants";
import HeaderNavbarComponent from "../../components/HeaderNavbar";
import TransitionExpandComponent from "../../components/TransitionExpand";
import ToolbarComponent from "../../components/Toolbar";
import ToolbarSocial from "../../components/ToolbarSocial";
import SeeMoreComponent from "../../components/SeeMore";
import PlaceImageComponent from "../../components/PlaceImage";
import PlaceTitleComponent from "../../components/PlaceTitle";
import ActionsArticleComponent from "../../components/ActionsArticle";
import BookmarkComponent from "../../components/Bookmark";
import ShareComponent from "../../components/Share";
import PublicFriendsRatingComponent from "../../components/PublicFriendsRating";
import ArticleView from "../../views/ArticleView";
import _ from "lodash";

import { copySuccess, copyError } from "../../utils/copy";
import { sharePost, sharePlace } from "../../utils/share";
import { getUrlImg, getUrlImgArticle } from "../../utils/cloundinary-image";

export default {
  components: {
    HeaderNavbarComponent,
    ActionsArticleComponent,
    ToolbarComponent,
    ToolbarSocial,
    TransitionExpandComponent,
    SeeMoreComponent,
    PlaceImageComponent,
    BookmarkComponent,
    ShareComponent,
    PublicFriendsRatingComponent,
    PlaceTitleComponent,

    ArticleView
  },
  data() {
    return {
      constants: C,
      isActiveTip: false,
      // posts: [],
      meId: null,
    };
  },
  watch: {
    bookmarkStatus(news) {
      if (news) {
        this.showToastBottom();
      }
    },
  },
  computed: {
    ...mapGetters("detailPost", {
      postInfo: "info",
      posts: "data",
      pageType: "pageType"
    }),
    ...mapGetters("bookMark", {
      bookmarkStatus: "bookmark"
    }),
    ...mapGetters("auth", {
      me: "me"
    }),
    redirect() {
      if (this.$store.state.social.data.redirect) {
        return this.$store.state.social.data.redirect;
      } else {
        const currentPage = localStorage.getItem("currentPage");
        return currentPage;
      }
    }
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Saved to plan",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    },
    handleShareToArticle(id) {
      FB.ui({
        method: "share",
        href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.POST + "/" + id
      });
    },
    toggleTip() {
      const self = this;
      self.posts.isActiveTip = !self.posts.isActiveTip;
      this.$forceUpdate();
    },

    onPhotoBrowser() {
      this.$refs.photoBrowser.open();
    },
    onCopySuccess(e) {
      return copySuccess(e, this);
    },
    onCopyError(e) {
      return copyError(e);
    },

    // To detail
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params); // set id place detail
    },

    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    getUrlImg(photo, index) {
      return getUrlImgArticle(photo, index);
    },
    photoBrowser(photo) {
      return photo;
    }
  }
};
</script>

<style scoped>
</style>
