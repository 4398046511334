import gql from "graphql-tag";

const inviteFriend = gql `
mutation inviteFriend($id: ID!) {
  inviteFriend(id: $id) {
    id
  }
}
`

export  {
  inviteFriend

};
