import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';
import gqlQuery from '../../_gql/query/index.js';
import C from "../../_helpers/constants";

export default {
  namespaced: true,
  state: {
    data: [],
    pageType: null
  },

  getters: {
    data: state => state.data,
    pageType: state => state.pageType
  },

  actions: {

    ACTION_GET_COMMENT({
      commit
    }, params) {
      const id = params.id
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              id
            },
            mutation: gqlQuery.detail
          })

          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_GET_COMMENT', res.data.post)
            commit('MUTATION_GET_PAGE_TYPE', params.pageType)
            resolve(res.data)
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.POST_COMMENT,
              params: {
                id: id
              }
            });
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_POST_COMMENT({
      commit
    }, params) {
      let data = params.params
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.createComment
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_POST_COMMENT', res.data.createComment)

            if (params.pageType.type == C.PAGE_CONTENT_TYPE.FEED) {
              commit('feeds/MUTATION_INCREMENT_COMMENT', {
                id: params.params.id
              }, {
                root: true
              })
            }
            if (params.pageType.type == C.PAGE_CONTENT_TYPE.COLLECTION_POST) {
              commit('collectionDetailPost/MUTATION_INCREMENT_COMMENT', {
                id: params.params.id
              }, {
                root: true
              })
            }
            if (params.pageType.type == C.PAGE_CONTENT_TYPE.SUMMARY) {
              commit('summaryFeed/MUTATION_INCREMENT_COMMENT', {
                id: params.params.id
              }, {
                root: true
              })
            }
            if (params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED || params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS) {
              commit('magazine/MUTATION_INCREMENT_COMMENT_POSTED', {
                id: params.params.id
              }, {
                root: true
              })
              commit('magazine/MUTATION_INCREMENT_COMMENT_ADD_TO_PLANS', {
                id: params.params.id
              }, {
                root: true
              })
            }

            commit('detailPost/MUTATION_INCREMENT_COMMENT', {
              id: params.params.id
            }, {
              root: true
            })

            resolve(res)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_PUT_COMMENT({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.updateComment
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_PUT_COMMENT', res.data.updateComment)
            resolve(res)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATION_GET_COMMENT(state, details) {
      state.data = details
    },

    MUTATION_GET_PAGE_TYPE(state, item) {
      state.pageType = item
    },

    MUTATION_POST_COMMENT(state, item) {
      state.data.comments.push(item)
    },

    MUTATION_PUT_COMMENT(state, items) {
      const arr = _.map(state.data.comments, function (a) { // Change new value with item
        return a.id === items.id ?
          items :
          a;
      });
      state.data.comments = arr;
    },

  }
}
