import gql from "graphql-tag";

const categoriesEvents = gql `query categoriesEvents {
  categoriesEvents{
    id,
    name
  }
}
`

export {
  categoriesEvents
};
