import LoginPage from '../pages/Login'
import HomePage from '../pages/Home'
import FeedPage from '../pages/feed/Feed'
import PostComment from '../pages/post/Comment'
import RecsPage from '../pages/Recs'
import EventPage from '../pages/Event'
import PostPage from '../pages/post/Create'
import PostDetailPage from '../pages/post/DetailPost'
import PlacePage from '../pages/Place'
import CollectionPage from '../pages/collection/Collection'
import PassportPage from '../pages/passport/Index'
import PassportListPage from '../pages/passport/List'
import MagazineTipComment from '../pages/passport/Comment'
import SearchPage from '../pages/Search'
import Notification from '../pages/Notification'
import Settings from '../pages/Settings'
import SeeFriendsPage from "../pages/passport/SeeFriends"
import Privacy from "../pages/Privacy"
import Terms from "../pages/TermAndCondition";
import TopicPostsComponent from "../components/TopicPostsComponent";
import EventDetail from "../components/EventDetail";
import NotFoundPage from '../pages/404'
import CollectionDetailPost from "../pages/collection/DetailCollectionPost"
import CollectionDetailPlace from "../pages/collection/DetailCollectionPlace"
import CollectionDetailEvent from "../pages/collection/DetailCollectionEvent"
import App from "../components/App"
import C from "../_helpers/constants";
import { gaPageview } from "../utils/gaHelpers"

let currentPosition

const routes = [{
    path: "/app",
    name: C.ROUTES_NAME.APP,
    component: App
  },
  {
    path: "/",
    name: C.ROUTES_NAME.LOGIN_PAGE,
    component: LoginPage,
  },
  {
    path: "/home/",
    name: C.ROUTES_NAME.HOME_PAGE,
    component: HomePage
  },
  {
    path: "/feeds/",
    name: C.ROUTES_NAME.FEED_PAGE,
    component: FeedPage,
    keepAlive: true,
    on: {
      pageAfterIn: function (event) {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.FEEDS);
      },
      pageBeforeIn: function(event) {
        $(event.currentTarget).find('.page-content').scrollTop(currentPosition);
      },
      pageBeforeOut: function (event) {
        currentPosition = $(event.currentTarget).find('.page-content').scrollTop();
      }
    }
  },
  {
    path: "/see-friends/",
    name: C.ROUTES_NAME.SEE_FRIENDS_PAGE,
    component: SeeFriendsPage
  },
  {
    path: "/privacy/",
    name: C.ROUTES_NAME.PRIVACY,
    component: Privacy
  },
  {
    path: "/terms/",
    name: C.ROUTES_NAME.TERMS,
    component: Terms
  },
  {
    path: "/recs/",
    name: C.ROUTES_NAME.RECS_PAGE,
    component: RecsPage,
    keepAlive: true,
    on: {
      pageAfterIn: function (event, page) {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.RECS);
      },
    }
  },
  {
    path: "/event/",
    name: C.ROUTES_NAME.EVENT_PAGE,
    component: EventPage,
    on: {
      pageAfterIn: function (event, page) {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.EVENT);
      },
    }
  },
  {
    path: "/search/",
    name: C.ROUTES_NAME.SEARCH_PAGE,
    component: SearchPage,
    on: {
      pageAfterOut: function () {
        localStorage.removeItem("ADD_FRIENDS_FROM_RECS");
      },
      pageAfterIn: function (event, page) {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.SEARCH);
      },
    }
  },
  {
    path: "/notification/",
    name: C.ROUTES_NAME.NOTIFICATION,
    component: Notification
  },
  {
    path: "/settings/",
    name: C.ROUTES_NAME.SETTINGS,
    component: Settings,
    on: {
      pageAfterIn: function (event, page) {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.PASSPORT);
      },
    }
  },
  {
    path: "/post/",
    name: C.ROUTES_NAME.POST_PAGE,
    component: PostPage,
    on: {
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.POST);
      },
    }
  },
  {
    path: "/post/detail/:id/",
    name: C.ROUTES_NAME.POST_DETAIL_PAGE,
    component: PostDetailPage,
    on: {
      // pageBeforeOut: function (event, page) {
      //   window.history.pushState("", "", "/");
      // },
    }
  },
  {
    path: "/post/place/detail/:id/",
    name: C.ROUTES_NAME.PLACE_PAGE,
    component: PlacePage,
    on: {
      // pageBeforeOut: function (event, page) {
      //   window.history.pushState("", "", "/");
      // },
    }
  },
  {
    path: "/post/comment/:id/",
    name: C.ROUTES_NAME.POST_COMMENT,
    component: PostComment
  },
  {
    path: "/collection/",
    name: C.ROUTES_NAME.COLLECTION_PAGE,
    component: CollectionPage,
    on: {
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.COLLECTION);
      },
    }
  },
  {
    path: "/collection/detail/post/:id/",
    name: C.ROUTES_NAME.COLLECTION_DETAIL_POST,
    component: CollectionDetailPost,
    on: {
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.COLLECTION);
      },
    }
  },
  {
    path: "/collection/detail/event/:id/",
    name: C.ROUTES_NAME.COLLECTION_DETAIL_EVENT,
    component: CollectionDetailEvent,
    on: {
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.COLLECTION);
      },
    }
  },
  {
    path: "/collection/detail/place/:id/",
    name: C.ROUTES_NAME.COLLECTION_DETAIL_PLACE,
    component: CollectionDetailPlace,
    on: {
      // pageBeforeOut: function (event, page) {
      //   window.history.pushState("", "", "/");
      // },
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.COLLECTION);
      },
    }
  },
  {
    path: "/passport/",
    name: C.ROUTES_NAME.PASSPORT,
    component: PassportPage,
    on: {
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.PASSPORT);
      },
      // pageBeforeOut: function (event, page) {
      //   window.history.pushState("", "", "/");
      // },
    }
  },
  {
    path: "/passport/friend/:id/",
    name: C.ROUTES_NAME.PASSPORT_FRIEND_PROFILE_PAGE,
    component: PassportPage,
    on: {
      pageAfterIn: function () {
        localStorage.setItem("currentPage", C.REDIRECT_PAGE.PASSPORT);
      },
      // pageBeforeOut: function (event, page) {
      //   window.history.pushState("", "", "/");
      // },
    }
  },
  {
    path: "/passport/magazine/:id/",
    name: C.ROUTES_NAME.PASSPORT_LIST_PAGE,
    component: PassportListPage,
    on: {
      // pageBeforeOut: function (event, page) {
      //   window.history.pushState("", "", "/");
      // },
    }
  },
  {
    path: "/passport/magazine/tip/comment/:id/",
    name: C.ROUTES_NAME.MAGAZINE_TIP_COMMENT,
    component: MagazineTipComment
  },
  {
    path: "/topic-posts/:id/:name/",
    name: C.ROUTES_NAME.TOPIC_POSTS,
    component: TopicPostsComponent,
  },
  {
    path: "/event/:id/",
    name: C.ROUTES_NAME.EVENT_DETAIL,
    component: EventDetail,
  },
  {
    path: "(.*)",
    component: NotFoundPage
  }
]

// inject Pageview tracking into each route
routes.forEach(route => {
  const onMixin = {
    pageAfterIn: function() {
      gaPageview({ page_path: route.path, page_title: route.name, page_location: location.href })
    }
  }

  if (route.component == NotFoundPage) { return }
  if (!route.on) {
    route.on = onMixin
    return
  }

  if (!route.on.pageAfterIn) {
    route.on.pageAfterIn = onMixin.pageAfterIn
    return
  }

  const origin = route.on.pageAfterIn
  function updated() {
    origin()
    onMixin.pageAfterIn()
  }
  route.on.pageAfterIn = updated
})

export default routes;
