<template>
  <div class="sharing-block">
    <!-- <f7-row no-gap display-flex flex-direction-column justify-content-center> -->
    <f7-row no-gap>
      <f7-col>
        <f7-link class="facebook-icon" icon-ios="f7:logo_facebook" :href="fb_link" target="_blank" external v-if="fb_link">
          <p>facebook page</p>
        </f7-link>
        <f7-link class="facebook-icon" icon-ios="f7:logo_facebook" v-else>
          <p>not listed</p>
        </f7-link>
      </f7-col>
      <f7-col>
        <f7-link icon-ios="f7:world" :href="website" target="_blank" external v-if="website">
          <p>website</p>
        </f7-link>
        <f7-link icon-ios="f7:world" v-else>
          <p>not listed</p>
        </f7-link>
      </f7-col>
      <f7-col>
        <f7-link icon-ios="f7:phone_fill" :href="'tel:'+phone" external v-if="phone">
          <p>{{phone}}</p>
        </f7-link>
        <f7-link icon-ios="f7:phone_fill" v-else>
          <p>not listed</p>
        </f7-link>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
export default {
  props: {
    phone: {
      required: false,
      type: String
    },
    website: {
      required: false,
      type: String
    },
    fb_link: {
      required: false,
      type: String
    },
  }
};
</script>
