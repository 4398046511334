import gql from "graphql-tag";

const recs = gql`
  query recs($page: Int, $where: RecsFilterInput!, $IDFriend: ID, $filterType: RECS_FILTER) {
    recs(page: $page, where: $where) {
      id
      name
      address
      rating
      placeId
      priceLever
      rateStarts(where: { friendId: $IDFriend }) {
        publicStatus
        publicScore
        publicStats
        friendStatus
        friendScore
        friendStats
      }
      categories {
        id
        name
      }
      rates(where: { authorId: $IDFriend, filterType: $filterType }, last: 1) {
        id
        action
        author {
          id
          nickname
          avatar {
            name
            url
          }
        }
      }
      tips(where: { authorId: $IDFriend, filterType: $filterType }) {
        id
        content
        author {
          id
          nickname
          avatar {
            url
            name
          }
        }
        rate {
          id
          action
        }
        place {
          rateStatus {
            id
            action
          }
        }
        createdAt
      }

      rateStatus {
        id
        action
      }
      photos {
        name
        url
      }
      distance
      bookmarkStatus
    }
  }
`;

export { recs };
