<template>
  <f7-page id="page-search" name="search">
    <f7-toolbar position="top">
      <div class="search">
        <img src="@/static/icons/search.svg" alt>
        <form action="javascript:void(0);">
          <input
            type="search"
            class="form-control"
            v-on:keypress="handleSearchEnter"
            placeholder="Search"
            v-model="theKeyword"
          >
        </form>
          <f7-link
            v-if="activeCategory == constants.TYPE_SEARCH.ALL"
            @click.stop="launchAllSearch"
          >
            <img src="@/static/icons/location.svg">
          </f7-link>
          <f7-link
            v-if="activeCategory == constants.TYPE_SEARCH.PLACES"
            @click.stop="launchPlacesSearch"
          >
            <img src="@/static/icons/location.svg">
          </f7-link>
          <f7-link
            v-if="activeCategory == constants.TYPE_SEARCH.FRIENDFAVS"
            @click.stop="launchFriendFavsSearch"
          >
            <img src="@/static/icons/location.svg">
          </f7-link>
      </div>

      <f7-link>
        <div class="navbar-right">
          <f7-link back>Close</f7-link>
        </div>
      </f7-link>
    </f7-toolbar>
    <f7-block class="block-city">
      <f7-row>
        <f7-col width="70" class="col-left">{{city.name}}</f7-col>
        <f7-col width="30" class="col-right">
          <f7-link @click="toHome">Change</f7-link>
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-block class="block-category">
      <f7-swiper class="friends" :params="{speed:500, slidesPerView: 'auto', spaceBetween: 0}">
        <f7-swiper-slide v-for="(item, index) in categories" :key="index">
          <span
            :class="item == activeCategory ? 'active' : ''"
            @click="handleCategory(index, item)"
          >{{ item }}</span>
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>
    <div
      :class="`${activeCategory ==  constants.TYPE_SEARCH.FRIENDFAVS ? 'active' : ''} ${activeCategory == constants.TYPE_SEARCH.ALL ? 'active border-bottom' : ''} vendor-list friendFavs`"
      no-padding v-if="friendFavs.length > 0"
    >
      <f7-block-title class="vendor-title">FRIENDS' FAVS</f7-block-title>
      <f7-row v-for="(item, key) in (!(activeCategory == constants.TYPE_SEARCH.ALL)?friendFavs:friendFavs.slice(0, 5))" :key="key">
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item
              :title="friendFavs.indexOf(item)+1+'. '+item.name"
              @click="toPlaceDetail(item.placeId, constants.PAGE_CONTENT_TYPE.SEARCH)"
            >
              <img slot="media" v-if="item.coverUrl" :src="item.coverUrl">

              <span class="coverUrl-null" slot="media" v-else>
                <i class="f7-icons color-white">camera</i>
              </span>
              <span class="line-clamp-1" slot="text">
                <span >{{displayFriendNames(item.rateStarts.friendNames)}}</span>
              </span>
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30">
          <f7-row no-gap class="rating-display">
            <f7-col>
              <div class="title">Public</div>
              <div class="count">
                <span>
                  <img
                    v-if="comparePublicStatus(item, constants.RATE_STARTS.FAVS)"
                    src="@/static/icons/rating_3_active.png"
                    alt
                  />
                  <img
                    v-else-if="comparePublicStatus(item, constants.RATE_STARTS.LIKE)"
                    src="@/static/icons/rating_2_active.png"
                    alt
                  />
                  <img
                    v-else-if="comparePublicStatus(item, constants.RATE_STARTS.OK)"
                    src="@/static/icons/rating_1_active.png"
                    alt
                  />
                  <img
                    v-else
                    src="@/static/icons/rating_0.png"
                    alt
                  />
                </span>
                <label>{{publicScoreOrDefault(item)}}</label>
              </div>
              <div class="total-rate">{{publicStatsOrDefault(item)}} rated</div>
            </f7-col>
            <f7-col>
              <div class="title">Friends</div>
              <div class="count">
                <span>
                  <img
                    v-if="compareFriendStatus(item, constants.RATE_STARTS.FAVS)"
                    src="@/static/icons/rating_3_active.png"
                    alt
                  />
                  <img
                    v-else-if="compareFriendStatus(item, constants.RATE_STARTS.LIKE)"
                    src="@/static/icons/rating_2_active.png"
                    alt
                  />
                  <img
                    v-else-if="compareFriendStatus(item, constants.RATE_STARTS.OK)"
                    src="@/static/icons/rating_1_active.png"
                    alt
                  />
                  <img
                    v-else
                    src="@/static/icons/rating_0.png"
                    alt
                  />
                </span>
                <label>{{friendScoreOrDefault(item)}}</label>
              </div>
              <div class="total-rate">{{friendStatsOrDefault(item)}} rated</div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
      <div class="button-see-more"  
        v-show="(friendFavs.length > 5)&&(activeCategory == constants.TYPE_SEARCH.ALL)" 
        id="see-more-friendFavs"
        @click="seeMoreFriendFavs" >See more Favs</div>
    </div>

    <div
      :class="`${activeCategory ==  constants.TYPE_SEARCH.PLACES ? 'active' : ''} ${activeCategory == constants.TYPE_SEARCH.ALL ? 'active border-bottom' : ''} vendor-list places`"
      no-padding v-if="places.length > 0"
    >
      <f7-block-title class="vendor-title">PLACES</f7-block-title>
      <f7-row v-for="(item, key) in (activeCategory == constants.TYPE_SEARCH.ALL ? places.slice(0, 5) : places)" :key="key">
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item
              :title="item.name"
              @click="toPlaceDetail(item.placeId, constants.PAGE_CONTENT_TYPE.SEARCH)"
            >
              <img slot="media" v-if="item.coverUrl" :src="item.coverUrl">

              <span class="coverUrl-null" slot="media" v-else>
                <i class="f7-icons color-white">camera</i>
              </span>
              <span class="line-clamp-1" slot="text">
                <span v-if="showDistance">{{item.distance}} •</span>
                {{ item.address | truncate(30, '...') }}
              </span>
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30">
          <f7-row no-gap class="rating-display">
            <f7-col>
              <div class="title">Public</div>
              <div class="count">
                <span>
                  <img
                    v-if="comparePublicStatus(item, constants.RATE_STARTS.FAVS)"
                    src="@/static/icons/rating_3_active.png"
                    alt
                  />
                  <img
                    v-else-if="comparePublicStatus(item, constants.RATE_STARTS.LIKE)"
                    src="@/static/icons/rating_2_active.png"
                    alt
                  />
                  <img
                    v-else-if="comparePublicStatus(item, constants.RATE_STARTS.OK)"
                    src="@/static/icons/rating_1_active.png"
                    alt
                  />
                  <img
                    v-else
                    src="@/static/icons/rating_0.png"
                    alt
                  />
                </span>
                <label>{{publicScoreOrDefault(item)}}</label>
              </div>
              <div class="total-rate">{{publicStatsOrDefault(item)}} rated</div>
            </f7-col>
            <f7-col>
              <div class="title">Friends</div>
              <div class="count">
                <span>
                  <img
                    v-if="compareFriendStatus(item, constants.RATE_STARTS.FAVS)"
                    src="@/static/icons/rating_3_active.png"
                    alt
                  />
                  <img
                    v-else-if="compareFriendStatus(item, constants.RATE_STARTS.LIKE)"
                    src="@/static/icons/rating_2_active.png"
                    alt
                  />
                  <img
                    v-else-if="compareFriendStatus(item, constants.RATE_STARTS.OK)"
                    src="@/static/icons/rating_1_active.png"
                    alt
                  />
                  <img
                    v-else
                    src="@/static/icons/rating_0.png"
                    alt
                  />
                </span>
                <label>{{friendScoreOrDefault(item)}}</label>
              </div>
              <div class="total-rate">{{friendStatsOrDefault(item)}} rated</div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
      <div class="button-see-more"  
        v-show="(places.length > 5) && (activeCategory == constants.TYPE_SEARCH.ALL)" 
        id="see-more-places"
        @click="seeMorePlaces" >See more places</div>
    </div>
    <!-- remove CITIS Section for now -->
    <!-- <div
      :class="`${activeCategory == constants.TYPE_SEARCH.CITIES ? 'active' : ''} ${activeCategory == constants.TYPE_SEARCH.ALL ? 'active border-bottom' : ''} vendor-list cities`"
      no-padding
    >
      <f7-block-title class="vendor-title">CITIES</f7-block-title>
      <f7-row>
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Hanoi" text="33 FRIENDS VISITED">
              <img slot="media" src="@/static/icons/address.svg">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
    </div> -->

    <div
      :class="`${activeCategory == constants.TYPE_SEARCH.PEOPLE ? 'active' : ''} ${activeCategory == constants.TYPE_SEARCH.ALL ? 'active border-bottom' : ''} vendor-list people`"
      no-padding v-if="people.length > 0"
    >
      <f7-block-title class="vendor-title">PEOPLE</f7-block-title>
      <!-- <div class="no-data" v-if="people.length == 0">No results</div> -->
      <f7-row v-for="(item, key) in (!(activeCategory == constants.TYPE_SEARCH.ALL)?people:people.slice(0, 5))" :key="key">
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item>
              <span
                slot="title"
                @click="actionFetchAuthProfile(item.id)"
              >{{ item && (item.nickname || item.username) }}</span>
              <span v-if="item" slot="text">{{ item.noOfFollowers }} Followers . {{ item.noOfRecs }} recs</span>
              <img
                slot="media"
                v-if="item && item.avatar && item.avatar.name"
                :src="getAvatarAuthImg(item.avatar.name)"
              >
              <span
                @click="actionFetchAuthProfile(item.id)"
                slot="media"
                v-else
                class="text"
              >{{item && item.nickname && item.nickname.charAt(0)}}</span>
            </f7-list-item>
            <f7-button
              v-if="item && item.friendStatus == null"
              raised
              @click="inviteFriend(item.id)"
            >ADD FRIEND</f7-button>
            <f7-button v-if="item && item.friendStatus == 'ACCEPTED'">FRIENDS</f7-button>
            <f7-button v-if="item && item.friendStatus == 'SEND'">REQUESTED</f7-button>
            <!-- <f7-button raised>FOLLOW</f7-button> -->
          </f7-list>
        </f7-col>
      </f7-row>
      <div class="button-see-more"
        v-show="(people.length > 5)&&(activeCategory == constants.TYPE_SEARCH.ALL)" 
        id="see-more-people"
        @click="seeMorePeople" >See more people</div>
    </div>

    <div
      :class="`${activeCategory == constants.TYPE_SEARCH.EVENT ? 'active' : ''} ${activeCategory == constants.TYPE_SEARCH.ALL ? 'active border-bottom' : ''} vendor-list event`"
      no-padding v-if="events.length > 0"
    >
      <f7-block-title class="vendor-title">EVENTS</f7-block-title>
      <!-- <div class="no-data" v-if="events.length == 0">No results</div> -->
      <f7-row v-for="(item, key) in (!(activeCategory == constants.TYPE_SEARCH.ALL)?events:events.slice(0, 5))" :key="key">
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item 
            :title="item.name"
            :text="item.place.address | truncate(27, '...')"
            @click="toEventDetail(item.id, constants.PAGE_CONTENT_TYPE.SEARCH)">
              <img slot="media" :src="item.cover">
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30">
          <span class="event-date">{{ showEventDate(item) }}</span>
          <span class="event-time">{{ item.startTime }}</span>
        </f7-col>
      </f7-row>
      <div class="button-see-more"
        v-show="(events.length > 5)&&(activeCategory == constants.TYPE_SEARCH.ALL)" 
        id="see-more-events"
        @click="seeMoreEvents" >See more events</div>
    </div>

    <div
      :class="`${activeCategory == constants.TYPE_SEARCH.MAGAZINES ? 'active' : ''} ${activeCategory == constants.TYPE_SEARCH.ALL ? 'active border-bottom' : ''} vendor-list mazagines`"
      no-padding
    >
      <f7-block-title class="vendor-title">MAGAZINES</f7-block-title>
      <f7-row>
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Peter Wright" text="HO CHI MINH CITY">
              <img slot="media" src="/static/images/mazagines_1.svg">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Ada Houston" text="HO CHI MINH CITY">
              <img slot="media" src="/static/images/mazagines_2.svg">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Ella Price" text="HO CHI MINH CITY">
              <img slot="media" src="/static/images/mazagines_3.svg">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Ollie Hernandez" text="HO CHI MINH CITY">
              <img slot="media" src="/static/images/mazagines_4.svg">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Eula Ruiz" text="HO CHI MINH CITY">
              <img slot="media" src="/static/images/mazagines_5.svg">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
      <!-- <span class="button-see-more">See more magazines</span> -->
    </div>
    <div v-if="isLoading">
      <loading/>
    </div>
    <toolbar
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
  </f7-page>
</template>

<script>
import Toolbar from "../components/Toolbar";
import Loading from "../components/Loading";
import C from "../_helpers/constants";
import _ from "lodash";
import { mapGetters, mapState, mapActions } from "vuex";
import fetchGeoLocation from '../utils/fetchGeoLocation'
import { showEventDate, showEventTime } from '../utils/eventHelpers';

export default {
  components: { Loading, Toolbar },
  data() {
    return {
      constants: C,
      categories: [
        C.TYPE_SEARCH.ALL,
        C.TYPE_SEARCH.FRIENDFAVS,
        C.TYPE_SEARCH.PLACES,
        // C.TYPE_SEARCH.CITIES,
        C.TYPE_SEARCH.PEOPLE,
        C.TYPE_SEARCH.EVENT,
        C.TYPE_SEARCH.MAGAZINES
      ],
      items: [],
      people: [],
      search: "",
      theKeyword: "",
      activeCategory: C.TYPE_SEARCH.ALL,
      isLoading: false,

      currentLocation: { lat: 0, lng: 0 },
      // cityLocation: { lat: 0, lng: 0 },
      geolocationAvailable: true,
      getCurrentPosition: false,
      searchKeyboardEnter: false,

      paginationPlace: 0,
      itemNearbySearch: 5,
      maxNumber: 5,
      nearbySearch: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("cities", {
      city: "currentCity"
    }),
    ...mapGetters("search", {
      places: "places",
      friendFavs: "friendFavs",
      showDistance: "showDistance",
      events: "events"
    })
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    seeMoreFriendFavs() {
      const self = this;
      self.activeCategory = C.TYPE_SEARCH.FRIENDFAVS;
    },
    seeMorePlaces() {
      const self = this;
      self.activeCategory = C.TYPE_SEARCH.PLACES;
    },
    seeMorePeople() {
      const self = this;
      self.activeCategory = C.TYPE_SEARCH.PEOPLE;
    },
    seeMoreEvents() {
      const self = this;
      self.activeCategory = C.TYPE_SEARCH.EVENT;
    },
    handleSearchEnter(e) {
      if (e.which !== 13) return

      this.searchKeyboardEnter = true;
      switch (this.activeCategory) {
        case C.TYPE_SEARCH.ALL:
          this.doSearchAll(this.theKeyword)
          break
        case C.TYPE_SEARCH.PLACES:
          this.doSearchPlace(this.theKeyword)
          break
        case C.TYPE_SEARCH.FRIENDFAVS:
          this.doSearchFriendFavs(this.theKeyword)
          break
        case C.TYPE_SEARCH.PEOPLE:
          this.apiPeopleSearch(this.theKeyword)
          break
        case C.TYPE_SEARCH.PEOPLE:
          this.doEventsSearch(this.theKeyword)
          break
      }
    },
    cancelTheKeyWord() {
      this.theKeyword = "";
    },
    inviteFriend(id) {
      const self = this;
      this.$store.dispatch("friend/ACTION_ADD_FRIEND", id).then(res => {
        if (res.data) {
          this.apiPeopleSearch(this.theKeyword);
        }
      });
    },
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return null;
      }
    },
    handleCategory(index, item) {
      const self = this;
      self.activeCategory = item;
    },

    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params);
    },

    toEventDetail(id, form) {
      const params = { id, form };
      this.$store.dispatch("eventDetail/ACTION_GET", params);
    },

    doSearchAll(keyword) {
      if (keyword) {
        this.friendFavsSearch(keyword);
        this.placeSearch(keyword);
        this.apiPeopleSearch(keyword);
        this.eventsSearch(keyword);
      }
    },
    doSearchPlace(keyword) {
      // one could expand here to search other services besides Google...
      if (keyword) {
        this.placeSearch(keyword);
      }
    },
    doSearchFriendFavs(keyword) {
      // one could expand here to search other services besides Google...
      if (keyword) {
        this.friendFavsSearch(keyword);
      }
    },
    doEventsSearch(keyword) {
      if (keyword) {
        this.eventsSearch(keyword);
      }
    },
    friendFavsSearch(keyword) {
      const params = {
        q: keyword,
        cityId: this.city.id
      }
      this.$store.dispatch("search/ACTION_FRIENDFAVS_SEARCH", params);
    },
    placeSearch(keyword) {
      const params = {
        q: keyword,
        cityId: this.city.id
      }
      this.$store.dispatch("search/ACTION_PLACE_SEARCH", params);
    },
    apiPeopleSearch(keyword) {
      const params = {
        q: keyword,
        cityId: this.city.id
      }
      const self = this;
      this.$store.dispatch("search/ACTION_PEOPLE_SEARCH", params).then(res => {
        const resultPeople = _.pullAllBy(res, [{ id: self.me.id }], "id");
        self.people = resultPeople;
      });
    },
    eventsSearch(keyword) {
      const params = {
        q: keyword,
        cityId: this.city.id
      }
      this.$store.dispatch("search/ACTION_EVENTS_SEARCH", params);
    },
    launchAllSearch() {
      this.getLocation();
      this.searchKeyboardEnter = false;
      this.doSearchAll(this.theKeyword);
    },
    launchFriendFavsSearch() {
      this.searchKeyboardEnter = false;
      this.doSearchFriendFavs(this.theKeyword);
    },
    launchEventsSearch() {
      this.searchKeyboardEnter = false;
      this.doEventsSearch(this.theKeyword);
    },
    launchPlacesSearch() {
      this.getLocation();
      this.searchKeyboardEnter = false;
      this.doSearchPlace(this.theKeyword);
    },
    launchPeopleSearch() {
      this.searchKeyboardEnter = false;
      this.apiPeopleSearch(this.theKeyword);
    },
    getLocation: function() {
      const self = this
      fetchGeoLocation({
        onSuccess: (lat, lng) => {
          self.$store.dispatch('search/ACTION_SHOW_DISTANCE', true)
          self.currentLocation = { lat, lng }
        },
        onError: (error) => {
          self.$store.dispatch("search/ACTION_SHOW_DISTANCE", false)
        }
      })
    },
    toHome() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.HOME);
    },
    displayFriendNames(array) {
      if(!array) return null
      switch(array.length) {
        case 1:
          return `${array[0]} rated`
          break;
        case 2: 
          return `${array[0]} and ${array[1]} rated`
          break;
        case 3: 
          return `${array[0]}, ${array[1]} and 1 other rated`
          break;
        default:
          return `${array[0]}, ${array[1]} and ${array.length-2} others rated`
      }
    },
    comparePublicStatus(item, status) {
       return item?.rateStarts?.publicStatus == status
    },
    compareFriendStatus(item, status) {
       return item?.rateStarts?.friendStatus == status
    },
    publicScoreOrDefault(item) {
      return item && item.rateStarts && item.rateStarts.publicScore ? item.rateStarts.publicScore : 0
    },
    friendScoreOrDefault(item) {
      return item && item.rateStarts && item.rateStarts.friendScore ? item.rateStarts.friendScore : 0
    },
    publicStatsOrDefault(item) {
      return item && item.rateStarts && item.rateStarts.publicStats ? item.rateStarts.publicStats : 0
    },
    friendStatsOrDefault(item) {
      return item && item.rateStarts && item.rateStarts.friendStats ? item.rateStarts.friendStats : 0
    },
    showEventDate,
    showEventTime
  },
  beforeMount() {
    const self = this;
    const checkAddFriendsFromRecs = localStorage.getItem(
      "ADD_FRIENDS_FROM_RECS"
    );
    if (checkAddFriendsFromRecs) {
      self.activeCategory = "People";
    }
  }
};
</script>
