import Analytics from "analytics"
import segmentPlugin from "@analytics/segment"
import C from "../_helpers/constants"
import {mapGetters} from "vuex"

let plugins = [segmentPlugin({ writeKey: C.SEGMENT_KEY})]

// if(SEGMENT_KEY) {
//   plugins.push(segmentPlugin({ writeKey: SEGMENT_KEY }))
// }
// if (plugins.length == 0) {
//   console.log("Instantiating analyticsService without any plugins")
// }
const analyticsService = {
  analytics: Analytics({
    app: "favs_app",
    plugins,
  }),

  trackThing: (opName, info) => {
    analyticsService.analytics.track(opName, {
      ...info,
      userId: localStorage.getItem("meId"),
      cityId: localStorage.getItem("cityId")

    })
  }
}

export { analyticsService as default }