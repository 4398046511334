import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';

export default {
  namespaced: true,
  state: {
    data: [],
  },

  getters: {
    data: state => state.data,
  },

  actions: {
    ACTION_ADD_FRIEND({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              id
            },
            mutation: gqlMutation.inviteFriend
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_ADD_FRIEND', res)
            commit('notification/MUTATION_REFETCH_NOTIFICATION', null, {
              root: true
            })
            commit('me/MUTATION_REFETCH_ME', null, {
              root: true
            })
            resolve(res)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
    MUTATION_ADD_FRIEND(state, items) {
      state.data = items
    },

  }
}
