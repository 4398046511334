import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

function initialState() {
  return {
    data: [],
    place: [],
    city: {},
    user: null,

  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => state.data,
    city: state => state.city,
    user: state => state.user,
    place: (state, getters, rootState) => {
      return _.forEach(state.place, function (item, key) {
        item.isActiveBookmark = item.bookmarkStatus;
        item.isActiveLikeStatus = item.likeStatus;
        if (item.length > 0) {
          _.forEach(item, function (place, placeIdx) {
            place.isActiveBookmark = place.bookmarkStatus;
          });
        }
      });
    }

  },

  actions: {
    ACTION_RESET_STATE({
      commit
    }) {
      commit('MUTATIONS_RESET_STATE');
    },

    ACTION_GET_DETAIL_COLLECTION_PLACE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.collectionDetailPlace
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_DETAIL_COLLECTION_PLACE', res.data.collection.places);
            commit('MUTATION_GET_DETAIL_COLLECTION_PLACE_CITY', res.data.collection.city);
            commit('MUTATION_GET_DETAIL_COLLECTION_PLACE_USER', res.data.collection.user);
            resolve(res.data.collection);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_GET_DETAIL_COLLECTION_PLACE_SHARE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.collectionDetailPlaceShare
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_DETAIL_COLLECTION_PLACE', res.data.collection.places);
            commit('MUTATION_GET_DETAIL_COLLECTION_PLACE_CITY', res.data.collection.city);
            commit('MUTATION_GET_DETAIL_COLLECTION_PLACE_USER', res.data.collection.user);
            resolve(res.data.collection);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

  },

  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.data.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATIONS_RESET_STATE(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    MUTATION_GET_DETAIL_COLLECTION_PLACE(state, items) {
      let arr = state.place
      arr.push(...items)
      state.place = arr;
    },
    MUTATION_GET_DETAIL_COLLECTION_PLACE_CITY(state, data) {
      state.city = data
    },
    MUTATION_GET_DETAIL_COLLECTION_PLACE_USER(state, data) {
      state.user = data
    },
    // Bookmark
    MUTATION_BOOKMARK(state, {
      id
    }) {
      const cartItem = state.place.find(item => item.id === id)
      if (cartItem) {
        cartItem.bookmarkStatus = true
        cartItem.isActiveBookmark = true
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK(state, {
      id
    }) {
      const cartItem = state.place.find(item => item.id === id)
      if (cartItem) {
        cartItem.isActiveBookmark = false
        cartItem.bookmarkStatus = false
      }
    },

    MUTATION_UPDATE_RATE(state, rate) {
      const cartItem = state.place.find(item => item.id === rate.place);
      if (cartItem) {
        cartItem.rates[0].action = rate.data.updateRate.action
      }
      _.forEach(cartItem.tips, function (item, key) {
        if (item.author.id == rate.me) {
          item.rate.action = rate.data.updateRate.action
        }
      })
    }

  }
}
