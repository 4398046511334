<template>
  <img
    v-if="author.avatar != null"
    :src="`${handleAvatar(author.avatar.name)}`"
    @click="actionFetchAuthProfile(author.id)"
  >
  <span
    v-else
    class="text"
    @click="actionFetchAuthProfile(author.id)"
  >{{author.nickname.charAt(0)}}</span>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: {
    author: {
      required: false,
      type: Object
    }
  },

  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    handleAvatar(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, {
          width: 300,
          crop: "fit"
        });
      } else {
        return "";
      }
    }
  }
};
</script>
