import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query';
import _ from 'lodash';

function initialState() {
  return {
    data: [],
    events: [],
    city: {},
    user: {},

  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => state.data,
    city: state => state.city,
    user: state => state.user,
    events: (state, getters, rootState) => {
      return _.forEach(state.events, function (item, key) {
        item.isActiveBookmark = item.bookmarkStatus;
      });
    }

  },

  actions: {
    ACTION_RESET_STATE({
      commit
    }) {
      commit('MUTATIONS_RESET_STATE');
    },

    ACTION_GET_DETAIL_COLLECTION_EVENT({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.collectionDetailEvent
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_DETAIL_COLLECTION_EVENT', res.data.collection.events);
            commit('MUTATION_GET_DETAIL_COLLECTION_EVENT_CITY', res.data.collection.city);
            commit('MUTATION_GET_DETAIL_COLLECTION_EVENT_USER', res.data.collection.user);
            resolve(res.data.collection);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_GET_DETAIL_COLLECTION_EVENT_SHARE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            variables: params,
            mutation: gqlQuery.collectionDetailEventShare
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_DETAIL_COLLECTION_EVENT', res.data.collection.events);
            commit('MUTATION_GET_DETAIL_COLLECTION_EVENT_CITY', res.data.collection.city);
            commit('MUTATION_GET_DETAIL_COLLECTION_EVENT_USER', res.data.collection.user);
            resolve(res.data.collection);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

  },

  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.data.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },

    MUTATIONS_RESET_STATE(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    MUTATION_GET_DETAIL_COLLECTION_EVENT(state, items) {
      let arr = state.events
      arr.push(...items)
      state.events = arr;
    },
    MUTATION_GET_DETAIL_COLLECTION_EVENT_CITY(state, data) {
      state.city = data
    },
    MUTATION_GET_DETAIL_COLLECTION_EVENT_USER(state, data) {
      state.user = data
    }
  }
}
