import gql from "graphql-tag";

const collectionDetailPlaceShare = gql`
  query collection(
    $type: COLLECTION_TYPE!
    $cityId: ID!
    $userId: ID
    $filterType: COLLECTION_FILTER
    $page: Int!
    $IDFriend: ID
  ) {
    collection(
      type: $type
      cityId: $cityId
      userId: $userId
      filterType: $filterType
      page: $page
    ) {
      city {
        id
        name
        country {
          name
        }
      }
      user {
        id
        nickname
        avatar {
          url
          name
        }
      }
      places {
        id
        placeId
        name
        photos {
          name
          url
        }
        rates(where: { author: { id: $IDFriend } }, last: 1) {
          action
          author {
            id
            nickname
            avatar {
              name
              url
            }
          }
        }
        # rateStarts {
        #   publicStatus
        #   publicScore
        #   publicStats
        #   friendStatus
        #   friendScore
        #   friendStats
        # }
        categories {
          id
          name
        }
        city {
          name
        }
        tips(where: { author: { id: $IDFriend } }) {
          id
          content
          author {
            id
            nickname
            avatar {
              url
              name
            }
          }
          rate {
            id
            action
          }
          place {
            # rateStatus {
            #   id
            #   action
            # }
            rates {
              id
              action
            }
          }
          createdAt
        }
      }
    }
  }
`;

export { collectionDetailPlaceShare };
