import gql from "graphql-tag";

const createComment = gql `
mutation createComment($data: CommentInput!) {
  createComment(data: $data) {
    id
    content
    author {
      id
      nickname
      avatar {
        url
        name
      }
    }
    createdAt
  }
}
`

export  {
  createComment

};
