import gql from "graphql-tag";

const deleteTip = gql `
  mutation deleteTip($id: ID!) {
    deleteTip(id: $id) 
  }
`

export  {
  deleteTip
};
