import gql from "graphql-tag";
import { ImageUploader } from "vue-image-upload-resize";

const events = gql`
  query events($page: Int!, $where: EventFilterInput!) {
    events(page: $page, where: $where) {
      id
      name
      description
      cover
      createdAt
      eventDate
      startTime
      endTime
      bookmarkStatus
      place {
        id
        name
        photos {
          name
          url
        }
      }
    }
  }
`;
export { events };
