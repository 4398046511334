<template>
  <span
    v-if="isShow"
    :class="`${isActive ? '' : 'active'} button-see-more`"
    @click="()=>toggleTip()"
  >{{ isActive ? `See less` : `See more` }}</span>
</template>
<script>
export default {
  props: {
    item: {
      required: false,
      type: Object
    },
    isShow: {
      required: false,
      type: Boolean,
      default: false
    },
    isActive: {
      required: false,
      type: Boolean
    },
    toggleTip: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

