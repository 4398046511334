<template>
  <f7-block class="post">
    <user-info-component
      :onAuthProfile="()=> onAuthProfile()"
      :getAvatar="getAvatar"
      :nickname="nickname"
      :actionsOpen="actionsOpen"
      v-if="isShowAvatar"
    />
    <title-article-component
      @click.native="track"
      :title="title"
      class="line-clamp-3 no-margin-left block-title-medium"
      :externalUrl="externalUrl"
    />
    <div v-if="photos.length > 0 || externalUrl && photos.length > 0">
      <div class="no-margin wrapper-card">
        <div class="wrapper-content-card">
          <div
            :class="`${photos.length <= 1 ? 'grid1Col' : (photos.length <= 2 ? 'grid2Col' : 'grid3Col')} wrapper-photo`"
          >
            <iframe v-if="externalSource == 'YOUTUBE'" width="100%" height="226" :src="externalUrl"></iframe>
            <article-thumb-component
              v-else
              :onPhotoBrowser="() => onPhotoBrowser()"
              :photos="photos"
              :onGetUrlImg="(photo, idx)=>onGetUrlImg(photo, idx)"
              :externalUrl="externalUrl"
            />
          </div>
          <span v-if="photos.length > 3" class="numberPhotos">+{{photos.length - 3}}</span>

          <title-city-place-component
            class="caption-text"
            :city="city"
            :places="places"
            :cityName="cityName"
          />
        </div>

        <f7-card-footer>
          <heart-component
            :type="type"
            :item="item"
            :isActiveLikeStatus="item.likeStatus"
            :id="id"
            :pageType="pageType"
          />

          <comment-component :id="id" :pageType="pageType"/>

          <bookmark-component
            :type="type"
            :item="item"
            :id="id"
            :pageType="pageType"
            :isActiveBookmarkStatus="item.bookmarkStatus"
          />
        </f7-card-footer>
      </div>

      <!-- Show content of the link -->
      <text-article-component
        v-if="isMagazineShow && siteDescription && externalUrl && content"
        :content="content"
        :onToPostDetail="()=> onToPostDetail()"
        :className="`line-clamp-3 content`"
        :externalUrl="externalUrl"
        :isShow="false"
        :isDetail="isDetail"
      />
      <span
        class="line-between"
        v-show="isMagazineShow && siteDescription && externalUrl && content"
      ></span>
      <!-- End show content of the link -->

      <text-article-component
        v-if="!siteDescription && isMagazineShow"
        :content="content"
        :onToPostDetail="()=> onToPostDetail()"
        :className="`line-clamp-3 content`"
        :externalUrl="externalUrl"
        :isDetail="isDetail"
      />

      <site-description-component
        v-if="siteDescription && isMagazineShow"
        :siteDescription="siteDescription"
        :siteName="siteName"
        :onToPostDetail="()=> onToPostDetail()"
        :className="`line-clamp-3 content`"
        :externalUrl="externalUrl"
        :isDetail="isDetail"
      />
      <!-- <external-url-component :showExternalUrl="showExternalUrl" :externalUrl="externalUrl"/> -->
    </div>

    <div v-else>
      <div v-if="photos.length == 0 && title && content == '' || externalUrl && photos.length == 0">
        <title-city-place-component
          class="caption-text-city"
          :city="city"
          :places="places"
          :cityName="cityName"
        />

        <div class="wrapper-card no-photo no-video no-content">
          <f7-card-footer>
            <heart-component
              :type="type"
              :item="item"
              :id="id"
              :isActiveLikeStatus="item.likeStatus"
              :pageType="pageType"
            />

            <comment-component :id="id" :pageType="pageType"/>

            <bookmark-component
              :type="type"
              :item="item"
              :id="id"
              :pageType="pageType"
              :isActiveBookmarkStatus="item.bookmarkStatus"
            />
          </f7-card-footer>
        </div>
      </div>

      <div class="wrapper-card no-photo no-video" v-else>
        <div class="wrapper-content-card">
          <text-article-component
            :content="content"
            :onToPostDetail="()=> onToPostDetail()"
            :className="`line-clamp-3 content`"
            :isDetail="isDetail"
          />

          <span class="line-between" v-if="siteDescription && externalUrl && content"></span>

          <site-description-component
            v-if="siteDescription"
            :siteDescription="siteDescription"
            :siteName="siteName"
            :onToPostDetail="()=> onToPostDetail()"
            :className="`line-clamp-3 content`"
            :isDetail="isDetail"
          />
          <!-- <external-url-component :showExternalUrl="showExternalUrl" :externalUrl="externalUrl"/> -->
          <title-city-place-component
            class="caption-text-city"
            :city="city"
            :places="places"
            :cityName="cityName"
          />
        </div>
        <f7-card-footer>
          <heart-component
            :type="type"
            :item="item"
            :id="id"
            :isActiveLikeStatus="item.likeStatus"
            :pageType="pageType"
          />

          <comment-component :id="id" :pageType="pageType"/>

          <bookmark-component
            :type="type"
            :item="item"
            :id="id"
            :pageType="pageType"
            :isActiveBookmarkStatus="item.bookmarkStatus"
          />
        </f7-card-footer>
      </div>
      <!-- <div :onPhotoBrowser="() => onPhotoBrowser()" v-show="false"/> -->
    </div>

    <stats-comment-component
      class="like-comment like-comment--magazine"
      :statsLike="statsLike"
      :statsComment="statsComment"
      :id="id"
      :pageType="pageType"
    />

    <created-at-component v-if="createdAt" :createdAt="createdAt"/>
  </f7-block>
</template>
<script>
import analyticsService from "../utils/analyticsService"
import UserInfoComponent from "../components/UserInfo";
import TitleArticleComponent from "../components/Title";
import TitleCityPlaceComponent from "../components/TitleCityPlace";
import StatsCommentComponent from "../components/StatsComment";
import CreatedAtComponent from "../components/CreatedAt";
import ArticleThumbComponent from "../components/ArticleThumb";
import SiteDescriptionComponent from "../components/SiteDescription";
import TextArticleComponent from "../components/Text";
import BookmarkComponent from "../components/Bookmark";
import HeartComponent from "../components/Heart";
import CommentComponent from "../components/Comment";
import ExternalUrlComponent from "../components/ExternalUrl";

export default {
  components: {
    UserInfoComponent,
    TitleArticleComponent,
    TitleCityPlaceComponent,
    StatsCommentComponent,
    CreatedAtComponent,
    ArticleThumbComponent,
    SiteDescriptionComponent,
    TextArticleComponent,
    BookmarkComponent,
    HeartComponent,
    CommentComponent,
    ExternalUrlComponent
  },
  props: {
    isActiveBookmarkStatus: {
      required: false,
      type: Boolean,
      default: false
    },
    pageType: {
      required: false,
      type: String
    },
    item: {
      required: false,
      type: Object
    },
    isDetail: {
      required: false,
      type: Boolean,
      default: false
    },
    isShowAvatar: {
      required: false,
      type: Boolean,
      default: true
    },
    isMagazineShow: {
      required: false,
      type: Boolean,
      default: true
    },
    id: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    nickname: {
      type: String,
      default: null
    },
    getAvatar: {
      required: false,
      type: String
    },
    onGetUrlImg: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    actionsOpen: {
      required: false,
      type: String
    },
    title: {
      required: false,
      type: String
    },
    content: {
      required: false,
      type: String
    },
    siteDescription: {
      required: false,
      type: String
    },
    showExternalUrl: {
      required: false,
      type: Boolean
    },
    externalUrl: {
      required: false,
      type: String,
      default: null
    },
    siteName: {
      required: false,
      type: String
    },
    onToPostDetail: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    photos: {
      required: false,
      type: Array
    },
    externalUrl: {
      required: false,
      type: String
    },
    externalSource: {
      required: false,
      type: String
    },
    cityName: {
      required: false,
      type: String
    },
    city: {
      required: false,
      type: Object
    },
    places: {
      required: false,
      type: Array
    },
    createdAt: {
      required: false,
      type: String
    },
    statsLike: {
      required: false,
      type: Number
    },
    statsComment: {
      required: false,
      type: Number
    },
    onPhotoBrowser: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onAuthProfile: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  mounted() {
    this.$f7.lazy.create("img.lazy");
  },

  methods: {
    onHandleDefault() {
      return null;
    },
    track() {
      const info = {
        authorNickname: this.$props.item.author.nickname,
        authorId: this.$props.item.author.id,
        title: this.$props.title,
        url: this.$props.externalUrl
      }
      analyticsService.trackThing("articleTrack", info);
    }
  }
};
</script>

