import gql from "../../utils/apollo-client";
import gqlMutation from "../../_gql/mutation/index.js";
import gqlQuery from "../../_gql/query/index.js";
import _ from "lodash";
import C from "../../_helpers/constants";
export default {
  namespaced: true,
  state: {
    data: {},
    id: null,
    rateStatus: false,
    tip: [],
    form: null
  },

  getters: {
    // data: state => state.data,
    data: (state, getters, rootState) => {
      _.forEach(state.data, function (item, key) {
        state.data.place.isActiveBookmark = state.data.place.bookmarkStatus; // Add field active isActiveBookmark
      });
      return state.data.place ? state.data.place : {};
    },
    id: state => state.id,
    rateStatus: state => state.rateStatus,
    tip: state => state.tip,
    form: state => state.form
  },

  actions: {
    ACTION_GET({
      commit
    }, params) {
      const id = params.id;
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              id: id
            },
            mutation: gqlQuery.place
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_SET_FORM", params.form);
            commit("MUTATION_GET", res.data);
            commit("MUTATION_TIP", res.data.place.tips);
            commit("MUTATION_STATUS_RATE", res.data.place.rateStatus);
            commit("MUTATIONS_GET_ID_PALCE", res.data.place);
            resolve(res.data);
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.PLACE_PAGE,
              params: {
                id: id
              }
            });
            commit('currentUrl/MUTATIONS_GET_CURRENT_URL', C.ROUTES_NAME.PLACE_PAGE, {
              root: true
            })
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_GET_SHARE({
      commit
    }, params) {
      const id = params.id;
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              id
            },
            mutation: gqlQuery.placeFromShare
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_SET_FORM", params.form);
            commit("MUTATION_GET", res.data);
            commit("MUTATION_TIP", res.data.place.tips);
            commit("MUTATION_STATUS_RATE", res.data.place.rateStatus);
            commit("MUTATIONS_GET_ID_PALCE", res.data.place);
            resolve(res.data);
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.PLACE_PAGE,
              params: {
                id: id
              }
            });
            commit('currentUrl/MUTATIONS_GET_CURRENT_URL', C.ROUTES_NAME.PLACE_PAGE, {
              root: true
            })
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },


    ACTION_POST_TIP({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.createTip
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_TIP", res.data.createTip.place.tips);
            resolve(res.data);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_PUT_TIP({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.updateTip
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_TIP", res.data.updateTip.place.tips);
            resolve(res.data);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },

    ACTION_DELETE_TIP({
      commit
    }, { id }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: { id },
            mutation: gqlMutation.deleteTip
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_DELETE_TIP", id)
            commit('recs/MUTATION_UPDATE_RATE', null, { root: true })
            resolve(res)
          })
          .catch(error => {
            commit("STATUS_ERROR", error)
            resolve(error)
          });
      });
    },

    ACTION_ADD_NEW_PHOTOS({
      commit
    }, photos) {
      commit("MUTATION_ADD_NEW_PHOTOS", photos)
    },

    ACTION_POST_RATE({
      commit
    }, params) {
      const data = {
        id: params.id,
        action: params.action,
      }
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.createRate
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_STATUS_RATE", true);
            resolve(res.data);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            commit("MUTATION_STATUS_RATE", true);
            resolve(error);
          });
      });
    },
    ACTION_PUT_RATE({
      commit
    }, params) {
      const data = {
        id: params.id,
        action: params.action,
      }
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.updateRate
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_STATUS_RATE", true);
            commit("MUTATION_UPDATE_RATE", res.data);
            switch (params.typePage) {
              case `${C.PAGE_CONTENT_TYPE.RECS}`:
                commit('recs/MUTATION_UPDATE_RATE', null, { root: true })
                break;
              case `${C.PAGE_CONTENT_TYPE.COLLECTION_PLACES}`:
                commit('collectionDetailPlace/MUTATION_UPDATE_RATE', {
                  data: res.data,
                  me: params.me,
                  place: params.idPlace
                }, {
                  root: true
                })
                break;
              case `${C.PAGE_CONTENT_TYPE.MAGAZINE_PLACE}`:
                commit('magazine/MUTATION_UPDATE_RATE', {
                  data: res.data,
                  me: params.me,
                  place: params.idPlace
                }, {
                  root: true
                })
                break;
            }
            resolve(res.data);
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            commit("MUTATION_STATUS_RATE", false);
            resolve(error);
          });
      });
    },

    ACTION_DELETE_RATE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: params,
            mutation: gqlMutation.deleteRate
          })

          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_STATUS_RATE", true)
            commit("MUTATION_DELETE_RATE", params)
            commit('recs/MUTATION_UPDATE_RATE', null, { root: true })
            resolve(res)
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            commit("MUTATION_STATUS_RATE", false)
            resolve(error);
          });
      });
    }

  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
    },
    STATUS_SUCCESS(state) {
      state.status = "success";
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", {
        error
      });
    },
    MUTATION_SET_FORM(state, item) {
      state.form = item;
    },
    MUTATION_GET(state, details) {
      state.data = details;
    },

    MUTATION_STATUS_RATE(state, item) {
      state.rateStatus = item ? true : false
    },

    MUTATION_TIP(state, item) {
      state.tip = item;
    },

    MUTATION_DELETE_TIP(state, id) {
      state.tip = state.tip.filter(t => t.id !== id)
    },

    MUTATIONS_GET_ID_PALCE(state, data) {
      state.id = data.id;
    },

    // Bookmark
    MUTATION_BOOKMARK(state, {
      id
    }) {
      if (state.data && state.data.place && state.data.place.id === id) {
        state.data.place.bookmarkStatus = true;
        state.data.place.isActiveBookmark = true;
      }
    },

    // UnBookmark
    MUTATION_UNBOOKMARK(state, {
      id
    }) {
      if (state.data && state.data.place && state.data.place.id === id) {
        state.data.place.bookmarkStatus = false;
        state.data.place.isActiveBookmark = false;
      }
    },

    MUTATION_UPDATE_RATE(state, data) {
      return _.forEach(state.tip, function (item, key) {
        if (item.rate.id === data.updateRate.id) {
          item.rate.action = data.updateRate.action
        }
      })
    },

    MUTATION_DELETE_RATE(state, { id }) {
      const tips = state.tip
      state.tip = tips.filter(({ rate }) => rate.id !== id)
    },

    MUTATION_ADD_NEW_PHOTOS(state, photos) {
      state.data.place.photos = photos
    }
  }
};
