<template>
  <f7-card :no-shadow="true" :no-border="true">
    <f7-card-content :padding="false">
      <div class="topic-card" :style="{ backgroundColor: `${topic.theme_color}` }">
        <f7-card-header class="topic-card-header">
          <h2 class="topic-name">{{ topic.title }}</h2>
          <p class="topic-description">{{ topic.description }}</p>
        </f7-card-header>
        <f7-link @click="handleLikeEvent(topic)" class="heart-icon" icon-ios="f7:heart" raised v-if="!topic.isLike"></f7-link>
        <f7-link @click="handleLikeEvent(topic)" class="heart-icon" icon-ios="f7:heart_fill" raised v-else></f7-link>
        <f7-link class="ellipsis-icon" icon-ios="f7:more_fill" raised ></f7-link>
      </div>
    </f7-card-content>
  </f7-card>
</template>

<script>
  import C from "../_helpers/constants";
  export default {
    props: {
      topic: {
        required: true,
        type: Object
      }
    },
    methods: {
      handleLikeEvent(topic) {
        const params = {
          id: topic.id,
          type: 'TOPIC',
          status: topic.isLike ? C.LIKE_STATUS.UNLIKE : C.LIKE_STATUS.LIKE
        }

        const pageType = {
          type: C.PAGE_CONTENT_TYPE.TOPIC
        }

        this.$store
          .dispatch("like/ACTION_LIKE", { params, pageType })
      }
    }
  }
</script>
