<template>
  <div class="block-article-list" v-if="item">
    <div class="block-article">
      <article-view
        :onAuthProfile="()=>actionFetchAuthProfile(item.author.id)"
        :nickname="item.author.nickname"
        :getAvatar="getAvatarThumb(item.author)"
        :actionsOpen="`.actions-article-${item.id}`"
        :title="item.title | truncate(90, '...')"
        :onToPostDetail="()=>toPostDetail(item.id, constants.PAGE_CONTENT_TYPE.FEED)"
        :photos="item.photos"
        :onGetUrlImg="(photo, idx) => photo.url"
        :cityName="this.city.name"
        :places="item.places"
        :city="this.city"
        :siteName="item.siteName"
        :siteDescription="item.siteDescription"
        :content="item.content"
        :statsLike="item.statsLike"
        :statsComment="item.statsComment"
        :externalUrl="item.externalUrl"
        :onPhotoBrowser="()=>onPhotoBrowser(0)"
        :type="constants.BOOKMARK_CONTENT_TYPE.POST"
        :item="item"
        :id="item.id"
        :showExternalUrl="false"
        :externalSource="item.externalSource"
        :pageType="constants.PAGE_CONTENT_TYPE.FEED"
      />

      <f7-photo-browser theme="dark" :photos="photoBrowser(item.photos)" ref="photoBrowser"></f7-photo-browser>
      <actions-article-component
        :actionsOpenName="`actions-article-${item.id}`"
        :actionsId="item && item.id ? item.id : null"
        :onCopySuccess="onCopySuccess"
        :onCopyError="onCopyError"
      />

      <f7-block
        :class="`${index == 2 ? 'border-bottom-none' : ''} vendor-list wrap-places`"
        no-padding
        v-for="(place, index) in item.places.slice(0,3)"
        :key="index"
      >
        <f7-row>
          <f7-col width="40">
            <f7-card>
              <place-image-component :url="place.photos.length > 0 ? place.photos[0].url : ''"/>
              <div class="wrap-icon-bookmark-share">
                <bookmark-component
                  class="icon-bookmark"
                  :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                  :item="place"
                  :id="place.id"
                  :isActiveBookmarkStatus="place.bookmarkStatus"
                  :pageType="constants.PAGE_CONTENT_TYPE.FEED_POST_PLACE"
                />
                <share-component
                  :actionsOpenId="`actions-article-place-${place.id}`"
                  :type="constants.TYPE_SHARE.PLACE"
                  :typeId="place.id"
                />
              </div>
            </f7-card>
          </f7-col>
          <f7-col width="60">
            <div class="vendor-title">{{index+1}}. {{ place.name }}</div>

            <public-friends-rating-component :item="place" :constants="constants"/>
          </f7-col>
          <div
            class="wrapper-place"
            v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.FEED_POST_PLACE)"
          ></div>
        </f7-row>
      </f7-block>

      <transition-expand-component>
        <div v-if="item.isActiveTip" class="wrapper-expanded">
          <f7-block
            class="vendor-list wrap-places"
            no-padding
            v-for="(place, index) in item.places.slice(3)"
            :key="index"
          >
            <f7-row>
              <f7-col width="40">
                <f7-card>
                  <place-image-component
                    :url="place.photos.length > 0 ? place.photos[0].url : ''"
                  />
                  <div class="wrap-icon-bookmark-share">
                    <bookmark-component
                      class="icon-bookmark"
                      :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                      :item="place"
                      :id="place.id"
                      :isActiveBookmarkStatus="place.bookmarkStatus"
                      :pageType="constants.PAGE_CONTENT_TYPE.FEED_POST_PLACE"
                    />
                    <share-component
                      :actionsOpenId="`actions-article-place-${place.id}`"
                      :type="constants.TYPE_SHARE.PLACE"
                      :typeId="place.id"
                    />
                  </div>
                </f7-card>
              </f7-col>
              <f7-col width="60">
                <div class="vendor-title">{{index+4}}. {{ place.name }}</div>
                <public-friends-rating-component :item="place" :constants="constants"/>
              </f7-col>
              <div
                class="wrapper-place"
                v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.FEED_POST_PLACE)"
              ></div>
            </f7-row>
          </f7-block>
        </div>
      </transition-expand-component>
      <see-more-component
        :item="item"
        :isShow="item.places && item.places.length > 3"
        :isActive="item.isActiveTip"
        :toggleTip="()=>toggleTip(key)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import { handleSharePost, handleSharePlace } from "../utils/share";
import { getUrlImg, getUrlImgArticle } from "../utils/cloundinary-image";
import UserInfoComponent from "../components/UserInfo";
import PlaceTitleComponent from "../components/PlaceTitle";
import StatsCommentComponent from "../components/StatsComment";
import CreatedAtComponent from "../components/CreatedAt";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import SeeMoreComponent from "../components/SeeMore";
import TransitionExpandComponent from "../components/TransitionExpand";
import ActionsArticleComponent from "../components/ActionsArticle";
import ArticleView from "../views/ArticleView";
import PublicFriendsRatingComponent from "../components/PublicFriendsRating";
import PlaceImageComponent from "../components/PlaceImage";
export default {
  components: {
    UserInfoComponent,
    PlaceTitleComponent,
    StatsCommentComponent,
    CreatedAtComponent,
    BookmarkComponent,
    ShareComponent,
    SeeMoreComponent,
    TransitionExpandComponent,
    ArticleView,
    ActionsArticleComponent,
    PublicFriendsRatingComponent,
    PlaceImageComponent
  },
  data() {
    return {
      constants: C,
    };
  },
  computed: {
    ...mapGetters("auth", {
      data: "data"
    }),
    ...mapGetters("cities", {
      city: "currentCity"
    }),
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    handleImg(name) {
      return getUrlImg(name);
    },
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return null;
      }
    },
    handleImgArticle(photo, index) {
      return getUrlImgArticle(photo, index);
    },
    toPostDetail(id, pageType) {
      const params = {
        id: id,
        pageType: pageType
      };
      this.$store.dispatch("detailPost/ACTION_GET", params);
    },
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params);
    },
    photoBrowserSummary(photo) {
      return photo;
    },
    // Get avatar thumb
    // NOTE: this code is coupled with cloudinary and abuses the 'name' attribute.
    // TODO: make BE provide something like author.thumbUrl instead
    getAvatarThumb(author) {
      const name = author.avatar?.name
      if (name) {
        return this.$cl.url(name, { width: 300, crop: "fit" });
      } else {
        return "";
      }
    },
    onPhotoBrowser(key) {
      this.$refs.photoBrowser[key].open();
    },
    photoBrowser(photo) {
      return photo;
    },
    onCopySuccess(e) {
      return copySuccess(e, this);
    },
    onCopyError(e) {
      return copyError(e);
    },
  }
};
</script>

