import gql from "graphql-tag";

const magazine = gql`
  query magazine(
    $cityId: ID!
    $where: MagazineFilter!
    $page: Int
    $IDFriend: ID
  ) {
    magazine(cityId: $cityId, where: $where, page: $page) {
      profile {
        id
        nickname
        email
        avatar {
          name
          url
        }
      }
      city {
        id
        name
        statsPlace
        statsPost
        cover {
          name
          url
        }
      }
      magazines {
        timelines {
          id
          createdAt
          relationship {
            __typename
            ... on Bookmark {
              post {
                id
                title
                content
                externalUrl
                externalSource
                siteName
                siteDescription
                city {
                  name
                  country {
                    name
                  }
                }
                author {
                  id
                  nickname
                  avatar {
                    url
                    name
                  }
                }
                photos {
                  name
                  url
                }
                statsLike
                statsComment
                likeStatus
                bookmarkStatus
                createdAt
                places {
                  id
                  placeId
                  name
                  photos {
                    name
                    url
                  }
                  rateStarts {
                    publicStatus
                    publicScore
                    publicStats
                    friendStatus
                    friendScore
                    friendStats
                  }
                  categories {
                    id
                    name
                  }
                  city {
                    name
                  }
                  bookmarkStatus
                }
              }
              place {
                id
                name
                rateStarts {
                  publicStatus
                  publicScore
                  publicStats
                  friendStatus
                  friendScore
                  friendStats
                }
                photos {
                  name
                  url
                }
                bookmarkStatus
              }
              event {
                id
                name
                description
                cover
                createdAt
                bookmarkStatus
                place {
                  id
                  name
                  photos {
                    name
                    url
                  }
                }
              }
            }

            ... on Post {
              id
              title
              content
              externalUrl
              externalSource
              siteName
              siteDescription
              city {
                name
                country {
                  name
                }
              }
              author {
                id
                nickname
                avatar {
                  url
                  name
                }
              }
              photos {
                name
                url
              }
              statsLike
              statsComment
              likeStatus
              bookmarkStatus
              createdAt
              places {
                id
                placeId
                name
                photos {
                  name
                  url
                }
                rateStarts {
                  publicStatus
                  publicScore
                  publicStats
                  friendStatus
                  friendScore
                  friendStats
                }
                categories {
                  id
                  name
                }
                city {
                  name
                }
                bookmarkStatus
              }
            }
            ... on Rate {
              action
              place {
                id
                name
                rateStarts {
                  publicStatus
                  publicScore
                  publicStats
                  friendStatus
                  friendScore
                  friendStats
                }
                photos {
                  name
                  url
                }
                bookmarkStatus
              }
            }

            ... on Rate {
              action
              place {
                name
                rateStarts {
                  publicStatus
                  publicScore
                  publicStats
                  friendStatus
                  friendScore
                  friendStats
                }
                photos {
                  name
                  url
                }
                bookmarkStatus
              }
            }
            ... on Tip {
              tipId: id
              tipContent: content
              tipLikeStatus: likeStatus
              tipPlace: place {
                id
                name
                rateStarts {
                  publicStatus
                  publicScore
                  publicStats
                  friendStatus
                  friendScore
                  friendStats
                }
                rateStatus {
                  id
                  action
                }
                photos {
                  name
                  url
                }
                bookmarkStatus
              }
              tipComments: comments {
                id
                content
                author {
                  id
                  nickname
                  avatar {
                    url
                    name
                  }
                }
                createdAt
              }
            }
          }
        }
        places {
          id
          name
          address
          rating
          placeId
          priceLever
          rateStarts {
            publicStatus
            publicScore
            publicStats
            friendStatus
            friendScore
            friendStats
          }
          categories {
            id
            name
          }
          rates(where: { author: { id: $IDFriend } }, last: 1) {
            action
            author {
              id
              nickname
              avatar {
                name
                url
              }
            }
          }
          tips(where: { author: { id: $IDFriend } }) {
            id
            content
            author {
              id
              nickname
              avatar {
                url
                name
              }
            }
            place {
              rates {
                id
                action
              }
              rateStatus {
                id
                action
              }
            }
            rate {
              id
              action
            }
            createdAt
          }
          rateStatus {
            id
            action
          }
          photos {
            name
            url
          }
          distance
          bookmarkStatus
        }
      }
    }
  }
`;

export { magazine };
