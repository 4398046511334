<template>
  <f7-page id="page-place-detail" name="place-detail">
    <header-navbar-action-component
      :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
      :item="places"
      :id="places.id"
      :actionType="constants.ACTION_TYPE.BOOKMARK"
      :isActiveBookmarkStatus="places.isActiveBookmark"
      :pageType="form"
      :userId="me.id"
    />

    <p class="head-background">
      <img
        :src="places && places.photos && places.photos[0].url"
        v-if="places && places.photos && places.photos.length > 0"
      >
      <img
        :src="places && places.coverUrl"
        v-else-if="places && ( places.coverUrl != '' )"
      >
      <img :src="headingImg" v-else>
    </p>

    <f7-block strong inset class="block-content">
      <header-place-detail-component :items="places"/>

      <rating-add-tip-view/>

      <div class="address-block">
        <f7-block-title class="bearer">ADDRESS</f7-block-title>
        <address-component :item="places.address"/>

        <gmap-map
          :center="{lat: places.location.coordinates[1], lng: places.location.coordinates[0] }"
          :zoom="15"
          style="width: 100%; height: 300px; margin-top: 30px;"
        >
          <gmap-marker
            :position="{lat: places.location.coordinates[1], lng: places.location.coordinates[0] }"
          />
        </gmap-map>

        <hours-open-component/>
      </div>

      <sharing-component :website="places.website" :fb_link="places.fb_link" :phone="places.phone"/>

      <tips-view/>

      <div class="media-block">
        <f7-block-title class="bearer">Photos</f7-block-title>
        <f7-swiper :params="{speed:500, slidesPerView: 2}" class="place">
          <f7-swiper-slide v-for="(place, index) in places.photos" :key="index">
            <f7-card :padding="false">
              <place-image-component
                :url="place.url"
                :onHandle="onPhotoBrowser"
              />
            </f7-card>
          </f7-swiper-slide>
        </f7-swiper>
        <f7-photo-browser theme="dark" :photos="photoBrowser(places.photos)" ref="photoBrowser"></f7-photo-browser>
      </div>
      <div class="media-block">
        <f7-row class="place-photos">
          <f7-block-title class="bearer">Add Photos</f7-block-title>
          <f7-button raised @click="uploadPlacePhotos" :style="{width: '100px', color: '#d70000'}">Upload</f7-button>
        </f7-row>
        <f7-card-header class="place-add-photos" style="text-align:center">
          <select-a-photo-component
              title=""
              :items="imagesUpload"
              :fileSelected="fileSelected"
              :handleRemoveImage="(key) => handleRemoveImage(key)"
          />
        </f7-card-header>
      </div>
      <div class="media-block">
        <f7-block-title class="bearer">Videos</f7-block-title>
        <f7-swiper :params="{speed:500, slidesPerView: 2}" class="place">
          <f7-swiper-slide v-for="(place, index) in trending" :key="index">
            <f7-card>
              <f7-card-header
                :style="`background: url(https://images.unsplash.com/photo-1556742393-d75f468bfcb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80)`"
              >
                <f7-block class="user-info">
                  <img src="https://randomuser.me/api/portraits/women/18.jpg">
                  <span>Jenny</span>
                </f7-block>
                <i class="f7-icons play-btn">play_round_fill</i>
              </f7-card-header>
            </f7-card>
          </f7-swiper-slide>
        </f7-swiper>
      </div>
    </f7-block>
    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import Toolbar from "../components/Toolbar";
import HeaderNavbarActionComponent from "../components/HeaderNavbarAction";
import HeaderPlaceDetailComponent from "../components/HeaderPlaceDetail";
import PublicFriendsRatingComponent from "../components/PublicFriendsRating";
import RatingComponent from "../components/Rating";
import AddressComponent from "../components/Address";
import HoursOpenComponent from "../components/HoursOpen";
import SharingComponent from "../components/Sharing";
import PlaceImageComponent from "../components/PlaceImage";
import BookmarkComponent from "../components/Bookmark";
import StatsComment from "../components/StatsComment";
import CreatedAtComment from "../components/CreatedAt";
import SelectAPhotoComponent from "../components/SelectAPhoto";
import { UploadImages } from "../utils/cloundinary-upload"; 

import RatingAddTipView from "../views/RatingAddTip";
import TipsView from "../views/Tips";

export default {
  components: {
    Toolbar,
    HeaderNavbarActionComponent,
    HeaderPlaceDetailComponent,
    PublicFriendsRatingComponent,
    RatingComponent,
    AddressComponent,
    HoursOpenComponent,
    SharingComponent,
    PlaceImageComponent,
    BookmarkComponent,
    StatsComment,
    CreatedAtComment,
    SelectAPhotoComponent,
    RatingAddTipView,
    TipsView
  },
  name: "detail",
  data() {
    return {
      constants: C,
      headingImg:
        "https://i1.wp.com/www.kesaksian.org/wp-content/uploads/2018/06/6809e5b65772abb5a6305a36b5e9c0c82ccc20b5.jpeg?fit=1000%2C669",
      trending: [ ], // TODO: remove trending section below with videos
      imagesUpload: [],
      addPhotosParams: {  }
    };
  },
  watch: {
    bookmarkStatus(news) {
      if (news) {
        this.showToastBottom();
      }
    },
  },
  computed: {
    ...mapGetters("placeDetail", {
      places: "data",
      form: "form"
    }),
    ...mapGetters("bookMark", {
      bookmarkStatus: "bookmark"
    }),
    ...mapGetters("auth", {
      me: "me"
    })
  },
  mounted() {
    this.$store.dispatch(
      "currentUrl/ACTION_GET_CURRENT_URL",
      C.ROUTES_NAME.PLACE_PAGE
    );
  },

  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),
    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Saved to plan",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    },
    photoBrowser(photo) {
      return photo;
    },
    onPhotoBrowser() {
      this.$refs.photoBrowser.open();
    },
    fileSelected(e) {
      const self = this;
      if (self.imagesUpload.length < 10) {
        if (e.target.files.length > 10) {
          self.$f7.dialog.alert("Sorry, you’ve exceeded our max of 10 photos.");
        } else {
          const selectedFiles = Array.prototype.slice.call(e.target.files);
          selectedFiles.forEach(file => {
            let reader = new FileReader();
            const self = this;
            reader.onload = e => {
              var img = document.createElement("img");
              img.onload = () => {
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var MAX_WIDTH = 1024;
                var MAX_HEIGHT = 1024;
                var width = img.width;
                var height = img.height;
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                var exifOrientation = "";
                // Check orientation in EXIF metadatas
                EXIF.getData(img, function() {
                  var allMetaData = EXIF.getAllTags(this);
                  exifOrientation = allMetaData.Orientation;
                });
                // set proper canvas dimensions before transform & export
                if (jQuery.inArray(exifOrientation, [5, 6, 7, 8]) > -1) {
                  canvas.width = height;
                  canvas.height = width;
                } else {
                  canvas.width = width;
                  canvas.height = height;
                }
                // transform context before drawing image
                switch (exifOrientation) {
                  case 2:
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break;
                  case 3:
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break;
                  case 4:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                  case 5:
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                  case 6:
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                  case 7:
                    ctx.transform(0, -1, -1, 0, height, width);
                    break;
                  case 8:
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                  default:
                    ctx.transform(1, 0, 0, 1, 0, 0);
                }
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/jpeg");
                if (this.imagesUpload.length < 10) {
                  this.imagesUpload.push(dataurl);
                }
              };
              img.src = e.target.result;
            };
            reader.readAsDataURL(file);
          });
        }
      } else {
        self.$f7.dialog.alert("Sorry, you’ve exceeded our max of 10 photos.");
      }
    },

    // Remove image
    handleRemoveImage(index) {
      this.imagesUpload.splice(index, 1);
    },

    async uploadPlacePhotos() {
      const self = this

      self.addPhotosParams.id = self.places.id
      if (self.imagesUpload.length == 0) {
        self.$f7.dialog.alert("No photos selected.");
        return
      }

      self.$f7.dialog.progress("Posting...");
      if (self.imagesUpload.length > 0 && self.imagesUpload[0] != null) {
        let images

        // If image upload > 0
        try {
          images = await UploadImages(this.imagesUpload); // Upload
        } catch(e) {
          self.$f7.dialog.close();
          return self.$f7.dialog.alert(e);
        }

        if (images.some(image => image.url == undefined)) {
          // Error
          self.$f7.dialog.close();
          return self.$f7.dialog.alert("Error, image size has exceeded 10MB");
        }

        self.addPhotosParams.photos = images
      }

      self.$f7.dialog.close();

      self.$store.dispatch("place/ACTION_UPLOAD_PLACE_PHOTOS", self.addPhotosParams).then(res => {
        if (res.photos) {
          // a unique case when it's acceptable reload page after photos have been added
          self.imagesUpload = []
          self.$store.dispatch(
            "placeDetail/ACTION_ADD_NEW_PHOTOS",
            res.photos
          )
          self.$forceUpdate()
        } else {
          // Error
          self.$f7.dialog.alert("Error uploading place photos");
        }
      });
    }
  },
};
</script>
