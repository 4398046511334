const fetchCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

export default async function fetchGeoLocation({ onSuccess, onError }) {
  const options = {
    timeout: 5000,
    maximumAge: 10000,
    enableHighAccuracy: true
  }

  if ('geolocation' in navigator) {
    try {
      const { coords } = await fetchCurrentPosition();
      const { latitude, longitude } = coords;

      localStorage.setItem(
        "currentLatLocation",
        latitude
      );
      localStorage.setItem(
        "currentLngLocation",
        longitude
      );

      onSuccess ? onSuccess(latitude, longitude) : null
    } catch(error) {
      onError ? onError(error.message) : null
    }
  } else {
    onError ? onError('Geolocation is not available') : null
  }
}
