import gql from "graphql-tag";

const creatorSuggestion = gql`
  query creatorSuggestion {
    creatorSuggestion {
      id
      nickname
      email
      username
      role
      avatar {
        name
        url
      }
      background {
        name
        url
      }
      isActive
      friendStatus
    }
  }
`;

export { creatorSuggestion };
