export const resizeImage = (e) => {
  return new Promise((resolve, reject) => {
    const selectedFiles = Array.prototype.slice.call(e.target.files);
    selectedFiles.forEach(file => {
      let reader = new FileReader();
      reader.onload = e => {
        var img = document.createElement("img");
        img.onload = () => {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = 1024;
          var MAX_HEIGHT = 1024;
          var width = img.width;
          var height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          var exifOrientation = "";
          // Check orientation in EXIF metadatas
          EXIF.getData(img, function () {
            var allMetaData = EXIF.getAllTags(this);
            exifOrientation = allMetaData.Orientation;
          });

          // set proper canvas dimensions before transform & export
          if (jQuery.inArray(exifOrientation, [5, 6, 7, 8]) > -1) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }

          // transform context before drawing image
          switch (exifOrientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0);
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height);
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height);
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              ctx.transform(0, 1, -1, 0, height, 0);
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, height, width);
              break;
            case 8:
              ctx.transform(0, -1, 1, 0, 0, width);
              break;
            default:
              ctx.transform(1, 0, 0, 1, 0, 0);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL("image/jpeg");
          resolve(dataurl)
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  })
}
