<template>
  <f7-col :width="width">
    <div
      class="vendor-title"
      v-on:click="(value)=> onToPlaceDetail(value)"
    >{{index+1}}. {{ place.name }}</div>
    <f7-block-header class="sub-title" v-if="showDistance">
      <span class="f7-icons">placemark_fill</span>
      {{place.distance}}
    </f7-block-header>

    <f7-row no-gap class="rating-display">
      <f7-col>
        <div class="title">Public</div>
        <div class="count">
          <span>
            <img
              v-if="place && place.rateStarts && place.rateStarts.publicStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_3_active.png"
              alt
            >
            <img
              v-if="place && place.rateStarts && place.rateStarts.publicStatus == constants.RATE_STARTS.LIKE"
              src="@/static/icons/rating_2_active.png"
              alt
            >
            <img
              v-if="place && place.rateStarts && place.rateStarts.publicStatus == constants.RATE_STARTS.OK"
              src="@/static/icons/rating_1_active.png"
              alt
            >
            <img
              v-if="place && place.rateStarts && place.rateStarts.publicStatus == constants.RATE_STARTS.OK && place && place.rateStarts && place.rateStarts.publicStatus == constants.RATE_STARTS.LIKE && place && place.rateStarts && place.rateStarts.publicStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_0.png"
              alt
            >
          </span>
          <label>{{place && place.rateStarts && place.rateStarts.publicScore ? place.rateStarts.publicScore : 0}}</label>
        </div>
        <div class="total-rate">{{place && place.rateStarts && place.rateStarts.publicStats ? place.rateStarts.publicStats : 0}} rated</div>
      </f7-col>
      <f7-col>
        <div class="title">Friends</div>
        <div class="count">
          <span>
            <img
              v-if="place && place.rateStarts && place.rateStarts.friendStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_3_active.png"
              alt
            >
            <img
              v-if="place && place.rateStarts && place.rateStarts.friendStatus == constants.RATE_STARTS.LIKE"
              src="@/static/icons/rating_2_active.png"
              alt
            >
            <img
              v-if="place && place.rateStarts && place.rateStarts.friendStatus == constants.RATE_STARTS.OK"
              src="@/static/icons/rating_1_active.png"
              alt
            >
            <img
              v-if="place && place.rateStarts && place.rateStarts.friendStatus == constants.RATE_STARTS.OK && place && place.rateStarts && place.rateStarts.friendStatus == constants.RATE_STARTS.LIKE && place && place.rateStarts && place.rateStarts.friendStatus == constants.RATE_STARTS.FAVS"
              src="@/static/icons/rating_0.png"
              alt
            >
          </span>
          <label>{{place && place.rateStarts && place.rateStarts.friendScore ? place.rateStarts.friendScore : 0}}</label>
        </div>
        <div class="total-rate">{{place && place.rateStarts && place.rateStarts.friendStats ? place.rateStarts.friendStats : 0}} rated</div>
      </f7-col>
    </f7-row>
  </f7-col>
</template>
<script>
export default {
  props: {
    place: {
      required: true,
      type: Object
    },
    width: {
      required: false,
      type: Number
    },
    onToPlaceDetail: {
      required: true,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    index: {
      required: false,
      type: Number
    },
    constants: {
      required: true,
      type: Object
    },
    showDistance: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

