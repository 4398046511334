<template>
  <f7-row class="title-block">
    <f7-col width="70" class="col-left">
      <f7-block-title class="title">{{name}}</f7-block-title>
      <f7-block-header class="sub-title">{{statsPlace}} Recs</f7-block-header>
    </f7-col>
    <f7-col width="30" class="col-right">
      <f7-button raised :actions-open="`.actions-share-magazine-detail-${cityId}`">Share</f7-button>
    </f7-col>
    <actions-magazine-component
      :actionsOpenName="`actions-share-magazine-detail-${cityId}`"
      :userId="userId"
      :cityId="cityId"
    />
  </f7-row>
</template>
<script>
import ActionsMagazineComponent from "./ActionsMagazine";
import C from "../_helpers/constants";
export default {
  components: {
    ActionsMagazineComponent
  },
  data() {
    return {
      constants: C
    };
  },
  props: {
    name: {
      required: false,
      type: String
    },
    statsPlace: {
      required: false,
      type: Number
    },
    userId: {
      required: false,
      type: String
    },
    cityId: {
      required: false,
      type: String
    }
  }
};
</script>
