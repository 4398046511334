<template>
  <f7-page class="page-feed" name="feeds" ptr @ptr:refresh="refetchData">
    <header-navbar-component title="Recs" link="home" :backButtonEnabled="false"/>
      <div class="isShow">
        <nav-city-component :name="city.name"/>
          <f7-block class="page-feed__block-category">
            <f7-swiper :params="{speed:500, slidesPerView: 'auto'}" >
              <f7-swiper-slide
                v-for="(topic, key) in topics"
                :key="key">
                <select-topic :color="topic.theme_color" :name="topic.name" :onHandle="() => handleOnTopicClick(topic)" :active="currentTopic == topic.name"/>
              </f7-swiper-slide>
            </f7-swiper>
          </f7-block>
      </div>
      <div v-if="isLoading">
        <recs-skeleton-component
          v-for="n in 10"
          :key="n"
          :className="`skeleton-text skeleton-effect-pulse`"
        />
      </div>
      <div v-if="!isLoading">
        <topic-component 
          v-for="(topic, index) in topics"
          :key="index"
          :topic="topic"
          :users="users"
          :city="city"
          :pendingFriends="pendingFriends.map(f => f.receive.id)"
        />
      </div>
    <toolbar-component
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="true"	
      :current_recs="false"	
      :current_post="false"	
      :current_collection="false"	
      :current_passport="false"	
      :handleFeeds="handleScrollFeeds"	
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import HeaderNavbarComponent from "../../components/HeaderNavbar"
import CategoryComponent from "../../components/Category"
import TopicComponent from "../../components/TopicComponent"
import NavCityComponent from "../../components/NavCity"
import ToolbarComponent from "../../components/Toolbar"
import SelectTopic from "../../components/SelectTopic"
import RecsSkeletonComponent from "../../components/RecsSkeleton";
import C from "../../_helpers/constants"

export default {
  components: {
    HeaderNavbarComponent,
    TopicComponent,
    CategoryComponent,
    NavCityComponent,
    ToolbarComponent,
    SelectTopic,
    RecsSkeletonComponent
  },

  created() {
    this.fetchTopic()
    this.fetchCategory()
    this.fetchUsers()
    this.fetchPendingFriends()
  },

  data() {
    return {
      constants: C,
      currentTopic: null,
      users: [],
      pendingFriends: []
    }
  },

  watch: {
    reload(isReload) {
      if (!isReload) return
      this.fetchTopic()
      this.fetchUsers()
      this.fetchPendingFriends()
    }
  },

  computed: {
    ...mapGetters("topics", {
      topics: "data",
      reload: "reload",
      isLoading: "isLoading"
    }),
    ...mapGetters("cities", {
      country: "currentCountry",
      city: "currentCity"
    })
  },

  methods: {
    handleScrollFeeds() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    fetchTopic() {
      const self = this

      this.$store.dispatch("topics/ACTION_SET_LOADING")
      this.$store.dispatch("topics/ACTION_FETCH_TOPIC").then((topics) => {
        self.currentTopic = topics[0]?.name
      })
   },

    fetchCategory() {
      this.$store.dispatch("categories/ACTION_FETCH_CATEOGRY");
    },

    refetchData(_event, done) {
      this.$store.dispatch("topics/ACTION_FETCH_TOPIC").then(() => {
        done()
      })
    },

    handleOnTopicClick({ id, name }) {
      this.currentTopic = name
      $('.page-content').animate({
        scrollTop: $(`div[topic="${id}"]`).offset().top - 44 // not a magic number, it's a title height
      }, 1500)
    },

    fetchUsers(params, actionType = "feeds/ACTION_FETCH_USERS") {
      const self = this
      this.$store
      .dispatch(actionType, params)
      .then(res => {
        this.users = res
      })
      .catch(error => {
      })
    },

    fetchPendingFriends(params, actionType = "feeds/ACTION_FETCH_PENDING_FRIENDS") {
      const self = this
      this.$store
      .dispatch(actionType, params)
      .then(res => {
        this.pendingFriends = res
      })
      .catch(error => {
      })
    }
  }
};
</script>
