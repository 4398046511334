import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import C from "../../_helpers/constants";
function initialState() {
  return {
    data: [],
    profile: [],
    magazines: []
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => state.data,
    profile: state => state.profile,
    magazines: state => state.magazines
  },

  actions: {
    // Handle author profile when click author show info at passport page
    actionFetchAuthProfile({
      commit
    }, userId) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              userId: userId
            },
            mutation: gqlQuery.passport
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_DATA_AUTH_PROFILE', res.data.passport.profile)
            commit('MUTATION_FETCH_DATA_MAGAZINE', res.data.passport.magazines)
            commit('MUTATION_FETCH_DATA', res.data.passport)
            resolve(res.data.passport.profile)
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.PASSPORT_FRIEND_PROFILE_PAGE,
              params: {
                id: userId
              }
            }, { animate: false });
            commit('currentUrl/MUTATIONS_GET_CURRENT_URL', C.ROUTES_NAME.PASSPORT_PAGE, {
              root: true
            })
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_FETCH_DATA_SHARE({
      commit
    }, userId) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              userId: userId
            },
            mutation: gqlQuery.passportShare
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_DATA_AUTH_PROFILE', res.data.passport.profile)
            commit('MUTATION_FETCH_DATA_MAGAZINE', res.data.passport.magazines)
            commit('MUTATION_FETCH_DATA', res.data.passport)
            resolve(res.data.passport.profile)
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.PASSPORT_FRIEND_PROFILE_PAGE,
              params: {
                id: userId
              }
            });
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_GET_INFO_SHARE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              userId: params.userId
            },
            mutation: gqlQuery.passportShare
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_DATA_AUTH_PROFILE', res.data.passport.profile)
            commit('MUTATION_FETCH_DATA_MAGAZINE', res.data.passport.magazines)
            commit('MUTATION_FETCH_DATA', res.data.passport)
            resolve(res.data.passport.profile)
            commit('magazine/MUTATIONS_GET_MAGAZINE_SELECTED', params, {
              root: true
            })
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_GET_INFO({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              userId: params.userId
            },
            mutation: gqlQuery.passport
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_DATA_AUTH_PROFILE', res.data.passport.profile)
            commit('MUTATION_FETCH_DATA_MAGAZINE', res.data.passport.magazines)
            commit('MUTATION_FETCH_DATA', res.data.passport)
            resolve(res.data.passport.profile)
            commit('magazine/MUTATIONS_GET_MAGAZINE_SELECTED', params, {
              root: true
            })
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },

    ACTION_GET_PROFILE({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              userId: params.userId
            },
            mutation: gqlQuery.passportShare
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH_DATA_AUTH_PROFILE', res.data.passport.profile)
            commit('MUTATION_FETCH_DATA_MAGAZINE', res.data.passport.magazines)
            commit('MUTATION_FETCH_DATA', res.data.passport)
            resolve(res.data.passport.profile)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    }

  },





  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.data.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
    MUTATION_FETCH_DATA(state, items) {
      state.data = items
    },
    MUTATION_FETCH_DATA_MAGAZINE(state, items) {
      state.magazines = items
    },
    MUTATION_FETCH_DATA_AUTH_PROFILE(state, items) {
      state.profile = items
    }
  }
}
