import gql from "graphql-tag";

const pendingFriends = gql`
  query pendingFriends {
    pendingFriends {
      id
      status
      createdAt
      receive {
        id
        nickname
        avatar {
          name
          url
        }
        background {
          name
          url
        }
      }
    }
  }
`;

export { pendingFriends };
