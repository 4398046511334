import gql from '../../utils/apollo-client';
import gqlMutation from '../../_gql/mutation/index.js';
import C from "../../_helpers/constants";
export default {
  namespaced: true,
  state: {
    data: []
  },

  getters: {
    data: state => state.data,
  },

  actions: {
    ACTION_LIKE({
      commit
    }, params) {
      let data = params.params
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {
              data
            },
            mutation: gqlMutation.like
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            resolve(res.data.like)
            if (params.pageType.type == C.PAGE_CONTENT_TYPE.TOPIC) {
              commit('topics/MUTATION_UPDATE_LIKE', {
                id: params.params.id
              }, {
                root: true
              })
            }
            if (params.params.status == C.LIKE_STATUS.LIKE) {
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.FEED) {
                commit('feeds/MUTATION_INCREMENT_LIKE', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.COLLECTION_POST) {
                commit('collectionDetailPost/MUTATION_INCREMENT_LIKE', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.SUMMARY) {
                commit('summaryFeed/MUTATION_INCREMENT_LIKE', {
                  id: data.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TIP) { // Chua co api
                commit('magazine/MUTATION_INCREMENT_LIKE_ADD_TIP', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED || params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS) {
                commit('magazine/MUTATION_INCREMENT_LIKE_POSTED', {
                  id: params.params.id
                }, {
                  root: true
                })
                commit('magazine/MUTATION_INCREMENT_LIKE_ADD_TO_PLANS', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              commit('detailPost/MUTATION_INCREMENT_LIKE', {
                id: params.params.id
              }, {
                root: true
              })
            } else {
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.FEED) {
                commit('feeds/MUTATION_DECREMENT_LIKE', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.COLLECTION_POST) {
                commit('collectionDetailPost/MUTATION_DECREMENT_LIKE', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.SUMMARY) {
                commit('summaryFeed/MUTATION_DECREMENT_LIKE', {
                  id: data.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TIP) { // Chua co api
                commit('magazine/MUTATION_DECREMENT_LIKE_ADD_TIP', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              if (params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_POSTED || params.pageType.type == C.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS) {
                commit('magazine/MUTATION_DECREMENT_LIKE_POSTED', {
                  id: params.params.id
                }, {
                  root: true
                })
                commit('magazine/MUTATION_DECREMENT_LIKE_ADD_TO_PLANS', {
                  id: params.params.id
                }, {
                  root: true
                })
              }
              commit('detailPost/MUTATION_DECREMENT_LIKE', {
                id: params.params.id
              }, {
                root: true
              })
            }
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
  }
}
