<template>
  <f7-list-item text>
    <span class="title-tip" slot="title" @click="() => onAuthProfile()">
      {{
      `${rate.author && rate.author.nickname ? rate.author.nickname : ""}`
      }}
    </span>
    <img
      @click="() => onAuthProfile()"
      v-if="rate.author && rate.author.avatar != null"
      slot="media"
      :src="getUrlImg"
    />
    <span
      v-else
      slot="media"
      class="text no-avatar"
      @click="() => onAuthProfile()"
    >{{ rate.author && rate.author.nickname.charAt(0) }}</span>

    <f7-link class="like-comment">
      <span>
        <img
          v-if="rate.action == constants.RATE_STARTS.FAVS"
          src="@/static/icons/rating_3_active.png"
          alt
        />
        <img
          v-if="rate.action == constants.RATE_STARTS.LIKE"
          src="@/static/icons/rating_2_active.png"
          alt
        />
        <img
          v-if="rate.action == constants.RATE_STARTS.OK"
          src="@/static/icons/rating_1_active.png"
          alt
        />
      </span>
    </f7-link>
  </f7-list-item>
</template>
<script>
export default {
  props: {
    rate: {
      required: true,
      type: Object
    },
    getUrlImg: {
      required: true,
      type: String
    },
    constants: {
      required: true,
      type: Object
    },
    onAuthProfile: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>
