<template>
  <f7-block class="post">
    <span
      medium
      v-show="title"
      @click="(value)=> onToPostDetail(value)"
      class="no-margin-left block-title-medium line-clamp-3"
    >{{ title }}</span>
    <!-- <f7-photo-browser theme="dark" :photos="(e)=>onPhotoBrowser(e)" ref="photoBrowser"></f7-photo-browser> -->

    <div v-if="photos.length > 0 || externalUrl && photos.length > 0">
      <div class="no-margin wrapper-card">
        <div class="wrapper-content-card">
          <div
            :class="`${photos.length <= 1 ? 'grid1Col' : (photos.length <= 2 ? 'grid2Col' : 'grid3Col')} wrapper-photo`"
          >
            <iframe
              v-if="externalSource == 'YOUTUBE' && externalUrl"
              width="100%"
              height="226"
              :src="externalUrl"
            ></iframe>
            <div v-else>
              <f7-button raised @click="() => onPhotoBrowser()"></f7-button>
              <div v-if="photos.length == 1">
                <img
                  v-for="(photo, idx) in photos.slice(0, 1)"
                  :key="idx"
                  :src="onGetUrlImg(photo, idx)"
                >
              </div>
              <div
                v-else
                v-for="(photo, idx) in photos.slice(0, 3)"
                :key="idx"
                :class="`no-border card-header photo-${idx}`"
                valign="bottom"
                :style="`background-image:url(`+onGetUrlImg(photo, idx)+`)`"
              ></div>
            </div>
          </div>
          <span v-if="photos.length > 3" class="numberPhotos">+{{photos.length - 3}}</span>
          <h3 class="caption-text" v-if="city != null">
            <span class="wrap" v-if="places.length > 0">
              {{ places[0].name }} •
              <span class="city-name">{{ cityName }}</span>
            </span>
            <span class="wrap" v-else>
              <span class="city-name">{{ cityName }}</span> •
              <span class="country-name">{{ cityCountryName }}</span>
            </span>
          </h3>
        </div>

        <f7-card-footer v-if="city != null && photos.length > 0 || externalUrl">
          <span>
            <img
              v-if="isActiveLikeStatus == false"
              @click="(value) => onToggleHeartFeed(value)"
              src="@/static/icons/like.svg"
            >
            <img
              v-else
              @click="(value) => onToggleHeartFeed(value)"
              src="@/static/icons/like_active.svg"
            >
          </span>

          <span>
            <img @click="(id) => onToPostComment(id)" src="@/static/icons/comment.svg">
          </span>

          <span @click="(value) => onToggleBookMarkFeed(value)" v-if="isActiveBookmark == false">
            <img src="@/static/icons/bookmark.svg">
          </span>
          <span v-else @click="(value) => onToggleBookMarkFeed(value)">
            <img src="@/static/icons/bookmark_active.svg">
          </span>
        </f7-card-footer>
      </div>
    </div>

    <div v-else>
      <div v-if="photos.length == 0 && title && content == '' || externalUrl && photos.length == 0">
        <h3 class="caption-text-city" v-if="city != null">
          <span class="wrap" v-if="places.length > 0">
            {{ places[0].name }} •
            <span class="city-name">{{ cityName }}</span>
          </span>
          <span class="wrap" v-else>
            <span class="city-name">{{ cityName }}</span> •
            <span class="country-name">{{ cityCountryName }}</span>
          </span>
        </h3>
        <div class="wrapper-card no-photo no-video no-content">
          <f7-card-footer>
            <span>
              <img
                v-if="isActiveLikeStatus == false"
                @click="(value) => onToggleHeartFeed(value)"
                src="@/static/icons/like.svg"
              >
              <img
                v-else
                @click="(value) => onToggleHeartFeed(value)"
                src="@/static/icons/like_active.svg"
              >
            </span>

            <span>
              <img @click="(id) => onToPostComment(id)" src="@/static/icons/comment.svg">
            </span>

            <span v-if="isActiveBookmark == false" @click="(value) => onToggleBookMarkFeed(value)">
              <img src="@/static/icons/bookmark.svg">
            </span>
            <span v-else @click="(value) => onToggleBookMarkFeed(value)">
              <img src="@/static/icons/bookmark_active.svg">
            </span>
          </f7-card-footer>
        </div>
      </div>

      <div class="wrapper-card no-photo no-video" v-else>
        <div class="wrapper-content-card">
          <p class="content line-clamp-3" @click="(value)=> onToPostDetail(value)">{{ content }}</p>
          <p v-if="siteDescription && content" @click="(value)=> onToPostDetail(value)">
            <span class="line-between"></span>
          </p>
          <p
            v-if="siteDescription"
            class="content line-clamp-3"
            @click="(value)=> onToPostDetail(value)"
          >
            <span v-if="siteName">
              <span class="site-name">{{siteName}}</span>
            </span>
            {{ siteDescription }}
          </p>

          <h3 class="caption-text-city" v-if="city != null">
            <span class="wrap" v-if="places.length > 0">
              {{ places[0].name }} •
              <span class="city-name">{{ cityName }}</span>
            </span>
            <span class="wrap" v-else>
              <span class="city-name">{{ cityName }}</span> •
              <span class="country-name">{{ cityCountryName }}</span>
            </span>
          </h3>
        </div>

        <f7-card-footer>
          <span>
            <img
              v-if="isActiveLikeStatus == false"
              @click="(value) => onToggleHeartFeed(value)"
              src="@/static/icons/like.svg"
            >
            <img
              v-else
              @click="(value) => onToggleHeartFeed(value)"
              src="@/static/icons/like_active.svg"
            >
          </span>

          <span>
            <img @click="(id) => onToPostComment(id)" src="@/static/icons/comment.svg">
          </span>

          <span v-if="isActiveBookmark == false" @click="(value) => onToggleBookMarkFeed(value)">
            <img src="@/static/icons/bookmark.svg">
          </span>
          <span v-else @click="(value) => onToggleBookMarkFeed(value)">
            <img src="@/static/icons/bookmark_active.svg">
          </span>
        </f7-card-footer>
      </div>
    </div>
    <div
      class="like-comment like-comment--magazine"
    >{{ statsLike}} likes • {{ statsComment}} comments</div>
    <p class="time">{{ createdAt | moment("from", true) }} ago</p>
  </f7-block>
</template>
<script>
export default {
  props: {
    onGetUrlImg: {
      required: true,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    actionsOpen: {
      required: false,
      type: String
    },
    title: {
      required: false,
      type: String
    },
    content: {
      required: false,
      type: String
    },
    siteDescription: {
      required: false,
      type: String
    },
    siteName: {
      required: false,
      type: String
    },
    onToPostDetail: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    photos: {
      required: false,
      type: Array
    },
    externalUrl: {
      required: false,
      type: String
    },
    externalSource: {
      required: false,
      type: String
    },
    cityName: {
      required: false,
      type: String
    },
    cityCountryName: {
      required: false,
      type: String
    },
    city: {
      required: false,
      type: Object
    },
    places: {
      required: false,
      type: Array
    },
    isActiveLikeStatus: {
      required: false,
      type: Boolean
    },
    isActiveBookmark: {
      required: false,
      type: Boolean
    },
    onToggleHeartFeed: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onToPostComment: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onToggleBookMarkFeed: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    createdAt: {
      required: false,
      type: String
    },
    statsLike: {
      required: false,
      type: Number
    },
    statsComment: {
      required: false,
      type: Number
    },
    onPhotoBrowser: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

