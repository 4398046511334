import gql from "graphql-tag";

const detailFromShare = gql`
  query post($id: ID!) {
    post(id: $id) {
      id
      title
      content
      createdAt
      statsLike
      statsComment
      siteName
      siteDescription
      externalUrl
      externalSource
      city {
        name
        country {
          name
        }
      }
      author {
        id
        nickname
        avatar {
          url
          name
        }
      }
      photos {
        name
        url
      }
      comments {
        id
        content
        author {
          id
          nickname
          avatar {
            name
            url
          }
        }
        createdAt
      }
      places {
        id
        placeId
        name
        photos {
          name
          url
        }
        # rateStarts {
        #   publicStatus
        #   publicScore
        #   publicStats
        #   friendStatus
        #   friendScore
        #   friendStats
        # }
        categories {
          id
          name
        }
        city {
          name
        }
      }
    }
  }
`;

export { detailFromShare };
