<template>
  <div>
    <a v-if="externalUrl" :href="externalUrl" target="_blank" external class="external">
      <div              
        v-for="(photo, idx) in photos.slice(0, 3)"
        :key="idx"
        :class="`no-border card-header photo-${idx}`"
        valign="bottom"
        :style="`background-image:url(`+handleImgArticle(photo, idx)+`)`"
      ></div>
    </a>
    <div v-else>
      <f7-button raised @click="() => onPhotoBrowser()"></f7-button>
      <div v-if="photos.length == 1">
        <div              
          v-for="(photo, idx) in photos.slice(0, 3)"
          :key="idx"
          :class="`no-border card-header photo-${idx}`"
          valign="bottom"
          :style="`background-image:url(`+handleImgArticle(photo, idx)+`)`"
        ></div>
      </div>
      <div
        v-else
        v-for="(photo, idx) in photos.slice(0, 3)"
        :key="idx"
        :class="`no-border card-header photo-${idx}`"
        valign="bottom"
        :style="`background-image:url(`+onGetUrlImg(photo, idx)+`)`"
      ></div>
    </div>
  </div>
</template>
<script>
import { getUrlImg, getUrlImgArticle } from "../utils/cloundinary-image";
export default {
  props: {
    onPhotoBrowser: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    photos: {
      required: false,
      type: Array
    },
    onGetUrlImg: {
      required: true,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    externalUrl: {
      required: false,
      type: String
    },
  },
  mounted() {
    this.$f7.lazy.create('img.lazy');
  },
  methods: {
    onHandleDefault() {
      return null;
    },
    handleImgArticle(photo, index) {
      return getUrlImgArticle(photo, index);
    },
  }
};
</script>

