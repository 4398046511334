<template>
  <h3 :class="className" v-if="city != null">
    <span class="wrap" v-if="places.length > 0">
      {{ places[0].name }} •
      <span class="city-name">{{ cityName }}</span>
    </span>
    <span class="wrap" v-else>
      <span class="city-name">{{ cityName }}</span>
    </span>
  </h3>
</template>
<script>
export default {
  props: {
    city: {
      required: false,
      type: Object
    },
    places: {
      required: false,
      type: Array
    },
    cityName: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String
    }
  }
};
</script>

