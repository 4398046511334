<template>
  <div class="block-article-friend-favs block-article" v-if="friendFavs.length > 0">
    <div
      v-for="(placesInCat, keyCat) in friendFavs"
      :key="keyCat"
    >
      <div class="wrapper-friend-favs" v-if="placesInCat.places.length > 0">
        <f7-block>
          <f7-block-header>
            <div class="avatar">
              <div>
                <img src="@/static/icons/ic-fav.svg" alt>
              </div>
              <div class="name">favs</div>
            </div>
            <div class="action">
              <f7-link icon-ios="f7:more_fill"></f7-link>
            </div>
          </f7-block-header>
          <title-component
            :title="`Friends' Favorites: ${placesInCat.category.name}`"
            class="no-margin-left block-title block-title-medium"
          />
        </f7-block>
        <div id="wrap-col-friend-favs">
          <div
            class="box-friend-favs"
            v-for="(friendFav, keyFriendFav) in placesInCat.places"
            :key="keyFriendFav"
          >
            <f7-card>
              <div v-on:click="toPlaceDetail(friendFav.id)">
              <f7-card-header v-if="friendFav.photos.length" :style="`background-image:url(`+friendFav.photos[0].url+`)`"></f7-card-header>
              <f7-card-header v-else :style="`background: black`"></f7-card-header>
              </div>
              <div class="wrap-icon-bookmark-share">
                <bookmark-component
                  class="icon-bookmark"
                  :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                  :item="friendFav"
                  :id="friendFav.id"
                />
                <share-component
                  :actionsOpenId="`actions-fav-place-${friendFav.id}`"
                  :type="constants.TYPE_SHARE.PLACE"
                  :typeId="friendFav.id"
                />
              </div>
              <public-friends-rating-component :item="friendFav" :constants="constants"/>
              <h4 v-on:click="toPlaceDetail(friendFav.id)" >{{keyFriendFav + 1}}. {{friendFav.name}}</h4>
              <div class="background-overlay"></div>
              <div class="background-overlay background-overlay--bottom"></div>
            </f7-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TitleComponent from "../components/Title";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import PublicFriendsRatingComponent from "../components/PublicFriendsRating";
import C from "../_helpers/constants";
export default {
  components: {
    TitleComponent,
    BookmarkComponent,
    ShareComponent,
    PublicFriendsRatingComponent
  },
  props: {
    friendFavs: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      constants: C
    };
  },
  beforeMount() {
    // this.fetchFriendFavs(); // Fetch Trendings
  },
  methods: {
    // Get Friend favs
    // fetchFriendFavs() {
    //   this.$store.dispatch("friendFavourite/ACTION_FETCH_FRIEND_FAVOURITE");
    // },

    toPlaceDetail(id) {
      this.$store.dispatch("placeDetail/ACTION_GET", { id });
    },
  }
};
</script>

