import C from "../_helpers/constants";
import {
  Cloudinary
} from 'cloudinary-core'

const cloudinary = new Cloudinary({
  cloud_name: C.CLOUD.NAME
})


export const getUrlImg = (photos) => {
  if (photos) {
    let img = photos;
    return cloudinary.url(img, {
      width: 640,
      crop: "fit"
    });
  } else {
    return "";
  }
}

export const getUrlImgArticle = (photo, index) => {
  if (photo && index <= 2) {
    let img = photo.name;
    return cloudinary.url(img, { width: 520, height: 1024, crop: "fit" });
  } else {
    return "";
  }
}