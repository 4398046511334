import {loginFB} from './loginFB';
import {loginEmail} from './loginEmail';
import {registerEmail} from './registerEmail';
import {createPost} from './createPost';
import {createRate} from './createRate';
import {createTip} from './createTip';
import {updateTip} from './updateTip';
import {deleteTip} from './deleteTip'
import {updateRate} from './updateRate';
import {deleteRate} from './deleteRate'
import {like} from './like';
import {bookmark} from './bookmark';
import {createComment} from './createComment';
import {updateComment} from './updateComment';
import {requestFriend} from './requestFriend';
import {updateProfile} from './updateProfile';
import {inviteFriend} from './inviteFriend';
import {updateTagPopularity} from './updateTagPopularity'
import { uploadPlacePhotos } from './uploadPlacePhotos'

export default {
  loginFB,
  loginEmail,
  registerEmail,
  createPost,
  createRate,
  createTip,
  updateTip,
  deleteTip,
  updateRate,
  deleteRate,
  like,
  bookmark,
  createComment,
  updateComment,
  requestFriend,
  updateProfile,
  inviteFriend,
  updateTagPopularity,
  uploadPlacePhotos,
};
