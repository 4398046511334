<template>
  <div class="card-city">
    <f7-link v-if="published == false" class="coming-soon">
      <div class="caption">
        <div class="title">{{ name }}</div>
        <span v-show="friendsCount > 0">
          <f7-icon f7="persons"></f7-icon>
          {{ friendsCount }} {{ text }}
        </span>
        <!-- <span v-show="isMagazine ? false : true">{{ statsPlace }} {{ text }}</span> -->
      </div>
      <f7-button class="col button-coming-soon">COMING SOON</f7-button>
    </f7-link>
    <f7-link @click="()=> onHandle()" v-else>
      <div class="caption">
        <div class="title">{{ name }}</div>
        <span v-show="friendsCount > 0">
          <f7-icon f7="persons"></f7-icon>
          {{ friendsCount }} {{ text }}
        </span>
        <!-- <span v-show="isMagazine ? false : true">{{ statsPlace }} {{ text }}</span> -->
      </div>
    </f7-link>
    <f7-button
      v-if="published && isMagazine"
      class="share-cities col"
      raised
      :actions-open="actionsOpen"
    >SHARE</f7-button>
  </div>
</template>
<script>
export default {
  props: {
    published: {
      required: false,
      type: Boolean,
      default: true
    },
    isMagazine: {
      required: false,
      type: Boolean,
      default: false
    },
    actionsOpen: {
      required: false,
      type: String,
      default: ""
    },
    name: {
      required: false,
      type: String
    },
    friendsCount: {
      required: false,
      type: Number,
      default: null
    },
    statsPlace: {
      required: false,
      type: Number,
      default: null
    },
    text: {
      required: false,
      type: String
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

