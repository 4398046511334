<template>
  <f7-block class="block-city">
    <f7-row>
      <f7-col width="70" class="col-left">{{name}}</f7-col>
      <f7-col width="30" class="col-right">
        <f7-link @click="action()">Change</f7-link>
      </f7-col>
    </f7-row>
  </f7-block>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    name: {
      required: false,
      type: String
    }
  },
  methods: {
    action() {
      this.$f7router.navigate(C.ROUTER_NAVIGATE.HOME);
    }
  }
};
</script>
