<template>
  <f7-page
    id="passport-detail-page"
    name="magazine"
    infinite
    :infinite-preloader="false"
    @infinite="loadMore"
  >
    <header-navbar-component title="Magazine"/>

    <p class="head-background">
      <img :src="geBackgroundCity(city && city.cover && city.cover.url)">
    </p>
    <div class="content-wrap">
      <div class="user-widget avatar">
        <span class="avatar-wrap">
          <img
            v-if="profile && profile.avatar && profile.avatar.name"
            :src="getAvatarAuthImg(profile && profile.avatar && profile.avatar.name)"
          >
          <span v-else class="text">{{ profile && profile.nickname && profile.nickname.charAt(0)}}</span>
        </span>
        <span class="user-name">{{profile.nickname}}</span>
      </div>

      <f7-block strong inset class="main-body">
        <header-magazine-detail-component
          :userId="magazineSelected.userId"
          :cityId="magazineSelected.cityId"
          :name="city.name"
          :statsPlace="city.statsPlace"
        />

        <div class="block-category">
          <f7-swiper :params="{speed:500, slidesPerView: 'auto'}" class="categories-block">
            <f7-swiper-slide>
              <f7-button
                tab-link-active
                :tab-link="`#tab-magazine-timeline`"
                @click="handleCategories(null, null)"
              >Journey</f7-button>
            </f7-swiper-slide>
            <f7-swiper-slide v-for="(item, index) in categories" :key="index">
              <f7-button
                :tab-link="`#tab-magazine-place-${index}`"
                @click="handleCategories(index, item.id)"
              >{{ item.name }}</f7-button>
            </f7-swiper-slide>
            <!-- <f7-swiper-slide>
              <f7-button tab-link-active :tab-link="`#tab-magazine-photos`">Photos</f7-button>
            </f7-swiper-slide> -->
          </f7-swiper>
        </div>

        <!---dropdown goes here!-->
        <f7-row class="sorting-block" v-if="indexCategories.id != null">
          <f7-col width="100">
            <sort-by-magazine-component
              :handleSortBy="(type, text)=>handleSortBy(type, text)"
              :toggleSorting="()=>toggleSorting()"
              :textSortBy="itemSortBy"
              :isSorting="isSorting"
              :indexSortBy="indexSortBy"
              :textFriendRating="textFriendRating"
              :created="true"
            />
          </f7-col>
        </f7-row>
        <f7-tabs>
          <f7-tab id="tab-magazine-timeline" class="page-content" tab-active>
            <div class="page-recs" v-if="!isLoading">
                <f7-block v-show="timelines.length === 0" class="recs-null" no-padding>
                  <h4 v-if="timelines.length === 0">
                    {{ me.id === profile.id ? 'Your' : profile.nickname }} journey needs some love 😥
                    <!-- <img src="@/static/icons/questions.png" alt> -->
                  </h4>
                  <p v-if="timelines.length === 0 && me.id === profile.id">
                    <span>
                      Rate a place or post to add to your journey.
                    </span>
                  </p>
                </f7-block>
            </div>
            <div v-for="(item, key) in timelines" :key="key" :class="`block-article`">
              <div v-if="item.relationship.__typename === 'Post'">
                <f7-block class="title-posted">POSTED</f7-block>
                <article-view
                  :title="item.relationship.title"
                  :onToPostDetail="()=>toPostDetail(item.relationship.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED)"
                  :photos="item.relationship.photos"
                  :onGetUrlImg="(photo, idx) => handleImgArticle(photo, idx)"
                  :cityName="item.relationship.city.name"
                  :cityCountryName="item.relationship.city.country.name"
                  :places="item.relationship.places"
                  :city="item.relationship.city"
                  :siteName="item.relationship.siteName"
                  :siteDescription="item.relationship.siteDescription"
                  :content="item.relationship.content"
                  :createdAt="item.createdAt"
                  :statsLike="item.relationship.statsLike"
                  :statsComment="item.relationship.statsComment"
                  :onPhotoBrowser="()=>onPhotoBrowser(item.relationship.key)"
                  :type="constants.BOOKMARK_CONTENT_TYPE.POST"
                  :item="item.relationship"
                  :id="item.relationship.id"
                  :externalSource="item.relationship.externalSource"
                  :externalUrl="item.relationship.externalUrl"
                  :isMagazineShow="false"
                  :isShowAvatar="false"
                  :showExternalUrl="false"
                  :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED"
                  :isActiveBookmarkStatus="item.relationship.isActiveBookmark"
                />
                <f7-photo-browser
                  theme="dark"
                  :photos="photoBrowser(item.relationship.photos)"
                  ref="photoBrowser"
                ></f7-photo-browser>

                <div v-if="item.relationship && item.relationship.places.length > 0">
                  <f7-block
                    :class="`${index == 2 ? 'border-bottom-none' : ''} vendor-list wrap-places`"
                    no-padding
                    v-for="(place, index) in item.relationship.places.slice(0,3)"
                    :key="index"
                  >
                    <f7-row>
                      <f7-col width="40">
                        <f7-card>
                          <place-image-component
                            :url="place && place.photos.length > 0 ? place && place.photos[0].url : ''"
                          />

                          <div class="wrap-icon-bookmark-share">
                            <bookmark-component
                              class="icon-bookmark"
                              :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                              :item="place"
                              :id="place.id"
                              :isActiveBookmarkStatus="place.isActiveBookmark"
                              :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE"
                            />

                            <share-component
                              :actionsOpenId="`actions-timeline-posted-place-${place.id}`"
                              :type="constants.TYPE_SHARE.PLACE"
                              :typeId="place.id"
                            />
                          </div>
                        </f7-card>
                      </f7-col>

                      <article-place-info
                        :place="place"
                        :onToPlaceDetail="(id)=>toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE)"
                        :index="index"
                        :constants="constants"
                        :width="60"
                      />
                      <div
                        class="wrapper-place"
                        v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE)"
                      ></div>
                    </f7-row>
                  </f7-block>

                  <transition-expand>
                    <div v-if="item.isActiveTipTimeline" class="wrapper-expanded">
                      <f7-block
                        :class="`vendor-list wrap-places`"
                        no-padding
                        v-for="(place, index) in item.relationship.places.slice(3)"
                        :key="index"
                      >
                        <f7-row>
                          <f7-col width="40">
                            <f7-card>
                              <place-image-component
                                :url="place && place.photos.length > 0 ? place && place.photos[0].url : ''"
                              />

                              <div class="wrap-icon-bookmark-share">
                                <bookmark-component
                                  class="icon-bookmark"
                                  :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                                  :item="place"
                                  :id="place.id"
                                  :isActiveBookmarkStatus="place.isActiveBookmark"
                                  :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE"
                                />

                                <share-component
                                  :actionsOpenId="`actions-timeline-posted-place-${place.id}`"
                                  :type="constants.TYPE_SHARE.PLACE"
                                  :typeId="place.id"
                                />
                              </div>
                            </f7-card>
                          </f7-col>

                          <article-place-info
                            :place="place"
                            :onToPlaceDetail="(id)=>toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE)"
                            :index="index+3"
                            :constants="constants"
                            :width="60"
                          />
                          <div
                            class="wrapper-place"
                            v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_POSTED_PLACE)"
                          ></div>
                        </f7-row>
                      </f7-block>
                    </div>
                  </transition-expand>
                  <see-more-component
                    :isShow="item.relationship.places.length > 3"
                    :isActive="item.isActiveTipTimeline"
                    :toggleTip="()=>toggleTipTimeLine(key)"
                  />
                </div>
              </div>

              <div v-if="item.relationship.__typename === 'Tip'">
                <f7-block class="title-posted">ADDED TIP</f7-block>
                <f7-block
                  class="vendor-list vendor-list--padding-top-0 vendor-list--padding-bottom-1 vendor-list--border-none"
                  no-padding
                >
                  <f7-row>
                    <rated
                      :item="item.relationship.tipPlace"
                      :action="''"
                      :index="key"
                      :constants="constants"
                      :onToPlaceDetail="(id)=>toPlaceDetail(item.relationship.tipPlace.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TIP)"
                    />
                    <f7-col width="40">
                      <f7-card>
                        <place-image-component
                          :url="item.relationship.tipPlace && item.relationship.tipPlace.photos.length > 0 ? item.relationship.tipPlace && item.relationship.tipPlace.photos[0].url : ''"
                        />

                        <div class="wrap-icon-bookmark-share">
                          <bookmark-component
                            class="icon-bookmark"
                            :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                            :item="item.relationship.tipPlace"
                            :id="item.relationship.tipPlace.id"
                          />

                          <share-component
                            :actionsOpenId="`actions-timeline-tip-place-${item.relationship.tipPlace.id}`"
                            :type="constants.TYPE_SHARE.PLACE"
                            :typeId="item.relationship.tipPlace.id"
                          />
                        </div>
                      </f7-card>
                    </f7-col>
                    <div
                      class="wrapper-place"
                      v-on:click="toPlaceDetail(item.relationship.tipPlace.id,  constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TIP)"
                    ></div>
                  </f7-row>
                </f7-block>

                <timeline-tip
                  :content="item.relationship.tipContent"
                  :createdAt="item.createdAt"
                  :statsLike="item.relationship.statsLike ? item.relationship.statsLike : 0"
                  :statsComment="item.relationship.statsComment ? item.relationship.statsComment : 0"
                  :item="item.relationship"
                  :id="item.relationship.tipId"
                />
              </div>

              <div v-if="item.relationship.__typename === 'Bookmark'">
                <f7-block class="title-posted">ADDED TO PLANS</f7-block>
                <div v-if="item.relationship.post">
                  <article-view
                    :title="item.relationship.post.title"
                    :onToPostDetail="()=>toPostDetail(item.relationship.post.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS)"
                    :photos="item.relationship.post.photos"
                    :onGetUrlImg="(photo, idx) => handleImgArticle(photo, idx)"
                    :cityName="item.relationship.post.city.name"
                    :cityCountryName="item.relationship.post.city.country.name"
                    :places="item.relationship.post.places"
                    :city="item.relationship.post.city"
                    :siteName="item.relationship.post.siteName"
                    :siteDescription="item.relationship.post.siteDescription"
                    :content="item.relationship.post.content"
                    :createdAt="item.createdAt"
                    :statsLike="item.relationship.post.statsLike"
                    :statsComment="item.relationship.post.statsComment"
                    :onPhotoBrowser="()=>onPhotoBrowserAdded(item.relationship.post.key)"
                    :type="constants.BOOKMARK_CONTENT_TYPE.POST"
                    :item="item.relationship.post"
                    :id="item.relationship.post.id"
                    :externalSource="item.relationship.post.externalSource"
                    :externalUrl="item.relationship.post.externalUrl"
                    :isMagazineShow="false"
                    :isShowAvatar="false"
                    :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS"
                    :isActiveBookmarkStatus="item.relationship.post.isActiveBookmark"
                  />
                  <f7-photo-browser
                    theme="dark"
                    :photos="photoBrowserAdded(item.relationship.post.photos)"
                    ref="photoBrowserAdded"
                  ></f7-photo-browser>

                  <div v-if="item.relationship.post && item.relationship.post.places.length > 0">
                    <f7-block
                      :class="`${index == 2 ? 'border-bottom-none' : ''} vendor-list wrap-places`"
                      no-padding
                      v-for="(place, index) in item.relationship.post.places.slice(0,3)"
                      :key="index"
                    >
                      <f7-row>
                        <f7-col width="40">
                          <f7-card>
                            <place-image-component
                              :url="place && place.photos.length > 0 ? place && place.photos[0].url : ''"
                            />

                            <div class="wrap-icon-bookmark-share">
                              <bookmark-component
                                class="icon-bookmark"
                                :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                                :item="place"
                                :id="place.id"
                                :isActiveBookmarkStatus="place.isActiveBookmark"
                                :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE"
                              />

                              <share-component
                                :actionsOpenId="`actions-timeline-plan-place-${place.id}`"
                                :type="constants.TYPE_SHARE.PLACE"
                                :typeId="place.id"
                              />
                            </div>
                          </f7-card>
                        </f7-col>
                        <article-place-info
                          :place="place"
                          :onToPlaceDetail="(id)=>toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE)"
                          :index="index"
                          :constants="constants"
                          :width="60"
                        />
                        <div
                          class="wrapper-place"
                          v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE)"
                        ></div>
                      </f7-row>
                    </f7-block>

                    <transition-expand>
                      <div v-if="item.isActivePlacePlan" class="wrapper-expanded">
                        <f7-block
                          :class="`vendor-list wrap-places`"
                          no-padding
                          v-for="(place, index) in item.relationship.post.places.slice(3)"
                          :key="index"
                        >
                          <f7-row>
                            <f7-col width="40">
                              <f7-card>
                                <place-image-component
                                  :url="place && place.photos.length > 0 ? place && place.photos[0].url : ''"
                                />

                                <div class="wrap-icon-bookmark-share">
                                  <bookmark-component
                                    class="icon-bookmark"
                                    :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                                    :item="place"
                                    :id="place.id"
                                    :isActiveBookmarkStatus="place.isActiveBookmark"
                                    :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE"
                                  />

                                  <share-component
                                    :actionsOpenId="`actions-timeline-plan-place-${place.id}`"
                                    :type="constants.TYPE_SHARE.PLACE"
                                    :typeId="place.id"
                                  />
                                </div>
                              </f7-card>
                            </f7-col>
                            <article-place-info
                              :place="place"
                              :onToPlaceDetail="(id)=>toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE)"
                              :index="index+3"
                              :constants="constants"
                              :width="60"
                            />
                            <div
                              class="wrapper-place"
                              v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE)"
                            ></div>
                          </f7-row>
                        </f7-block>
                      </div>
                    </transition-expand>
                    <see-more-component
                      :isShow="item.relationship.post.places.length > 3"
                      :isActive="item.isActivePlacePlan"
                      :toggleTip="()=>togglePlacePlan(key)"
                    />
                  </div>
                </div>

                <f7-block
                  v-if="item.relationship.place"
                  class="vendor-list vendor-list--padding-top-0 vendor-list--padding-bottom-0"
                  no-padding
                >
                  <f7-row>
                    <rated
                      :item="item.relationship.place"
                      :action="''"
                      :index="key"
                      :constants="constants"
                      :onToPlaceDetail="(id)=>toPlaceDetail(item.relationship.place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE_ONLY)"
                    />
                    <f7-col width="40">
                      <f7-card>
                        <place-image-component
                          :url="item.relationship.place && item.relationship.place.photos.length > 0 ? item.relationship.place && item.relationship.place.photos[0].url : ''"
                        />

                        <div class="wrap-icon-bookmark-share">
                          <bookmark-component
                            class="icon-bookmark"
                            :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                            :item="item.relationship.place"
                            :id="item.relationship.place.id"
                            :isActiveBookmarkStatus="item.relationship.place.isActiveBookmark"
                            :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE_ONLY"
                          />

                          <share-component
                            :actionsOpenId="`actions-timeline-rate-place-${item.relationship.place.id}`"
                            :type="constants.TYPE_SHARE.PLACE"
                            :typeId="item.relationship.place.id"
                          />
                        </div>
                      </f7-card>
                    </f7-col>
                    <p class="time">{{ item.createdAt | moment("from", true) }} ago</p>
                    <div
                      class="wrapper-place"
                      v-on:click="toPlaceDetail(item.relationship.place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_ADD_TO_PLANS_PLACE_ONLY)"
                    ></div>
                  </f7-row>
                </f7-block>
                <event-list-view
                  :showOrdinal="false"
                  v-if="item.relationship.event"
                  :items="[item.relationship.event]"
                />
              </div>
              <div v-if="item.relationship.__typename === 'Rate'">
                <f7-block class="title-posted">RATED</f7-block>
                <f7-block
                  class="vendor-list vendor-list--padding-top-0 vendor-list--padding-bottom-0"
                  no-padding
                >
                  <f7-row>
                    <rated
                      :item="item.relationship.place"
                      :action="item.relationship.action"
                      :index="key"
                      :constants="constants"
                      :onToPlaceDetail="(id)=>toPlaceDetail(item.relationship.place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_PLACE)"
                    />
                    <f7-col width="40">
                      <f7-card>
                        <place-image-component
                          :url="item.relationship.place && item.relationship.place.photos.length > 0 ? item.relationship.place && item.relationship.place.photos[0].url : ''"
                        />

                        <div class="wrap-icon-bookmark-share">
                          <bookmark-component
                            class="icon-bookmark"
                            :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                            :item="item.relationship.place"
                            :id="item.relationship.place.id"
                          />

                          <share-component
                            :actionsOpenId="`actions-timeline-rate-place-${item.relationship.place.id}`"
                            :type="constants.TYPE_SHARE.PLACE"
                            :typeId="item.relationship.place.id"
                          />
                        </div>
                      </f7-card>
                    </f7-col>
                    <p class="time">{{ item.createdAt | moment("from", true) }} ago</p>
                    <div
                      class="wrapper-place"
                      v-on:click="toPlaceDetail(item.relationship.place.id, constants.PAGE_CONTENT_TYPE.MAGAZINE_PLACE)"
                    ></div>
                  </f7-row>
                </f7-block>
              </div>
            </div>
          </f7-tab>

          <f7-tab
            :id="`tab-magazine-place-${index}`"
            class="page-content"
            v-for="(item, index) in categories"
            :key="index"
            >
            <div class="page-recs" v-if="!isLoading">
              <place-view :items="places" :pageType="constants.PAGE_CONTENT_TYPE.MAGAZINE_PLACE"/>

                <f7-block v-show="places.length === 0" class="recs-null" no-padding>
                  <h4 v-if="places.length === 0">
                    {{ me.id === profile.id ? 'Your' : profile.nickname }} list needs some love 😥
                    <!-- <img src="@/static/icons/questions.png" alt> -->
                  </h4>
                  <p v-if="places.length === 0 && me.id === profile.id">
                    <span>
                      Rate a place to add to your favs list.
                      <span>Select "All" or a friend for suggestions.</span>
                    </span>
                  </p>
                </f7-block>
            </div>
          </f7-tab>

          <!-- <f7-tab :id="`tab-magazine-photos`" class="page-content">
            <div>
              <ul>

                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
                <li>
                  <img src="@/static/images/passport-default.png">
                </li>
              </ul>
            </div>
          </f7-tab> -->

        </f7-tabs>
        <div class="preloader infinite-scroll-preloader" v-show="hasMoreItems">
          <span class="preloader-inner">
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
          </span>
        </div>
      </f7-block>
    </div>
    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="true"
      :handlePassport="handleScrollPassport"
    />

    <toolbar-social
      v-else
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="true"
      :handlePassport="handleScrollPassport"
    />
  </f7-page>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import Toolbar from "../../components/Toolbar";
import ToolbarSocial from "../../components/ToolbarSocial";
import C from "../../_helpers/constants";
import { getUrlImg, getUrlImgArticle } from "../../utils/cloundinary-image";
import TransitionExpand from "../../components/TransitionExpand.vue";
import SelectAction from "../../components/Select";
import ArticleMagazine from "../../components/ArticleMagazine";
import Rated from "../../components/Rated";
import TimelineTip from "../../components/TimelineTip";
import ArticlePlaceInfo from "../../components/ArticlePlaceInfo";
import HeaderNavbarComponent from "../../components/HeaderNavbar";
import HeaderMagazineDetailComponent from "../../components/HeaderMagazineDetail";
import SortByMagazineComponent from "../../components/SortByMagazine";
import PlaceImageComponent from "../../components/PlaceImage";
import BookmarkComponent from "../../components/Bookmark";
import ShareComponent from "../../components/Share";
import SeeMoreComponent from "../../components/SeeMore";

import EventListView from "../../views/EventList";
import PlaceView from "../../views/PlaceView";
import ArticleView from "../../views/ArticleView";
export default {
  components: {
    Toolbar,
    ToolbarSocial,
    TransitionExpand,
    SelectAction,
    ArticleMagazine,
    Rated,
    ArticlePlaceInfo,
    TimelineTip,
    HeaderNavbarComponent,
    HeaderMagazineDetailComponent,
    SortByMagazineComponent,
    PlaceImageComponent,
    BookmarkComponent,
    ShareComponent,
    SeeMoreComponent,

    PlaceView,
    ArticleView,
    EventListView
  },
  data() {
    return {
      constants: C,
      headingImg:
        "https://i1.wp.com/www.kesaksian.org/wp-content/uploads/2018/06/6809e5b65772abb5a6305a36b5e9c0c82ccc20b5.jpeg?fit=1000%2C669",

      isSorting: false,
      allowInfinite: true,
      hasMoreItems: true,
      nextItem: 0,
      // timelines: [],
      // places: [],
      indexCategories: { index: null, id: null },
      indexSortBy: C.FILTER.FRIEND_RATE,
      itemSortBy: C.FILTER.TEXT_MY_RATE,
      textFriendRating: C.FILTER.TEXT_MY_RATE,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters("bookMark", {
      bookmarkStatus: "bookmark"
    }),
    ...mapGetters("magazine", {
      magazineSelected: "magazineSelected",
      city: "city",
      timelines: "timelines",
      places: "places"
    }),
    ...mapGetters("passport", {
      profile: "profile"
    }),
    ...mapGetters("categories", {
      categories: "data"
    }),
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("currentUrl", {
      currentUrl: "data"
    })
  },

  mounted() {
    this.$store.dispatch("magazine/ACTION_RESET_STATE"); // Reset state in the store
    if (this.currentUrl == C.ROUTES_NAME.PASSPORT_LIST_PAGE) {
      this.loadMore();
    }
    if (this.categories.length == 0) {
      this.fetchCategory();
    }
    if (this.me.id == this.profile.id) {
      this.textFriendRating = C.FILTER.TEXT_MY_RATE;
      this.itemSortBy = C.FILTER.TEXT_MY_RATE;
    } else {
      this.textFriendRating =
        this.profile.nickname +
        `${this.profile.nickname.slice(-1) == "s" ? "'" : "'s"}` +
        " Ratings";
      this.itemSortBy =
        this.profile.nickname +
        `${this.profile.nickname.slice(-1) == "s" ? "'" : "'s"}` +
        " Ratings";
    }
  },
  watch: {
    bookmarkStatus(news) {
      if (news) {
        this.showToastBottom();
      }
    },
    currentUrl(news, old) {
      if (news == C.ROUTES_NAME.PASSPORT_LIST_PAGE) {
        this.loadMore();
        if (this.categories.length == 0) {
          this.fetchCategory();
        }
      }
    }
  },
  methods: {
    // Fetch category
    fetchCategory() {
      this.$store.dispatch("categories/ACTION_FETCH_CATEOGRY");
    },
    showToastBottom() {
      const self = this;
      // Create toast
      if (!self.toastBottom) {
        self.toastBottom = self.$f7.toast.create({
          text: "Saved to plan",
          closeTimeout: 2000
        });
      }
      // Open it
      self.toastBottom.open();
    },
    // Function call api fetch data recs
    actionFetchData() {
      const self = this;
      // params
      const params = {
        cityId: self.magazineSelected.cityId,
        where: {
          userId: self.magazineSelected.userId,
          categoryId: self.indexCategories.id,
          filterType: self.indexSortBy
        },
        page: self.nextItem
      };
      if (self.me.id) {
        // Logined
        this.$store.dispatch("magazine/ACTION_FETCH_DATA", params).then(res => {
          if (
            // Timelines
            self.indexCategories.id == null &&
            res &&
            res.magazine &&
            res.magazine.magazines &&
            res.magazine.magazines.timelines
          ) {
            self.allowInfinite = true;
          } else {
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          }
          if (
            // Places
            self.indexCategories.id &&
            res &&
            res.magazine &&
            res.magazine.magazines &&
            res.magazine.magazines.places
          ) {
            self.allowInfinite = true;
          } else {
            // When data null
            self.allowInfinite = false; // Set status allow Infinite
            self.hasMoreItems = false; // Remove icon load more
          }
          self.isLoading = false
        });
      } else {
        // Not auth
        this.$store
          .dispatch("magazine/ACTION_FETCH_DATA_SHARE", params)
          .then(res => {
            if (
              // Timelines
              self.indexCategories.id == null &&
              res &&
              res.magazine &&
              res.magazine.magazines &&
              res.magazine.magazines.timelines
            ) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false; // Set status allow Infinite
              self.hasMoreItems = false; // Remove icon load more
            }
            if (
              // Places
              self.indexCategories.id &&
              res &&
              res.magazine &&
              res.magazine.magazines &&
              res.magazine.magazines.places
            ) {
              self.allowInfinite = true;
            } else {
              // When data null
              self.allowInfinite = false; // Set status allow Infinite
              self.hasMoreItems = false; // Remove icon load more
            }
          });
      }
    },

    loadMore() {
      const self = this;
      if (!self.allowInfinite) return; // Return (not call api)
      self.allowInfinite = false; // Set status allow Infinite
      self.nextItem++;
      this.actionFetchData();
    },

    photoBrowserPosted(photo) {
      return photo;
    },
    photoBrowserAdded(photo) {
      return photo;
    },
    onPhotoBrowserAdded(key) {
      // this.$refs.photoBrowserAdded[key].open();
      return null;
    },
    photoBrowser(photo) {
      return photo;
    },
    onPhotoBrowser(key) {
      // this.$refs.photoBrowser[key].open();
      return null;
    },

    // To post detail
    toPostDetail(id, pageType) {
      const params = {
        id: id,
        pageType: pageType
      };
      this.$store.dispatch("detailPost/ACTION_GET", params);
    },

    geBackgroundCity(name) {
      return getUrlImg(name);
    },
    handleImgArticle(photo, index) {
      return getUrlImgArticle(photo, index);
    },
    handleImgPlace(name) {
      return getUrlImg(name);
    },

    handleScrollPassport() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    // See more place
    togglePlacePlan(index) {
      const self = this;
      self.timelines[index].isActivePlacePlan = !self.timelines[index]
        .isActivePlacePlan;
      this.$forceUpdate();
    },

    // See more tip
    toggleTipTimeLine(index) {
      const self = this;
      self.timelines[index].isActiveTipTimeline = !self.timelines[index]
        .isActiveTipTimeline;
      this.$forceUpdate();
    },

    // To detail
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params); // set id place detail
    },

    // Handle Category
    handleCategories(index, id) {
      const self = this;
      self.indexCategories.index = index; // Get index
      self.indexCategories.id = id; // Get index
      self.nextItem = 0;
      self.hasMoreItems = true; // Remove icon load more
      self.allowInfinite = true; // Set status allow Infinite
      self.isLoading = true;
      this.$store.dispatch("magazine/ACTION_RESET_STATE"); // Reset state in the store recs
      this.loadMore(); // Load more

      // self.nextItem = 1; // Set page 1
      // self.hasMoreItems = true; // Remove icon load more
      // self.allowInfinite = true; // Set status allow Infinite
      // self.isLoading = true;
      // this.$store.dispatch("recs/ACTION_RESET_STATE"); // Reset state in the store recs
      // this.actionFetchData(); // Call api fetch data recs
    },

    // Handle Sort by
    handleSortBy(type, item) {
      const self = this;
      self.itemSortBy = item; // Get text
      self.indexSortBy = type; // Get type
      self.isSorting = !this.isSorting;
      self.allowInfinite = true;
      self.nextItem = 0;

      self.hasMoreItems = true; // Remove icon load more
      self.allowInfinite = true; // Set status allow Infinite
      self.isLoading = true;
      this.$store.dispatch("magazine/ACTION_RESET_STATE"); // Reset state in the store recs
      if (type == C.FILTER.DISTANCE) {
        this.geolocation();
      }
      this.loadMore(); // Load more
    },

    // Status sort by
    toggleSorting() {
      this.isSorting = !this.isSorting;
    },

    // Get location
    geolocation: function() {
      const self = this;
      const options = {
        timeout: 1000,
        maximumAge: 10000,
        enableHighAccuracy: true
      };
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            //do succes handling
            if (position.coords.latitude && position.coords.longitude) {
              localStorage.setItem(
                "currentLatLocation",
                position.coords.latitude
              );
              localStorage.setItem(
                "currentLngLocation",
                position.coords.longitude
              );
            }
            // self.cityLocation = {
            //   lat: self.city.location.lat,
            //   lng: self.city.location.lng
            // };
          },
          function errorCallback(error) {
            self.$f7.dialog.alert(error.message, "Geolocation Error");
            //do error handling
            // self.cityLocation = {
            //   lat: self.city.location.lat,
            //   lng: self.city.location.lng
            // };
          },
          options
        );
      } else {
        self.$f7.dialog.alert(
          "Geolocation is not available",
          "Geolocation Error"
        );
      }
    }
  }
};
</script>
