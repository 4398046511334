<template>
  <f7-page class="page-home" name="home">
    <f7-toolbar position="top">
      <f7-nav-left>
        <f7-link back>
          <img src="@/static/icons/back.svg" alt="Back">
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>
        <h2 class="logo">
          <img src="@/static/icons/favs.png" alt="Favs">
        </h2>
      </f7-nav-title>
      <f7-link>
        <div class="navbar-right">

        </div>
      </f7-link>
    </f7-toolbar>
    <f7-block>
      <div class="main-title">Explore cities</div>
      <div class="sub-title">Select a city to view the feed.</div>
    </f7-block>

    <!-- will remove later-->
    <f7-block v-for="(country, key) in countryList" :key="key" class="cities-list city-block">
      <div class="block-header" medium>{{ country.name }}</div>
      <f7-swiper :params="{speed:500, slidesPerView: 2, spaceBetween: 10}">
        <f7-swiper-slide
          v-for="city in country.cities"
          :style="`background-image:url(`+getImageCity(city && city.cover && city.cover.url)+`)`"
          :key="city.id"
        >
          <city-info-component
            :published="city.published"
            :name="city.name"
            :friendsCount="city.friendsCount"
            text="friends visited"
            :onHandle="() => onHandle(country.id, city.id)"
          />
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>
    <!-- will remove later-->
  </f7-page>
</template>

<script>
import C from "../_helpers/constants";
import { getUrlImg } from "../utils/cloundinary-image";
import CityInfoComponent from "../components/CityInfo";
export default {
  components: {
    CityInfoComponent
  },
  data() {
    return {
      constants: C,
      countryList: []
    };
  },
  beforeMount() {
    this.$store.dispatch("cities/ACTION_FETCH_CITIES").then(res => {
      this.countryList = this.$store.state.cities.data;
    })
  },

  methods: {
    getImageCity(photos) {
      return getUrlImg(photos);
    },

    // Redirect to current position page
    onHandle(idCountry, idCity) {
      if (idCountry || idCity) {
        const cities = {
          idCountry: idCountry,
          countryList: this.countryList,
          idCity: idCity
        };
        this.$store.dispatch("social/ACTION_REDIRECT_PAGE", {
          status: true,
          redirect: C.REDIRECT_PAGE.FEEDS
        });
        const currentPage = localStorage.getItem("currentPage");
        this.$store.dispatch("cities/ACTION_GET_COUNTRY_SELECT", cities);
        if (!localStorage.getItem('skipTutorial')) {
          this.$f7.welcomescreen.open()
        }
        if (currentPage == C.REDIRECT_PAGE.FEEDS) {
          this.$f7router.navigate(C.ROUTER_NAVIGATE.FEEDS);
          this.$store.dispatch(
            "currentUrl/ACTION_GET_CURRENT_URL",
            C.ROUTES_NAME.FEED_PAGE
          );
        } else if (currentPage == C.REDIRECT_PAGE.RECS) {
          this.$f7router.navigate(C.ROUTER_NAVIGATE.RECS);
          this.$store.dispatch(
            "currentUrl/ACTION_GET_CURRENT_URL",
            C.ROUTES_NAME.RECS_PAGE
          );
        } else if (currentPage == C.REDIRECT_PAGE.SEARCH) {
          this.$f7router.navigate(C.ROUTER_NAVIGATE.SEARCH);
          this.$store.dispatch(
            "currentUrl/ACTION_GET_CURRENT_URL",
            C.ROUTES_NAME.SEARCH_PAGE
          );
        } else {
          this.$f7router.navigate(C.ROUTER_NAVIGATE.FEEDS);
          this.$store.dispatch(
            "currentUrl/ACTION_GET_CURRENT_URL",
            C.ROUTES_NAME.FEED_PAGE
          );
        }
      }
    }
  }
};
</script>
