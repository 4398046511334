import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';

function initialState() {
  return {
    data: [],
    url_search_params: {},
  }
}


export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => state.data,
    url_search_params: state => state.url_search_params
  },

  actions: {
    ACTION_GET_COLLECTIONS({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST');
        gql
          .mutate({
            mutation: gqlQuery.collections
          })
          .then(res => {
            commit('STATUS_SUCCESS');
            commit('MUTATION_GET_COLLECTIONS', res.data.collections);
            resolve(res.data.collections);
          })
          .catch(error => {
            commit('STATUS_ERROR', error);
            resolve(error);
          });
      });
    },

    ACTION_GET_URL_SEARCH_PARAMS({
      commit
    }, params) {
      if (params) {
        commit('MUTATIONS_GET_URL_SEARCH_PARAMS', params);
      }
    },

  },

  mutations: {

    STATUS_REQUEST(state) {
      state.data.status = 'loading'
    },
    STATUS_SUCCESS(state) {
      state.data.status = 'success'
    },
    STATUS_ERROR(state, error) {
      state.data.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },
    MUTATION_GET_COLLECTIONS(state, items) {
      state.data = items;
    },
    MUTATIONS_GET_URL_SEARCH_PARAMS(state, items) {
      state.url_search_params = items
    }
  }
}
