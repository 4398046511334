<template>
  <div>
    <f7-block-title class="sort-by-menu" @click.native="toggleSorting">
      Sort by:
      <span class="text-value">{{textSortBy}}</span>
      <span class="icon-chevron">
        <f7-icon f7="chevron_up" :class="{'active': isSorting}"></f7-icon>
      </span>
    </f7-block-title>
    <transition>
      <f7-list class="sort-list" v-show="isSorting">
        <f7-list-group>
          <f7-list-item
            :class="indexSortBy == constants.FILTER.FRIEND_RATE ? 'active' : ''"
            :title="textFriendRating"
            @click="handleSortBy(constants.FILTER.FRIEND_RATE, textFriendRating)"
          ></f7-list-item>
          <f7-list-item
            :class="indexSortBy == constants.FILTER.PUBLIC_RATE ? 'active' : ''"
            :title="constants.FILTER.TEXT_PUBLIC_RATE"
            @click="handleSortBy(constants.FILTER.PUBLIC_RATE, constants.FILTER.TEXT_PUBLIC_RATE)"
          ></f7-list-item>
          <f7-list-item
            :class="indexSortBy == constants.FILTER.DISTANCE ? 'active' : ''"
            :title="constants.FILTER.TEXT_DISTANCE"
            @click="handleSortBy(constants.FILTER.DISTANCE, constants.FILTER.TEXT_DISTANCE)"
          ></f7-list-item>
          <f7-list-item
            v-show="created"
            :class="indexSortBy == constants.FILTER.CREATED_ASC ? 'active' : ''"
            :title="constants.FILTER.TEXT_CREATED_ASC"
            @click="handleSortBy(constants.FILTER.CREATED_ASC, constants.FILTER.TEXT_CREATED_ASC)"
          ></f7-list-item>
          <f7-list-item
            v-show="created"
            :class="indexSortBy == constants.FILTER.CREATED_DESC ? 'active' : ''"
            :title="constants.FILTER.TEXT_CREATED_DESC"
            @click="handleSortBy(constants.FILTER.CREATED_DESC, constants.FILTER.TEXT_CREATED_DESC)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </transition>
  </div>
</template>
<script>
import C from "../_helpers/constants";
export default {
  props: {
    textSortBy: {
      required: false,
      type: String,
      default: ""
    },
    textFriendRating: {
      required: false,
      type: String,
      default: ""
    },
    indexSortBy: {
      required: false,
      type: String,
      default: ""
    },
    isSorting: {
      required: false,
      type: Boolean,
      default: false
    },
    created: {
      required: false,
      type: Boolean,
      default: false
    },

    toggleSorting: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    handleSortBy: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  data() {
    return {
      constants: C
      // indexSortBy: C.FILTER.FRIEND_RATE
    };
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>

