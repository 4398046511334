<template>
  <f7-button :style="active ? { backgroundColor: `${color}`} : ''"  :class="`topic-btn ${active ? 'active' : ''}`" @click="()=> onHandle()">{{name}}</f7-button>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean
    },
    color: {
      required: true,
      type: String
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    name: {
      required: false,
      type: String
    }
  },
  methods: {
    onHandleDefault() {
      return null;
    }
  }
};
</script>
