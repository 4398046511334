<template>
  <div>
    <div id="map" style="height:0px"></div>
    <div class="item-content item-input location-search">
      <div class="item-inner">
        <div class="item-title item-label">
          {{title}}
          <span>(S)</span>*
        </div>
        <div class="item-input-wrap">
          <input
            type="text"
            class="search-location"
            placeholder="Search locations"
            @input="handleLocation"
            v-model="keyWord"
          >
          <span class="input-clear-button"></span>
        </div>
        <ul class="media-list" v-if="isShow">
          <li v-for="(item, key) in items" :key="key">
            <span class="wrap-name-city" slot="text" @click="onHandle(item), handleSelect()">
              <i class="f7-icons">placemark_fill</i>
              <span class="name">{{item.name}}</span>
              <span class="city">{{ item.vicinity}}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="list-chip">
      <div class="chip-place" v-for="(item, key) in places" :key="key">
        <span class="text">
          {{item.name}}
          <span class="chip-delete link" @click="onDelete(key)"></span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import $Scriptjs from "scriptjs";
import { eventBus } from "../js/event-bus.js";
import C from "../_helpers/constants"

export default {
  props: {
    title: {
      required: false,
      type: String,
      default: "Add Location"
    },
    places: {
      required: false,
      type: Array,
      default: []
    },
    onDelete: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    },
    onHandle: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  data() {
    return {
      items: [],
      keyWord: "",
      currentLocation: { lat: 0, lng: 0 },
      cityLocation: { lat: 0, lng: 0 },
      geolocationAvailable: true,
      getCurrentPosition: true,

      isShow: false
    };
  },

  computed: {
    ...mapGetters("cities", {
      cities: "published",
      city: "currentCity"
    })
  },
  mounted: function() {
    $Scriptjs(
      `https://maps.googleapis.com/maps/api/js?key=${C.API_KEYS.GOOGLE_MAPS}&libraries=places`,
      () => {
        this.initMap();
      }
    );
    this.geolocation();

    eventBus.$on("launchKeywordSearchPlacesPost", payload => {
      this.doSearchPlacePost(payload);
    });

  },
  methods: {
    handleSelect() {
      this.isShow = false;
    },
    onHandleDefault() {
      return null;
    },
    initMap() {
      const self = this;
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: {
          lat: self.currentLocation.lat,
          lng: self.currentLocation.lng
        },
        zoom: 15,
        gestureHandling: "greedy"
      });
      let location = new google.maps.LatLng(
        self.currentLocation.lat,
        self.currentLocation.lng
      );
      if (!this.marker) {
        this.marker = new google.maps.Marker({
          position: location,
          map: this.map
        });
      } else {
        this.marker.setPosition(location);
      }
      google.maps.event.addListener(this.map, "click", event => {
        this.lastLat = event.latLng.lat();
        this.lastLng = event.latLng.lng();
        this.updateMarker(event.latLng);
        let geocoder = new google.maps.Geocoder();
        // stripped down example - needs error checking - we will fill this in
        geocoder.geocode({ location: event.latLng }, (results, status) => {
          // showing how you would grab the first address...
          const firstAddress = results[0].formatted_address;
          eventBus.$emit("mapAddress", results);
        });
      });
    },
    geolocation: function() {
      const self = this;
      if ("geolocation" in navigator) {
        self.geolocationAvailable = true;
        navigator.geolocation.getCurrentPosition(
          function(position) {
            //do succes handling
            self.getCurrentPosition = true;
            if (position.coords.latitude && position.coords.longitude) {
              self.currentLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
            }
            return (self.cityLocation = {
              lat: self.city.location.lat,
              lng: self.city.location.lng
            });
          },
          function errorCallback(error) {
            self.getCurrentPosition = false;
            //do error handling
            self.cityLocation = {
              lat: self.city.location.lat,
              lng: self.city.location.lng
            };
          },
          {
            maximumAge: Infinity,
            timeout: 5000
          }
        );
      } else {
        /* geolocation IS NOT available */
        self.geolocationAvailable = false;
      }
    },
    doSearchPlacePost(keyword) {
      // one could expand here to search other services besides Google...
      if (keyword) {
        this.googlePlacesSearchPost(keyword);
        // this.apiPeopleSearch(keyword);
      }
    },
    googlePlacesSearchPost(keyword) {
      const self = this;
      let params;
      if (self.geolocationAvailable && self.getCurrentPosition) {
        // Location current
        params = {
          location: {
            lat: self.currentLocation.lat,
            lng: self.currentLocation.lng
          },
          radius: 1000,
          keyword: keyword
        };
      } else if (self.geolocationAvailable && !self.getCurrentPosition) {
        // Location city
        params = {
          location: {
            lat: self.cityLocation.lat,
            lng: self.cityLocation.lng
          },
          radius: 15000,
          keyword: keyword
        };
      } else if (!self.geolocationAvailable && !self.getCurrentPosition) {
        // Browser Not Available - User location city
        params = {
          location: {
            lat: self.cityLocation.lat,
            lng: self.cityLocation.lng
          },
          radius: 15000,
          keyword: keyword
        };
        self.$f7.dialog.alert("No location available");
      }
      this.doSearchPost(params);
    },
    doSearchPost(params) {
      let _this = this;
      const map = new google.maps.Map(document.getElementById("map"));
      const placesService = new google.maps.places.PlacesService(map);
      placesService.nearbySearch(params, async function(
        results,
        PlacesServiceStatus,
        PlaceSearchPagination
      ) {
        if (PlacesServiceStatus !== "OK") return;

        _this.searchResultMarkersPost(results);
      });
    },
    searchResultMarkersPost(results) {
      let self = this;
      results.map(place => {
        let image = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(15, 15)
        };

        let marker = new google.maps.Marker({
          map: this.map,
          icon: image,
          title: place.name,
          position: place.geometry.location
        });
      });
      return (self.items = results);
    },
    launchPlacesSearch() {
      eventBus.$emit("launchKeywordSearchPlacesPost", this.keyWord);
    },
    handleLocation() {
      this.isShow = true;
      setTimeout(() => {
        this.launchPlacesSearch();
      }, 300);
    }
  }
};
</script>

