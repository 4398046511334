<template>
  <f7-page id="page-notification" name="notification">
    <header-navbar title="Search"/>

    <f7-block class="vendor-list list-notification" no-padding>
      <f7-row v-for="(item, key) in notification" :key="key">
        <f7-col width="85">
          <f7-list media-list no-hairlines>
            <f7-list-item :title="`${item.users[0].nickname} ${item.actionText}`">
              <span slot="text">{{ item.ago }}</span>
              <img
                slot="media"
                v-if="item.users[0].avatar && item.users[0].avatar.url"
                :src="item.users[0].avatar.url"
              >
              <span v-else slot="media" class="text">{{item.users[0].nickname.charAt(0)}}</span>

              <!-- <img slot="media" src="https://randomuser.me/api/portraits/men/40.jpg"> -->
            </f7-list-item>
            <span class="icon-status" v-if="item.status == 'PENDING'"></span>
          </f7-list>
        </f7-col>
        <f7-col width="15">
          <img
            v-if="item.type === 'FRIEND_LIKE' && item.post"
            :src="item.post.url"
            width="40"
            height="40"
            >
        </f7-col>
      </f7-row>
      <!-- <f7-row>
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Hum Vegetarian" text="120 Followers . 160 recs">
              <img slot="media" src="https://randomuser.me/api/portraits/men/40.jpg">
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30">
          <f7-button raised @click="skip = false" class="friends">FRIENDS</f7-button>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Hum Vegetarian" text="120 Followers . 160 recs">
              <img slot="media" src="https://randomuser.me/api/portraits/men/40.jpg">
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30">
          <f7-button raised @click="skip = false" class="confirm">CONFIRM</f7-button>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="70">
          <f7-list media-list no-hairlines>
            <f7-list-item title="Hum Vegetarian" text="120 Followers . 160 recs">
              <img slot="media" src="https://randomuser.me/api/portraits/men/40.jpg">
            </f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="30" class="right-img">
          <img src="https://randomuser.me/api/portraits/men/40.jpg">
        </f7-col>
      </f7-row>-->
    </f7-block>

    <!-- <f7-block class="noti-block">
      <f7-list media-list>

        <f7-list-item class="fresh">
          <f7-row no-gap>
            <span class="fresh-noti"></span>
            <f7-col class="col-left">
              <div class="img-slots">
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
              </div>
            </f7-col>
            <f7-col class="col-right">
              <div class="noti-info with-actions">
                <p class="noti-title">Cecilia Welsh requests to follow you</p>
                <p class="noti-subtitle"> 2 hours ago</p>
              </div>
              <div class="noti-actions">
                <f7-button raised>Confirm</f7-button>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <f7-row no-gap>
            <f7-col class="col-left">
              <div class="img-slots multiple-imgs">
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
              </div>
            </f7-col>
            <f7-col class="col-right">
              <div class="noti-info with-actions">
                <p class="noti-title">Cecilia Welsh requests to follow you</p>
                <p class="noti-subtitle"> 2 hours ago</p>
              </div>
              <div class="noti-actions">
                <div class="img-preview">
                  <img src="https://randomuser.me/api/portraits/men/40.jpg">
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <f7-row no-gap>
            <f7-col class="col-left">
              <div class="img-slots">
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
              </div>
            </f7-col>
            <f7-col class="col-right">
              <div class="noti-info with-actions">
                <p class="noti-title">Cecilia Welsh requests to follow you</p>
                <p class="noti-subtitle"> 2 hours ago</p>
              </div>
              <div class="noti-actions">
                <f7-button raised>Confirm</f7-button>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <f7-row no-gap>
            <f7-col class="col-left">
              <div class="img-slots multiple-imgs">
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
              </div>
            </f7-col>
            <f7-col class="col-right">
              <div class="noti-info with-actions">
                <p class="noti-title">Genevieve Bell, Calvin Wolfe, and 121 others liked your post.</p>
                <p class="noti-subtitle"> 2 hours ago</p>
              </div>
              <div class="noti-actions">
                <div class="img-preview">
                  <img src="https://randomuser.me/api/portraits/men/40.jpg">
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <f7-row no-gap>
            <f7-col class="col-left">
              <div class="img-slots">
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
              </div>
            </f7-col>
            <f7-col class="col-right">
              <div class="noti-info with-actions">
                <p class="noti-title">Cecilia Welsh requests to follow you</p>
                <p class="noti-subtitle"> 2 hours ago</p>
              </div>
              <div class="noti-actions">
                <f7-button raised>Follow Back</f7-button>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <f7-row no-gap>
            <f7-col class="col-left">
              <div class="img-slots multiple-imgs">
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
                <div class="img-wrap">
                  <img src="https://cdn.framework7.io/placeholder/fashion-88x88-6.jpg">
                </div>
              </div>
            </f7-col>
            <f7-col class="col-right">
              <div class="noti-info with-actions">
                <p class="noti-title">Genevieve Bell, Calvin Wolfe, and 121 others liked your post.</p>
                <p class="noti-subtitle"> 2 hours ago</p>
              </div>
              <div class="noti-actions">
                <div class="img-preview">
                  <img src="https://randomuser.me/api/portraits/men/40.jpg">
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>
      </f7-list>
    </f7-block>-->
    <toolbar
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />
  </f7-page>
</template>
<script>
import HeaderNavbar from "../components/HeaderNavbar";
import Toolbar from "../components/Toolbar";
import C from "../_helpers/constants";
export default {
  components: { HeaderNavbar, Toolbar },
  data() {
    return {
      constants: C,
      notification: []
    };
  },

  methods: {
    fetchData() {
      const self = this;
      this.$store.dispatch("notification/ACTION_GET_NOTIFICATION").then(( latestNotifications) => {
        self.notification = latestNotifications
      })
    },
    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return null;
      }
      // return null;
    },
    onConfirm(id) {
      const self = this;
      if (id) {
        const params = {
          id: id,
          status: "ACCEPTED"
        };
        this.$store
          .dispatch("notification/ACTION_CONFIRM_NOTIFICATION", params)
          .then(res => {
            if (res.requestFriend.id) {
              this.fetchData()
            }
          });
      }
    }
  },
  beforeMount() {
    this.fetchData();
  }
};
</script>
