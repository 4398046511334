<template>
  <f7-page id="page-collection" name="collection" hide-bars-on-scroll>
    <header-navbar title="Collection"/>

    <f7-block class="card-simple">
      <f7-card v-for="(item, key) in items" :key="key" :padding="false" :outline="true">
        <f7-card-content :padding="false">
          <div @click="toDetailCollection(item.id)" class="wrapper-content">
            <img
              :src="getUrlImg(item.cover != null ? item.cover : null)"
              :alt="item.cover != null ? item.cover : null"
            >
            <div class="overlay">
              <div class="caption">
                <div class="title">{{item.name}}</div>
                <div class="sub">{{item.statsPlace + item.statsPost}} idea{{(item.statsPlace + item.statsPost != 1 ? 's' : null)}} </div>
              </div>
            </div>
          </div>
        </f7-card-content>
        <f7-card-footer>
          <share-component
            :actionsOpenId="`actions-colleciton-${item.id}`"
            :type="constants.TYPE_SHARE.COLLECTION"
            :typeId="item.id"
            :userId="me.id"
          />
        </f7-card-footer>
      </f7-card>
    </f7-block>

    <f7-block>
      <f7-button outline color="white" large class="text-color-red-bold">New plan</f7-button>
    </f7-block>

    <toolbar
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="true"
      :current_passport="false"
      :handleCollection="handleScrollCollection"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { getUrlImg } from "../../utils/cloundinary-image";
import HeaderNavbar from "../../components/HeaderNavbar";
import Toolbar from "../../components/Toolbar";
import C from "../../_helpers/constants";
import ShareComponent from "../../components/Share";
export default {
  components: { HeaderNavbar, Toolbar, ShareComponent },
  name: "collection",
  data() {
    return {
      constants: C,
      items: []
    };
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    })
  },
  mounted() {
    // Call api collection
    this.$store.dispatch("collection/ACTION_GET_COLLECTIONS").then(res => {
      this.items = res;
    });
  },
  methods: {
    // Scroll to top
    handleScrollCollection() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Get Image
    getUrlImg(photos) {
      return getUrlImg(photos);
    },

    // Redirect to list place
    toDetailCollection(id) {
      const self = this;
      // this.$store.dispatch("collectionDetailPlace/ACTION_RESET_STATE");
      // Params
      const paramsUrl = {
        uid: self.me.id,
        cid: id
      };
      // Call api
      this.$store.dispatch(
        "collection/ACTION_GET_URL_SEARCH_PARAMS",
        paramsUrl
      );
      // Router
      self.$f7router.navigate({
        name: C.ROUTES_NAME.COLLECTION_DETAIL_PLACE,
        params: { id: self.me.id }
      });
    }
  }
};
</script>

<style scoped>
</style>
