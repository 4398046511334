<template>
  <div>
    <f7-block-title class="bearer">
      HOURS •
      <span class="open">OPEN</span>
    </f7-block-title>
    <div class="data-table">
      <table>
        <tr>
          <td>Monday:</td>
          <td class="right">17:00 - 22:00</td>
        </tr>
        <tr>
          <td>Tuesday:</td>
          <td class="right">17:00 - 22:00</td>
        </tr>
      </table>
    </div>
    <p class="time-toggle">See less</p>
  </div>
</template>
<script>
export default {
  components: {},

  props: {}
};
</script>
