import gql from "../../utils/apollo-client";
import gqlQuery from "../../_gql/query/index.js";
import _ from "lodash";
import C from "../../_helpers/constants";

export default {
  namespaced: true,
  state: {
    event: {},
  },

  getters: {
    event: state => state.event,
  },

  actions: {
    ACTION_GET({
      commit
    }, params) {
      const id = params.id;
      return new Promise((resolve, reject) => {
        commit("STATUS_REQUEST");
        gql
          .mutate({
            variables: {
              id: id
            },
            mutation: gqlQuery.eventDetail
          })
          .then(res => {
            commit("STATUS_SUCCESS");
            commit("MUTATION_GET", res.data.eventDetail);
            resolve(res.data.eventDetail);
            this._vm.$f7.views.main.router.navigate({
              name: C.ROUTES_NAME.EVENT_DETAIL,
              params: {
                id: id
              }
            });
            commit('currentUrl/MUTATIONS_GET_CURRENT_URL', C.ROUTES_NAME.EVENT_PAGE, {
              root: true
            })
          })
          .catch(error => {
            commit("STATUS_ERROR", error);
            resolve(error);
          });
      });
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = "loading";
    },
    STATUS_SUCCESS(state) {
      state.status = "success";
    },
    STATUS_ERROR(state, error) {
      state.status = "error";
      this.dispatch("status/ACTION_ERROR", { error });
    },
    MUTATION_RELOAD_PAGE(state) {
      state.reload = true;
    },
    MUTATION_GET(state, event) {
      state.event = event;
    },
  }
};
