<template>
  <f7-list-item :text="tip.content" @click="()=> toPlaceDetail(placeId, pageType)">
    <span v-if="me.id == tip.author.id" class="title-tip" slot="title">Me</span>
    <span
      class="title-tip"
      slot="title"
      v-else
      @click="()=> onAuthProfile()"
    >{{`${tip.author && tip.author.nickname ? tip.author.nickname : ''}`}}</span>
    <img
      @click="()=> onAuthProfile()"
      v-if="tip.author && tip.author.avatar != null"
      slot="media"
      :src="getUrlImg"
    />
    <span
      v-else
      slot="media"
      class="text no-avatar"
      @click="()=> onAuthProfile()"
    >{{tip.author && tip.author.nickname.charAt(0)}}</span>
    <span class="time">{{ tip.createdAt | moment("from", true) }} ago</span>

    <f7-link class="like-comment">
      <span>
        <img
          v-if="tip && tip.rate && tip.rate.action == constants.RATE_STARTS.FAVS"
          src="@/static/icons/rating_3_active.png"
          alt
        />
        <img
          v-if="tip && tip.rate && tip.rate.action == constants.RATE_STARTS.LIKE"
          src="@/static/icons/rating_2_active.png"
          alt
        />
        <img
          v-if="tip && tip.rate && tip.rate.action == constants.RATE_STARTS.OK"
          src="@/static/icons/rating_1_active.png"
          alt
        />
      </span>
    </f7-link>
  </f7-list-item>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  props: {
    tip: {
      required: true,
      type: Object
    },
    placeId: {
      required: true,
      type: String
    },
    getUrlImg: {
      required: true,
      type: String
    },
    pageType: {
      required: true,
      type: String
    },
    constants: {
      required: true,
      type: Object
    },
    onAuthProfile: {
      required: false,
      type: Function,
      default() {
        return () => this.onHandleDefault();
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      me: "me"
    })
  },
  methods: {
    onHandleDefault() {
      return null;
    },
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params); // set id place detail
    }
  }
};
</script>

