import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [] // no types provided
    }
  }
});
const cache = new InMemoryCache({
  fragmentMatcher
});

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV == "production"
      ? "https://api.myfavs.app"
      : process.env.NODE_ENV == "development"
      ? "http://localhost:4000"
      : "https://api-staging.myfavs.app"
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem("token") || null,
      location: `${localStorage.getItem("currentLngLocation")},${localStorage.getItem(
        "currentLatLocation"
      )}`,
      cityId: `${localStorage.getItem("cityId")}`
    }
  });
  return forward(operation);
});

// use with apollo-client
const link = middlewareLink.concat(httpLink);

const apolloClient = new ApolloClient({
  link: link,
  connectToDevTools: true, // TODO: remove this before launch
  cache,
  onError: e => {
    console.log("apollo Client Error", e);
  }
});

export default apolloClient;
