import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import { gaSetUser } from "../../utils/gaHelpers"

export default {
  namespaced: true,
  state: {
    data: [],
    currentLocation: {},
    refetchMe: false
  },

  getters: {
    data: state => state.data,
    refetchMe: state => state.refetchMe,
    currentLocation: state => state.currentLocation
  },

  actions: {
    ACTION_GET_ME({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.me
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_GET_ME', res.data.me)
            gaSetUser(res.data.me.id)
            resolve(res.data.me)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
    ACTION_REFETCH_ME({
      commit
    }) {
      commit('MUTATION_REFETCH_ME');
    }
  },


  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state = Object.assign(state, { status: 'success', refetchMe: false })
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {error})
    },

    MUTATION_GET_ME(state, data) {
      state.data = data
    },

    MUTATION_REFETCH_ME(state) {
      state.refetchMe = true;
    }
  }
}
