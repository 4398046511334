import gql from '../../utils/apollo-client';
import gqlQuery from '../../_gql/query/index.js';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    data: [],
    status: ''
  },

  getters: {
    data: (state, getters, rootState) => {
      return _.forEach(state.data, function (item, key) {
        if (item && item.events.length > 0) {
          _.forEach(item.events, function (event, placeIdx) {
            event.isActiveBookmark = event.bookmarkStatus; // Add field active isActiveBookmark
          });
        }
      });
    },
    status: state => state.status,
  },

  actions: {
    ACTION_FETCH({
      commit
    }) {

      return new Promise((resolve, reject) => {
        commit('STATUS_REQUEST')
        gql
          .mutate({
            variables: {},
            mutation: gqlQuery.trendingEvents
          })
          .then(res => {
            commit('STATUS_SUCCESS')
            commit('MUTATION_FETCH', res.data.trendingEvents)
            resolve(res.data.trendingEvents)
          })
          .catch(error => {
            commit('STATUS_ERROR', error)
            resolve(error)
          })
      })
    },
  },

  mutations: {
    STATUS_REQUEST(state) {
      state.status = 'loading';
    },
    STATUS_SUCCESS(state) {
      state.status = 'success';
    },
    STATUS_ERROR(state, error) {
      state.status = 'error';
      this.dispatch('status/ACTION_ERROR', {
        error
      })
    },
    MUTATION_FETCH(state, items) {
      state.data = items;
    },
  }
}
