<template>
  <f7-block
    class="wrapper-summary-of-post block-article"
    v-if="stories.length > 0"
  >
    <title-component
      :title="`Top stories in ${this.city.name}`"
      class="no-margin-left block-title block-title-medium"
    />
    <div
    v-for="(story, index) in stories"
    :key="index">
      <f7-row
      :class="`summary-of-post post-${index}`"
      >
        <f7-col width="40">
          <f7-card class="wrapper-content-card">
            <div>
              <f7-photo-browser
                theme="dark"
                :photos="photoBrowserStory(story.photos)"
                ref="photoBrowserStory"
              ></f7-photo-browser>
              <f7-button
                v-if="story.externalSource == null || story.externalSource == 'LINK'"
                raised
                @click="$refs.photoBrowserStory[index].open()"
              ></f7-button>
              <div
                :class="`${story.photos.length <= 1 ? 'grid1Col' : (story.photos.length <= 2 ? 'grid2Col' : 'grid3Col')} wrapper-photo`"
              >
                <iframe
                  v-if="story.externalSource == 'YOUTUBE'"
                  width="100%"
                  height="90"
                  :src="story.externalUrl"
                ></iframe>
                <div
                  v-else
                  v-for="(photo, idx) in story.photos.slice(0, 3)"
                  :key="idx"
                  :class="`no-border card story-main-photo`"
                  valign="bottom"
                  :style="`background-image:url(`+handleImgArticle(photo, idx)+`)`"
                ></div>
              </div>
              <span
                v-if="story.photos.length > 3"
                class="numberPhotos"
              >+{{story.photos.length - 3}}</span>
            </div>
          </f7-card>
        </f7-col>
        <f7-col width="60" class="post-details">
          <f7-row>
            <div class="avatarInSummary">
              <span class="story-index">{{ index + 1 }}</span>
              <img
                slot="media"
                v-if="story.author && story.author.avatar && story.author.avatar.name"
                :src="getAvatarAuthImg(story.author.avatar.name)"
              >
              <span
                @click="actionFetchAuthProfile(story.author.id)"
                slot="media"
                v-else
                class="text"
              >{{story.author && story.author.nickname && story.author.nickname.charAt(0)}}</span>
              <span
                slot="title"
                @click="actionFetchAuthProfile(story.author.id)"
                >{{ story.author && (story.author.nickname || story.author.username) }}</span>
            </div>
          </f7-row>
          <a v-if="story.externalUrl" :href="story.externalUrl" target="_blank" external class="external link">
            <f7-row class="story-title">
              {{story.title}}
            </f7-row>
          </a>
          <f7-row v-else
            class="post-title"
            @click="toPostDetail(story.id, constants.PAGE_CONTENT_TYPE.SUMMARY)">
            {{story.title}}
          </f7-row>
          <div class="story-actions">
            <div class="story-bookmark">
              <bookmark-component
                class="story-icon-bookmark"
                :type="constants.BOOKMARK_CONTENT_TYPE.POST"
                :item="story"
                :id="story.id"
                :isActiveBookmarkStatus="story.bookmarkStatus"
                :pageType="constants.PAGE_CONTENT_TYPE.POST"
              />
              <share-component
                :actionsOpenId="`actions-story-post-${story.id}`"
                :type="constants.TYPE_SHARE.POST"
                :typeId="story.id"
              />
            </div>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </f7-block>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import C from "../_helpers/constants";
import { handleSharePost, handleSharePlace } from "../utils/share";
import { getUrlImg, getUrlImgArticle } from "../utils/cloundinary-image";
import UserInfoComponent from "../components/UserInfo";
import PlaceTitleComponent from "../components/PlaceTitle";
import TitleComponent from "../components/Title";
import StatsCommentComponent from "../components/StatsComment";
import CreatedAtComponent from "../components/CreatedAt";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
export default {
  components: {
    TitleComponent,
    UserInfoComponent,
    PlaceTitleComponent,
    StatsCommentComponent,
    CreatedAtComponent,
    BookmarkComponent,
    ShareComponent,
  },
  data() {
    return {
      constants: C
    };
  },
  beforeMount() {
    // this.fetchstoryFeed(); // Fetch Trendings
  },
  computed: {
    ...mapGetters("auth", {
      data: "data"
    }),
    ...mapGetters("cities", {
      city: "currentCity"
    }),
  },
  props: {
    stories: {
      required: true,
      type: Array
    }
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    handleImg(name) {
      return getUrlImg(name);
    },

    getAvatarAuthImg(name) {
      if (name) {
        let img = name;
        return this.$cl.url(img, { width: 300, crop: "fit" });
      } else {
        return null;
      }
    },

    handleImgArticle(photo, index) {
      return getUrlImgArticle(photo, index);
    },

    toPostDetail(id, pageType) {
      const params = {
        id: id,
        pageType: pageType
      };
      this.$store.dispatch("detailPost/ACTION_GET", params);
    },
    
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params);
    },

    photoBrowserStory(photo) {
      return photo;
    }
  }
};
</script>
