import C from "../_helpers/constants";

export const sharePost = (id) => {
  FB.ui({
    method: "share",
    href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.POST + "/" + id
  });
}

export const sharePlace = (id) => {
  FB.ui({
    method: "share",
    href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.PLACE + "/" + id
  });
}

export const sharePassport = (userId) => {
  FB.ui({
    method: "share",
    href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.PASSPORT + "/" + userId
  });
}

export const shareMagazine = (userId, cityId) => {
  FB.ui({
    method: "share",
    href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.PASSPORT + "/" + userId + "/" + cityId
  });
}

export const shareCollection = (userId, id) => {
  FB.ui({
    method: "share",
    href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.COLLECTIONS + "/" + userId + "/" + id
  });
}

export const shareEvent = (id) => {
  FB.ui({
    method: "share",
    href: C.DOMAIN_SHARE.DOMAIN + "/" + C.TYPE_URL.EVENT + "/" + id
  });
}