import C from "../_helpers/constants"

export const gaSetUser = (userId) => {
  if (process.env.NODE_ENV === 'production') {
    gtag('config', C.API_KEYS.GA_MEASUREMENT_ID, { 'user_id': userId });
  } else {
    console.log(`gaSetUser (not running in ${process.env.NODE_ENV})`, userId)
  }
}

export const gaPageview = (obj) => {
  if (process.env.NODE_ENV === 'production') {
    gtag("config", C.API_KEYS.GA_MEASUREMENT_ID, obj)
  } else {
    console.log(`gaPageview (not running in ${process.env.NODE_ENV})`, obj)
  }
}