<template>
  <div class="block-trending block" v-if="trendings[indexTrending] && trendings[indexTrending].places.length > 0">
    <f7-block class="heading">
      <f7-block-title medium>{{trendings[indexTrending].title}}</f7-block-title>
    </f7-block>
    <f7-swiper :params="{ speed: 500, slidesPerView: 2 }" class="place" ref="trendSwiper">
      <f7-swiper-slide v-for="(place, index) in trendings[indexTrending].places" :key="index">
        <f7-card>
          <place-image-component :url="place.photos.length > 0 ? place.photos[0].url : '' "
            :onHandle="()=>toPlaceDetail(place.id, null)"/>
          <div class="wrap-icon-bookmark-share">
            <bookmark-component
              class="icon-bookmark"
              :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
              :item="place"
              :id="place.id"
            />
            <share-component
              :actionsOpenId="`actions-trending-place-${place.id}`"
              :type="constants.TYPE_SHARE.PLACE"
              :typeId="place.id"
            />
          </div>
        </f7-card>
        <f7-block>
          <place-title-component
            :onHandle="()=>toPlaceDetail(place.id, null)"
            class="main"
            :title="`${ index + 1 }. ${ place.name }`"
          />

          <f7-block-header class="sub" v-if="place.categories.length > 0">
            <span class="category" v-for="(item, key) in place.categories" :key="key">
              <span class="name">{{item.name}}</span>
              <span class="circle">•</span>
            </span>
          </f7-block-header>

          <public-friends-rating-component 
          :item="place" 
          :constants="constants"
          :onHandle="()=>toPlaceDetail(place.id, null)"/>
        </f7-block>
      </f7-swiper-slide>
    </f7-swiper>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";

import C from "../_helpers/constants";
import { sharePost, sharePlace } from "../utils/share";
import { copySuccess, copyError } from "../utils/copy";
import PublicFriendsRatingComponent from "../components/PublicFriendsRating";
import BookmarkComponent from "../components/Bookmark";
import ShareComponent from "../components/Share";
import PlaceImageComponent from "../components/PlaceImage";
import PlaceTitleComponent from "../components/PlaceTitle";
export default {
  components: {
    PublicFriendsRatingComponent,
    BookmarkComponent,
    ShareComponent,
    PlaceImageComponent,
    PlaceTitleComponent
  },
  
  data() {
    return {
      constants: C
    };
  },
  
  props: {
    trendings: {
      required: true,
      type: Array
    },
    indexTrending: {
      required: true,
      type: Number
    }
  },

  methods: {

    handleSharePlace(id) {
      return sharePlace(id);
    },
    onCopySuccess(e) {
      return copySuccess(e, this);
    },
    onCopyError(e) {
      return copyError(e);
    },
   // To detail
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params); // set id place detail
    }
  }
};
</script>

