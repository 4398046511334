import gql from "graphql-tag";

const requestFriend = gql `
mutation requestFriend($data: FriendRequestInput!) {
  requestFriend(data: $data) {
    id
    send {
      id
      friends {
        id
        status
        createdAt
        receive {
          id
          nickname
          avatar {
            url
            name
          }
        }
      }
    }
  }
}
`

export  {
  requestFriend

};
