<template>
  <f7-page
    class="page-feed page-detail-collection-post"
    name="detail-collection-post"
    infinite
    :infinite-preloader="false"
    @infinite="loadMore"
  >
    <header-navbar-action-component
      :onChange="(event)=>handleOnChangePost(event)"
      :actionType="constants.ACTION_TYPE.ACTIONS"
      :actionsOpenName="`actions-share-collection-${city.id}`"
      :id="city.id"
      :userId="me.id"
      :selectedPost="true"
    />

    <f7-block-title class="city-selected" no-padding>{{city.name}}</f7-block-title>

    <sort-by-component
      :handleSortBy="(type, text)=>handleSortBy(type, text)"
      :toggleSorting="()=>toggleSorting()"
      :textSortBy="itemSortBy"
      :isSorting="isSorting"
      :indexSortBy="indexSortBy"
      :textFriendRating="textFriendRating"
    />

    <!-- article with comment-->
    <div class="block-article-list">
      <div
        v-for="(item, key) in items"
        :key="key"
        :class="`${key == 2 ? 'block-article-friend-favs ' : ''}block-article`"
      >
        <article-view
          :onAuthProfile="()=>actionFetchAuthProfile(item.author.id)"
          :nickname="item.author.nickname"
          :getAvatar="getAvatarAuthImg(item.author.avatar ? item.author.avatar.url : '')"
          :actionsOpen="`.actions-article-${item.id}`"
          :title="item.title"
          :onToPostDetail="()=>toPostDetail(item.id, constants.PAGE_CONTENT_TYPE.COLLECTION_POST)"
          :photos="item.photos"
          :onGetUrlImg="(photo, idx) => getUrlImg(photo, idx)"
          :cityName="item.city.name"
          :cityCountryName="item.city.country.name"
          :places="item.places"
          :city="item.city"
          :siteName="item.siteName"
          :siteDescription="item.siteDescription"
          :content="item.content"
          :createdAt="item.createdAt"
          :statsLike="item.statsLike"
          :statsComment="item.statsComment"
          :externalUrl="item.externalUrl"
          :onPhotoBrowser="()=>onPhotoBrowser(key)"
          :type="constants.BOOKMARK_CONTENT_TYPE.POST"
          :item="item"
          :id="item.id"
          :showExternalUrl="false"
          :externalSource="item.externalSource"
          :pageType="constants.PAGE_CONTENT_TYPE.COLLECTION_POST"
          :isActiveBookmarkStatus="item.bookmarkStatus"
        />
        <f7-photo-browser theme="dark" :photos="photoBrowser(item.photos)" ref="photoBrowser"></f7-photo-browser>

        <actions-article-component
          :actionsOpenName="`actions-article-${item.id}`"
          :actionsId="item && item.id ? item.id : null"
          :handleSharePost="()=>handleSharePost(item.id)"
          :onCopySuccess="onCopySuccess"
          :onCopyError="onCopyError"
        />

        <f7-block
          :class="`${index == 2 ? 'border-bottom-none' : ''} vendor-list wrap-places`"
          no-padding
          v-for="(place, index) in item.places.slice(0,3)"
          :key="index"
        >
          <f7-row>
            <f7-col width="40">
              <f7-card>
                <place-image-component :url="place.photos.length > 0 ? place.photos[0].url : ''"/>

                <div class="wrap-icon-bookmark-share">
                  <bookmark-component
                    class="icon-bookmark"
                    :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                    :item="place"
                    :id="place.id"
                    :isActiveBookmarkStatus="place.bookmarkStatus"
                    :pageType="constants.PAGE_CONTENT_TYPE.COLLECTION_POST_PLACE"
                  />
                  <share-component
                    :actionsOpenId="`actions-article-place-${place.id}`"
                    :type="constants.TYPE_SHARE.PLACE"
                    :typeId="place.id"
                  />
                </div>
              </f7-card>
            </f7-col>
            <f7-col width="60">
              <div class="vendor-title">{{index+1}}. {{ place.name | truncate(30, '...') }}</div>

              <place-title-component
                v-if="place.city != null"
                :title="place.city != null ? place.city.name : null"
              />

              <public-friends-rating-component :item="place" :constants="constants"/>
            </f7-col>
            <div
              class="wrapper-place"
              v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.COLLECTION_POST_PLACE)"
            ></div>
          </f7-row>
        </f7-block>

        <transition-expand-component>
          <div v-if="item.isActiveTip" class="wrapper-expanded">
            <f7-block
              class="vendor-list wrap-places"
              no-padding
              v-for="(place, index) in item.places.slice(3)"
              :key="index"
            >
              <f7-row>
                <f7-col width="40">
                  <f7-card>
                    <place-image-component
                      :url="place.photos.length > 0 ? place.photos[0].url : ''"
                    />
                    <div class="wrap-icon-bookmark-share">
                      <bookmark-component
                        class="icon-bookmark"
                        :type="constants.BOOKMARK_CONTENT_TYPE.PLACE"
                        :item="place"
                        :id="place.id"
                        :isActiveBookmarkStatus="place.bookmarkStatus"
                        :pageType="constants.PAGE_CONTENT_TYPE.COLLECTION_POST_PLACE"
                      />
                      <share-component
                        :actionsOpenId="`actions-article-place-${place.id}`"
                        :type="constants.TYPE_SHARE.PLACE"
                        :typeId="place.id"
                      />
                    </div>
                  </f7-card>
                </f7-col>
                <f7-col width="60">
                  <div class="vendor-title">{{index+4}}. {{ place.name | truncate(30, '...') }}</div>

                  <place-title-component
                    v-if="place.city != null"
                    :title="place.city != null ? place.city.name : null"
                  />

                  <public-friends-rating-component :item="place" :constants="constants"/>
                </f7-col>
                <div
                  class="wrapper-place"
                  v-on:click="toPlaceDetail(place.id, constants.PAGE_CONTENT_TYPE.COLLECTION_POST_PLACE)"
                ></div>
              </f7-row>
            </f7-block>
          </div>
        </transition-expand-component>
        <see-more-component
          :item="item"
          :isShow="item.places && item.places.length > 3"
          :isActive="item.isActiveTip"
          :toggleTip="()=>toggleTip(key)"
        />
      </div>
    </div>
    <!-- end article with comment-->

    <toolbar
      v-if="me.id"
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="true"
      :current_passport="false"
      :handleCollection="handleScrollCollection"
    />

    <toolbar-social
      v-else
      :feeds="constants.TOOLBAR.EXPLORE"
      :recs="constants.TOOLBAR.FAVS"
      :post="constants.TOOLBAR.POST"
      :collection="constants.TOOLBAR.PLANS"
      :passport="constants.TOOLBAR.PASSPORT"
      :current_feeds="false"
      :current_recs="false"
      :current_post="false"
      :current_collection="false"
      :current_passport="false"
    />

    <div class="preloader infinite-scroll-preloader" v-show="hasMoreItems"></div>
  </f7-page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { sharePost } from "../../utils/share";
import { copySuccess, copyError } from "../../utils/copy";
import { getUrlImg, getUrlImgArticle } from "../../utils/cloundinary-image";
import C from "../../_helpers/constants";

import Toolbar from "../../components/Toolbar";
import ToolbarSocial from "../../components/ToolbarSocial";
import HeaderNavbarActionComponent from "../../components/HeaderNavbarAction";
import SortByComponent from "../../components/SortBy";
import ArticleView from "../../views/ArticleView";
import ActionsArticleComponent from "../../components/ActionsArticle";
import SeeMoreComponent from "../../components/SeeMore";
import PlaceImageComponent from "../../components/PlaceImage";
import BookmarkComponent from "../../components/Bookmark";
import ShareComponent from "../../components/Share";
import TransitionExpandComponent from "../../components/TransitionExpand";
import PublicFriendsRatingComponent from "../../components/PublicFriendsRating";
import PlaceTitleComponent from "../../components/PlaceTitle";
export default {
  components: {
    Toolbar,
    ToolbarSocial,
    HeaderNavbarActionComponent,
    SortByComponent,
    ArticleView,
    ActionsArticleComponent,
    SeeMoreComponent,
    PlaceImageComponent,
    BookmarkComponent,
    ShareComponent,
    TransitionExpandComponent,
    PublicFriendsRatingComponent,
    PlaceTitleComponent
  },
  data() {
    return {
      constants: C,
      isLoading: false,
      nextItem: 0,
      allowInfinite: true,
      hasMoreItems: true,
      idUrl: null,
      // items: [],
      isSorting: false,
      itemSortBy: C.FILTER.TEXT_MY_RATE,
      indexSortBy: C.FILTER.FRIEND_RATE,
      textFriendRating: C.FILTER.TEXT_MY_RATE
    };
  },
  mounted() {
    this.$store.dispatch("collectionDetailPost/ACTION_RESET_STATE");
    this.loadMore();
  },

  computed: {
    ...mapGetters("auth", {
      me: "me"
    }),
    ...mapGetters("collection", {
      urlSearchParams: "url_search_params"
    }),
    ...mapGetters("collectionDetailPlace", {
      city: "city"
    }),
    ...mapGetters("collectionDetailPost", {
      collectionDetail: "collectionType",
      items: "post"
    })
  },
  methods: {
    ...mapActions("passport", ["actionFetchAuthProfile"]),

    // Scroll to top
    handleScrollCollection() {
      this.$f7
        .$(".page-content")
        .scrollTo(0, $(".page-content").offset().top, 700);
    },

    // Handle share
    handleSharePost(id) {
      return sharePost(id);
    },

    // Show photo
    onPhotoBrowser(key) {
      this.$refs.photoBrowser[key].open();
    },

    // See more
    toggleTip(index) {
      const self = this;
      self.items[index].isActiveTip = !self.items[index].isActiveTip;
      this.$forceUpdate();
    },

    onCopySuccess(e) {
      return copySuccess(e, this);
    },

    onCopyError(e) {
      return copyError(e);
    },

    // Change select
    handleOnChangePost(e) {
      const self = this;
      if (e.target.value == C.DETAIL_COLLECTION_TYPE.PLACE) {
        self.$store.dispatch("collectionDetailPlace/ACTION_RESET_STATE");
        self.$f7router.navigate({
          name: C.ROUTES_NAME.COLLECTION_DETAIL_PLACE,
          params: { id: self.urlSearchParams.uid }
        });
      }
      if (e.target.value == C.DETAIL_COLLECTION_TYPE.EVENT) {
        self.$f7router.navigate({
          name: C.ROUTES_NAME.COLLECTION_DETAIL_EVENT,
          params: { id: self.urlSearchParams.uid }
        });
      }
    },

    // Handle sort by
    handleSortBy(type, item) {
      const self = this;
      self.itemSortBy = item;
      self.indexSortBy = type;
      self.isSorting = false;
      if (self.urlSearchParams.uid) {
        if (type) {
          self.nextItem = 0;
          this.$store.dispatch("collectionDetailPost/ACTION_RESET_STATE");
          if (type == C.FILTER.DISTANCE) {
            this.geolocation();
          }
          this.loadMore();
        }
      }
    },

    // Status show/hide sort by
    toggleSorting() {
      this.isSorting = !this.isSorting;
    },

    // Get data
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      self.nextItem++;
      // Params
      const params = {
        filterType: self.indexSortBy,
        type: C.COLLECTION_TYPE.POST,
        userId: self.urlSearchParams.uid,
        cityId: self.urlSearchParams.cid,
        page: self.nextItem
      };
      if (self.me.id) {
        // If exist auth
        this.$store
          .dispatch(
            "collectionDetailPost/ACTION_GET_DETAIL_COLLECTION_POST",
            params
          )
          .then(res => {
            this.textFriendRating = C.FILTER.TEXT_MY_RATE;
            this.itemSortBy = C.FILTER.TEXT_MY_RATE;
            if (res.posts.length > C.PAGE_SIZE.DEFAULT) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false;
              self.hasMoreItems = false;
            }
          });
      } else {
        // If not auth
        this.$store
          .dispatch(
            "collectionDetailPost/ACTION_GET_DETAIL_COLLECTION_POST_SHARE",
            params
          )
          .then(res => {
            this.textFriendRating =
              res.user &&
              res.user.nickname +
                `${res.user.nickname.slice(-1) == "s" ? "'" : "'s"}` +
                " Ratings";
            this.itemSortBy =
              res.user &&
              res.user.nickname +
                `${res.user.nickname.slice(-1) == "s" ? "'" : "'s"}` +
                " Ratings";
            if (res.posts.length > C.PAGE_SIZE.DEFAULT) {
              self.allowInfinite = true;
            } else {
              self.allowInfinite = false;
              self.hasMoreItems = false;
            }
          });
      }
    },

    getAvatarAuthImg(name) {
      return getUrlImg(name);
    },

    getUrlImg(photo, index) {
      return getUrlImgArticle(photo, index);
    },

    photoBrowser(photo) {
      return photo;
    },

    // To detail
    toPlaceDetail(id, form) {
      const params = {
        id: id,
        form: form
      };
      this.$store.dispatch("placeDetail/ACTION_GET", params); // set id place detail
    },

    // To post detail
    toPostDetail(id, pageType) {
      const params = {
        id: id,
        pageType: pageType
      };
      this.$store.dispatch("detailPost/ACTION_GET", params);
    },

    // Get location
    geolocation: function() {
      const self = this;
      const options = {
        timeout: 1000,
        maximumAge: 10000,
        enableHighAccuracy: true
      };
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            //do succes handling
            if (position.coords.latitude && position.coords.longitude) {
              localStorage.setItem(
                "currentLatLocation",
                position.coords.latitude
              );
              localStorage.setItem(
                "currentLngLocation",
                position.coords.longitude
              );
            }
          },
          function errorCallback(error) {
            self.$f7.dialog.alert(error.message, "Geolocation Error");
          },
          options
        );
      } else {
        self.$f7.dialog.alert(
          "Geolocation is not available",
          "Geolocation Error"
        );
      }
    }
  }
};
</script>
