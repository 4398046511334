<template>
  <span class="icon-share">
    <f7-link raised :actionsOpen="`.${actionsOpenId}`">
      <img src="@/static/icons/share.svg" alt>
    </f7-link>
    <f7-actions :class="`${actionsOpenId}`">
      <f7-actions-group v-if="type == constants.TYPE_SHARE.POST">
        <f7-actions-button color="blue" @click="()=>handleSharePost(typeId)">Share to...</f7-actions-button>
        <f7-actions-button color="blue">
          <f7-link
            v-clipboard:copy="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.POST + '/' + typeId}`"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          >Copy Link</f7-link>
        </f7-actions-button>
        <input
          type="hidden"
          :v-model="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.POST + '/' + typeId}`"
        >
      </f7-actions-group>
      <f7-actions-group v-if="type == constants.TYPE_SHARE.PLACE">
        <f7-actions-button color="blue" @click="()=>handleSharePlace(typeId)">Share to...</f7-actions-button>
        <f7-actions-button color="blue">
          <f7-link
            v-clipboard:copy="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.PLACE + '/' + typeId}`"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          >Copy Link</f7-link>
        </f7-actions-button>
        <input
          type="hidden"
          :v-model="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.PLACE + '/' + typeId}`"
        >
      </f7-actions-group>
      <f7-actions-group v-if="type == constants.TYPE_SHARE.EVENT">
        <f7-actions-button color="blue" @click="()=>handleShareEvent(typeId)">Share to...</f7-actions-button>
        <f7-actions-button color="blue">
          <f7-link
            v-clipboard:copy="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.EVENT + '/' + typeId}`"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          >Copy Link</f7-link>
        </f7-actions-button>
        <input
          type="hidden"
          :v-model="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.EVENT + '/' + typeId}`"
        >
      </f7-actions-group>
      <f7-actions-group v-if="type == constants.TYPE_SHARE.COLLECTION">
        <f7-actions-button
          color="blue"
          @click="()=>handleShareCollection(userId, typeId)"
        >Share to...</f7-actions-button>
        <f7-actions-button color="blue">
          <f7-link
            v-clipboard:copy="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.COLLECTIONS + '/' + userId + '/' + typeId}`"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          >Copy Link</f7-link>
        </f7-actions-button>
        <input
          type="hidden"
          :v-model="`${constants.DOMAIN_SHARE.DOMAIN + '/' + constants.TYPE_URL.COLLECTIONS + '/' + userId + '/' + typeId}`"
        >
      </f7-actions-group>

      <f7-actions-group>
        <f7-actions-button color="blue" bold>Cancel</f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </span>
</template>
<script>
import C from "../_helpers/constants";
import { sharePost, sharePlace, shareCollection, shareEvent } from "../utils/share";
import { copySuccess, copyError } from "../utils/copy";
export default {
  props: {
    actionsOpenId: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    typeId: {
      required: false,
      type: String
    },
    userId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      constants: C
    };
  },
  methods: {
    handleSharePost(id) {
      return sharePost(id);
    },
    handleSharePlace(id) {
      return sharePlace(id);
    },
    handleShareEvent(id) {
      return shareEvent(id);
    },
    handleShareCollection(userId, id) {
      return shareCollection(userId, id);
    },
    onCopySuccess(e) {
      return copySuccess(e, this);
    },
    onCopyError(e) {
      return copyError(e);
    }
  }
};
</script>

